import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Config from '../config/config';
import { getStreams } from '../utils/broadcastsHttp';

import { catchPromise } from '../utils/http';

import Baron from 'react-baron/dist/es5';

export class BroadcastsCoursesPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			openedCourse: null,
			loaded: true,
			pageError: false,
			streams: [],
			pageNumber: 1
		};
	}
	errorCb = () => {
		this.setState({ pageError: true });
	};
	loadStreams = ({ page_num }) => {
		this.setState({ loaded: true });
		getStreams({ page_num: this.state.pageNumber, category: "COURSE" })
			.then(res => {
				this.setState({
					streams: res.data.streams,
					loaded: false,
					pageNumber: page_num
				},
					() => {
						setTimeout(() => {
							const pageScroller = $('.page-content > .clipper > .scroller');
							const pageContentWrap = $('.content-wrapper');
							if (
								pageScroller.scrollTop() + pageScroller.height() >= (pageContentWrap.height() - 200) &&
								!this.state.loaded && this.state.page_num < this.state.page_total
							)
								this.loadStreams(this.state.page_num + 1);
						}, 500)
					});
			}).catch((err) => {
				this.setState({ loaded: false });
				catchPromise(
					err,
					this.loadStreams.bind(this, { page_num }),
					this.errorCb
				);
			})
	}

	scrollContent = () => {
		const pageScroller = $('.page-content > .clipper > .scroller');
		const pageContentWrap = $('.content-wrapper');
		if (
			pageScroller.scrollTop() + pageScroller.height() >= (pageContentWrap.height() - 200) &&
			!this.state.loaded && this.state.page_num < this.state.page_total
		)
			this.loadStreams({ page_num: this.state.pageNumber + 1 });
	};

	componentDidMount() {
		$(".page-content > .clipper > .scroller").scroll(() => {
			this.scrollContent();
		});
		this.loadStreams(1);
	}

	render() {
		return (
			<div className='h-68 d-flex justify-content-center'>
				<div className="broadcastsStreamsWrapper">
					<Baron>
					{
						!this.state.pageError ? (
							<div>
								{!this.state.openedCourse
									? (
										<div className="broadcastsCoursesContainer">
											{
												this.state.streams.map(stream => (
													<div className="broadcastsCourse">
														<div className="broadcastsCourseInfo">
															<div className="broadcastsCourseInfo-text">
																<div className="broadcastsCourseInfo-courseName">{stream.title}</div>
																<div className="broadcastsCourseInfo-leadName">{`${stream.host["last_name"]} ${stream.host["first_name"]}`}</div>
															</div>
															<div className="broadcastsCourseInfo-avatar" style={{ 'background-image': `url("${stream.host.avatar}")` }}></div>
														</div>
														{stream.available
															? (
																<div className="broadcastsCourseBtn" onClick={() => {
																	this.setState({ openedCourse: stream });
																}}>
																	Продолжить обучение
																</div>
															)
															: (
																<a href={stream.buy_url} target="_blank" style={{ width: "100%" }}>
																	<div className="broadcastsCourseBtn">
																		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M12.6667 7.33301H3.33333C2.59695 7.33301 2 7.92996 2 8.66634V13.333C2 14.0694 2.59695 14.6663 3.33333 14.6663H12.6667C13.403 14.6663 14 14.0694 14 13.333V8.66634C14 7.92996 13.403 7.33301 12.6667 7.33301Z" stroke="#2E3C47" stroke-linecap="round" stroke-linejoin="round" />
																			<path d="M4.66699 7.33301V4.66634C4.66699 3.78229 5.01818 2.93444 5.6433 2.30932C6.26842 1.6842 7.11627 1.33301 8.00033 1.33301C8.88438 1.33301 9.73223 1.6842 10.3573 2.30932C10.9825 2.93444 11.3337 3.78229 11.3337 4.66634V7.33301" stroke="#2E3C47" stroke-linecap="round" stroke-linejoin="round" />
																		</svg>
																		<span>Разблокировать</span>
																	</div>
																</a>
															)
														}
													</div>
												))
											}
										</div>
									) : (
										<div className="broadcastsInternContainer">
											<div className="broadcastsIntern-header">
												<div className="broadcastsIntern-return">
													<div className="broadcastsIntern-returnBtn" onClick={() => {
														this.setState({ openedCourse: null });
													}}>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M19 12H5" stroke="#3A4044" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
															<path d="M12 19L5 12L12 5" stroke="#3A4044" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
														</svg>
													</div>
													<span>{this.state.openedCourse.title}</span>
												</div>
											</div>
											<ul className="broadcastsInternList">
												{this.state.openedCourse.lessons.map((lesson, index) => (
													<li>
														<a href={lesson.url} target="_blank">{index + 1}. {lesson.name}</a>
													</li>
												)
												)}
											</ul>
										</div>
									)
								}
								{this.state.loaded && (
									<div className="mt-2 mb-2 text-center spinner-container">
										<span>
											<img
												src="/images/ui/load-spinner.png"
												className="spinner"
												alt=""
											/>
										</span>
									</div>
								)}
							</div>
						) : (
								<h3 className="text-center text-danger">Произошла ошибка</h3>
							)
					}
					</Baron>
				</div>
			</div>
		);
	}
}

export default connect()(BroadcastsCoursesPage);
