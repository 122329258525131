import React from 'react';
import { Link } from 'react-router-dom';

import { catchPromise } from '../../utils/http';
import { uploadFile } from '../../utils/documentsHttp';

export default class DocNeedScan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadedFiles: [
        {
          id: '',
          fileName: '',
          link: '',
          file: {},
          error: '',
          uploading: false,
        },
      ],
      validationError: '',
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
    let uploadedFilesList = this.state.uploadedFiles;
    for (let i = 0; i < this.state.uploadedFiles.length; i++) {
      uploadedFilesList[i].uploading = false;
    }
    this.setState({ uploadFile: uploadedFilesList, pageError: true });
  };
  componentDidMount() {}
  onFileChange = (e, index) => {
    const fileData = e.target.files[0];
    var formData = new FormData();
    formData.append('file', fileData, fileData.name);
    let list = this.state.uploadedFiles;
    list[index].uploading = true;
    this.setState({ uploadedFiles: list }, () => {
      this.onUploadFile(formData, fileData.name, index);
    });
  };
  onUploadFile = (formData, fileName, index) => {
    let list = this.state.uploadedFiles;
    uploadFile(formData)
      .then((res) => {
        list[index].id = res.data.file_id;
        list[index].fileName = fileName;
        list[index].link = res.data.url;
        list[index].uploading = false;
        list[index].error = '';
        this.setState({ uploadedFiles: list }, () => {
          this.addFile();
        });
      })
      .catch((err) => {
        if (err.response.data && err.response.status != 401) {
          list[index].error = err.response.data.message;
          this.setState({ uploadedFiles: list });
        }
        catchPromise(
          err,
          this.onUploadFile.bind(this, formData, fileName, index),
          this.errorCb
        );
      });
  };

  onRemoveFile = (index) => {
    let list = this.state.uploadedFiles;
    list[index].id = '';
    this.setState({ uploadedFile: list });
    document.getElementById(index + 'Field').value = '';
  };

  addFile = () => {
    this.setState({
      uploadedFiles: [
        ...this.state.uploadedFiles,
        { id: '', fileName: '', file: {}, uploading: false },
      ],
    });
  };

  onSubmit = () => {
    let idList = [];
    for (let i = 0; i < this.state.uploadedFiles.length; i++) {
      if (this.state.uploadedFiles[i].id != '') {
        idList.push(this.state.uploadedFiles[i].id);
      }
    }
    if (idList.length > 0) {
      this.props.onSubmit(idList);
    } else {
      this.setState({ validationError: 'Загрузите документы.' });
    }
  };

  render() {
    return (
      <div>
        <div className="docPage-uploadDisc">
          1. Скачайте документы, ознакомьтесь и подпишите.
        </div>
        {this.props.files.length > 0 ? (
          <div className="docNeedScan-links">
            {this.props.files.map((file, index) => (
              <a key={index} className="btn btnLink mr-2 mb-2" href={file.url}>
                <span>
                  Скачать
                  <img
                    src="/images/ui/uploadIcon.png"
                    className="uploadIcon"
                    alt=""
                  />
                </span>
              </a>
            ))}
          </div>
        ) : !this.props.docsWaitingErr ? (
          <div className="docNeedScan-links">
            <span>
              <i className="icon-spinner2 spinner mr-2"></i> Пожалуйста,
              подождите, документы подготавливаются
            </span>
          </div>
        ) : (
          <div className="docNeedScan-links text-danger">
            {this.props.docsWaitingErr}
          </div>
        )}
        <div className="docPage-uploadDisc">
          2. Загрузите сканы подписанных документов <b>(синей ручкой)</b>.
        </div>
        <div className="docNeedScan-upload">
          {this.state.uploadedFiles.map((uploadedFile, index) => (
            <div key={index} className="docNeedScan-uploadBtn">
              <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                <input
                  type="file"
                  id={`${index}Field`}
                  className="form-control-uniform-custom"
                  accept=".png, .jpg, .pdf, .jpeg"
                  onChange={(e) => {
                    this.onFileChange(e, index);
                  }}
                />
                {!uploadedFile.uploading && uploadedFile.id === '' && (
                  <span>
                    Загрузить
                    <img
                      src="/images/ui/uploadIcon.png"
                      className="uploadIcon"
                      alt=""
                    />
                  </span>
                )}
                {uploadedFile.uploading && uploadedFile.id === '' && (
                  <span>
                    Загрузить
                    <i className="icon-spinner2 uploaderSpinner spinner"></i>
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="docNeedScan-uploadFileList">
          {this.state.uploadedFiles.map(
            (uploadedFile, index) =>
              !uploadedFile.uploading &&
              uploadedFile.id != '' && (
                <div>
                  <a href={uploadedFile.link} target="_blank">
                    {uploadedFile.fileName}
                  </a>
                  <i
                    className="icon-cross2 docRemoveFile"
                    onClick={() => {
                      this.onRemoveFile(index);
                    }}
                  ></i>
                </div>
              )
          )}
        </div>
        <div className="docPage-uploadDisc">
          3. Отправьте сканы и ожидайте проверки. Как только мы проверим
          документы, статус изменится и вы сможете приступить к следующему этапу
          подготовки отчетности.
        </div>
        {this.state.validationError != '' && (
          <h6 className="text-danger mt-2">{this.state.validationError}</h6>
        )}
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <Link to="/app/documents/moving" className="btn btn-white">
            Отменить
          </Link>
          <button
            className="btn btn-success"
            disabled={this.props.isSending}
            onClick={(e) => this.onSubmit(e)}
          >
            Создать
            {this.props.isSending && (
              <i className="icon-spinner2 uploaderSpinner spinner"></i>
            )}
          </button>
        </div>
      </div>
    );
  }
}
