import axios from "axios";
import Config from "../config/config";

export const getBonuses = (type) => {
  const params = type ? { type } : {};

  return axios({
    method: "get",
    url: `${Config.apiUrl}/v1/bonuses/withdraw`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
    params
  });
};

export const getBonuseById = (id, type) => {
  const params = type ? { type } : {};

  return axios({
    method: "get",
    url: `${Config.apiUrl}/v1/bonuses/withdraw/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
    params
  });
};

export const postFile = (file) => {
  return axios({
    method: "post",
    url: `${Config.apiUrl}/v1/files/wallet_screenshots/`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
    data: file
  }); 
}

export const createDocument = (data) => {
  return axios({
    method: "post",
    url: `${Config.apiUrl}/v1/bonuses/withdraw`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
    data
  }); 
}

export const getBonusIds = () => {
  return axios({
    method: "get",
    url: `${Config.apiUrl}/v1/bonus-ids/`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const createBonusId = (data) => {
  return axios({
    method: "post",
    url: `${Config.apiUrl}/v1/bonus-ids/`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
    data
  }); 
}

export const requestBonusId = (data) => {
  return axios({
    method: "post",
    url: `${Config.apiUrl}/v1/docs/bonus-apply/`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
    data
  }); 
}

export const deleteBonusId = (id) => {
  return axios({
    method: "delete",
    url: `${Config.apiUrl}/v1/bonus-ids/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  }); 
}
