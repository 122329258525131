import React, { Component } from 'react';
import Baron from 'react-baron/dist/es5';


export default class TechTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
    };
  }
  componentDidMount() {
    const { scrollContent } = this.props;
    $( ".tech-table .clipper > .scroller" ).scroll(() => {
      scrollContent();
    });
  }
  render() {
    const { 
      enableSections,
      onBoardChange,
      instSearch,
      onInstSearchChange,
      instruments
    } = this.props;
    return (
        <div className="tech-table"> 
          <ul className="nav nav-tabs boardsTabs">
            {
              enableSections.map((section, index) => (
                <li
                  className="nav-item"
                  onClick={() => {
                    onBoardChange(section.id);
                  }}
                >
                  <span className={`isBoard nav-link text-center board-${section.id}`} data-toggle="tab">
                    <span>{section.title.replace(' MOEX', '')}</span>
                  </span>
                </li>
              ))
            }
          </ul>
              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  style={{ overflowX: 'auto' }}
                >
                  <div className="tech-searchContainer">
                    <div className="tech-search">
                      <input
                        type="text"
                        className={`inputUnderLine`}
                        placeholder="Поиск по инструменту"
                        value={instSearch}
                        onChange={onInstSearchChange}
                      />
                      <div className="searchIcon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="#4B6CE3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M17.5 17.5L13.875 13.875" stroke="#4B6CE3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <table className="table">
                    <thead>
                      <tr className="tech-tableTitles">
                        <td className="transactionTableInst">ИНСТРУМЕНТ</td>
                        <td className="transactionTableName">НАЗВАНИЕ</td>
                        <td className="transactionTableZayavki">МАКС. ЗАЯВОК</td>
                        <td className="transactionTableLot">МАКС. ЛОТ</td>
                        <td className="transactionTableShort">ШОРТ</td>
                        <td className="transactionTableDostup">ДОСТУПНОСТЬ</td>
                      </tr>
                    </thead>
                  </table>
                  <Baron>
                    {
                      instruments.length > 0 ? (
                        <table className="table">
                          <tbody>
                            {instruments.map((instrument, index) => (
                              <tr
                                key={index}
                                className={`${Math.abs(index % 2) == 1 ? 'tableCellOdd' : ''}`}
                              >
                                <td className="transactionTableInst">{instrument.full_code}</td>
                                <td className='transactionTableName'>{instrument.description}</td>
                                <td className="transactionTableZayavki">{instrument.max_orders}</td>
                                <td className="transactionTableLot">{instrument.max_volume}</td>
                                <td className="transactionTableShort">{instrument.short_allowed ? 'Да' : 'Нет'}</td>
                                <td className="transactionTableDostup">{instrument.trade_allowed ? 'Открыт для торговли' : 'Закрыт для торговли'}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div style={{width: '100%', height: '270px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <span className="emptyData">нет данных</span>
                        </div>
                      )
                    }
                  </Baron>
                </div>
              </div>
        </div>
    );
  }
}
