import axios from 'axios';
import Config from '../config/config';

export const getNewOtp = () => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/users/otp/`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getExistingOtp = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/users/otp/`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const confirmGoogleAuth = (data) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/users/otp/confirm/`,
    withCredentials: true,
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
export const deleteGoogleAuth = (data) => {
  return axios({
    method: 'delete',
    url: `${Config.apiUrl}/v1/users/otp/`,
    withCredentials: true,
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}