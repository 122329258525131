import React from 'react';
import InputMask from 'react-input-mask';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import 'moment/locale/ru';
import Baron from 'react-baron/dist/es5';

export default class AnketaProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validate: false,
      validateActiveFields: {
        d__personal__passport__series: false,
        d__personal__passport__number: false,
        d__personal__passport__department_code: false,
      },
      d__personal__last_name: props.data.d__personal__last_name || '',
      d__personal__first_name: props.data.d__personal__first_name || '',
      d__personal__middle_name: props.data.d__personal__middle_name || '',
      d__personal__birth_date: props.data.d__personal__birth_date
        ? moment(props.data.d__personal__birth_date, 'YYYY-MM-DD')
        : null,
      d__personal__birth_place: props.data.d__personal__birth_place || '',
      d__personal__sex: props.data.d__personal__sex || 0,
      d__personal__passport__series:
        props.data.d__personal__passport__series || '',
      d__personal__passport__number:
        props.data.d__personal__passport__number || '',
      d__personal__passport__issued_by:
        props.data.d__personal__passport__issued_by || '',
      d__personal__passport__issued_date: props.data
        .d__personal__passport__issued_date
        ? moment(props.data.d__personal__passport__issued_date, 'YYYY-MM-DD')
        : null,
      d__personal__passport__department_code:
        props.data.d__personal__passport__department_code || '',
      selectIsOpened: false,
    };
  }

  componentDidMount() {
    $('body').on('click', () => {
      if (this.state.selectIsOpened) {
        setTimeout(() => this.setState({ selectIsOpened: false }), 100);
      }
      if ($('.customSelectListMonth').hasClass('isShowSelect'))
        setTimeout(() => {
          $('.customSelectListMonth').removeClass('isShowSelect');
        }, 50);
      if ($('.customSelectListYear').hasClass('isShowSelect'))
        setTimeout(() => {
          $('.customSelectListYear').removeClass('isShowSelect');
        }, 50);
    });
  }

  action = (action, currIndex, dest) => {
    this.setState({ validate: true });
    this.setState({
      validateActiveFields: {
        d__personal__passport__series: true,
        d__personal__passport__number: true,
        d__personal__passport__department_code: true,
      },
    });
    if (this.validating() || dest === 'prev') {
      this.props.action(action, currIndex, 'profile', {
        d__personal__last_name: this.state.d__personal__last_name,
        d__personal__first_name: this.state.d__personal__first_name,
        d__personal__middle_name: this.state.d__personal__middle_name,
        d__personal__birth_date: this.state.d__personal__birth_date && this.state.d__personal__birth_date.format(
          'YYYY-MM-DD'
        ),
        d__personal__birth_place: this.state.d__personal__birth_place,
        d__personal__sex: this.state.d__personal__sex,
        d__personal__passport__series: this.state.d__personal__passport__series,
        d__personal__passport__number: this.state.d__personal__passport__number,
        d__personal__passport__issued_by: this.state
          .d__personal__passport__issued_by,
        d__personal__passport__issued_date: this.state.d__personal__passport__issued_date && this.state.d__personal__passport__issued_date.format(
          'YYYY-MM-DD'
        ),
        d__personal__passport__department_code: this.state
          .d__personal__passport__department_code,
      });
    }
  };
  validating = () => {
    return (
      this.state.d__personal__last_name != '' &&
      this.state.d__personal__first_name != '' &&
      this.state.d__personal__birth_date != null &&
      this.state.d__personal__birth_date != '' &&
      this.state.d__personal__birth_place != '' &&
      this.state.d__personal__passport__series.length === 4 &&
      this.state.d__personal__passport__number.length === 6 &&
      this.state.d__personal__passport__issued_by != '' &&
      this.state.d__personal__passport__issued_date != null &&
      this.state.d__personal__passport__issued_date != '' &&
      this.state.d__personal__passport__department_code != '' &&
      this.state.d__personal__passport__department_code.replace(/[-_]/g, '')
        .length === 6
    );
  };

  onLastNameChange = (e) => {
    const d__personal__last_name = e.target.value;
    this.setState(() => ({ d__personal__last_name }));
  };
  onFirstNameChange = (e) => {
    const d__personal__first_name = e.target.value;
    this.setState(() => ({ d__personal__first_name }));
  };
  onThirdNameChange = (e) => {
    const d__personal__middle_name = e.target.value;
    this.setState(() => ({ d__personal__middle_name }));
  };
  onDateBirthChange = (d__personal__birth_date) => {
    this.setState({ d__personal__birth_date });
  };
  onPlaceBirthChange = (e) => {
    const d__personal__birth_place = e.target.value;
    this.setState(() => ({ d__personal__birth_place }));
  };
  onSexChange = (value) => {
    const d__personal__sex = parseInt(value);
    this.setState({ selectIsOpened: false, d__personal__sex });
  };
  onPassportSeriaChange = (e) => {
    const d__personal__passport__series = e.target.value;
    if (
      !d__personal__passport__series ||
      (d__personal__passport__series.match(/^[0-9]+$/) &&
        d__personal__passport__series.length <= 4)
    ) {
      this.setState(() => ({
        d__personal__passport__series,
        validateActiveFields: {
          ...this.state.validateActiveFields,
          d__personal__passport__series: false,
        },
      }));
    }
  };
  onPassportNumChange = (e) => {
    const d__personal__passport__number = e.target.value;
    if (
      !d__personal__passport__number ||
      (d__personal__passport__number.match(/^[0-9]+$/) &&
        d__personal__passport__number.length <= 6)
    ) {
      this.setState(() => ({
        d__personal__passport__number,
        validateActiveFields: {
          ...this.state.validateActiveFields,
          d__personal__passport__number: false,
        },
      }));
    }
  };
  onPassportWhosChange = (e) => {
    const d__personal__passport__issued_by = e.target.value;
    this.setState(() => ({ d__personal__passport__issued_by }));
  };
  onPassportDateChange = (d__personal__passport__issued_date) => {
    this.setState(() => ({ d__personal__passport__issued_date }));
  };
  onPassportCodeWhereChange = (e) => {
    const d__personal__passport__department_code = e.target.value;
    this.setState(() => ({
      d__personal__passport__department_code,
      validateActiveFields: {
        ...this.state.validateActiveFields,
        d__personal__passport__department_code: false,
      },
    }));
  };
  changeSelect = (name, value) => {
    if (value) $('.' + name).addClass('isShowSelect');
    else $('.' + name).removeClass('isShowSelect');
  };
  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    let rows = [];
    for (let i = 0; i < 100; i++) {
      rows.push(i);
    }
    return (
      <div
        className="singleDatePickerSelect"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div className="customSelect customSelectSingleDatepicker">
          <div
            className="customSelectedItem"
            onClick={() => {
              this.changeSelect('customSelectListMonth', true);
            }}
          >
            {month.format('MMMM')}
          </div>
          {
            <ul className="customSelectList customSelectListMonth">
              <Baron>
                {moment.months().map((label, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      onMonthSelect(month, index);
                      this.changeSelect('customSelectListMonth', false);
                    }}
                  >
                    {label}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
        <div className="customSelect customSelectSingleDatepicker customSelectSingleDatepicker-year">
          <div
            className="customSelectedItem"
            onClick={() => {
              this.changeSelect('customSelectListYear', true);
            }}
          >
            {month.year()}
          </div>
          {
            <ul className="customSelectList customSelectListYear">
              <Baron>
                {rows.map((row) => (
                  <li
                    key={row}
                    onClick={() => {
                      onYearSelect(month, moment().year() - row);
                      this.changeSelect('customSelectListYear', false);
                    }}
                  >
                    {moment().year() - row}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <h2 className="subtitle">Личные данные</h2>
        <div className="row">
          <div className="col-lg-6 inputFields-left">
            <div className="position-relative formField-container">
              <label
                className={`floating-label ${
                  this.state.d__personal__last_name == '' ? 'hidden' : ''
                }`}
              >
                Фамилия
              </label>
              <input
                type="text"
                className={`inputUnderLine profile-fioField ${
                  this.state.validate && this.state.d__personal__last_name == ''
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  this.state.validate && this.state.d__personal__last_name != ''
                    ? 'inputValidateCorrect'
                    : ''
                }`}
                placeholder="Фамилия"
                value={this.state.d__personal__last_name}
                onChange={this.onLastNameChange}
              />
            </div>
            <div className="position-relative formField-container">
              <label
                className={`floating-label ${
                  this.state.d__personal__first_name == '' ? 'hidden' : ''
                }`}
              >
                Имя
              </label>
              <input
                type="text"
                className={`inputUnderLine profile-fioField ${
                  this.state.validate &&
                  this.state.d__personal__first_name == ''
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  this.state.validate &&
                  this.state.d__personal__first_name != ''
                    ? 'inputValidateCorrect'
                    : ''
                }`}
                placeholder="Имя"
                value={this.state.d__personal__first_name}
                onChange={this.onFirstNameChange}
              />
            </div>
            <div className="position-relative formField-container">
              <label
                className={`floating-label ${
                  this.state.d__personal__middle_name == '' ? 'hidden' : ''
                }`}
              >
                Отчество
              </label>
              <input
                type="text"
                className="inputUnderLine profile-fioField"
                placeholder="Отчество"
                value={this.state.d__personal__middle_name}
                onChange={this.onThirdNameChange}
              />
            </div>
          </div>
          <div className="col-lg-6 inputFields-right">
            <div className="formField-container">
              <label
                style={{
                  fontSize: '12px',
                  paddingTop: '8px',
                  opacity: '0.8',
                  letterSpacing: '0.06px',
                  display: 'inline-block',
                  width: '105px',
                  fontWeight: 'normal',
                }}
              >
                Дата рождения
              </label>
              <div
                className="dateBubble"
                style={{
                  display: 'inline-block',
                  width: '85px',
                  marginLeft: '20px',
                }}
              >
                <SingleDatePicker
                  date={this.state.d__personal__birth_date}
                  placeholder=""
                  onDateChange={this.onDateBirthChange}
                  focused={this.state.focused}
                  onFocusChange={({ focused }) => this.setState({ focused })}
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  hideKeyboardShortcutsPanel={true}
                  renderMonthElement={this.renderMonthElement}
                />
              </div>
            </div>
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__personal__birth_place == '' ? 'hidden' : ''
                }`}
              >
                Место рождения
              </label>
              <input
                type="text"
                className={`inputUnderLine ${
                  this.state.validate &&
                  this.state.d__personal__birth_place == ''
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  this.state.validate &&
                  this.state.d__personal__birth_place != ''
                    ? 'inputValidateCorrect'
                    : ''
                }`}
                placeholder="Место рождения"
                value={this.state.d__personal__birth_place}
                onChange={this.onPlaceBirthChange}
              />
            </div>
            <div className="formField-container row">
              <label className="col-lg-3">Пол</label>
              <div className="col-lg-9">
                <div className="customSelect" style={{ width: '100px' }}>
                  <div
                    className="customSelectedItem"
                    onClick={() => {
                      this.setState({ selectIsOpened: true });
                    }}
                  >
                    {this.state.d__personal__sex === 0 ? 'Мужской' : 'Женский'}
                    <i className="toggle icon-menu-open"></i>
                  </div>
                  {this.state.selectIsOpened && (
                    <ul className="customSelectList">
                      <li
                        onClick={() => {
                          this.onSexChange(0);
                        }}
                      >
                        Мужской
                      </li>
                      <li
                        onClick={() => {
                          this.onSexChange(1);
                        }}
                      >
                        Женский
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 className="subtitle mt-3">Паспорт</h2>
        <div>
          <div
            style={{
              width: '60px',
              display: 'inline-block',
            }}
          >
            <div
              className="formField-container position-relative"
              style={{ width: '60px' }}
            >
              <label
                className={`floating-label ${
                  this.state.d__personal__passport__series == '' ? 'hidden' : ''
                }`}
              >
                Серия
              </label>
              <input
                className={`inputUnderLine ${
                  this.state.validate &&
                  this.state.d__personal__passport__series.length != 4 &&
                  this.state.validateActiveFields.d__personal__passport__series
                    ? 'inputValidateCurrentWrong'
                    : ''
                } ${
                  this.state.validate &&
                  this.state.d__personal__passport__series.length != 4
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  this.state.validate &&
                  this.state.d__personal__passport__series.length == 4
                    ? 'inputValidateCorrect'
                    : ''
                }`}
                placeholder="Серия"
                value={this.state.d__personal__passport__series}
                onChange={this.onPassportSeriaChange}
              />
            </div>
          </div>
          <div
            style={{
              width: '85px',
              display: 'inline-block',
              marginLeft: '30px',
            }}
          >
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__personal__passport__number == '' ? 'hidden' : ''
                }`}
              >
                Номер
              </label>
              <input
                className={`inputUnderLine ${
                  this.state.validate &&
                  this.state.d__personal__passport__number.length != 6 &&
                  this.state.validateActiveFields.d__personal__passport__number
                    ? 'inputValidateCurrentWrong'
                    : ''
                } ${
                  this.state.validate &&
                  this.state.d__personal__passport__number.length != 6
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  this.state.validate &&
                  this.state.d__personal__passport__number.length == 6
                    ? 'inputValidateCorrect'
                    : ''
                }`}
                placeholder="Номер"
                value={this.state.d__personal__passport__number}
                onChange={this.onPassportNumChange}
              />
            </div>
          </div>
          <div
            style={{
              width: '332px',
              display: 'inline-block',
              marginLeft: '25px',
            }}
          >
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__personal__passport__issued_by == ''
                    ? 'hidden'
                    : ''
                }`}
              >
                Кем выдан
              </label>
              <input
                type="text"
                className={`inputUnderLine ${
                  this.state.validate &&
                  this.state.d__personal__passport__issued_by == ''
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  this.state.validate &&
                  this.state.d__personal__passport__issued_by != ''
                    ? 'inputValidateCorrect'
                    : ''
                }`}
                placeholder="Кем выдан"
                value={this.state.d__personal__passport__issued_by}
                onChange={this.onPassportWhosChange}
              />
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              position: 'static',
              width: '175px',
              display: 'inline-block',
            }}
          >
            <div className="formField-container">
              <label
                className="docCreate-dateLabel"
                style={{
                  display: 'inline-block',
                  width: '85px',
                  opacity: '0.8',
                  letterSpacing: '0.06px',
                  fontWeight: 'normal',
                }}
              >
                Дата выдачи
              </label>
              <div
                className="dateBubble"
                style={{
                  position: 'static',
                  display: 'inline-block',
                  width: '85px',
                  marginLeft: '5px',
                }}
              >
                <SingleDatePicker
                  date={this.state.d__personal__passport__issued_date}
                  placeholder=""
                  onDateChange={this.onPassportDateChange}
                  focused={this.state.focusedPassport}
                  onFocusChange={() =>
                    this.setState({
                      focusedPassport: !this.state.focusedPassport,
                    })
                  }
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  hideKeyboardShortcutsPanel={true}
                  renderMonthElement={this.renderMonthElement}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: '130px',
              display: 'inline-block',
              marginLeft: '25px',
            }}
          >
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__personal__passport__department_code == ''
                    ? 'hidden'
                    : ''
                }`}
              >
                Код подразделения
              </label>
              <InputMask
                className={`inputUnderLine profile-codeCompanyField ${
                  this.state.validate &&
                  this.state.d__personal__passport__department_code.replace(
                    /[-_]/g,
                    ''
                  ).length != 6 &&
                  this.state.validateActiveFields
                    .d__personal__passport__department_code
                    ? 'inputValidateCurrentWrong'
                    : ''
                } ${
                  this.state.validate &&
                  this.state.d__personal__passport__department_code.replace(
                    /[-_]/g,
                    ''
                  ).length != 6
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  this.state.validate &&
                  this.state.d__personal__passport__department_code.replace(
                    /[-_]/g,
                    ''
                  ).length == 6
                    ? 'inputValidateCorrect'
                    : ''
                }`}
                mask="999-999"
                placeholder="Код подразделения"
                value={this.state.d__personal__passport__department_code}
                onChange={this.onPassportCodeWhereChange}
              />
            </div>
          </div>
        </div>
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <button
            className="btn btnBack"
            onClick={() => {
              this.action(this.props.previous, -1, 'prev');
            }}
          >
            Назад
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              this.action(this.props.next, 1);
            }}
          >
            Далее
          </button>
        </div>
      </div>
    );
  }
}
