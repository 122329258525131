import axios from "axios";
import Config from "../config/config";

export const getListenKey = () => {
  return axios({
    method: "get",
    url: `${Config.apiUrl}/v1/trading/listen_key`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateListenKey = () => {
  return axios({
    method: "put",
    url: `${Config.apiUrl}/v1/trading/listen_key`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
