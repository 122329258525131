import React from "react";
import moment from "moment";
import "moment/locale/ru";
import amplitude from 'amplitude-js';

import { catchPromise } from "../utils/http";
import { getCryptoFinres } from "../utils/finresHttp";
import CryptoFinres from "../components/CryptoFinres";

export class FinresPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),
      calendarFocused: null,
      dateRangeCurr: "dateRangeMonth",
      isLoading: false,
      pageError: false,
      pnls: [],
      records: 0,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    moment.locale("ru");
    amplitude.getInstance().logEvent('finres_page.page_view');

    getCryptoFinres(this.state.startDate, this.state.endDate)
      .then((res) => {
        this.setState({
          pnls: res.data.pnls,
          records: res.data.records
        });
      })
      .catch((err) => {
        catchPromise(
          err,
          getFinresLoad.bind(this, this.state.startDate, this.state.endDate),
          this.errorCb
        );
      });
  }

  onDatesChange = ({ startDate, endDate, dateRange }) => {
    this.setState({ startDate, endDate, dateRangeCurr: dateRange }, () => {
      if (this.state.startDate && this.state.endDate) {
        getCryptoFinres(startDate, endDate)
          .then((res) => {
            this.setState({
              pnls: res.data.pnls,
            });
          })
          .catch((err) => {
            catchPromise(
              err,
              getFinresLoad.bind(
                this,
                this.state.startDate,
                this.state.endDate
              ),
              this.errorCb
            );
          });
      }
    });
  };
  onFocusChange = (calendarFocused) => {
    this.setState({ calendarFocused });
  };

  selectCalendarDates = (dateRangeCurr) => {
    switch (dateRangeCurr) {
      case "dateRangeWeek":
        this.onDatesChange({
          startDate: moment().startOf("week"),
          endDate: moment().endOf("week"),
          dateRange: dateRangeCurr,
        });
        break;
      case "dateRangeMonth":
        this.onDatesChange({
          startDate: moment().startOf("month"),
          endDate: moment().endOf("month"),
          dateRange: dateRangeCurr,
        });
        break;
      case "dateRangeQuarter":
        this.onDatesChange({
          startDate: moment().startOf("quarter"),
          endDate: moment().endOf("quarter"),
          dateRange: dateRangeCurr,
        });
        break;
      case "dateRange30days":
        this.onDatesChange({
          startDate: moment().subtract(1, "months"),
          endDate: moment(),
          dateRange: dateRangeCurr,
        });
        break;
      case "dateRange90days":
        this.onDatesChange({
          startDate: moment().subtract(3, "months"),
          endDate: moment(),
          dateRange: dateRangeCurr,
        });
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div className="row">
        <div className="page-content-center finres">
          {!this.state.pageError ? (
            <CryptoFinres
              pnls={this.state.pnls}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              dateRangeCurr={this.state.dateRangeCurr}
              onChangeAccountFilter={this.onChangeAccountFilter}
              onDatesChange={this.onDatesChange}
              calendarFocused={this.state.calendarFocused}
              onFocusChange={this.onFocusChange}
              selectCalendarDates={this.selectCalendarDates}
              records={this.state.records}
              isLoading={this.state.isLoading}
            />
          ) : (
            <h3 className="text-center text-danger">Произошла ошибка</h3>
          )}
        </div>
      </div>
    );
  }
}

export default FinresPage;
