import React from 'react';

export default class ChangeAnketaAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dadataFocusPostalCodeReg: false,
      dadataFocusRegionReg: false,
      dadataFocusAreaReg: false,
      dadataFocusCityReg: false,
      dadataFocusStreetReg: false,
      dadataFocusHouseReg: false,

      dadataFocusPostalCodePostal: false,
      dadataFocusRegionPostal: false,
      dadataFocusAreaPostal: false,
      dadataFocusCityPostal: false,
      dadataFocusStreetPostal: false,
      dadataFocusHousePostal: false,

      dadataFocusPostalCodeLive: false,
      dadataFocusRegionLive: false,
      dadataFocusAreaLive: false,
      dadataFocusCityLive: false,
      dadataFocusStreetLive: false,
      dadataFocusHouseLive: false,
    };
  }

  onRegPostalChange = (e) => {
    const d__address__reg__postal_code = e.target.value;
    if (
      !d__address__reg__postal_code ||
      (d__address__reg__postal_code.match(/^[0-9]+$/) &&
        d__address__reg__postal_code.length <= 6)
    ) {
      this.props.onValueChange(
        'd__address__reg__postal_code',
        d__address__reg__postal_code
      );
    }
  };
  onRegRegionChange = (e) => {
    const d__address__reg__region = e.target.value;
    this.props.onValueChange(
      'd__address__reg__region',
      d__address__reg__region
    );
  };
  onRegAreaChange = (e) => {
    const d__address__reg__area = e.target.value;
    this.props.onValueChange('d__address__reg__area', d__address__reg__area);
  };
  onRegCityChange = (e) => {
    const d__address__reg__city = e.target.value;
    this.props.onValueChange('d__address__reg__city', d__address__reg__city);
  };
  onRegStreetChange = (e) => {
    const d__address__reg__street = e.target.value;
    this.props.onValueChange(
      'd__address__reg__street',
      d__address__reg__street
    );
  };
  onRegHouseChange = (e) => {
    const d__address__reg__house = e.target.value;
    this.props.onValueChange('d__address__reg__house', d__address__reg__house);
  };
  onRegBlockChange = (e) => {
    const d__address__reg__block = e.target.value;
    this.props.onValueChange('d__address__reg__block', d__address__reg__block);
  };
  onRegFlatChange = (e) => {
    const d__address__reg__flat = e.target.value;
    this.props.onValueChange('d__address__reg__flat', d__address__reg__flat);
  };

  onPostalPostalChange = (e) => {
    const d__address__postal__postal_code = e.target.value;
    if (
      !d__address__postal__postal_code ||
      (d__address__postal__postal_code.match(/^[0-9]+$/) &&
        d__address__postal__postal_code.length <= 6)
    ) {
      this.props.onValueChange(
        'd__address__postal__postal_code',
        d__address__postal__postal_code
      );
    }
  };
  onPostalRegionChange = (e) => {
    const d__address__postal__region = e.target.value;
    this.props.onValueChange(
      'd__address__postal__region',
      d__address__postal__region
    );
  };
  onPostalAreaChange = (e) => {
    const d__address__postal__area = e.target.value;
    this.props.onValueChange(
      'd__address__postal__area',
      d__address__postal__area
    );
  };
  onPostalCityChange = (e) => {
    const d__address__postal__city = e.target.value;
    this.props.onValueChange(
      'd__address__postal__city',
      d__address__postal__city
    );
  };
  onPostalStreetChange = (e) => {
    const d__address__postal__street = e.target.value;
    this.props.onValueChange(
      'd__address__postal__street',
      d__address__postal__street
    );
  };
  onPostalHouseChange = (e) => {
    const d__address__postal__house = e.target.value;
    this.props.onValueChange(
      'd__address__postal__house',
      d__address__postal__house
    );
  };
  onPostalBlockChange = (e) => {
    const d__address__postal__block = e.target.value;
    this.props.onValueChange(
      'd__address__postal__block',
      d__address__postal__block
    );
  };
  onPostalFlatChange = (e) => {
    const d__address__postal__flat = e.target.value;
    this.props.onValueChange(
      'd__address__postal__flat',
      d__address__postal__flat
    );
  };

  onLivePostalChange = (e) => {
    const d__address__live__postal_code = e.target.value;
    if (
      !d__address__live__postal_code ||
      (d__address__live__postal_code.match(/^[0-9]+$/) &&
        d__address__live__postal_code.length <= 6)
    ) {
      this.props.onValueChange(
        'd__address__live__postal_code',
        d__address__live__postal_code
      );
    }
  };
  onLiveRegionChange = (e) => {
    const d__address__live__region = e.target.value;
    this.props.onValueChange(
      'd__address__live__region',
      d__address__live__region
    );
  };
  onLiveAreaChange = (e) => {
    const d__address__live__area = e.target.value;
    this.props.onValueChange('d__address__live__area', d__address__live__area);
  };
  onLiveCityChange = (e) => {
    const d__address__live__city = e.target.value;
    this.props.onValueChange('d__address__live__city', d__address__live__city);
  };
  onLiveStreetChange = (e) => {
    const d__address__live__street = e.target.value;
    this.props.onValueChange(
      'd__address__live__street',
      d__address__live__street
    );
  };
  onLiveHouseChange = (e) => {
    const d__address__live__house = e.target.value;
    this.props.onValueChange(
      'd__address__live__house',
      d__address__live__house
    );
  };
  onLiveBlockChange = (e) => {
    const d__address__live__block = e.target.value;
    this.props.onValueChange(
      'd__address__live__block',
      d__address__live__block
    );
  };
  onLiveFlatChange = (e) => {
    const d__address__live__flat = e.target.value;
    this.props.onValueChange('d__address__live__flat', d__address__live__flat);
  };

  onFocusDadata = (name) => {
    setTimeout(() => {
      this.setState(() => ({ [name]: true }));
    }, 300);
  };
  onBlurDadata = (name) => {
    setTimeout(() => {
      this.setState(() => ({ [name]: false }));
    }, 300);
  };

  render() {
    let {
      validate,
      isReadableAddress,
      isHandEditing,
      regAddressFull,
      postalAddressFull,
      liveAddressFull,
      isSamePostal,
      isSameLive,
      cityRegIdValue,
      cityPostalIdValue,
      cityLiveIdValue,
      addressChange,
      suggestions,
      setAddressState,
      clearAddressFields,
      dotify,
      d__address__reg__postal_code,
      d__address__reg__region,
      d__address__reg__area,
      d__address__reg__city,
      d__address__reg__street,
      d__address__reg__house,
      d__address__reg__block,
      d__address__reg__flat,
      d__address__postal__postal_code,
      d__address__postal__region,
      d__address__postal__area,
      d__address__postal__city,
      d__address__postal__street,
      d__address__postal__house,
      d__address__postal__block,
      d__address__postal__flat,
      d__address__live__postal_code,
      d__address__live__region,
      d__address__live__area,
      d__address__live__city,
      d__address__live__street,
      d__address__live__house,
      d__address__live__block,
      d__address__live__flat,
    } = this.props;
    return (
      <div>
        <div className="anketaChange-subtitleContainer d-flex justify-content-between align-items-center">
          <div className="anketaChange-subtitle">Адреса</div>
          <span
            className="addressHandWriting"
            onClick={() => {
              this.props.setReadableBlock('isReadableAddress');
            }}
          >
            {isReadableAddress ? (
              <span className="text-danger addressHandWriting-reset">
                Отменить
              </span>
            ) : (
              <span className="text-primary addressHandWriting-change">
                Изменить
              </span>
            )}
          </span>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  d__address__reg__postal_code == '' ? 'hidden' : ''
                }`}
              >
                Индекс
              </label>
              <div className="dadataContainer">
                <input
                  type="text"
                  className={`inputUnderLine ${
                    validate &&
                    d__address__reg__postal_code.length != 6
                      ? 'inputValidateWrong'
                      : ''
                  } ${
                    validate && d__address__reg__postal_code.length == 6
                      ? 'inputValidateCorrect'
                      : ''
                  } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                  placeholder="Индекс"
                  readOnly={!isReadableAddress}
                  value={d__address__reg__postal_code}
                  onChange={(e) => {
                    addressChange(
                      e,
                      'd__address__reg__postal_code',
                      'postal_code',
                      '',
                      ''
                    );
                  }}
                  onFocus={(e) => {
                    this.onFocusDadata('dadataFocusPostalCodeReg');
                  }}
                  onBlur={(e) => {
                    this.onBlurDadata('dadataFocusPostalCodeReg');
                  }}
                />
                {this.state.dadataFocusPostalCodeReg && suggestions.length > 0 && (
                  <ul className="dadataList">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setAddressState({
                            d__address__reg__street: dotify(
                              suggestion.data.street_with_type
                            ),
                            streetRegIdValue: suggestion.data.street_fias_id,
                            suggestions: [],
                            d__address__reg__postal_code:
                              suggestion.data.postal_code ||
                              d__address__reg__postal_code ||
                              '',
                            d__address__reg__region: dotify(
                              suggestion.data.region_with_type
                            ),
                            regionRegIdValue: suggestion.data.region_fias_id,
                            d__address__reg__city: dotify(
                              suggestion.data.city_with_type
                            ),
                            cityRegIdValue: suggestion.data.city_fias_id,
                          });
                        }}
                      >
                        {dotify(suggestion.unrestricted_value)}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  d__address__reg__region == '' ? 'hidden' : ''
                }`}
              >
                Регион
              </label>
              <div className="dadataContainer">
                <input
                  className={`inputUnderLine ${
                    validate && d__address__reg__region == ''
                      ? 'inputValidateWrong'
                      : ''
                  } ${
                    validate && d__address__reg__region != ''
                      ? 'inputValidateCorrect'
                      : ''
                  } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                  name="region"
                  type="text"
                  placeholder="Регион"
                  readOnly={!isReadableAddress}
                  value={d__address__reg__region}
                  onChange={(e) => {
                    addressChange(
                      e,
                      'd__address__reg__region',
                      'region',
                      '',
                      '',
                      [
                        'regionRegIdValue',
                        'd__address__reg__area',
                        'd__address__reg__city',
                        'cityRegIdValue',
                        'd__address__reg__street',
                        'streetRegIdValue',
                        'd__address__reg__house',
                      ]
                    );
                  }}
                  onFocus={(e) => {
                    this.onFocusDadata('dadataFocusRegionReg');
                  }}
                  onBlur={(e) => {
                    this.onBlurDadata('dadataFocusRegionReg');
                  }}
                />
                {this.state.dadataFocusRegionReg && suggestions.length > 0 && (
                  <ul className="dadataList">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setAddressState({
                            d__address__reg__region: dotify(suggestion.value),
                            regionRegIdValue: suggestion.data.region_fias_id,
                            suggestions: [],
                            d__address__reg__postal_code:
                              suggestion.data.postal_code ||
                              d__address__reg__postal_code ||
                              '',
                          });
                          clearAddressFields([
                            'd__address__reg__area',
                            'd__address__reg__city',
                            'cityRegIdValue',
                            'd__address__reg__street',
                            'streetRegIdValue',
                            'd__address__reg__house',
                          ]);
                        }}
                      >
                        {dotify(suggestion.value)}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  d__address__reg__area == '' ? 'hidden' : ''
                }`}
              >
                Район
              </label>
              <div className="dadataContainer">
                <input
                  className={`inputUnderLine`}
                  name="area"
                  type="text"
                  placeholder="Район"
                  readOnly={!isReadableAddress}
                  value={d__address__reg__area}
                  onChange={(e) => {
                    addressChange(
                      e,
                      'd__address__reg__area',
                      'area',
                      'region_fias_id',
                      'regionRegIdValue'
                    );
                  }}
                  onFocus={(e) => {
                    this.onFocusDadata('dadataFocusAreaReg');
                  }}
                  onBlur={(e) => {
                    this.onBlurDadata('dadataFocusAreaReg');
                  }}
                />
                {this.state.dadataFocusAreaReg && suggestions.length > 0 && (
                  <ul className="dadataList">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setAddressState({
                            d__address__reg__area: dotify(suggestion.value),
                            suggestions: [],
                          });
                        }}
                      >
                        {dotify(suggestion.value)}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  d__address__reg__city == '' ? 'hidden' : ''
                }`}
              >
                Город
              </label>
              <div className="dadataContainer">
                <input
                  className={`inputUnderLine ${
                    validate && d__address__reg__city == ''
                      ? 'inputValidateWrong'
                      : ''
                  } ${
                    validate && d__address__reg__city != ''
                      ? 'inputValidateCorrect'
                      : ''
                  } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                  name="city"
                  type="text"
                  placeholder="Город"
                  readOnly={!isReadableAddress}
                  value={d__address__reg__city}
                  onChange={(e) => {
                    addressChange(
                      e,
                      'd__address__reg__city',
                      'city',
                      'region_fias_id',
                      'regionRegIdValue',
                      [
                        'cityRegIdValue',
                        'd__address__reg__street',
                        'streetRegIdValue',
                        'd__address__reg__house',
                      ]
                    );
                  }}
                  onFocus={(e) => {
                    this.onFocusDadata('dadataFocusCityReg');
                  }}
                  onBlur={(e) => {
                    this.onBlurDadata('dadataFocusCityReg');
                  }}
                />
                {this.state.dadataFocusCityReg && suggestions.length > 0 && (
                  <ul className="dadataList">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setAddressState({
                            d__address__reg__city: dotify(suggestion.value),
                            cityRegIdValue: suggestion.data.city_fias_id,
                            suggestions: [],
                            d__address__reg__postal_code:
                              suggestion.data.postal_code ||
                              d__address__reg__postal_code ||
                              '',
                            d__address__reg__region: dotify(
                              suggestion.data.region_with_type
                            ),
                            regionRegIdValue: suggestion.data.region_fias_id,
                          });
                          clearAddressFields([
                            'd__address__reg__street',
                            'streetRegIdValue',
                            'd__address__reg__house',
                          ]);
                        }}
                      >
                        {dotify(suggestion.value)}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  d__address__reg__street == '' ? 'hidden' : ''
                }`}
              >
                Улица
              </label>
              <div className="dadataContainer">
                <input
                  className={`inputUnderLine ${
                    validate && d__address__reg__street == ''
                      ? 'inputValidateWrong'
                      : ''
                  } ${
                    validate && d__address__reg__street != ''
                      ? 'inputValidateCorrect'
                      : ''
                  } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                  name="street"
                  type="text"
                  placeholder="Улица"
                  readOnly={!isReadableAddress}
                  value={d__address__reg__street}
                  onChange={(e) => {
                    addressChange(
                      e,
                      'd__address__reg__street',
                      'street',
                      'city_fias_id',
                      'cityRegIdValue',
                      ['streetRegIdValue', 'd__address__reg__house']
                    );
                  }}
                  onFocus={(e) => {
                    this.onFocusDadata('dadataFocusStreetReg');
                  }}
                  onBlur={(e) => {
                    this.onBlurDadata('dadataFocusStreetReg');
                  }}
                />
                {this.state.dadataFocusStreetReg &&
                  suggestions.length > 0 &&
                  !(d__address__reg__city && !cityRegIdValue) && (
                    <ul className="dadataList">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setAddressState({
                              d__address__reg__street: dotify(suggestion.value),
                              streetRegIdValue: suggestion.data.street_fias_id,
                              suggestions: [],
                              d__address__reg__postal_code:
                                suggestion.data.postal_code ||
                                d__address__reg__postal_code ||
                                '',
                              d__address__reg__region: dotify(
                                suggestion.data.region_with_type
                              ),
                              regionRegIdValue: suggestion.data.region_fias_id,
                              d__address__reg__city: dotify(
                                suggestion.data.city_with_type
                              ),
                              cityRegIdValue: suggestion.data.city_fias_id,
                            });
                            clearAddressFields(['d__address__reg__house']);
                          }}
                        >
                          {!cityRegIdValue && !d__address__reg__city
                            ? dotify(suggestion.unrestricted_value)
                            : dotify(suggestion.value)}
                        </li>
                      ))}
                    </ul>
                  )}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  d__address__reg__house == '' ? 'hidden' : ''
                }`}
              >
                Дом
              </label>
              <div className="dadataContainer">
                <input
                  className={`inputUnderLine ${
                    validate && d__address__reg__house == ''
                      ? 'inputValidateWrong'
                      : ''
                  } ${
                    validate && d__address__reg__house != ''
                      ? 'inputValidateCorrect'
                      : ''
                  } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                  name="house"
                  type="text"
                  placeholder="Дом"
                  readOnly={!isReadableAddress}
                  value={d__address__reg__house}
                  onChange={(e) => {
                    addressChange(
                      e,
                      'd__address__reg__house',
                      'house',
                      'street_fias_id',
                      'streetRegIdValue'
                    );
                  }}
                  onFocus={(e) => {
                    this.onFocusDadata('dadataFocusHouseReg');
                  }}
                  onBlur={(e) => {
                    this.onBlurDadata('dadataFocusHouseReg');
                  }}
                />
                {this.state.dadataFocusHouseReg &&
                  suggestions.length > 0 &&
                  !(d__address__reg__house && !cityRegIdValue) &&
                  d__address__reg__street && (
                    <ul className="dadataList">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setAddressState({
                              d__address__reg__house: dotify(suggestion.value),
                              suggestions: [],
                              d__address__reg__postal_code:
                                suggestion.data.postal_code ||
                                d__address__reg__postal_code ||
                                '',
                              d__address__reg__region: dotify(
                                suggestion.data.region_with_type
                              ),
                              regionRegIdValue: suggestion.data.region_fias_id,
                              d__address__reg__city: dotify(
                                suggestion.data.city_with_type
                              ),
                              cityRegIdValue: suggestion.data.city_fias_id,
                              d__address__reg__street: dotify(
                                suggestion.data.street_with_type
                              ),
                              streetRegIdValue: suggestion.data.street_fias_id,
                            });
                          }}
                        >
                          {dotify(suggestion.value)}
                        </li>
                      ))}
                    </ul>
                  )}
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  d__address__reg__flat == '' ? 'hidden' : ''
                }`}
              >
                Квартира
              </label>
              <input
                type="text"
                className="inputUnderLine"
                placeholder="Квартира"
                readOnly={!isReadableAddress}
                value={d__address__reg__flat}
                onChange={this.onRegFlatChange}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="">
              <div className="form-check form-check-inline">
                <label className="checkboxContainer">
                  Почтовый адрес совпадает с местом регистрации
                  <input
                    type="checkbox"
                    name="samePostal"
                    value={isSamePostal}
                    disabled={!isReadableAddress}
                    checked={isSamePostal}
                    onChange={this.props.onPostalSameReg}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          {isSamePostal && (
            <div className="col-lg-12">
              <div className="formField-container position-relative">
                <input
                  type="text"
                  className="inputUnderLine"
                  placeholder="Почтовый адрес"
                  value={`${d__address__reg__postal_code} ${d__address__reg__region} ${d__address__reg__area} ${d__address__reg__city} ${d__address__reg__street} ${d__address__reg__house} ${d__address__reg__flat}`}
                  readOnly={true}
                />
              </div>
            </div>
          )}
        </div>
        {!isSamePostal && (
          <div className="row">
            <div className="col-lg-12 docCreate-anketaAddress-placeSubtitle">
              Почтовый адрес
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    d__address__postal__postal_code == '' ? 'hidden' : ''
                  }`}
                >
                  Индекс
                </label>
                <div className="dadataContainer">
                  <input
                    type="text"
                    className={`inputUnderLine ${
                      validate &&
                      d__address__postal__postal_code.length != 6
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      validate && d__address__postal__postal_code.length == 6
                        ? 'inputValidateCorrect'
                        : ''
                    } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                    placeholder="Индекс"
                    readOnly={!isReadableAddress}
                    value={d__address__postal__postal_code}
                    onChange={(e) => {
                      addressChange(
                        e,
                        'd__address__postal__postal_code',
                        'postal_code',
                        '',
                        ''
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusPostalCodePostal');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusPostalCodePostal');
                    }}
                  />
                  {this.state.dadataFocusPostalCodePostal &&
                    suggestions.length > 0 && (
                      <ul className="dadataList">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setAddressState({
                                d__address__postal__street: dotify(
                                  suggestion.data.street_with_type
                                ),
                                streetPostalIdValue:
                                  suggestion.data.street_fias_id,
                                suggestions: [],
                                d__address__postal__postal_code:
                                  suggestion.data.postal_code ||
                                  d__address__postal__postal_code ||
                                  '',
                                d__address__postal__region: dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionPostalIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__postal__city: dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityPostalIdValue: suggestion.data.city_fias_id,
                              });
                            }}
                          >
                            {dotify(suggestion.unrestricted_value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    d__address__postal__region == '' ? 'hidden' : ''
                  }`}
                >
                  Регион
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      validate && d__address__postal__region == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      validate && d__address__postal__region != ''
                        ? 'inputValidateCorrect'
                        : ''
                    } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                    name="region"
                    type="text"
                    placeholder="Регион"
                    readOnly={!isReadableAddress}
                    value={d__address__postal__region}
                    onChange={(e) => {
                      addressChange(
                        e,
                        'd__address__postal__region',
                        'region',
                        '',
                        '',
                        [
                          'regionPostalIdValue',
                          'd__address__postal__area',
                          'd__address__postal__city',
                          'cityPostalIdValue',
                          'd__address__postal__street',
                          'streetPostalIdValue',
                          'd__address__postal__house',
                        ]
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusRegionPostal');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusRegionPostal');
                    }}
                  />
                  {this.state.dadataFocusRegionPostal &&
                    suggestions.length > 0 && (
                      <ul className="dadataList">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setAddressState({
                                d__address__postal__region: dotify(
                                  suggestion.value
                                ),
                                regionPostalIdValue:
                                  suggestion.data.region_fias_id,
                                suggestions: [],
                                d__address__postal__postal_code:
                                  suggestion.data.postal_code ||
                                  d__address__postal__postal_code ||
                                  '',
                              });
                              clearAddressFields([
                                'd__address__postal__city',
                                'cityPostalIdValue',
                                'd__address__postal__street',
                                'streetPostalIdValue',
                                'd__address__postal__house',
                              ]);
                            }}
                          >
                            {dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    d__address__postal__area == '' ? 'hidden' : ''
                  }`}
                >
                  Район
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine`}
                    name="area"
                    type="text"
                    placeholder="Район"
                    readOnly={!isReadableAddress}
                    value={d__address__postal__area}
                    onChange={(e) => {
                      addressChange(
                        e,
                        'd__address__postal__area',
                        'area',
                        'region_fias_id',
                        'regionPostalIdValue'
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusAreaPostal');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusAreaPostal');
                    }}
                  />
                  {this.state.dadataFocusAreaPostal && suggestions.length > 0 && (
                    <ul className="dadataList">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setAddressState({
                              d__address__postal__area: dotify(
                                suggestion.value
                              ),
                              suggestions: [],
                            });
                          }}
                        >
                          {dotify(suggestion.value)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    d__address__postal__city == '' ? 'hidden' : ''
                  }`}
                >
                  Город
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      validate && d__address__postal__city == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      validate && d__address__postal__city != ''
                        ? 'inputValidateCorrect'
                        : ''
                    } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                    name="city"
                    type="text"
                    placeholder="Город"
                    readOnly={!isReadableAddress}
                    value={d__address__postal__city}
                    onChange={(e) => {
                      addressChange(
                        e,
                        'd__address__postal__city',
                        'city',
                        'region_fias_id',
                        'regionPostalIdValue',
                        [
                          'cityPostalIdValue',
                          'd__address__postal__street',
                          'streetPostalIdValue',
                          'd__address__postal__house',
                        ]
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusCityPostal');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusCityPostal');
                    }}
                  />
                  {this.state.dadataFocusCityPostal && suggestions.length > 0 && (
                    <ul className="dadataList">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setAddressState({
                              d__address__postal__city: dotify(
                                suggestion.value
                              ),
                              cityPostalIdValue: suggestion.data.city_fias_id,
                              suggestions: [],
                              d__address__postal__postal_code:
                                suggestion.data.postal_code ||
                                d__address__postal__postal_code ||
                                '',
                              d__address__postal__region: dotify(
                                suggestion.data.region_with_type
                              ),
                              regionPostalIdValue:
                                suggestion.data.region_fias_id,
                            });
                            clearAddressFields([
                              'd__address__postal__street',
                              'streetPostalIdValue',
                              'd__address__postal__house',
                            ]);
                          }}
                        >
                          {dotify(suggestion.value)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    d__address__postal__street == '' ? 'hidden' : ''
                  }`}
                >
                  Улица
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      validate && d__address__postal__street == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      validate && d__address__postal__street != ''
                        ? 'inputValidateCorrect'
                        : ''
                    } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                    name="city"
                    type="text"
                    placeholder="Улица"
                    readOnly={!isReadableAddress}
                    value={d__address__postal__street}
                    onChange={(e) => {
                      addressChange(
                        e,
                        'd__address__postal__street',
                        'street',
                        'city_fias_id',
                        'cityPostalIdValue',
                        ['streetPostalIdValue', 'd__address__postal__house']
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusStreetPostal');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusStreetPostal');
                    }}
                  />
                  {this.state.dadataFocusStreetPostal &&
                    suggestions.length > 0 &&
                    !(d__address__postal__city && !cityPostalIdValue) && (
                      <ul className="dadataList">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setAddressState({
                                d__address__postal__street: dotify(
                                  suggestion.value
                                ),
                                streetPostalIdValue:
                                  suggestion.data.street_fias_id,
                                suggestions: [],
                                d__address__postal__postal_code:
                                  suggestion.data.postal_code ||
                                  d__address__postal__postal_code ||
                                  '',
                                d__address__postal__region: dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionPostalIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__postal__city: dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityPostalIdValue: suggestion.data.city_fias_id,
                              });
                              clearAddressFields(['d__address__postal__house']);
                            }}
                          >
                            {!cityPostalIdValue && !d__address__postal__city
                              ? dotify(suggestion.unrestricted_value)
                              : dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    d__address__postal__house == '' ? 'hidden' : ''
                  }`}
                >
                  Дом
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      validate && d__address__postal__house == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      validate && d__address__postal__house != ''
                        ? 'inputValidateCorrect'
                        : ''
                    } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                    name="house"
                    type="text"
                    placeholder="Дом"
                    readOnly={!isReadableAddress}
                    value={d__address__postal__house}
                    onChange={(e) => {
                      addressChange(
                        e,
                        'd__address__postal__house',
                        'house',
                        'street_fias_id',
                        'streetPostalIdValue'
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusHousePostal');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusHousePostal');
                    }}
                  />
                  {this.state.dadataFocusHousePostal &&
                    suggestions.length > 0 &&
                    !(d__address__postal__house && !cityPostalIdValue) &&
                    d__address__postal__street && (
                      <ul className="dadataList">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setAddressState({
                                d__address__postal__house: dotify(
                                  suggestion.value
                                ),
                                suggestions: [],
                                d__address__postal__postal_code:
                                  suggestion.data.postal_code ||
                                  d__address__postal__postal_code ||
                                  '',
                                d__address__postal__region: dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionPostalIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__postal__city: dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityPostalIdValue: suggestion.data.city_fias_id,
                                d__address__postal__street: dotify(
                                  suggestion.data.street_with_type
                                ),
                                streetPostalIdValue:
                                  suggestion.data.street_fias_id,
                              });
                            }}
                          >
                            {dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    d__address__postal__flat == '' ? 'hidden' : ''
                  }`}
                >
                  Квартира
                </label>
                <input
                  type="text"
                  className="inputUnderLine"
                  placeholder="Квартира"
                  readOnly={!isReadableAddress}
                  value={d__address__postal__flat}
                  onChange={this.onPostalFlatChange}
                />
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <div className="mb-1">
              <div className="form-check form-check-inline">
                <label className="checkboxContainer">
                  Место фактического жительства совпадает с местом регистрации
                  <input
                    type="checkbox"
                    name="sameLive"
                    disabled={!isReadableAddress}
                    value={isSameLive}
                    checked={isSameLive}
                    onChange={this.props.onLiveSameReg}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          {isSameLive && (
            <div className="col-lg-12">
              <div className="formField-container position-relative">
                <input
                  type="text"
                  className="inputUnderLine"
                  placeholder="Почтовый адрес"
                  value={`${d__address__reg__postal_code} ${d__address__reg__region} ${d__address__reg__area} ${d__address__reg__city} ${d__address__reg__street} ${d__address__reg__house} ${d__address__reg__flat}`}
                  readOnly={true}
                />
              </div>
            </div>
          )}
        </div>
        {!isSameLive && (
          <div className="row">
            <div className="col-lg-12 docCreate-anketaAddress-placeSubtitle">
              Место фактического жительства
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    d__address__live__postal_code == '' ? 'hidden' : ''
                  }`}
                >
                  Индекс
                </label>
                <div className="dadataContainer">
                  <input
                    type="text"
                    className={`inputUnderLine ${
                      validate &&
                      d__address__live__postal_code.length != 6
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      validate && d__address__live__postal_code.length == 6
                        ? 'inputValidateCorrect'
                        : ''
                    } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                    placeholder="Индекс"
                    readOnly={!isReadableAddress}
                    value={d__address__live__postal_code}
                    onChange={(e) => {
                      addressChange(
                        e,
                        'd__address__live__postal_code',
                        'postal_code',
                        '',
                        ''
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusPostalCodeLive');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusPostalCodeLive');
                    }}
                  />
                  {this.state.dadataFocusPostalCodeLive &&
                    suggestions.length > 0 && (
                      <ul className="dadataList">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setAddressState({
                                d__address__live__street: dotify(
                                  suggestion.data.street_with_type
                                ),
                                streetLiveIdValue:
                                  suggestion.data.street_fias_id,
                                suggestions: [],
                                d__address__live__postal_code:
                                  suggestion.data.postal_code ||
                                  d__address__live__postal_code ||
                                  '',
                                d__address__live__region: dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionLiveIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__live__city: dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityLiveIdValue: suggestion.data.city_fias_id,
                              });
                            }}
                          >
                            {dotify(suggestion.unrestricted_value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    d__address__live__region == '' ? 'hidden' : ''
                  }`}
                >
                  Регион
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      validate && d__address__live__region == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      validate && d__address__live__region != ''
                        ? 'inputValidateCorrect'
                        : ''
                    } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                    name="region"
                    type="text"
                    placeholder="Регион"
                    readOnly={!isReadableAddress}
                    value={d__address__live__region}
                    onChange={(e) => {
                      addressChange(
                        e,
                        'd__address__live__region',
                        'region',
                        '',
                        '',
                        [
                          'regionLiveIdValue',
                          'd__address__live__area',
                          'd__address__live__city',
                          'cityLiveIdValue',
                          'd__address__live__street',
                          'streetLiveIdValue',
                          'd__address__live__house',
                        ]
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusRegionLive');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusRegionLive');
                    }}
                  />
                  {this.state.dadataFocusRegionLive && suggestions.length > 0 && (
                    <ul className="dadataList">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setAddressState({
                              d__address__live__region: dotify(
                                suggestion.value
                              ),
                              regionLiveIdValue: suggestion.data.region_fias_id,
                              suggestions: [],
                              d__address__live__postal_code:
                                suggestion.data.postal_code ||
                                d__address__live__postal_code ||
                                '',
                            });
                            clearAddressFields([
                              'd__address__live__city',
                              'cityLiveIdValue',
                              'd__address__live__street',
                              'streetLiveIdValue',
                              'd__address__live__house',
                            ]);
                          }}
                        >
                          {dotify(suggestion.value)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    d__address__live__area == '' ? 'hidden' : ''
                  }`}
                >
                  Район
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine`}
                    name="area"
                    type="text"
                    placeholder="Район"
                    readOnly={!isReadableAddress}
                    value={d__address__live__area}
                    onChange={(e) => {
                      addressChange(
                        e,
                        'd__address__live__area',
                        'area',
                        'region_fias_id',
                        'regionLiveIdValue'
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusAreaLive');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusAreaLive');
                    }}
                  />
                  {this.state.dadataFocusAreaLive && suggestions.length > 0 && (
                    <ul className="dadataList">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setAddressState({
                              d__address__live__area: dotify(suggestion.value),
                              suggestions: [],
                            });
                          }}
                        >
                          {dotify(suggestion.value)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    d__address__live__city == '' ? 'hidden' : ''
                  }`}
                >
                  Город
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      validate && d__address__live__city == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      validate && d__address__live__city != ''
                        ? 'inputValidateCorrect'
                        : ''
                    } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                    name="city"
                    type="text"
                    placeholder="Город"
                    readOnly={!isReadableAddress}
                    value={d__address__live__city}
                    onChange={(e) => {
                      addressChange(
                        e,
                        'd__address__live__city',
                        'city',
                        'region_fias_id',
                        'regionLiveIdValue',
                        [
                          'cityLiveIdValue',
                          'd__address__live__street',
                          'streetLiveIdValue',
                          'd__address__live__house',
                        ]
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusCityLive');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusCityLive');
                    }}
                  />
                  {this.state.dadataFocusCityLive && suggestions.length > 0 && (
                    <ul className="dadataList">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setAddressState({
                              d__address__live__city: dotify(suggestion.value),
                              cityLiveIdValue: suggestion.data.city_fias_id,
                              suggestions: [],
                              d__address__live__postal_code:
                                suggestion.data.postal_code ||
                                d__address__live__postal_code ||
                                '',
                              d__address__live__region: dotify(
                                suggestion.data.region_with_type
                              ),
                              regionLiveIdValue: suggestion.data.region_fias_id,
                            });
                            clearAddressFields([
                              'd__address__live__street',
                              'streetLiveIdValue',
                              'd__address__live__house',
                            ]);
                          }}
                        >
                          {dotify(suggestion.value)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    d__address__live__street == '' ? 'hidden' : ''
                  }`}
                >
                  Улица
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      validate && d__address__live__street == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      validate && d__address__live__street != ''
                        ? 'inputValidateCorrect'
                        : ''
                    } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                    name="city"
                    type="text"
                    placeholder="Улица"
                    readOnly={!isReadableAddress}
                    value={d__address__live__street}
                    onChange={(e) => {
                      addressChange(
                        e,
                        'd__address__live__street',
                        'street',
                        'city_fias_id',
                        'cityLiveIdValue',
                        ['streetLiveIdValue', 'd__address__live__house']
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusStreetLive');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusStreetLive');
                    }}
                  />
                  {this.state.dadataFocusStreetLive &&
                    suggestions.length > 0 &&
                    !(d__address__live__city && !cityLiveIdValue) && (
                      <ul className="dadataList">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setAddressState({
                                d__address__live__street: dotify(
                                  suggestion.value
                                ),
                                streetLiveIdValue:
                                  suggestion.data.street_fias_id,
                                suggestions: [],
                                d__address__live__postal_code:
                                  suggestion.data.postal_code ||
                                  d__address__live__postal_code ||
                                  '',
                                d__address__live__region: dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionLiveIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__live__city: dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityLiveIdValue: suggestion.data.city_fias_id,
                              });
                              clearAddressFields(['d__address__live__house']);
                            }}
                          >
                            {!cityLiveIdValue && !d__address__live__city
                              ? dotify(suggestion.unrestricted_value)
                              : dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    d__address__live__house == '' ? 'hidden' : ''
                  }`}
                >
                  Дом
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      validate && d__address__live__house == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      validate && d__address__live__house != ''
                        ? 'inputValidateCorrect'
                        : ''
                    } ${!isReadableAddress ? "anketaReadOnlyFields" : ""}`}
                    name="house"
                    type="text"
                    placeholder="Дом"
                    readOnly={!isReadableAddress}
                    value={d__address__live__house}
                    onChange={(e) => {
                      addressChange(
                        e,
                        'd__address__live__house',
                        'house',
                        'street_fias_id',
                        'streetLiveIdValue'
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusHouseLive');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusHouseLive');
                    }}
                  />
                  {this.state.dadataFocusHouseLive &&
                    suggestions.length > 0 &&
                    !(d__address__live__house && !cityLiveIdValue) &&
                    d__address__live__street && (
                      <ul className="dadataList">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setAddressState({
                                d__address__live__house: dotify(
                                  suggestion.value
                                ),
                                suggestions: [],
                                d__address__live__postal_code:
                                  suggestion.data.postal_code ||
                                  d__address__live__postal_code ||
                                  '',
                                d__address__live__region: dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionLiveIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__live__city: dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityLiveIdValue: suggestion.data.city_fias_id,
                                d__address__live__street: dotify(
                                  suggestion.data.street_with_type
                                ),
                                streetLiveIdValue:
                                  suggestion.data.street_fias_id,
                              });
                            }}
                          >
                            {dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    d__address__live__flat == '' ? 'hidden' : ''
                  }`}
                >
                  Квартира
                </label>
                <input
                  type="text"
                  className="inputUnderLine"
                  placeholder="Квартира"
                  readOnly={!isReadableAddress}
                  value={d__address__live__flat}
                  onChange={this.onLiveFlatChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
