import React from "react";
import "react-dates/initialize";
import "moment/locale/ru";

export default class AnketaForeignBankData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validate: false,
      banks: props.data.banks || [
        { 
          name: "",
          account: "",
          bik_swift: ""
        }
      ],
      d__foreign__bank__end_receiver:
        props.data.d__foreign__bank__end_receiver || "",
      d__foreign__bank__end_receiver_acc:
        props.data.d__foreign__bank__end_receiver_acc || "",
      d__foreign__bank__receiver_bank:
        props.data.d__foreign__bank__receiver_bank || "",
      d__foreign__bank__receiver_acc:
        props.data.d__foreign__bank__receiver_acc || "",
    };
  }

  action = (action, currIndex, dest) => {
    this.setState({ validate: true });
    if (this.validating() || dest === "prev") {
      this.props.action(action, currIndex, "foreignBankData", {
        banks: this.state.banks,
        d__foreign__bank__end_receiver:
          this.state.d__foreign__bank__end_receiver,
        d__foreign__bank__end_receiver_acc:
          this.state.d__foreign__bank__end_receiver_acc,
        d__foreign__bank__receiver_bank:
          this.state.d__foreign__bank__receiver_bank,
        d__foreign__bank__receiver_acc:
          this.state.d__foreign__bank__receiver_acc,
      });
    }
  };
  validating = () => {
    return true;
  };

  render() {
    return (
      <div>
        <h2 className="subtitle">Реквизиты получателя</h2>
        <div className="row mt-3">
          <div className="col-lg-7 pr-4">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__foreign__bank__end_receiver == ""
                    ? "hidden"
                    : ""
                }`}
              >
                Получатель (Бенефициар)
              </label>
              <div>
                <input
                  type="text"
                  className={`inputUnderLine ${
                    this.state.validate &&
                    this.state.d__foreign__bank__end_receiver == ""
                      ? "inputValidateWrong"
                      : ""
                  } ${
                    this.state.validate &&
                    this.state.d__foreign__bank__end_receiver != ""
                      ? "inputValidateCorrect"
                      : ""
                  }`}
                  placeholder="Получатель (Бенефициар)"
                  value={this.state.d__foreign__bank__end_receiver}
                  onChange={(e) => {
                    this.setState({
                      d__foreign__bank__end_receiver: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__foreign__bank__end_receiver_acc == ""
                    ? "hidden"
                    : ""
                }`}
              >
                Счет в банке получателя
              </label>
              <div>
                <input
                  type="text"
                  className={`inputUnderLine ${
                    this.state.validate &&
                    this.state.d__foreign__bank__end_receiver_acc == ""
                      ? "inputValidateWrong"
                      : ""
                  } ${
                    this.state.validate &&
                    this.state.d__foreign__bank__end_receiver_acc != ""
                      ? "inputValidateCorrect"
                      : ""
                  }`}
                  placeholder="Счет в банке получателя"
                  value={this.state.d__foreign__bank__end_receiver_acc}
                  onChange={(e) => {
                    this.setState({
                      d__foreign__bank__end_receiver_acc: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5 pr-4" style={{ marginTop: -8 }}>
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__foreign__bank__receiver_bank == ""
                    ? "hidden"
                    : ""
                }`}
              >
                Банк получатель
              </label>
              <div>
                <input
                  type="text"
                  className={`inputUnderLine ${
                    this.state.validate &&
                    this.state.d__foreign__bank__receiver_bank == ""
                      ? "inputValidateWrong"
                      : ""
                  } ${
                    this.state.validate &&
                    this.state.d__foreign__bank__receiver_bank != ""
                      ? "inputValidateCorrect"
                      : ""
                  }`}
                  placeholder="Банк получатель"
                  value={this.state.d__foreign__bank__receiver_bank}
                  onChange={(e) => {
                    this.setState({
                      d__foreign__bank__receiver_bank: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-7" style={{ marginTop: -8 }}>
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__foreign__bank__receiver_acc == ""
                    ? "hidden"
                    : ""
                }`}
              >
                Счёт банка получателя в банке-корреспонденте (посреднике)
              </label>
              <div>
                <input
                  type="text"
                  className={`inputUnderLine ${
                    this.state.validate &&
                    this.state.d__foreign__bank__receiver_acc == ""
                      ? "inputValidateWrong"
                      : ""
                  } ${
                    this.state.validate &&
                    this.state.d__foreign__bank__receiver_acc != ""
                      ? "inputValidateCorrect"
                      : ""
                  }`}
                  placeholder="Счёт банка получателя в банке-корреспонденте (посреднике)"
                  value={this.state.d__foreign__bank__receiver_acc}
                  onChange={(e) => {
                    this.setState({
                      d__foreign__bank__receiver_acc: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.banks.map((bank, index) => {
          return (
            <React.Fragment>
              <h2 className="subtitle">Посредник №{index + 1}</h2>
              <div className="row mt-3">
                <div className="col-lg-12">
                  <div className="formField-container position-relative">
                    <label
                      className={`floating-label ${
                        bank.name == ""
                          ? "hidden"
                          : ""
                      }`}
                    >
                      Банк корреспондент (посредник)
                    </label>
                    <div>
                      <input
                        type="text"
                        className={`inputUnderLine ${
                          this.state.validate &&
                          bank.name == ""
                            ? "inputValidateWrong"
                            : ""
                        } ${
                          this.state.validate &&
                          bank.name != ""
                            ? "inputValidateCorrect"
                            : ""
                        }`}
                        placeholder="Банк корреспондент (посредник)"
                        value={bank.name}
                        onChange={(e) => {
                          bank.name = e.target.value;
                          this.setState({
                            banks: [...this.state.banks],
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-lg-12">
                  <div className="formField-container position-relative">
                    <label
                      className={`floating-label ${
                        bank.account == ""
                          ? "hidden"
                          : ""
                      }`}
                    >
                      Счёт банка корреспондента (посредника) в другом банке корреспонденте (посреднике)
                    </label>
                    <div>
                      <input
                        type="text"
                        className={`inputUnderLine ${
                          this.state.validate &&
                          bank.account == ""
                            ? "inputValidateWrong"
                            : ""
                        } ${
                          this.state.validate &&
                          bank.account != ""
                            ? "inputValidateCorrect"
                            : ""
                        }`}
                        placeholder="Счёт банка корреспондента (посредника) в другом банке корреспонденте (посреднике)"
                        value={bank.account}
                        onChange={(e) => {
                          bank.account = e.target.value;
                          this.setState({
                            banks: [...this.state.banks],
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-lg-12">
                  <div className="formField-container position-relative">
                    <label
                      className={`floating-label ${
                        bank.bik_swift == ""
                          ? "hidden"
                          : ""
                      }`}
                    >
                      БИК банка корреспондента (посредника) / Swift-код банка корреспондента (посредника)
                    </label>
                    <div>
                      <input
                        type="text"
                        className={`inputUnderLine ${
                          this.state.validate &&
                          bank.bik_swift == ""
                            ? "inputValidateWrong"
                            : ""
                        } ${
                          this.state.validate &&
                          bank.bik_swift != ""
                            ? "inputValidateCorrect"
                            : ""
                        }`}
                        placeholder="БИК банка корреспондента (посредника) / Swift-код банка корреспондента (посредника)"
                        value={bank.bik_swift}
                        onChange={(e) => {
                          bank.bik_swift = e.target.value;
                          this.setState({
                            banks: [...this.state.banks],
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )
        })}
        <button
            className="btn btn-success addBank"
            onClick={() => {
              this.state.banks.push({ name: "", account: "", bik_swift: "" })
              this.setState({ banks: [...this.state.banks] });
            }}
          >
          Добавить посредника
        </button>
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <button
            className="btn btnBack"
            onClick={() => {
              this.action(this.props.previous, 3, "prev");
            }}
          >
            Назад
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              this.action(this.props.next, 5);
            }}
          >
            Далее
          </button>
        </div>
      </div>
    );
  }
}
