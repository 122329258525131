import React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import InputMask from 'react-input-mask';

import { catchPromise } from '../utils/http';
import { getPhoneCode } from '../utils/utilsHttp';
import { getProducts } from '../utils/shopHttp';

export class ShopListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    this.loadProducts();
  }
  loadProducts = () => {
    getProducts()
      .then((res) => {
        this.setState({ products: res.data.items });
      })
      .catch((err) => {
        catchPromise(err, this.loadProducts, this.errorCb);
      });
  };

  render() {
    return (
      <div className="content shop content-shopList shopList">
        {!this.state.pageError ? (
          <div>
            {this.state.products.map((product, index) => (
              <div key={index} className="shopList-container">
                <div
                  className="shopList-image"
                  style={{ backgroundImage: `url(${product.image_url})` }}
                ></div>
                <div className="card">
                  <div className="card-body">
                    <div className="shopListContent">
                      <div className="mt-2 shopListTitle">{product.title}</div>
                      <div className="mt-2 shopListText">
                        {product.short_description
                          ? product.short_description.replace(
                              /(?:\r\n|\r|\n)/g,
                              '<br />'
                            )
                          : product.short_description}
                      </div>
                    </div>
                    <hr />
                    <div className="shopListInfoBlock">
                      <p className="shopListInfo-name mb-1">
                        Цена: <b>{product.price} р.</b>
                      </p>
                      <div className="shopListInfo-link position-relative d-flex justify-content-between align-items-center">
                        <a
                          className="btn btn-primary"
                          href={`/shop/${product.id}`}
                        >
                          Подробнее
                        </a>
                        <a
                          className="btn btn-success"
                          href={`/shop/${product.id}/buy`}
                        >
                          Купить
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="shopContacts-link">
              <a href="/shop/contacts" target="_blank">
                Контакты
              </a>
            </div>
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
      </div>
    );
  }
}

export default connect()(ShopListPage);
