import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import cookie from 'react-cookies';

import { logout, catchPromise } from '../utils/http';
import { getTradingAccounts } from '../utils/transactionsHttp';
import { setTradingAccounts } from '../actions/tradingAccounts';
import { setTradingAccount } from '../actions/tradingAccount';
import SidebarComponent from '../components/Sidebar';

export class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: props.tradingAccounts.trading_accounts,
      accountId:
        props.tradingAccounts.trading_accounts && props.tradingAccounts.trading_accounts.length > 0
          ? props.tradingAccounts.trading_accounts[0].id
          : '',
      balance: props.tradingAccounts.balance_total,
      selectIsOpened: false,
    };
  }
  errorCb = () => {
    // alert('Произошла ошибка при получении баланса');
  };
  componentDidMount() {
    $('body').on('click', () => {
      if (this.state.selectIsOpened) {
        setTimeout(() => this.setState({ selectIsOpened: false }), 100);
      }
    });
    this.loadHeaderData();
  }
  loadHeaderData = () => {
    getTradingAccounts()
      .then((res) => {
        let account = {};
        if (
          cookie.load('accountObj') &&
          res.data.trading_accounts.find(
            (acc) => acc.id === cookie.load('accountObj').id
          )
        ) {
          account = res.data.trading_accounts.find(
            (acc) => acc.id === cookie.load('accountObj').id
          );
        } else {
          res.data.trading_accounts.length > 0 &&
            (account = res.data.trading_accounts.find(tradingAccount => !tradingAccount.bonus) || res.data.trading_accounts[0]);
        }

        this.props.setTradingAccounts(res.data);
        this.props.setTradingAccount(account);
        cookie.save('accountObj', account);
        this.setState({
          accountId: JSON.stringify(account) != '{}' ? account.id : '',
        });
      })
      .catch((err) => {
        catchPromise(err, this.loadHeaderData, this.errorCb);
      });
  };

  onLogout = (e) => {
    e.preventDefault();
    var elementUserEcho = document.getElementById('script_userecho');
    if (elementUserEcho) {
      elementUserEcho.parentNode.removeChild(elementUserEcho);
    }
    var elementChat = document.getElementById('chat');
    if (elementChat) {
      elementChat.parentNode.removeChild(elementChat);
    }
    var elementChatIframe = document.getElementById('chat-iframe-container');
    if (elementChatIframe) {
      elementChatIframe.parentNode.removeChild(elementChatIframe);
    }
    window.UE = null;
    logout();
  };

  onChangeAccountId = (accountId) => {
    this.props.setTradingAccount(
      this.props.tradingAccounts.trading_accounts.find(
        (obj) => obj.id === accountId
      )
    );
    this.setState({ selectIsOpened: false, accountId });
    cookie.save(
      'accountObj',
      this.props.tradingAccounts.trading_accounts.find(
        (obj) => obj.id === accountId
      )
    );
  };
  selectIsOpenedChange = () => {
    this.setState({ selectIsOpened: true });
  };

  render() {
    return (
      <SidebarComponent
        balance={this.props.tradingAccounts.balance_total}
        accounts={this.props.tradingAccounts.trading_accounts}
        currentAccount={this.props.tradingAccount}
        accountId={this.state.accountId}
        user={this.props.user}
        selectIsOpened={this.state.selectIsOpened}
        onLogout={this.onLogout}
        updates={this.props.updates}
        selectIsOpenedChange={this.selectIsOpenedChange}
        onChangeAccountId={this.onChangeAccountId}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    updates: state.updates,
    tradingAccounts: state.tradingAccounts,
    tradingAccount: state.tradingAccount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTradingAccounts: (tradingAccounts) =>
    dispatch(setTradingAccounts(tradingAccounts)),
  setTradingAccount: (tradingAccount) =>
    dispatch(setTradingAccount(tradingAccount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
