import React from "react";
import Popup from "../containers/Popup";
import { Link } from "react-router-dom";
import { deleteGoogleAuth } from "../utils/googleAuthHttp";
import GoogleAuthWrongCodeModal from "./GoogleAuthWrongCodeModal";
import { PasswordMask } from "./PasswordMask";

export default class GoogleAuthRemoveVerify extends React.Component {
  constructor(props) {
    super(props);
    this.passwordRef = React.createRef();

    this.state = {
      prevPage: props.prevPage,
      nextPage: props.nextPage,
      password: "",
      passwordVisible: false,
      otp_password: "",
      errorMessage: {},
      modalIsOpened: false,
    };
  }

  componentDidMount() {
    this.passwordRef.current.focus();
  }

  onChangePassword = (e) => {
    if (e.target.value.length <= 128) {
      if (e.target.value.length <= 128) {
        const password = e.target.value;
        this.setState(() => ({ 
          password
        }));
      }
    }
  };

  onOtpChangePassword = (e) => {
    const value = e.target.value
      .split("")
      .filter((c) =>
        ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(c)
      )
      .slice(0, 6)
      .join("");

    this.setState({
      otp_password: value,
    });
  };

  action = (action, currIndex) => {
    deleteGoogleAuth({
      password: this.state.password,
      otp_password: this.state.otp_password,
    })
      .then(() => {
        this.props.action(action, currIndex);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          this.setState({ errorMessage: err.response.data.message[0] });
        } else {
          if (err.response.data && err.response.data.message) {
            this.setState({ errorMessage: err.response.data.message })
          }
        }
      });
  };

  render() {
    return (
      <div className="googleAuthAddVerify">
        {this.state.modalIsOpened ? (
          <GoogleAuthWrongCodeModal
            modalIsOpened={this.state.modalIsOpened}
            closeModal={() => this.setState({ modalIsOpened: false })}
          />
        ) : null}
        <div className="row">
          <h2 className="col-12 subtitle">Подтвердите отключение</h2>
        </div>
        <div>
          Закончите подключение Google Authenticator, подтвердив действие при
          помощи пароля, с которым заходите в личный кабинет.
        </div>
        <div className="mt-4 col-12 text-center">
          <div className="position-relative formField-container col-6 m-auto p-0">
            <div className="d-flex w-100">
              <label
                className={`floating-label ${
                  this.state.password == "" ? "hidden" : ""
                }`}
              >
                Пароль от личного кабинета
              </label>
              <input
                ref={this.passwordRef}
                type={this.state.passwordVisible ? "text" : "password"}
                className={`inputUnderLine ${this.state.errorMessage &&
                  this.state.errorMessage.field === "password" ? 'inputValidateWrong' : ''}`}
                placeholder="Пароль от личного кабинета"
                value={this.state.password}
                onChange={this.onChangePassword}
              />
              <PasswordMask
                isInputOutlined={true}
                passwordVisible={this.state.passwordVisible}
                setPasswordVisible={(passwordVisible) => this.setState({ passwordVisible })}
              />
            </div>
            {this.state.errorMessage && this.state.errorMessage.field === "password" ? (
              <div className="text-danger text-left">
                {this.state.errorMessage.error}
              </div>
            ) : null}
          </div>
        </div>
        <div className="mt-3 col-12 text-center">
          <div className="position-relative formField-container col-6 m-auto p-0">
            <div className="text-left">6-значный код из приложения для двухэтапной верификации</div>
            <input
              type="text"
              className={`inputUnderLine ${this.state.errorMessage &&
                this.state.errorMessage.field === "otp_password" ? 'inputValidateWrong' : ''}`}
              value={this.state.otp_password}
              onChange={this.onOtpChangePassword}
            />
            {this.state.errorMessage && this.state.errorMessage.field === "otp_password" ? (
              <div className="text-danger text-left">
                {this.state.errorMessage.error}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className="codeNoMatch col-6 p-0"
          onClick={() => this.setState({ modalIsOpened: true })}
        >
          Код не подходит
        </div>
        {this.state.errorMessage &&
          this.state.errorMessage.field === "no_field" ? (
          <div
            className="col-6 p-0 text-danger text-left mx-auto mt-2"
          >
            {this.state.errorMessage.error}
          </div>
        ) : null}
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <Link to="/app/profile">
            <button className="btn btnBack">Назад</button>
          </Link>
          <button
            disabled={!this.state.password || !this.state.otp_password}
            className="btn btn-success"
            onClick={() => {
              this.action(this.props.next, this.state.nextPage, "next");
            }}
          >
            Далее
          </button>
        </div>
      </div>
    );
  }
}
