import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import 'moment/locale/ru';
import { DateRangePicker } from 'react-dates';
import Baron from 'react-baron/dist/es5';
import amplitude from 'amplitude-js';

import { catchPromise } from '../utils/http';
import Transactions from '../components/Transactions';
import {
  getTransactions,
  getTransactionTypes,
  getTransactionTotals,
} from '../utils/transactionsHttp';
import { currencyFormat, sumColor } from '../utils/currencyUtil';
import { setTransactions } from '../actions/transactions';

const isCrypto = true;

export class TransactionsPage extends React.Component {
  constructor(props) {
    super(props);

    this.isSetScrollMainTable = false;
    this.state = {
      filters: {
        accountFilter: props.tradingAccount.id || '',
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
        pageNum: 1,
      },
      transactionTypesLoaded: false,
      transactions: [],
      transactionTypes: [],
      checkedOthers: true,
      transactionTotals: {},
      currency: 'RUR',
      accountFilterList: props.tradingAccounts.trading_accounts,
      page_size: 20,
      dateRangeCurr: 'dateRangeMonth',
      calendarFocused: null,
      moreBtnVisible: false,
      endedData: false,
      isLoading: true,
      loaded: false,
      pageError: false,
    };
  }

  errorCb = () => {
    this.setState({ pageError: true });
  };

  componentDidMount() {
    amplitude.getInstance().logEvent('transactions_page.page_view');

    moment.locale('ru');
    if (this.props.tradingAccount.id) {
      this.searchTransactions();
      this.loadTransactionTotals();
    }
  }
  componentDidUpdate(prevProps) {
    if (Object.keys(this.props.tradingAccount).length && !this.state.transactionTypesLoaded) {
      this.setState({ transactionTypesLoaded: true });
      this.loadTransactionTypes();
    } else if (Object.keys(this.props.tradingAccount).length && Object.keys(prevProps.tradingAccount).length && this.props.tradingAccount.bonus !== prevProps.tradingAccount.bonus) {
      this.loadTransactionTypes();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.tradingAccount.id !== nextProps.tradingAccount.id) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            accountFilter: nextProps.tradingAccount.id || '',
          },
        },
        () => {
          this.searchTransactions();
          this.loadTransactionTotals();
        }
      );
    }
  }

  loadTransactionTypes = () => {
    this.setState({ isLoading: true });
    getTransactionTypes(this.props.tradingAccount.bonus ? "bonus" : "trader")
      .then((res) => {
        let types = res.data.types.filter((type) => type.id != 'PAY_OUT');
        for (let i = 0; i < types.length; i++) {
          types[i]['checked'] = true;
          types[i]['index'] = i;
        }
        this.setState({ transactionTypes: types, isLoading: false });
      })
      .catch((err) => {
        catchPromise(err, this.loadTransactionTypes, this.errorCb);
      });
  };

  searchTransactions = () => {
    this.isSetScrollMainTable = false;
    this.setState({
      filters: { ...this.state.filters, pageNum: 1 },
      isLoading: true,
      endedData: false,
    });

    let transaction_type = '';
    for (let i = 0; i < this.state.transactionTypes.length; i++) {
      if (this.state.transactionTypes[i].checked)
        transaction_type += `${this.state.transactionTypes[i].id},`;
    }
    if (transaction_type.length > 0)
      transaction_type = transaction_type.slice(0, -1);

    getTransactions({
      page_num: 1,
      date_start: this.state.filters.startDate.format('DD-MM-YYYY'),
      date_end: this.state.filters.endDate.format('DD-MM-YYYY'),
      account_id: this.state.filters.accountFilter,
      transaction_type,
    })
      .then((res) => {
        this.props.setTransactions(res.data);
        this.setState({
          endedData: res.data.transactions.length < this.state.page_size,
          isLoading: false,
        });
      })
      .catch((err) => {
        catchPromise(err, this.searchTransactions, this.errorCb);
      });
  };

  loadTransactionTotals = () => {
    getTransactionTotals({
      date_start: this.state.filters.startDate.format('DD-MM-YYYY'),
      date_end: this.state.filters.endDate.format('DD-MM-YYYY'),
      account_id: this.state.filters.accountFilter,
    })
      .then((res) => {
        this.setState({ transactionTotals: res.data });
      })
      .catch((err) => {
        catchPromise(err, this.loadTransactionTotals, this.errorCb);
      });
  };

  moreTransactions = () => {
    if (!this.state.endedData) {
      let transaction_type = '';
      for (let i = 0; i < this.state.transactionTypes.length; i++) {
        if (this.state.transactionTypes[i].checked)
          transaction_type += `${this.state.transactionTypes[i].id},`;
      }
      if (transaction_type.length > 0)
        transaction_type = transaction_type.slice(0, -1);
      this.setState({ loaded: true });

      getTransactions({
        page_num: this.state.filters.pageNum + 1,
        date_start: this.state.filters.startDate.format('DD-MM-YYYY'),
        date_end: this.state.filters.endDate.format('DD-MM-YYYY'),
        account_id: this.state.filters.accountFilter,
        transaction_type,
      })
        .then((res) => {
          this.props.setTransactions({
            transactions: [
              ...this.props.transactions.transactions,
              ...res.data.transactions,
            ],
            currency: res.data.currency,
            balance: res.data.balance,
            page_size: res.data.page_size,
          });
          this.setState({
            filters: {
              ...this.state.filters,
              pageNum: this.state.filters.pageNum + 1,
            },
            moreBtnVisible:
              res.data.transactions.length < this.state.page_size
                ? false
                : true,
            endedData: res.data.transactions.length < this.state.page_size,
            loaded: false,
          });
        })
        .catch((err) => {
          catchPromise(err, this.moreTransactions, this.errorCb);
        });
    }
  };
  onChangeFilter = (index) => {
    let tempTypes = this.state.transactionTypes;
    tempTypes[index].checked = !tempTypes[index].checked;
    this.setState({ transactionTypes: tempTypes }, this.searchTransactions());
  };
  onChangeFilterOthers = () => {
    let tempTypes = this.state.transactionTypes;
    for (let i = 0; i < tempTypes.length; i++) {
      if (tempTypes[i].include_in_other)
        tempTypes[i].checked = !tempTypes[i].checked;
    }

    this.setState(
      { transactionTypes: tempTypes, checkedOthers: !this.state.checkedOthers },
      this.searchTransactions()
    );
  };
  onDatesChange = ({ startDate, endDate, dateRange }) => {
    this.setState(
      {
        filters: { ...this.state.filters, startDate, endDate },
        dateRangeCurr: dateRange,
      },
      () => {
        if (this.state.filters.startDate && this.state.filters.endDate) {
          this.searchTransactions();
          this.loadTransactionTotals();
        }
      }
    );
  };
  onFocusChange = (calendarFocused) => {
    this.setState({ calendarFocused });
  };

  selectCalendarDates = (dateRangeCurr) => {
    switch (dateRangeCurr) {
      case 'dateRangeWeek':
        this.onDatesChange({
          startDate: moment().startOf('week'),
          endDate: moment().endOf('week'),
          dateRange: dateRangeCurr,
        });
        break;
      case 'dateRangeMonth':
        this.onDatesChange({
          startDate: moment().startOf('month'),
          endDate: moment().endOf('month'),
          dateRange: dateRangeCurr,
        });
        break;
      case 'dateRangeQuarter':
        this.onDatesChange({
          startDate: moment().startOf('quarter'),
          endDate: moment().endOf('quarter'),
          dateRange: dateRangeCurr,
        });
        break;
      default:
        break;
    }
  };

  scrollContent = (e) => {
    const pageScroller = $('.card .clipper > .scroller');
    const pageContentWrap = $('#mainTable');

    if (
      pageScroller.scrollTop() + pageScroller.height() >
        pageContentWrap.height() - 60 &&
      !this.state.loaded &&
      !this.state.endedData
    )
      this.moreTransactions();
  };
  setScrollEvent = () => {
    if (document.getElementById('mainTable') && !this.isSetScrollMainTable) {
      this.isSetScrollMainTable = true;
      document
        .getElementById('mainTable')
        .addEventListener('wheel', this.scrollContent);
    }
  };

  render() {
    return (
      <div className="row">
        <div className="page-content-center transactions">
          <h1 className="title card-content">Транзакции</h1>
          <div className="card card-content">
            <div
              className={`card-body p-0 finresFilters ${this.state.dateRangeCurr}`}
            >
              <div className="tableFilters-container">
                <div className="tableFilters-dates">
                  <p className="finresFilters-title">Период</p>
                  <div className="finresFilters-datePeriod mb-3">
                    <div className="tableFilters-datesRangeWeek">
                      <span
                        className="activePeriodWeek"
                        onClick={() => {
                          this.selectCalendarDates('dateRangeWeek');
                        }}
                      >
                        неделя
                      </span>
                    </div>
                    <div className="tableFilters-datesRangeMonth">
                      <span
                        className="activePeriodMonth"
                        onClick={() => {
                          this.selectCalendarDates('dateRangeMonth');
                        }}
                      >
                        месяц
                      </span>
                    </div>
                    <div className="tableFilters-datesRangeQuarter">
                      <span
                        className="activePeriodQuarter"
                        onClick={() => {
                          this.selectCalendarDates('dateRangeQuarter');
                        }}
                      >
                        квартал
                      </span>
                    </div>
                  </div>
                  <p className="finresFilters-title finresFilters-title-customPeriod">
                    Свой период
                  </p>
                  <div className="form-group">
                    <DateRangePicker
                      startDate={this.state.filters.startDate}
                      endDate={this.state.filters.endDate}
                      onDatesChange={({ startDate, endDate }) => {
                        this.onDatesChange({
                          startDate,
                          endDate,
                          dateRange: 'dateRangeCustom',
                        });
                      }}
                      startDatePlaceholderText="Начало"
                      endDatePlaceholderText="Конец"
                      focusedInput={this.state.calendarFocused}
                      onFocusChange={this.onFocusChange}
                      startDateId="start"
                      endDateId="end"
                      numberOfMonths={2}
                      minimumNights={0}
                      isOutsideRange={() => false}
                      hideKeyboardShortcutsPanel={true}
                    />
                  </div>
                </div>
                <div className="tableFilters-types">
                  {this.state.transactionTypes
                    .filter((type) => !type.include_in_other)
                    .map((type, index) => (
                      <div
                        key={type.id}
                        className="d-flex justify-content-between"
                      >
                        <p
                          className={`filter-subtitle ${
                            !type.checked ? 'text-muted' : ''
                          }`}
                        >
                          {type.name}
                        </p>
                        <div className="text-left">
                          <div className="switchContainer switchContainer-labelLeft">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={type.checked}
                                value={type.checked}
                                onChange={(e) => this.onChangeFilter(type.index)}
                              />
                              <div className="slider"></div>
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  {isCrypto ? null : (
                    <div className="d-flex justify-content-between">
                      <p
                        className={`filter-subtitle ${
                          !this.state.checkedOthers ? 'text-muted' : ''
                        }`}
                      >
                        Иное
                      </p>
                      <div className="text-left">
                        <div className="switchContainer switchContainer-labelLeft">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={this.state.checkedOthers}
                              value={this.state.checkedOthers}
                              onChange={(e) => this.onChangeFilterOthers()}
                            />
                            <div className="slider"></div>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="tableFilters-totals">
                  <div>
                    <div className="d-flex justify-content-between font-weight-bold">
                      <p>Итого за период</p>
                      <div></div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="totalSubTitle">Начало</p>
                      <p
                        className={`text-right totalSubTitle-num ${sumColor(
                          this.state.transactionTotals.balance_start || '0.00'
                        )}`}
                      >
                        {this.state.transactionTotals.balance_start
                          ? currencyFormat(
                              this.state.transactionTotals.balance_start
                            )
                          : '0.00'}
                      </p>
                    </div>
                    {isCrypto ? null : (
                      <div className="d-flex justify-content-between">
                        <p className="totalSubTitle">ФинРез</p>
                        <p
                          className={`text-right totalSubTitle-num ${sumColor(
                            this.state.transactionTotals.finres_total || '0.00'
                          )}`}
                        >
                          {this.state.transactionTotals.finres_total
                            ? currencyFormat(
                                this.state.transactionTotals.finres_total
                              )
                            : '0.00'}
                        </p>
                      </div>
                    )}
                    {/*
                                        <div className="row">
                                            <p className="col-6 pl-0 totalSubTitle">PayOut</p>
                                            <p className={`col-6 pr-0 text-right totalSubTitle-num ${sumColor(this.state.transactionTotals.payout_total || '0.00')}`}>
                                                {this.state.transactionTotals.payout_total ? currencyFormat(this.state.transactionTotals.payout_total) : '0.00'}
                                            </p>
                                        </div>
                                        */}
                    <div className="d-flex justify-content-between">
                      <p className="totalSubTitle">Пополнение</p>
                      <p
                        className={`text-right totalSubTitle-num ${sumColor(
                          this.state.transactionTotals.cash_total || '0.00'
                        )}`}
                      >
                        {this.state.transactionTotals.cash_total
                          ? currencyFormat(
                              this.state.transactionTotals.cash_total
                            )
                          : '0.00'}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="totalSubTitle">Конец</p>
                      <p
                        className={`text-right totalSubTitle-num ${sumColor(
                          this.state.transactionTotals.balance_end || '0.00'
                        )}`}
                      >
                        {this.state.transactionTotals.balance_end
                          ? currencyFormat(
                              this.state.transactionTotals.balance_end
                            )
                          : '0.00'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {this.props.transactions.transactions.length > 0 &&
              !this.state.isLoading ? (
                <div>
                  <table className="table">
                    <thead>
                      <tr className="finres-tableTitles">
                        <td className="transactionTableData">ДАТА</td>
                        <td className="text-left transactionTableDesc">
                          ОПИСАНИЕ
                        </td>
                        <td className="text-left transactionTablePlace">
                          ПЛОЩАДКА
                        </td>
                        <td className="transactionTableSum">СУММА</td>
                      </tr>
                    </thead>
                  </table>
                  <Baron>
                    {this.setScrollEvent()}
                    <div id="mainTable">
                      <table className="table">
                        <tbody>
                          {this.props.transactions.transactions.map(
                            (transaction, index) => (
                              <Transactions
                                key={index}
                                transaction={transaction}
                                currency={this.props.transactions.currency}
                              />
                            )
                          )}
                        </tbody>
                      </table>
                      {this.state.loaded && (
                        <div
                          className="mt-1 text-center spinner-container"
                          style={{ margin: '10px 0' }}
                        >
                          <span>
                            <img
                              src="/images/ui/load-spinner.png"
                              className="spinner"
                              alt=""
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  </Baron>
                </div>
              ) : (
                <div className="emptyDashboard-plug">
                  {this.state.isLoading ? (
                    <div className="mt-1 text-center spinner-container isLoadingSpinner">
                      <span>
                        <img
                          src="/images/ui/load-spinner.png"
                          className="spinner"
                          alt=""
                        />
                      </span>
                    </div>
                  ) : (
                    <div className="emptyData">нет данных</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tradingAccounts: state.tradingAccounts,
    tradingAccount: state.tradingAccount,
    user: state.user,
    transactions: state.transactions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTransactions: (transactions) => dispatch(setTransactions(transactions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);
