import axios from 'axios';
import Config from '../config/config';

export const sendAnketaData = (anketaData) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/users/personal-form/`,
    withCredentials: true,
    data: anketaData,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const sendAnketaDataUpdate = (anketaData) => {
  return axios({
    method: 'put',
    url: `${Config.apiUrl}/v1/users/personal-form/`,
    withCredentials: true,
    data: anketaData,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const uploadFile = (dataFile) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/files/personal-form/`,
    withCredentials: true,
    data: dataFile,
  });
};

export const getAnketa = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/users/personal-form/`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
