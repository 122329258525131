import axios from 'axios';
import Config from '../config/config';
import { history } from '../routers/AppRouter';

export const getUserTradingData = () => {
  return axios.get(`${Config.apiUrl}/v1/crypto_settings/`, {
    withCredentials: true,
  });
}
export const postCryptoSettings = (data) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/crypto_settings/`,
    data,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
// export const setUserData = (skype) => {
//     return axios({
//         method: 'put',
//         url: `${Config.apiUrl}/v1/users/`,
//         data: {"user": {skype}},
//         withCredentials: true,
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     })
// }
export const setUserSettings = (settings) => {
  return axios({
    method: 'put',
    url: `${Config.apiUrl}/v1/users/`,
    data: settings,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const changeUserPassword = (new_password, new_password_repeat, current_password) => {
  return axios({
    method: 'put',
    url: `${Config.apiUrl}/v1/users/password/`,
    data: { new_password, new_password_repeat, old_password: current_password },
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const changeUserPasswordTrading = (id, password) => {
  return axios({
    method: 'put',
    url: `${Config.apiUrl}/v1/platform-accounts/${id}/`,
    data: { password },
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const regUser = (user) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/users/register/`,
    data: user,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const sendConfirmationLink = (data) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/users/send_confirmation_link/`,
    data,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export const confirmRegister = (data) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/users/confirm_register/`,
    data,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
