import React from 'react';
import { connect } from 'react-redux';
import Baron from 'react-baron/dist/es5';

import { catchPromise } from '../utils/http';
import Rating from '../components/Rating';
import {getRating} from '../utils/ratingHttp';

export class RatingPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ratingList: [],
            page: 1,
            pages_total: 1,
            page_size: 100,
            isLoading: false,
            pageError: false,
        };
    }
    errorCb = () => {
        this.setState({ pageError: true });
    };
    componentDidMount() {
        this.loadRating(this.state.page);
        $('.ratingHowBtn').click(function(){
            $('.rating').addClass('isHinting');
        })
        $('.ratingHint-closeBtn').click(function(){
            $('.rating').removeClass('isHinting');
        })
    }
    loadRating = (page) => {
        this.setState({isLoading: true}, () => {
            getRating(page)
            .then((res) => {
                console.log(res.data);
                this.setState({
                    ratingList: this.state.ratingList.concat(res.data.leaders), 
                    page: res.data.page, 
                    page_size: res.data.page_size, 
                    pages_total: res.data.pages_total,
                    isLoading: false
                })
            })
            .catch(err => {
                catchPromise(err, this.loadRating, this.errorCb);
            })
        })
    }

    scrollContent = () => {
      const pageScroller = $('.ratingTable .clipper > .scroller');
      const pageContentWrap = $('.ratingTable .clipper > .scroller > .table');
      if (
        pageScroller.scrollTop() + pageScroller.height() >= (pageContentWrap.height() - 70) &&
        this.state.page < this.state.pages_total &&
        !this.state.isLoading
      ) {
        this.loadRating(this.state.page + 1);
      }
    };

    render() {
        return (
            <div className="row rating">
                <div className="page-content-center">
                    {!this.state.pageError ? (
                        <div>
                            <div className="card-content">                    
                                <div className="card">
                                    <div className="card-body">
                                        <div className="ratingTitles">
                                            <div className="ratingTitle">Текущий рейтинг</div>
                                            <div className="ratingHowBtn">Как начисляются баллы</div>
                                        </div>
                                        <Rating 
                                            ratingList={this.state.ratingList}
                                            scrollContent={this.scrollContent}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <h3 className="text-center text-danger">Произошла ошибка</h3>
                    )}
                </div>
                <div className="ratingHint">
                    <Baron>
                        <div>
                            <div className="ratingHint-closeBtn">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                        <div className="ratingHint-title">Общее</div>
                        <ul>
                            <li className="ratingHint-item">
                                <div className="ratingHint-itemText">
                                    Баллы обнуляются каждый месяц.
                                </div>
                            </li>
                            <li className="ratingHint-item">
                                <div className="ratingHint-itemText">
                                    В конкурсе участвуют трейдеры с риск капиталом до 200к. 
                                </div>
                            </li>
                            <li className="ratingHint-item">
                                <div className="ratingHint-itemText">
                                    При доступе к нескольким торговым площадкам, количество баллов высчитывается по каждой отдельной площадке.
                                </div>
                            </li>
                        </ul>
                        <div className="ratingHint-title">Приз победителям</div>
                        <ul>
                            <li className="ratingHint-item">
                                <div className="ratingHint-itemText">
                                    1 место: 20 000 риск капитала + худи и кепка
                                </div>
                            </li>
                            <li className="ratingHint-item">
                                <div className="ratingHint-itemText">
                                    2 место: 10 000 риск капитала
                                </div>
                            </li>
                            <li className="ratingHint-item">
                                <div className="ratingHint-itemText">
                                    3 место: 5 000 риск капитала
                                </div>
                            </li>
                            <li className="ratingHint-item">
                                <div className="ratingHint-itemText">
                                    Призовые места не делятся, победителей может быть несколько на каждом призовом месте.
                                </div>
                            </li>
                        </ul>
                        <div className="ratingHint-title">Начисление баллов</div>
                        <ul>
                            <li className="ratingHint-item">
                                <div className="ratingHint-itemText">
                                    Положительный результат трейдера после окончания торговой сессии составляет от 30% до 199% от просадки.
                                </div>
                                <div className="ratingHint-itemNum ratingHint-itemNumPlus">+1</div>
                            </li>
                            <li className="ratingHint-item">
                                <div className="ratingHint-itemText">
                                    Положительный результат трейдера после окончания торговой сессии составляет  200% и более от просадки.
                                </div>
                                <div className="ratingHint-itemNum ratingHint-itemNumPlus">+3</div>
                            </li>
                            <li className="ratingHint-item">
                                <div className="ratingHint-itemText">
                                    Закрытие 5 положительных торговых сессий подряд, при условии, что каждая из этих торговых сессий была закрыта с положительным результатом более 30%. Баллы начисляются при условии положительных дней в одной торговой неделе.
                                </div>
                                <div className="ratingHint-itemNum ratingHint-itemNumPlus">+3</div>
                            </li>
                            <li className="ratingHint-item">
                                <div className="ratingHint-itemText">
                                    Полезная активность в дискорд, на мастер классах, разборах и любых других мероприятиях компании (начисляется ведущим трейдером).
                                </div>
                                <div className="ratingHint-itemNum ratingHint-itemNumPlus">+1</div>
                            </li>
                        </ul>
                        <div className="ratingHint-title">Списание баллов</div>
                        <ul>
                            <li className="ratingHint-item">
                                <div className="ratingHint-itemText">
                                    Отрицательный результат трейдера составляет более 20% от просадки.
                                </div>
                                <div className="ratingHint-itemNum ratingHint-itemNumMinus">-1</div>
                            </li>
                            <li className="ratingHint-item">
                                <div className="ratingHint-itemText">
                                    Отрицательный результат трейдера превышает лимит дневной просадки.
                                </div>
                                <div className="ratingHint-itemNum ratingHint-itemNumMinus">-2</div>
                            </li>
                        </ul>
                    </Baron>
                </div>
            </div>
        );
    }
}

export default connect()(RatingPage);
