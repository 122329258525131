import axios from 'axios';
import Config from '../config/config';

export const getTops = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/tops/`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getAccountBoards = (accId) => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/trading-accounts/${accId}/boards/`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
