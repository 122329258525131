import { SET_ANALYSIS_SYMBOLS } from '../actions/analysis';

// Expenses Reducer
const reducerDefaultState = { data: [], symbols: [], symbols_total_abs: [] };

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_ANALYSIS_SYMBOLS:
      return action.analysis;
    default:
      return state;
  }
};
