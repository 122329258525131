import React, { useEffect, useState } from "react";
import "moment/locale/ru";
import Baron from "react-baron/dist/es5";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import amplitude from 'amplitude-js';

import { getTradingAccounts, innerTransfer } from "../utils/transactionsHttp";
import { setTradingAccounts } from "../actions/tradingAccounts";
import { setTradingAccount } from "../actions/tradingAccount";
import cookie from 'react-cookies';

export const InnerTransferCreate = (props) => {
  const [error, setError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [accountFrom, setAccountFrom] = useState("SPOT");
  const [accountFromSelectIsOpened, setAccountFromSelectIsOpened] =
    useState(false);
  const [accountTo, setAccountTo] = useState("FUTURE");
  const [accountToSelectIsOpened, setAccountToSelectIsOpened] = useState(false);
  const [currency, setCurrency] = useState("USDT");
  const [currencySelectIsOpened, setCurrencySelectIsOpened] = useState(false);
  const [sum, setSum] = useState("");
  const [step, setStep] = useState(1);

  useEffect(() => {
    amplitude.getInstance().logEvent('inner_transfer_create_page.page_view');
  }, []);

  useEffect(() => {
    setError("");
  }, [sum, accountFrom, accountTo, currency]);

  const onSubmit = () => {
    if (!validating()) return;
    const data = {
      from: accountFrom,
      to: accountTo,
      amount: Number(sum),
      currency,
    };

    innerTransfer(data)
      .then(() => {
        setStep(2);
        getTradingAccounts().then((res) => {
          let account = {};
          if (
            cookie.load("accountObj") &&
            res.data.trading_accounts.find(
              (acc) => acc.id === cookie.load("accountObj").id
            )
          ) {
            account = res.data.trading_accounts.find(
              (acc) => acc.id === cookie.load("accountObj").id
            );
          } else {
            res.data.trading_accounts.length > 0 &&
              (account =
                res.data.trading_accounts.find(
                  (tradingAccount) => !tradingAccount.bonus
                ) || res.data.trading_accounts[0]);
          }

          props.setTradingAccounts(res.data);
          props.setTradingAccount(account);
          cookie.save("accountObj", account);
        });
      })
      .catch((err) => {
        if (
          err.response.status === 400 &&
          err.response.data.message === "Insufficient balance"
        ) {
          setError("Сумма превышает доступный остаток по счету");
        } else if (
          err.response.status === 400 &&
          err.response.data.message === "Something went wrong"
        ) {
          setError("Что-то пошло не так. Обратитесь в техподдержку");
        } else {
          setError(err.response.data.message);
        }
      });
  };

  const validating = () => {
    if (!sum) {
      setError("Заполните сумму");
      return false;
    }
    if (!isNaN(sum) && +sum > getTradingAccountBalance(accountFrom)) {
      setError("Сумма превышает доступный остаток по счету");
      return false;
    }
    setError("");
    return true;
  };

  const onSumChange = (e) => {
    const sum = e.target.value;
    if (!sum || sum.match(/^[0-9]+$/)) {
      setSum(sum);
    }
  };

  const getTradingAccountBalance = (type) => {
    const account = props.tradingAccounts.trading_accounts.find(
      (acc) => acc.type === type
    );

    if (currency === "USDT") {
      return account.balance.trading;
    } else {
      return account.bnb_balance;
    }
  };

  return (
    <React.Fragment>
      {
      !props.tradingAccounts.trading_accounts.find(
        (acc) => acc.type === "FUTURE"
      ) ||
      !props.tradingAccounts.trading_accounts.find(
        (acc) => acc.type === "SPOT"
      ) ? (
        <Redirect to="/app" />
      ) : (
        <div className="documentCreatePage">
          <form onSubmit={onSubmit}>
            <h1 className="title card-content">Внутренние переводы</h1>
            <div className="card card-content">
              <div>
                <div className="card-body">
                  <div className="docCreateContent">
                    <Baron>
                      <h3>
                        {accountTo === "FUTURE"
                          ? "Cпотовый счет -> Фьючерсный счет"
                          : "Фьючерсный счет -> Cпотовый счет"}
                      </h3>
                      {step === 1 ? (
                        <React.Fragment>
                          <div className="row justify-content-between">
                            <div className="docCreateChoice mt-2 col-5">
                              <div className="docCreateChoice-title">
                                Откуда
                              </div>
                              <div className="docCreateChoice-select">
                                <div className="customSelect w-100">
                                  <div
                                    className="customSelectedItem"
                                    onClick={() => {
                                      setAccountFromSelectIsOpened(
                                        !accountFromSelectIsOpened
                                      );
                                    }}
                                  >
                                    {accountFrom === "FUTURE"
                                      ? "Фьючерсный"
                                      : "Спотовый"}
                                    <i className="toggle icon-arrow-down5"></i>
                                  </div>
                                  {accountFromSelectIsOpened && (
                                    <ul className="customSelectList">
                                      <li
                                        onClick={() => {
                                          setAccountFromSelectIsOpened(false);
                                          setAccountFrom("SPOT");
                                          setAccountTo("FUTURE");
                                        }}
                                      >
                                        Спотовый
                                      </li>
                                      <li
                                        onClick={() => {
                                          setAccountFromSelectIsOpened(false);
                                          setAccountFrom("FUTURE");
                                          setAccountTo("SPOT");
                                        }}
                                      >
                                        Фьючерсный
                                      </li>
                                    </ul>
                                  )}
                                </div>
                              </div>
                              <div
                                className="text-secondary d-flex"
                                style={{ fontSize: "9px", marginTop: "4px" }}
                              >
                                Баланс: {getTradingAccountBalance(accountFrom)}{" "}
                                {currency}
                              </div>
                            </div>
                            <div className="docCreateChoice mt-2 col-5">
                              <div className="docCreateChoice-title">
                                Валюта
                              </div>
                              <div className="docCreateChoice-select">
                                <div className="customSelect w-100">
                                  <div
                                    className="customSelectedItem"
                                    onClick={() => {
                                      setCurrencySelectIsOpened(
                                        !currencySelectIsOpened
                                      );
                                    }}
                                  >
                                    {currency}
                                    <i className="toggle icon-arrow-down5"></i>
                                  </div>
                                  {currencySelectIsOpened && (
                                    <ul className="customSelectList">
                                      <li
                                        onClick={() => {
                                          setCurrencySelectIsOpened(false);
                                          setCurrency("USDT");
                                        }}
                                      >
                                        USDT
                                      </li>
                                      <li
                                        onClick={() => {
                                          setCurrencySelectIsOpened(false);
                                          setCurrency("BNB");
                                        }}
                                      >
                                        BNB
                                      </li>
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-between mt-2">
                            <div className="docCreateChoice mt-2 col-5">
                              <div className="docCreateChoice-title">Куда</div>
                              <div className="docCreateChoice-select">
                                <div className="customSelect w-100">
                                  <div
                                    className="customSelectedItem"
                                    onClick={() => {
                                      setAccountToSelectIsOpened(
                                        !accountToSelectIsOpened
                                      );
                                    }}
                                  >
                                    {accountTo === "FUTURE"
                                      ? "Фьючерсный"
                                      : "Спотовый"}
                                    <i className="toggle icon-arrow-down5"></i>
                                  </div>
                                  {accountToSelectIsOpened && (
                                    <ul className="customSelectList">
                                      <li
                                        onClick={() => {
                                          setAccountToSelectIsOpened(false);
                                          setAccountTo("SPOT");
                                          setAccountFrom("FUTURE");
                                        }}
                                      >
                                        Спотовый
                                      </li>
                                      <li
                                        onClick={() => {
                                          setAccountToSelectIsOpened(false);
                                          setAccountTo("FUTURE");
                                          setAccountFrom("SPOT");
                                        }}
                                      >
                                        Фьючерсный
                                      </li>
                                    </ul>
                                  )}
                                </div>
                              </div>
                              <div
                                className="text-secondary d-flex"
                                style={{ fontSize: "9px", marginTop: "4px" }}
                              >
                                Баланс: {getTradingAccountBalance(accountTo)}{" "}
                                {currency}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="position-relative col-5">
                              <label
                                className={`floating-label`}
                                style={{ paddingLeft: "0.625rem" }}
                              >
                                Сумма перевода
                              </label>
                              <input
                                type="text"
                                className="inputUnderLine w-100"
                                placeholder="Сумма..."
                                value={sum}
                                onChange={onSumChange}
                              />
                              <div
                                className="text-secondary d-flex"
                                style={{ fontSize: "9px", marginTop: "4px" }}
                              >
                                Комиссии нет
                              </div>
                            </div>
                            <div
                              className="position-relative col-4 d-flex align-items-center"
                              style={{ bottom: "8px" }}
                            >
                              {currency}
                            </div>
                          </div>
                        </React.Fragment>
                      ) : step === 2 ? (
                        <React.Fragment>
                          <h3>
                            Перевод на сумму {sum} {currency}
                          </h3>
                          <div className="mt-4">
                            Зачисление происходит моментально. Баланс в личном
                            кабинете обновляется с небольшой задержкой.
                          </div>
                          <div className="mt-1">
                            Историю переводов можно найти в разделе{" "}
                            <a href="/app/transactions">транзакции</a>.
                          </div>
                        </React.Fragment>
                      ) : null}
                      {error && (
                        <h6 className="text-center text-danger mt-2">
                          {error}
                        </h6>
                      )}
                    </Baron>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {step === 1 && (
            <div className="btnSubmits card-content d-flex justify-content-end align-items-cetner position-static">
              <button
                className="btn btn-success"
                disabled={isSending}
                onClick={(e) => onSubmit(e)}
              >
                Создать
                {isSending && (
                  <i className="icon-spinner2 uploaderSpinner spinner"></i>
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  tradingAccounts: state.tradingAccounts,
  tradingAccount: state.tradingAccount,
});

const mapDispatchToProps = (dispatch) => ({
  setTradingAccounts: (tradingAccounts) =>
    dispatch(setTradingAccounts(tradingAccounts)),
  setTradingAccount: (tradingAccount) =>
    dispatch(setTradingAccount(tradingAccount)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InnerTransferCreate);
