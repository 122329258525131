import React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import InputMask from 'react-input-mask';

import { catchPromise } from '../utils/http';
import { getPhoneCode, verifyShopSms } from '../utils/utilsHttp';
import { getProduct } from '../utils/shopHttp';

export class ShopItemBuyPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: {
        full_description: '',
      },
      amount: 0,
      quantity: 1,
      fio: '',
      firstName: '',
      secondName: '',
      thirdName: '',
      timer: 0,
      email: '',
      phone: '',
      smsCode: '',
      smsId: '',
      isSmsCodeSinding: false,
      isAgreeData: false,
      isAgreeOffer: false,
      errorPhone: '',
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    this.loadProduct();
  }
  loadProduct = () => {
    getProduct(this.props.match.params.key)
      .then((res) => {
        this.setState({
          product: res.data,
          amount: parseInt(this.state.quantity) * parseInt(res.data.price),
        });
      })
      .catch((err) => {
        catchPromise(err, this.loadProduct, this.errorCb);
      });
  };
  sendForm = (e, eTarget) => {
    e.preventDefault();

    const eventTarget = e ? e.target : eTarget;

    if (
      this.state.quantity &&
      parseInt(this.state.amount) ==
        parseInt(this.state.quantity) * parseInt(this.state.product.price) &&
      this.state.secondName &&
      this.state.firstName &&
      this.state.thirdName &&
      this.state.email &&
      this.state.phone &&
      this.state.smsCode &&
      this.state.isAgreeData &&
      this.state.isAgreeOffer
    ) {
      verifyShopSms({
        phone: this.state.phone,
        sms_code: this.state.smsCode,
        sms_id: this.state.smsId,
      })
        .then((res) => {
          pay(eventTarget);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 409)
              this.setState({ errorPhone: err.response.data.message });
            else
              catchPromise(
                err,
                this.sendForm.bind(this, e, eventTarget),
                this.errorCb
              );
          }
        });
    }
  };

  onGetPhoneCode = () => {
    getPhoneCode({ phone: this.state.phone, mode: 'shop_order' })
      .then((res) => {
        this.setState({
          isSmsCodeSinding: true,
          smsId: res.data.sms_id,
          timer: 60,
          errorPhone: '',
        });

        setInterval(() => {
          if (this.state.timer > 0)
            this.setState({ timer: this.state.timer - 1 });
        }, 1000);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 401) {
            catchPromise(err, this.onGetPhoneCode, this.errorCb);
          } else {
            this.setState({
              errorPhone: err.response.data.message || undefined,
            });
          }
        }
      });
  };
  onFirstNameChange = (e) => {
    const firstName = e.target.value;
    this.setState({ firstName });
  };
  onSecondNameChange = (e) => {
    const secondName = e.target.value;
    this.setState({ secondName });
  };
  onThirdNameChange = (e) => {
    const thirdName = e.target.value;
    this.setState({ thirdName });
  };
  onEmailChange = (e) => {
    const email = e.target.value;
    this.setState({ email });
  };
  onPhoneChange = (e) => {
    const maskedPhone = e.target.value;
    let phone = '';
    for (let i = 0; i < maskedPhone.length; i++) {
      if (maskedPhone[i].match(/^[0-9]+$/)) {
        phone += maskedPhone[i];
      }
    }
    if (!phone || phone.match(/^[0-9]+$/)) {
      this.setState({ phone });
    }
  };
  onSmsCodeChange = (e) => {
    const smsCode = e.target.value;
    if (!smsCode || smsCode.match(/^[0-9]+$/)) {
      this.setState({ smsCode });
    }
  };
  onQuantityChange = (e) => {
    const quantity = e.target.value;
    if (!quantity || quantity.match(/^[0-9]+$/)) {
      this.setState({
        quantity,
        amount: parseInt(quantity) * parseInt(this.state.product.price),
      });
    }
  };
  onIsAgreeDataChange = () => {
    this.setState({ isAgreeData: !this.state.isAgreeData });
  };
  onIsAgreeOfferChange = () => {
    this.setState({ isAgreeOffer: !this.state.isAgreeOffer });
  };

  render() {
    return undefined;
  }
}

export default connect()(ShopItemBuyPage);
