import React from "react";
import { Link, Redirect } from "react-router-dom";
import Baron from "react-baron/dist/es5";
import { CopyToClipboard } from "react-copy-to-clipboard";
import amplitude from 'amplitude-js';

import { getUserTradingData, postCryptoSettings } from "../utils/userHttp";
import { createTooltip } from "../utils/viewUtils";

import { catchPromise } from "../utils/http";
import { connect } from "react-redux";
import Popup from "../containers/Popup";
import GoogleAuthWrongCodeModal from "./GoogleAuthWrongCodeModal";

export class TradingSettingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      api_key: "",
      secret_key: "",
      deposit_address: "",
      selectValue: "VIP-0",
      selectIsOpened: false,
      isLoading: true,
      pageError: false,
      error: "",
      modalIsOpened: false,
      wrongCodeModalIsOpened: false,
      otp_password: "",
      errorMessage: ""
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
    this.setState({});
  };
  componentDidMount() {
    amplitude.getInstance().logEvent('trading_settings_page.page_view');
    this.loadTradingSettingsData();
  }

  // Settings
  loadTradingSettingsData = () => {
    this.setState({ isLoading: true });

    getUserTradingData()
      .then((res) => {
        this.setState({
          api_key: new Array(64).fill("*").join(""),
          secret_key: new Array(64).fill("*").join(""),
          deposit_address: res.data.binance_account.deposit_address,
          isLoading: false,
          pageError: false,
        });
      })
      .catch((err) => {
        catchPromise(err, this.loadTradingSettingsData, this.errorCb);
      });
  };

  onSubmitCryptoSettings = () => {
    postCryptoSettings({ otp_password: this.state.otp_password })
      .then((res) => {
        this.setState({
          api_key: res.data.binance_account.api_key,
          secret_key: res.data.binance_account.secret_key,
          modalIsOpened: false,
        });
      }).catch(err => {
        this.setState({ error: err.response.data.message })
      })
  }

  onOtpChangePassword = (e) => {
    const value = e.target.value
      .split("")
      .filter((c) =>
        ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(c)
      )
      .slice(0, 6)
      .join("");

    this.setState({
      otp_password: value,
    });
  };

  togglePopup = () => {
    this.setState({ modalIsOpened: !this.state.modalIsOpened })
  }

  render() {
    const { tradingAccounts } = this.props;

    return (
      <React.Fragment>
        {tradingAccounts.trading_accounts.length > 0 ? (
          <div className="settings trading_settings">
            {!this.state.pageError ? (
              <div>
                {this.state.modalIsOpened ? (
                  <Popup
                    closePopup={this.togglePopup}
                    popupClass="googleAuth"
                  >
                    <div className="popup-title mb-3">Введите код подтверждения</div>
                    <div className="mt-3 text-center">
                      <div className="position-relative formField-container m-auto p-0">
                        <div className="text-left">6-значный код из приложения для двухэтапной верификации</div>
                        <input
                          type="text"
                          className={`inputUnderLine`}
                          value={this.state.otp_password}
                          onChange={this.onOtpChangePassword}
                        />
                      </div>
                      {this.state.error && (
                        <div className="text-danger text-left">
                          {this.state.error}
                        </div>
                      )}
                    </div>
                    <div
                      className="codeNoMatch p-0"
                      onClick={() => this.setState({ wrongCodeModalIsOpened: true })}
                    >
                      Код не подходит
                    </div>
                    {this.state.errorMessage ? (
                      <div className="errorMessage text-danger">
                        {this.state.errorMessage}
                      </div>
                    ) : null}
                    <div className="cryptoSettings popupSubmitBtns d-flex justify-content-end align-items-center">
                      <button
                        className="popupCancel"
                        onClick={this.onSubmitCryptoSettings}
                        disabled={this.state.otp_password.length < 6}
                      >
                        Продолжить
                      </button>
                    </div>
                  </Popup>
                ) : null}
                {this.state.wrongCodeModalIsOpened ? (
                  <GoogleAuthWrongCodeModal
                    modalIsOpened={this.state.wrongCodeModalIsOpened}
                    closeModal={() => this.setState({ wrongCodeModalIsOpened: false })}
                  />
                ) : null}
                <div className="title card-content">
                  Настройки API key
                </div>
                <div className="card card-content">
                  <div className="card-body">
                    <Baron>
                      {!this.state.isLoading ? (
                        <div>
                          <div className="anketaChange-subtitleContainer d-flex flex-column justify-content-center align-items-between mb-4">
                            <div className="anketaChange-subtitle">
                              API keys
                            </div>
                            <div>
                              Ваши ключи для доступа к торговле цифровыми активами на бирже Binance.
                              Храните ключи в надежном месте и не делитесь ими с другими.
                            </div>
                            {!this.props.user.user.two_factor_auth ? (
                              <div className="d-flex align-items-start text-danger position-relative mt-2 ml-3 pl-2">
                                <img className="forbiddenIcon" src="/images/ui/forbidden.png" style={{ left: "-14px" }} />
                                <div>
                                  Посмотреть ключи можно, если подключена двойная аутентификация.<br />
                                  Подключите Google Authentificator в разделе{' '}
                                  <Link to="/app/profile" className="text-danger" style={{ textDecoration: "underline" }}>
                                    профиль
                                  </Link>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="row">
                            <div className="col-lg-12 d-flex align-items-center">
                              <div className="position-relative formField-container w-100">
                                <label className={`floating-label`}>Key</label>
                                <input
                                  type="text"
                                  className={`inputUnderLine copyClipboardInput`}
                                  placeholder=""
                                  readOnly={true}
                                  value={this.state.api_key}
                                />
                                <CopyToClipboard text={this.state.api_key}>
                                  <img
                                    src="/images/ui/copyIcon.png"
                                    className="settings-copyIcon"
                                    onClick={(e) =>
                                      createTooltip(
                                        e,
                                        "Скопировано в буфер обмена"
                                      )
                                    }
                                    alt=""
                                  />
                                </CopyToClipboard>
                              </div>
                              <div
                                className="settings-showApiKeysBtn position-relative formField-container"
                              >
                                <button
                                  disabled={!this.props.user.user.two_factor_auth}
                                  onClick={() => {
                                    if (!(this.state.api_key.split("").filter(c => c === "*").length === this.state.api_key.length)) {
                                      this.setState({
                                        api_key: new Array(64).fill("*").join(""),
                                        secret_key: new Array(64).fill("*").join(""),
                                        modalIsOpened: false
                                      });
                                      return;
                                    }
                                    this.setState({ modalIsOpened: true });
                                  }}
                                >
                                  {this.state.api_key.split("").filter(c => c === "*").length === this.state.api_key.length ? "Показать" : "Скрыть"}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 d-flex align-items-center">
                              <div className="position-relative formField-container w-100">
                                <label className={`floating-label`}>
                                  Secret
                                </label>
                                <input
                                  type="text"
                                  className={`inputUnderLine copyClipboardInput`}
                                  placeholder=""
                                  readOnly={true}
                                  value={this.state.secret_key}
                                />
                                <CopyToClipboard text={this.state.secret_key}>
                                  <img
                                    src="/images/ui/copyIcon.png"
                                    className="settings-copyIcon"
                                    onClick={(e) =>
                                      createTooltip(
                                        e,
                                        "Скопировано в буфер обмена"
                                      )
                                    }
                                    alt=""
                                  />
                                </CopyToClipboard>
                              </div>
                              <div
                                className="settings-showApiKeysBtn position-relative formField-container"
                              >
                                <button
                                  disabled={true}
                                  className="invisible"
                                >
                                  {this.state.api_key.split("").filter(c => c === "*").length === this.state.api_key.length ? "Показать" : "Скрыть"}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="anketaChange-subtitleContainer d-flex flex-column justify-content-center align-items-between">
                            <div className="anketaChange-subtitle">
                              Кошелёк для пополнения
                            </div>
                            <div>
                              Адрес вашего торгового кошелька USDT (TRC20). Пополните его, чтобы начать торговлю.
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="position-relative formField-container">
                                <label className={`floating-label`}>
                                  USDT TRC20
                                </label>
                                <input
                                  type="text"
                                  className={`inputUnderLine copyClipboardInput`}
                                  placeholder=""
                                  readOnly={true}
                                  value={this.state.deposit_address}
                                />
                                <CopyToClipboard
                                  text={this.state.deposit_address}
                                >
                                  <img
                                    src="/images/ui/copyIcon.png"
                                    className="settings-copyIcon"
                                    onClick={(e) =>
                                      createTooltip(
                                        e,
                                        "Скопировано в буфер обмена"
                                      )
                                    }
                                    alt=""
                                  />
                                </CopyToClipboard>
                              </div>
                            </div>
                          </div>
                          <div className="anketaChange-subtitleContainer d-flex justify-content-between align-items-center">
                            <div className="anketaChange-subtitle">Ваш уровень торговой комиссии</div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              VIP 0
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="text-center spinner-container isLoadingSpinner">
                          <span>
                            <img
                              src="/images/ui/load-spinner.png"
                              className="spinner"
                              alt=""
                            />
                          </span>
                        </div>
                      )}
                    </Baron>
                  </div>
                </div>
              </div>
            ) : (
              <h3 className="text-center text-danger">Произошла ошибка</h3>
            )}
          </div>
        ) : (
          <Redirect to="/app" />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    tradingAccounts: state.tradingAccounts,
  };
};

export default connect(mapStateToProps)(TradingSettingsPage);
