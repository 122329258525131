import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import Baron from 'react-baron/dist/es5';

import FinresBoard from './FinresBoard';
import { currencyFormat, getCurrency, sumColor } from '../utils/currencyUtil';

export default class Finres extends Component {
  render() {
    const {
      finres,
      startDate,
      endDate,
      sort_by,
      sortAsc,
      dateRangeCurr,
      onDatesChange,
      calendarFocused,
      onFocusChange,
      selectCalendarDates,
      onChangeSort,
      isLoading,
    } = this.props;
    return (
      <div>
        <h1 className="title card-content">Финансовый результат</h1>
        <div className="card card-content">
          <div className={`card-body p-0 finresFilters ${dateRangeCurr}`}>
            <div className="row tableFilters-container">
              <div className="col-lg-6">
                <p className="filter-subtitle">Период</p>
                <div className="finresFilters-datePeriod mb-3">
                  <div className="tableFilters-datesRangeWeek">
                    <span
                      className="activePeriodWeek"
                      onClick={() => {
                        selectCalendarDates('dateRangeWeek');
                      }}
                    >
                      неделя
                    </span>
                  </div>
                  <div className="tableFilters-datesRangeMonth">
                    <span
                      className="activePeriodMonth"
                      onClick={() => {
                        selectCalendarDates('dateRangeMonth');
                      }}
                    >
                      месяц
                    </span>
                  </div>
                  <div className="tableFilters-datesRangeQuarter">
                    <span
                      className="activePeriodQuarter"
                      onClick={() => {
                        selectCalendarDates('dateRangeQuarter');
                      }}
                    >
                      квартал
                    </span>
                  </div>
                </div>
                <p className="filter-subtitle finresFilters-title-customPeriod">
                  Свой период
                </p>
                <div className="form-group">
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={({ startDate, endDate }) => {
                      onDatesChange({
                        startDate,
                        endDate,
                        dateRange: 'dateRangeCustom',
                      });
                    }}
                    startDatePlaceholderText="Начало"
                    endDatePlaceholderText="Конец"
                    focusedInput={calendarFocused}
                    onFocusChange={onFocusChange}
                    numberOfMonths={2}
                    minimumNights={0}
                    isOutsideRange={() => false}
                    hideKeyboardShortcutsPanel={true}
                  />
                </div>
              </div>
              <div className="col-lg-6 pr-3">
                {finres.boards.length > 0 && (
                  <div className="finresTotalsContainer">
                    {finres.boards.map((board, index) => (
                      <div className="row" key={index}>
                        <p className="col-8 pl-0 totalSubTitle">{board.name}</p>
                        <p
                          className={`col-4 pr-0 text-right ${sumColor(
                            board.total.amount
                          )}`}
                        >
                          {currencyFormat(board.total.amount)}
                        </p>
                      </div>
                    ))}
                    <div className="row finresTotals">
                      <p className="col-8 pl-0 font-weight-bold">
                        Итого по счету:
                      </p>
                      <p
                        className={`col-4 pr-0 text-right ${sumColor(
                          finres.total_amount_account
                        )}`}
                      >
                        {currencyFormat(finres.total_amount_account)}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {finres.boards.length > 0 && !isLoading ? (
              <div className="finresTableContainer">
                <table className="table">
                  <thead>
                    <tr className="finres-tableTitles">
                      <td
                        className="transactionTableData"
                        onClick={() => {
                          onChangeSort('date');
                        }}
                      >
                        {sort_by == 'date' && (
                          <span>
                            {sortAsc ? (
                              <i className="icon-arrow-up5"></i>
                            ) : (
                              <i className="icon-arrow-down5"></i>
                            )}
                          </span>
                        )}
                        ДАТА
                      </td>
                      <td
                        className="transactionTableFinres"
                        onClick={() => {
                          onChangeSort('amount');
                        }}
                      >
                        {sort_by == 'amount' && (
                          <span>
                            {sortAsc ? (
                              <i className="icon-arrow-up5"></i>
                            ) : (
                              <i className="icon-arrow-down5"></i>
                            )}
                          </span>
                        )}
                        ФИНРЕЗ,{' '}
                        {getCurrency(finres.trading_account.currency || '')}
                      </td>
                      <td className="transactionTableCommis">
                        КОМИССИЯ,{' '}
                        {getCurrency(finres.trading_account.currency || '')}
                      </td>
                      <td className="transactionTableMarj">МАРЖА</td>
                      <td className="transactionTableProsad">ПРОСАДКА</td>
                    </tr>
                  </thead>
                </table>
                <Baron>
                  <table className="table">
                    <tbody>
                      {finres.boards.map((board, index) => (
                        <FinresBoard
                          board={board}
                          index={index}
                          boardLength={finres.boards.length}
                        />
                      ))}
                    </tbody>
                  </table>
                </Baron>
              </div>
            ) : (
              <div className="emptyDashboard-plug">
                {isLoading ? (
                  <div className="mt-1 text-center spinner-container isLoadingSpinner">
                    <span>
                      <img
                        src="/images/ui/load-spinner.png"
                        className="spinner"
                        alt=""
                      />
                    </span>
                  </div>
                ) : (
                  <div className="emptyData">нет данных</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Finres.propTypes = {
  finres: PropTypes.array,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onDatesChange: PropTypes.func,
  calendarFocused: PropTypes.string,
  onFocusChange: PropTypes.func,
};
