import React from 'react';

import LiveDataBoard from './LiveDataBoard';

const LiveDataAccount = ({
  account,
  openOrders,
  closePosition,
  cancelOrders,
  cancelOrder,
  boardFilter,
  sort_by,
  sortAsc,
  onChangeSort,
}) => (
  <div>
    {account.boards
      .filter((board) => board.id == boardFilter)
      .map(
        (board, index) =>
          board.positions.length && (
            <LiveDataBoard
              key={index}
              board={board}
              boardFilter={boardFilter}
              currency={account.currency}
              openOrders={openOrders}
              closePosition={closePosition}
              cancelOrders={cancelOrders}
              cancelOrder={cancelOrder}
              sort_by={sort_by}
              sortAsc={sortAsc}
              onChangeSort={onChangeSort}
            />
          )
      )}
  </div>
);

export default LiveDataAccount;
