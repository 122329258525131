import React, { useEffect, useState } from "react";
import Baron from "react-baron/dist/es5";

import LiveDataPosition from "./LiveDataPosition";

const LiveDataBoard = ({
  board,
  boardFilter,
  currency,
  openOrders,
  closePosition,
  cancelOrders,
  cancelOrder,
  sort_by,
  sortAsc,
  onChangeSort,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

  useEffect(() => {
    window.onresize = () => {
      const isMobile = window.innerWidth < 500 ? true : false;
      setIsMobile(isMobile);
    };

    return () => {
      window.onresize = null;
    };
  }, []);

  return (
    <React.Fragment>
      {!isMobile ? (
        <div className={`${boardFilter != "0" ? "boardAllPositions" : ""}`}>
          <table className="tableLayoutFixes">
            <thead>
              <tr>
                {boardFilter === "0" && (
                  <td
                    className="text-right posPlosh"
                    onClick={() => {
                      onChangeSort("board_name");
                    }}
                  >
                    {sort_by == "board_name" && (
                      <span>
                        {sortAsc ? (
                          <i className="icon-arrow-up5"></i>
                        ) : (
                          <i className="icon-arrow-down5"></i>
                        )}
                      </span>
                    )}
                    ПЛОЩАДКА
                  </td>
                )}
                <td
                  className="text-right posUpdate"
                  onClick={() => {
                    onChangeSort("updated");
                  }}
                >
                  {sort_by == "updated" && (
                    <span>
                      {sortAsc ? (
                        <i className="icon-arrow-up5"></i>
                      ) : (
                        <i className="icon-arrow-down5"></i>
                      )}
                    </span>
                  )}
                  ОБНОВЛЕНО
                </td>
                <td className="text-right posInst">ИНСТРУМЕНТ</td>
                <td className="posPosit">ПОЗИЦИИ</td>
                <td className="posOrders">ЗАЯВКИ</td>
                <td
                  className="text-right posFinres"
                  onClick={() => {
                    onChangeSort("finres");
                  }}
                >
                  {sort_by == "finres" && (
                    <span>
                      {sortAsc ? (
                        <i className="icon-arrow-up5"></i>
                      ) : (
                        <i className="icon-arrow-down5"></i>
                      )}
                    </span>
                  )}
                  ФИНРЕЗ
                </td>
                <td className="text-right posCommis">КОМИССИЯ</td>
                <td className="text-right posDrawdown">ПРОСАДКА</td>
                <td className="posArrow"></td>
              </tr>
            </thead>
          </table>

          <Baron>
            <table className="tableLayoutFixes">
              <tbody>
                {board.positions.map((position, index) => (
                  <LiveDataPosition
                    key={index}
                    position={position}
                    currency={currency}
                    openOrders={openOrders}
                    boardFilter={boardFilter}
                    closePosition={closePosition}
                    cancelOrders={cancelOrders}
                    cancelOrder={cancelOrder}
                    indexPosition={index}
                  />
                ))}
              </tbody>
            </table>
          </Baron>
        </div>
      ) : (
        <React.Fragment>
          {board.positions.map((position, index) => (
            <LiveDataPosition
              isMobile={isMobile}
              key={index}
              position={position}
              currency={currency}
              openOrders={openOrders}
              boardFilter={boardFilter}
              closePosition={closePosition}
              cancelOrders={cancelOrders}
              cancelOrder={cancelOrder}
              indexPosition={index}
            />
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default LiveDataBoard;
