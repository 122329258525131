import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Baron from 'react-baron/dist/es5';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import amplitude from 'amplitude-js';

import { catchPromise } from '../utils/http';
import {
  changeUserPassword,
  changeUserPasswordTrading,
} from '../utils/userHttp';
import { createTooltip } from '../utils/viewUtils';
import Popup from '../containers/Popup';
import { PasswordInstructions } from './PasswordInstructions';
import { PasswordMask } from './PasswordMask';

export class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPass: '',
      currentPassVisible: '',
      pass: '',
      passVisible: false,
      repeatPass: '',
      repeatPassVisible: false,
      isShowPopupChange: false,
      isShowPopupChangePassword: false,
      errorMessage: '',
      isLoading: true,
      pageError: false,
      googleAuthRemoveModalIsOpened: false,
      passwordErrorTypes: null
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
    this.setState({
      isShowPopupChange: false,
      isShowPopupChangePassword: false,
    });
  };
  componentDidMount() {
    amplitude.getInstance().logEvent('profile_page.page_view');
  }
  togglePopup = (popupShowValue, isGoogleAuth) => {
    if (popupShowValue && !isGoogleAuth)
      this.setState({ 
        pass: '',
        passVisible: false,
        repeatPass: '',
        repeatPassVisible: false,
        currentPass: '',
        currentPassVisible: false,
        errorMessage: ''
      });
    this.setState({ [popupShowValue]: !this.state[popupShowValue] });
  };
  // Change password Popup
  onSubmitChangePassword = () => {
    if (this.state.currAccName === 'website') {
      this.changeWebsitePassword();
    } else {
      this.changeTerminalPassword();
    }
  };
  changeTerminalPassword = () => {
    if (!this.state.currentPass) {
      this.setState({ errorMessage: 'Введите текущий пароль.' });
      return;
    }
    if (!this.state.pass || !this.state.repeatPass) {
      this.setState({ errorMessage: 'Введите новый пароль.' });
      return;
    }
    if (this.state.pass.length < 6) {
      this.setState({ errorMessage: 'Пароль должен быть не менее 6 символов' });
      return;
    }
    if (this.state.pass !== this.state.repeatPass) {
      this.setState({ errorMessage: 'Пароли не совпадают' });
      return;
    }

    changeUserPasswordTrading(this.state.currAccName, this.state.pass)
      .then((res) => {
        this.setState({ errorMessage: '' });
        this.togglePopup('isShowPopupChangePassword');
      })
      .catch((err) => {
        this.setState({ pass: '', repeatPass: '', currentPass: '' });
        catchPromise(err, this.changeTerminalPassword, this.errorCb);
      });
  };
  changeWebsitePassword = () => {
    if (!this.state.pass) {
      this.setState({ errorMessage: 'Введите пароль' });
      return;
    }
    if (this.state.pass !== this.state.repeatPass) {
      this.setState({ errorMessage: 'Пароли не совпадают' });
      return;
    }
    this.setState({ errorMessage: '' });

    changeUserPassword(this.state.pass, this.state.repeatPass, this.state.currentPass)
      .then((res) => {
        this.setState({ errorMessage: '' });
        this.togglePopup('isShowPopupChangePassword');
      })
      .catch((err) => {
        if (err.response.status === 400) {
          if (err.response.data.password_error_message && err.response.data.password_error_types) {
            this.setState({ passwordErrorTypes: err.response.data.password_error_types })
          } else {
            this.setState({ errorMessage: err.response.data.message });
          }
        } else {
          catchPromise(err, this.changeWebsitePassword, this.errorCb);
        }
      });
  };

  onChangeCurrentPassword = (e) => {
    let currentPass = e.target.value;
    this.setState(() => ({ 
      currentPass, 
      passwordErrorTypes: null, 
    }));
  };
  onChangeNewPassword = (e) => {
    let pass = e.target.value;
    this.setState(() => ({ 
      pass, 
      passwordErrorTypes: null, 
    }));
  };
  onChangeConfirmNewPassword = (e) => {
    let repeatPass = e.target.value;
    this.setState(() => ({ 
      repeatPass, 
      passwordErrorTypes: null, 
    }));
  };

  render() {
    return (
      <div className="settings">
        {this.state.isShowPopupChange ? (
          <Popup closePopup={() => this.togglePopup('isShowPopupChange')}>
            <div className="docPageSendMessage">
              {this.props.user.form_status == 'APPROVED' ||
              this.props.user.form_status == 'NEED_UPDATE' ||
              this.props.user.form_status == 'UPDATED' ? (
                <div>Чтобы изменить анкетные данные создайте документ</div>
              ) : (
                <div>
                  Изменение анкетных данных невозможно пока не подтверждена
                  текущая анкета
                </div>
              )}
            </div>
            <div className="popupSubmitBtns d-flex justify-content-between align-items-center">
              {this.props.user.form_status == 'APPROVED' ||
              this.props.user.form_status == 'NEED_UPDATE' ||
              this.props.user.form_status == 'UPDATED' ? (
                <div
                  className="popupCancel"
                  onClick={() => this.togglePopup('isShowPopupChange')}
                >
                  Отмена
                </div>
              ) : (
                <div></div>
              )}
              {this.props.user.form_status == 'APPROVED' ||
              this.props.user.form_status == 'NEED_UPDATE' ||
              this.props.user.form_status == 'UPDATED' ? (
                <Link
                  to="/app/documents/create?code=CHANGE_PERSONAL_FORM"
                  className="popupSubmitBtn popupSubmitBtn-ok"
                >
                  Перейти
                </Link>
              ) : (
                <div
                  className="popupSubmitBtn popupSubmitBtn-ok"
                  onClick={() => this.togglePopup('isShowPopupChange')}
                >
                  ОК
                </div>
              )}
            </div>
          </Popup>
        ) : null}
        {this.state.isShowPopupChangePassword ? (
          <Popup
            closePopup={() => this.togglePopup('isShowPopupChangePassword')}
            popupClass="changePassword"
          >
            <div className="popup-title mb-3">Смена пароля</div>
            <div className="position-relative formField-container d-flex">
              <label
                className={`floating-label ${
                  this.state.currentPass == '' ? 'hidden' : ''
                }`}
              >
                Текущий пароль
              </label>
              <input
                type={this.state.currentPassVisible ? "text" : "password"}
                className={`inputUnderLine`}
                placeholder="Текущий пароль"
                value={this.state.currentPass}
                onChange={this.onChangeCurrentPassword}
                maxLength={128}
              />
              <PasswordMask
                isInputOutlined={true}
                passwordVisible={this.state.currentPassVisible}
                setPasswordVisible={(currentPassVisible) => this.setState({ currentPassVisible })}
              />
            </div>
            <div className="position-relative formField-container d-flex">
              <label
                className={`floating-label ${
                  this.state.pass == '' ? 'hidden' : ''
                }`}
              >
                Новый пароль
              </label>
              <input
                type={this.state.passVisible ? "text" : "password"}
                className={`inputUnderLine`}
                placeholder="Новый пароль"
                value={this.state.pass}
                onChange={this.onChangeNewPassword}
                maxLength={128}
              />
              <PasswordMask
                isInputOutlined={true}
                passwordVisible={this.state.passVisible}
                setPasswordVisible={(passVisible) => this.setState({ passVisible })}
              />
            </div>
            <div className="position-relative formField-container mb-0 d-flex">
              <label
                className={`floating-label ${
                  this.state.repeatPass == '' ? 'hidden' : ''
                }`}
              >
                Подтвердите пароль
              </label>
              <input
                type={this.state.repeatPassVisible ? "text" : "password"}
                className={`inputUnderLine`}
                placeholder="Подтвердите пароль"
                value={this.state.repeatPass}
                onChange={this.onChangeConfirmNewPassword}
                maxLength={128}
              />
              <PasswordMask 
                isInputOutlined={true}
                passwordVisible={this.state.repeatPassVisible}
                setPasswordVisible={(repeatPassVisible) => this.setState({ repeatPassVisible })}
              />
            </div>
            <PasswordInstructions passwordErrorTypes={this.state.passwordErrorTypes} />
            <div className="text-danger">{this.state.errorMessage}</div>
            <div className="popupSubmitBtns d-flex justify-content-between align-items-center">
              <div
                className="popupCancel"
                onClick={() => this.togglePopup('isShowPopupChangePassword')}
              >
                Отмена
              </div>
              <div
                className="popupSubmitBtn popupSubmitBtn-ok"
                onClick={this.onSubmitChangePassword}
              >
                Сохранить
              </div>
            </div>
          </Popup>
        ) : null}
        {!this.state.pageError ? (
          <div>
            <div className="title card-content">Профиль</div>
            <div className="card card-content">
              <div className="card-body">
                <Baron>
                  <div>
                    <div className="anketaChange-subtitleContainer">
                      <div className="anketaChange-subtitle">Настройки</div>
                      {!this.props.user.products_disabled.includes("terminal") ? <div className="hintBlock">
                        <div className="hintBlock__text">Используйте логин и пароль от личного кабинета при входе в терминал</div>
                      </div> : null}
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="settings-changePassTitle position-relative formField-container">
                          <span
                            onClick={() => {
                              this.togglePopup('isShowPopupChangePassword');
                              this.setState({ currAccName: 'website' });
                            }}
                          >
                            Сменить пароль
                          </span>
                        </div>
                      </div>
                    </div>
                    {this.state.googleAuthRemoveModalIsOpened && this.props.user.user.roles.length ? (
                      <Popup
                        closePopup={() => this.togglePopup('googleAuthRemoveModalIsOpened', true)}
                        popupClass="googleAuth"
                      >
                        <div className="popup-title mb-3">Вы точно хотите отключить Google Authenticator?</div>
                        <div className="mt-3">
                          Будут мгновенно заблокированы после отключения двухфакторной аутентификации:
                        </div>
                        <ul className="mt-2 ml-4">
                          <li>Все операции на вывод средств</li>
                          {this.props.user.user.roles.includes("trader") && <li>Доступ к API key</li>}
                        </ul>
                        <div className="popupSubmitBtns d-flex justify-content-between align-items-center">
                          <div
                            className="popupCancel"
                            onClick={() => this.togglePopup('googleAuthRemoveModalIsOpened', true)}
                          >
                            Отмена
                          </div>
                          <Link to="/app/profile/google_auth_remove">
                            <div
                              className="popupSubmitBtn popupSubmitBtn-ok"
                            >
                              Продолжить
                            </div>
                          </Link>
                        </div>
                      </Popup>
                    ) : null}
                    <div className="row">
                      <div className="col-6 pr-2">
                        <div>Google Authenticator</div>
                        <div className="mt-1" style={{ fontSize: '10px' }}>
                          {this.props.user.user.roles.length === 0 
                            ? 'Используется для безопасного входа в аккаунт.'
                            : this.props.user.user.roles.includes("trader")
                            ? 'Используется для безопасного входа в аккаунт, доступа к API key и операций вывода.'
                            : this.props.user.user.roles.includes("bonus")
                            ? 'Используется для безопасного входа в аккаунт и операций вывода.'
                            : null
                          }
                        </div>
                      </div>
                      {!this.props.user.user.two_factor_auth ? (
                        <div className="col-6 pl-2 position-relative d-flex flex-column justify-content-end">
                          <div className="d-flex align-items-start text-danger text-right ml-auto position-relative">
                            <img className="inaccessibleIcon" src="/images/ui/inaccessible.png" />
                            Выключен
                          </div>
                          <Link
                            to="/app/profile/google_auth_add"
                          >
                            <div
                              className="settings-googleAuthBtn position-relative formField-container mt-2"
                            >
                              <span>
                                Включить
                              </span>
                            </div>
                          </Link>
                        </div>
                      ) : (
                        <div className="col-6 pl-2 position-relative d-flex flex-column justify-content-end">
                          <div className="d-flex align-items-start text-success text-right ml-auto position-relative">
                            <img className="profileCheckIcon filterToGreen" src="/images/ui/check-icon-black.png" height="12" />
                            Включен
                          </div>
                          <div
                            className="settings-googleAuthBtn position-relative formField-container mt-2"
                          >
                            {this.props.user.user.roles.length === 0  ? (
                                <Link to="/app/profile/google_auth_remove">
                                  <span>
                                    Удалить
                                  </span>
                                </Link>
                              ) : (
                                <span
                                  onClick={() => {
                                    this.setState({ googleAuthRemoveModalIsOpened: true });
                                  }}
                                >
                                  Удалить
                                </span>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Baron>
              </div>
            </div>
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    user: state.user,
  };
};

export default connect(mapStateToProps)(ProfilePage);
