import { SET_ANKETA } from '../actions/anketa';

// Expenses Reducer
const reducerDefaultState = {};

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_ANKETA:
      return action.anketa;
    default:
      return state;
  }
};
