import React from "react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import "moment/locale/ru";
import Baron from "react-baron/dist/es5";

export default class AnketaCitizenship extends React.Component {
  constructor(props) {
    super(props);

    const countries = [
      { name: "Россия", code: "RU", mask: "+\\7 (999) 999-99-99" },
      { name: "Азербайджан", code: "AZ", mask: "+\\9\\9\\4 99-999-99-99" },
      { name: "Армения", code: "AM", mask: "+\\3\\7\\4 99-999-999" },
      { name: "Беларусь", code: "BY", mask: "+\\3\\7\\5 (99) 999-99-99" },
      { name: "Казахстан", code: "KZ", mask: "+\\7 (\\799) 999-99-99" },
      { name: "Киргизия", code: "KG", mask: "+\\9\\9\\6 (999) 999-999" },
      { name: "Латвия", code: "LV", mask: "+\\3\\7\\1 99-999-999" },
      { name: "Молдавия", code: "MD", mask: "+\\3\\7\\3 999-99-999" },
      { name: "Таджикистан", code: "TJ", mask: "+\\9\\9\\2 99-999-9999" },
      { name: "Туркменистан", code: "TM", mask: "+\\9\\9\\3 9-999-9999" },
      { name: "Узбекистан", code: "UZ", mask: "+\\9\\9\\8 99-999-999" },
      { name: "Украина", code: "UA", mask: "+\\3\\8 (\\099) 999-99-99" },
    ];

    this.state = {
      validate: false,
      d__foreign__nationality: props.data.d__foreign__nationality || "RU",
      d__foreign__tax_residency: props.data.d__foreign__tax_residency || "RU",
      d__foreign__country_live: props.data.d__foreign__country_live || "RU",
      d__foreign__country_live_base:
        props.data.d__foreign__country_live_base || "",
      d__foreign__tax_number: props.data.d__foreign__tax_number || "",

      nationality_selectIsOpened: false,
      country_live_selectIsOpened: false,
      tax_residency_selectIsOpened: false,
      country_live_base_selectIsOpened: false,

      nationality_country: countries.find(
        (c) => c.code === (props.data.d__foreign__nationality || "RU")
      ),
      tax_residency_country: countries.find(
        (c) => c.code === (props.data.d__foreign__tax_residency || "RU")
      ),
      country_live_country: countries.find(
        (c) => c.code === (props.data.d__foreign__country_live || "RU")
      ),

      countries,
    };
  }

  action = (action, currIndex, dest) => {
    this.setState({ validate: true });
    if (this.validating() || dest === "prev") {
      this.props.action(action, currIndex, "citizenshipData", {
        d__foreign__nationality: this.state.d__foreign__nationality,
        d__foreign__tax_residency: this.state.d__foreign__tax_residency,
        d__foreign__country_live: this.state.d__foreign__country_live,
        d__foreign__country_live_base: this.state.d__foreign__country_live_base,
        d__foreign__tax_number: this.state.d__foreign__tax_number,
      });
    }
  };
  validating = () => {
    return (
      this.state.d__foreign__nationality != "" &&
      this.state.d__foreign__tax_residency != "" &&
      this.state.d__foreign__country_live != "" &&
      this.state.d__foreign__country_live_base != "" &&
      this.state.d__foreign__tax_number != ""
    );
  };

  onCitizenshipChange = (e) => {
    const d__foreign__nationality = e.target.value;
    this.setState(() => ({ d__foreign__nationality }));
  };
  onTaxResidenceChange = (e) => {
    const d__foreign__tax_residency = e.target.value;
    this.setState(() => ({ d__foreign__tax_residency }));
  };
  onStateOfResidenceChange = (e) => {
    const d__foreign__country_live = e.target.value;
    this.setState(() => ({ d__foreign__country_live }));
  };
  onStateOfResidenceBaseChange = (e) => {
    const d__foreign__country_live_base = e.target.value;
    this.setState(() => ({ d__foreign__country_live_base }));
  };
  onTaxpayerNumberChange = (e) => {
    const d__foreign__tax_number = e.target.value;
    this.setState(() => ({ d__foreign__tax_number }));
  };
  changeCountry = (index, countryKey, dataKey) => {
    this.setState({
      [countryKey]: this.state.countries[index],
      [dataKey]: this.state.countries[index].code,
    });
  };

  render() {
    return (
      <div>
        <h2 className="subtitle">Главное</h2>
        <div className="row">
          <div className="col-lg-6 inputFields-left">
            <div className="position-relative formField-container">
              <label className={`floating-label country__select`}>
                Гражданство
              </label>
              <div className="customSelect">
                <div
                  className="customSelectedItem"
                  onClick={() => {
                    this.setState({ nationality_selectIsOpened: true });
                  }}
                >
                  {this.state.nationality_country.name}
                  <i className="toggle icon-menu-open"></i>
                </div>
                {this.state.nationality_selectIsOpened && (
                  <ul className="customSelectList">
                    {this.state.countries.map((country, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          this.setState({
                            nationality_selectIsOpened: false,
                          });
                          this.changeCountry(
                            index,
                            "nationality_country",
                            "d__foreign__nationality"
                          );
                        }}
                      >
                        {country.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="position-relative formField-container">
              <label className={`floating-label country__select`}>
                Государство проживания
              </label>
              <div className="customSelect">
                <div
                  className="customSelectedItem"
                  onClick={() => {
                    this.setState({ country_live_selectIsOpened: true });
                  }}
                >
                  {this.state.country_live_country.name}
                  <i className="toggle icon-menu-open"></i>
                </div>
                {this.state.country_live_selectIsOpened && (
                  <ul className="customSelectList">
                    {this.state.countries.map((country, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          this.setState({
                            country_live_selectIsOpened: false,
                          });
                          this.changeCountry(
                            index,
                            "country_live_country",
                            "d__foreign__country_live"
                          );
                        }}
                      >
                        {country.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="position-relative formField-container">
              <label className={`floating-label country__select`}>
                Налоговое резиденство
              </label>
              <div className="customSelect">
                <div
                  className="customSelectedItem"
                  onClick={() => {
                    this.setState({ tax_residency_selectIsOpened: true });
                  }}
                >
                  {this.state.tax_residency_country.name}
                  <i className="toggle icon-menu-open"></i>
                </div>
                {this.state.tax_residency_selectIsOpened && (
                  <ul className="customSelectList">
                    {this.state.countries.map((country, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          this.setState({
                            tax_residency_selectIsOpened: false,
                          });
                          this.changeCountry(
                            index,
                            "tax_residency_country",
                            "d__foreign__tax_residency"
                          );
                        }}
                      >
                        {country.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="position-relative formField-container">
              <label
                className={`floating-label ${
                  this.state.d__foreign__country_live_base == "" ? "hidden" : ""
                }`}
              >
                Государство проживания, основание
              </label>
              <input
                type="text"
                className={`inputUnderLine ${
                  this.state.validate &&
                  this.state.d__foreign__country_live_base == ""
                    ? "inputValidateWrong"
                    : ""
                } ${
                  this.state.validate &&
                  this.state.d__foreign__country_live_base != ""
                    ? "inputValidateCorrect"
                    : ""
                }`}
                placeholder="Государство проживания, основание"
                value={this.state.d__foreign__country_live_base}
                onChange={this.onStateOfResidenceBaseChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="position-relative formField-container">
              <label
                className={`floating-label ${
                  this.state.d__foreign__tax_number == "" ? "hidden" : ""
                }`}
              >
                Номер налогоплатильщика в иностраннем государстве
              </label>
              <input
                type="text"
                className={`inputUnderLine ${
                  this.state.validate && this.state.d__foreign__tax_number == ""
                    ? "inputValidateWrong"
                    : ""
                } ${
                  this.state.validate && this.state.d__foreign__tax_number != ""
                    ? "inputValidateCorrect"
                    : ""
                }`}
                placeholder="Номер налогоплатильщика в иностраннем государстве"
                value={this.state.d__foreign__tax_number}
                onChange={this.onTaxpayerNumberChange}
              />
            </div>
          </div>
        </div>

        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <button
            className="btn btnBack"
            onClick={() => {
              this.action(this.props.previous, 0, "prev");
            }}
          >
            Назад
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              this.action(this.props.next, 2);
            }}
          >
            Далее
          </button>
        </div>
      </div>
    );
  }
}
