import React from 'react';
import { Link } from 'react-router-dom';

export default class DocScanCheck extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {}
  render() {
    return (
      <div>
        <div className="docPage-uploadDisc">
          Мы проверяем ваши документы, обычно это занимает не более 1 рабочего
          дня. Если очень долго ждете, напишите в тех.поддержку.
        </div>
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <Link to="/app/documents/moving" className="btn btn-white">
            Отменить
          </Link>
        </div>
      </div>
    );
  }
}
