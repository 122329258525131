import React from "react";

export default class AnketaDocs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.getAnketa();
  }

  render() {
    const anketaData = this.props.anketaData;

    return (
      <div>
        <div className="row">
          <h3 className="col-12">Личные данные</h3>
          <div className="col-md-4">
            <div className="form-group">
              <label>Фамилия:</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={anketaData.d__personal__last_name || ""}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Имя:</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={anketaData.d__personal__first_name || ""}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Отчество:</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={anketaData.d__personal__middle_name || ""}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Дата рождения:</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={anketaData.d__personal__birth_date || ""}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Место рождения:</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={anketaData.d__personal__birth_place || ""}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Пол:</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={anketaData.d__personal__sex ? "Мужской" : "Женский"}
              />
            </div>
          </div>

          {!anketaData.foreign ? (
            <React.Fragment>
              <h3 className="col-12">Паспорт РФ</h3>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Серия:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={anketaData.d__personal__passport__series || ""}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Номер:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={anketaData.d__personal__passport__number || ""}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Кем выдано:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={anketaData.d__personal__passport__issued_by || ""}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Дата выдачи:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={anketaData.d__personal__passport__issued_date || ""}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Код подразделения:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={
                      anketaData.d__personal__passport__department_code || ""
                    }
                  />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h3 className="col-12">Паспорт</h3>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Серия:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={anketaData.d__foreign__passport__series || ""}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Номер:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={anketaData.d__foreign__passport__number || ""}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Кем выдано:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={anketaData.d__foreign__passport__issued_by || ""}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Дата выдачи:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={anketaData.d__foreign__passport__issued_date || ""}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Срок Действия:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={anketaData.d__foreign__passport__valid_until || ""}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Код подразделения:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={
                      anketaData.d__foreign__passport__department_code || ""
                    }
                  />
                </div>
              </div>
            </React.Fragment>
          )}

          <h3 className="col-12">Место регистрации</h3>
          <div className="col-md-4">
            <div className="form-group">
              <label>Индекс:</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={anketaData.d__address__reg__postal_code || ""}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Регион:</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={anketaData.d__address__reg__region || ""}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Район:</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={anketaData.d__address__reg__area || ""}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Город:</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={anketaData.d__address__reg__city || ""}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Улица:</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={anketaData.d__address__reg__street || ""}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Дом:</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={anketaData.d__address__reg__house || ""}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Корпус:</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={anketaData.d__address__reg__block || ""}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Квартира:</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                value={anketaData.d__address__reg__flat || ""}
              />
            </div>
          </div>

          {!anketaData.foreign ? (
            <React.Fragment>
              <h3 className="col-12">Дополнительные данные</h3>
              <div className="col-md-4">
                <div className="form-group">
                  <label>ИНН:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={anketaData.d__extra__inn || ""}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control inputWOLabel"
                    readOnly={true}
                    value={
                      anketaData.d__extra__fl_type == 0
                        ? "Физическое лицо"
                        : "ИП"
                    }
                  />
                </div>
              </div>
            </React.Fragment>
          ) : null}
          {anketaData.d__extra__fl_type == 1 ? (
            <div className="col-md-4">
              <div className="form-group">
                <label>ОГРНИП:</label>
                <input
                  type="text"
                  className="form-control"
                  readOnly={true}
                  value={anketaData.d__extra__ogrnip || ""}
                />
              </div>
            </div>
          ) : null}
          {anketaData.d__extra__fl_type == 1 ? (
            <div className="col-md-4">
              <div className="form-group">
                <label>Применяемая система налогообложения:</label>
                <input
                  type="text"
                  className="form-control"
                  readOnly={true}
                  value={anketaData.d__extra__tax_system == 0 ? "УСН" : "ОСНО"}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
