import axios from 'axios';
import Config from '../config/config';

export const SET_ANALYSIS_DAYS = 'SET_ANALYSIS_DAYS';
export const SET_ANALYSIS_SYMBOLS = 'SET_ANALYSIS_SYMBOLS';

export const setDealsAnalysisDays = (analysis) => ({
  type: SET_ANALYSIS_DAYS,
  analysis,
});
export const setDealsAnalysisSymbols = (analysis) => ({
  type: SET_ANALYSIS_SYMBOLS,
  analysis,
});
