import React, { useEffect, useRef, useState } from "react";
import Baron from "react-baron/dist/es5";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import amplitude from 'amplitude-js';

import Config from "../config/config";
import {
  cancelAllOrders,
  cancelOrder,
  closeAllPositions,
  closePosition,
  getOrders,
  getPositions,
} from "../utils/tradingHttps";
import { getListenKey, updateListenKey } from "../utils/wsHttp";
import { NotificationManager } from "react-notifications";

const TAB_POSITIONS = 1;
const TAB_ORDERS = 2;

export default function PositionsAndOrdersPage() {
  const [tabSelected, setTabSelected] = useState(TAB_POSITIONS);
  const webSocket = useRef();
  const [webSocketError, setWebSocketError] = useState();
  const listenKeyUpdateInterval = useRef();
  const [closingPositions, setClosingPositions] = useState([]);
  const [cancelingOrders, setCancelingOrders] = useState([]);

  const [ordersRequest, setOrdersRequest] = useState({
    isLoading: false,
    error: null,
  });
  const [orders, setOrders] = useState();

  const [positionsRequest, setPositionsRequest] = useState({
    isLoading: false,
    error: null,
  });
  const [positions, setPositions] = useState();

  const [listenKeyRequest, setListenKeyRequest] = useState({
    isLoading: false,
    error: null,
  });
  const [listenKey, setListenKey] = useState();

  const [cancelAllOrdersRequest, setCancelAllOrdersRequest] = useState({
    isLoading: false,
    error: null,
  });
  const [closeAllPositionsRequest, setCloseAllPositionsRequest] = useState({
    isLoading: false,
    error: null,
  });

  // On Component load
  useEffect(() => {
    amplitude.getInstance().logEvent('positions_and_orders_page.page_view');

    loadListenKey();
    return () => {
      clearInterval(listenKeyUpdateInterval.current);
    };
  }, []);

  // Requests
  const loadPositions = () => {
    setPositionsRequest({ isLoading: true });
    getPositions()
      .then((result) => {
        setPositionsRequest({ isLoading: false });
        setPositions(result.data);
      })
      .catch((error) => {
        setPositionsRequest({ error: error });
      });
  };
  const loadOrders = () => {
    setOrdersRequest({ isLoading: true });
    getOrders()
      .then((result) => {
        setOrdersRequest({ isLoading: false });
        setOrders(result.data);
      })
      .catch((error) => {
        setOrdersRequest({ error: error });
      });
  };
  const loadListenKey = () => {
    setListenKeyRequest({ isLoading: true });
    getListenKey()
      .then((result) => {
        setListenKeyRequest({ isLoading: false });
        setListenKey(result.data.listenKey);

        startListenKeyUpdating();
        loadOrders();
        loadPositions();
      })
      .catch((error) => {
        setListenKeyRequest({ error: error });
      });
  };
  const renewListenKey = () => {
    updateListenKey();
  };

  //
  const startListenKeyUpdating = () => {
    listenKeyUpdateInterval.current = setInterval(() => {
      renewListenKey();
    }, 1800000); // every 30 mins
  };

  // Socket
  useEffect(() => {
    if (!listenKey) {
      return;
    }

    webSocket.current = new WebSocket(
      `${Config.binanceFuturesSocketBaseUrl}/ws/${listenKey}`
    );

    webSocket.current.onerror = () => {
      setWebSocketError(new Error("Websocket error"));
    };
    webSocket.current.onclose = (event) => {
      setWebSocketError(new Error(`Websocket is closed. Code: ${event.code}`));
    };
    webSocket.current.onmessage = (messageEvent) => {
      try {
        const message = JSON.parse(messageEvent.data);
        handleMessage(message);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    webSocket.current.onopen = () => {};

    return () => {
      webSocket.current.close();
    };
  }, [listenKey]);

  // Handle Socket Messages
  const handleMessage = (message) => {
    switch (message.e) {
      case "ACCOUNT_UPDATE":
        handlePositionMessage(message);
        break;

      case "ORDER_TRADE_UPDATE":
        handleOrderMessage(message);
        break;

      default:
        break;
    }
  };
  const handlePositionMessage = (message) => {
    setPositions((positions) => {
      const newPositions = [...positions];
      message.a.P.forEach((positionMessage) => {
        const newPosition = posisionFrom(positionMessage);
        const index = newPositions.findIndex(
          (position) => position.symbol === newPosition.symbol
        );
        if (index > -1) {
          if (parseFloat(newPosition.positionAmt) === 0) {
            newPositions.splice(index, 1);
          } else {
            newPositions.splice(index, 1, newPosition);
          }
        } else {
          newPositions.push(newPosition);
        }
      });

      return newPositions;
    });
  };
  const handleOrderMessage = (message) => {
    if (message.o.X === "NEW") {
      setOrders((orders) => addOrder(orders, message));
    }
    if (message.o.X === "PARTIALLY_FILLED") {
      setOrders((orders) => updateOrder(orders, message));
    }
    if (
      message.o.X === "CANCELED" ||
      message.o.X === "EXPIRED" ||
      message.o.X === "FILLED"
    ) {
      setOrders((orders) => removeOrder(orders, message));
    }
  };

  // Orders funcs
  const orderFrom = (message) => {
    return {
      avgPrice: message.o.ap,
      clientOrderId: message.o.c,
      closePosition: message.o.cp,
      executedQty: message.o.z,
      orderId: message.o.i,
      origQty: message.o.q,
      origType: message.o.ot,
      positionSide: message.o.ps,
      price: message.o.p,
      reduceOnly: message.o.R,
      side: message.o.S,
      status: message.o.X,
      stopPrice: message.o.sp,
      symbol: message.o.s,
      time: message.o.T,
      timeInForce: message.o.f,
      type: message.o.o,
      updateTime: message.T,
      workingType: message.o.wt,
    };
  };
  const addOrder = (orders, message) => {
    return [orderFrom(message), ...orders];
  };
  const updateOrder = (orders, message) => {
    const updatedOrder = orderFrom(message);
    let newOrders = [...orders];
    const index = newOrders.findIndex((order) => order.orderId === message.o.i);
    if (index > -1) {
      newOrders.splice(index, 1, updatedOrder);
    }

    return newOrders;
  };
  const removeOrder = (orders, message) => {
    let newOrders = [...orders];
    const index = newOrders.findIndex((order) => order.orderId === message.o.i);
    if (index > -1) {
      newOrders.splice(index, 1);
    }

    return newOrders;
  };

  // Positions funcs
  const posisionFrom = (message) => {
    return {
      entryPrice: message.ep,
      isolatedWallet: message.iw,
      marginType: message.mt,
      positionAmt: message.pa,
      positionSide: message.ps,
      symbol: message.s,
      unRealizedProfit: message.up,
    };
  };

  // Buttons
  const onClosePositionsButtonClick = (_) => {
    setCloseAllPositionsRequest({ isLoading: true });
    closeAllPositions()
      .then((result) => {
        setCloseAllPositionsRequest({ isLoading: false });
      })
      .catch((error) => {
        setCloseAllPositionsRequest({ error });
        NotificationManager.error(
          error.message || "Не удалось закрыть все позиции",
          "Ошибка",
          2000
        );
      });
  };
  const onCancelOrdersButtonClick = (_) => {
    setCancelAllOrdersRequest({ isLoading: true });
    cancelAllOrders()
      .then((_) => {
        setCancelAllOrdersRequest({ isLoading: false });
      })
      .catch((error) => {
        setCancelAllOrdersRequest({ error });
        NotificationManager.error(
          error.message || "Не удалось отменить все заявки",
          "Ошибка",
          2000
        );
      });
  };

  // Tabs
  const onPositionsTabClick = () => {
    setTabSelected(TAB_POSITIONS);
  };
  const onOrdersTabClick = () => {
    setTabSelected(TAB_ORDERS);
  };

  // Tabs Content
  const PositionsTabContent = () => {
    const hasError = webSocketError || positionsRequest.error;
    const isLoading = listenKeyRequest.isLoading || positionsRequest.isLoading;

    const onClosePositionButtonClick = (position) => {
      setClosingPositions((closingPositions) => {
        return [...closingPositions, position];
      });

      closePosition(position.symbol)
        .then((_) => {
          // remove from closing positions
          setClosingPositions((closingPositions) => {
            const newClosingPositions = [...closingPositions];
            const index = newClosingPositions.indexOf(position);
            if (index > -1) {
              newClosingPositions.splice(index, 1);
            }
            return newClosingPositions;
          });
        })
        .catch((error) => {
          // remove from closing positions
          setClosingPositions((closingPositions) => {
            const newClosingPositions = [...closingPositions];
            const index = newClosingPositions.indexOf(position);
            if (index > -1) {
              newClosingPositions.splice(index, 1);
            }
            return newClosingPositions;
          });

          NotificationManager.error(
            error.message || "Не удалось закрыть позицию",
            "Ошибка",
            2000
          );
        });
    };

    return (
      <div className="tab-content">
        <div className="tab-pane fade active show">
          <div className="table">
            <table className="tableLayoutFixes">
              <thead>
                <tr>
                  <td>Инструмент</td>
                  <td>Объем</td>
                  <td>Цена открытия</td>
                  <td>Сторона</td>
                </tr>
              </thead>
            </table>
            <Baron>
              {isLoading && <LoadingIndicator />}
              {hasError && <ErrorPlaceholder />}
              {positions && !positions.length && <NoDataPlaceholder />}
              <table className="tableLayoutFixes">
                <tbody>
                  {!hasError &&
                    positions &&
                    positions.map((position, index) => {
                      const onCloseButtonClick = () => {
                        onClosePositionButtonClick(position);
                      };
                      return (
                        <tr key={index}>
                          <td>{position.symbol}</td>
                          <td>
                            <button
                              disabled={closingPositions.includes(position)}
                              className="borderless clear-backgroud closePosition"
                              onClick={onCloseButtonClick}
                            >
                              <span className="icon-cross3 text-danger dashboard-instCloseBtn" />
                            </button>{" "}
                            {position.positionAmt}
                          </td>
                          <td>{position.entryPrice}</td>
                          <td>{position.positionAmt > 0 ? 'LONG' : 'SHORT'}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Baron>
          </div>
        </div>
      </div>
    );
  };
  const OrdersTabContent = () => {
    const hasError = webSocketError || ordersRequest.error;
    const isLoading = listenKeyRequest.isLoading || ordersRequest.isLoading;

    const onCancelOrderButtonClick = (order) => {
      setCancelingOrders((cancelingOrders) => {
        return [...cancelingOrders, order];
      });

      cancelOrder(order.orderId, order.symbol)
        .then((_) => {
          // remove from canceling orders
          setCancelingOrders((cancelingOrders) => {
            const newCancelingOrders = [...cancelingOrders];
            const index = newCancelingOrders.indexOf(order);
            if (index > -1) {
              newCancelingOrders.splice(index, 1);
            }
            return newCancelingOrders;
          });
        })
        .catch((error) => {
          // remove from canceling orders
          setCancelingOrders((cancelingOrders) => {
            const newCancelingOrders = [...cancelingOrders];
            const index = newCancelingOrders.indexOf(order);
            if (index > -1) {
              newCancelingOrders.splice(index, 1);
            }
            return newCancelingOrders;
          });

          NotificationManager.error(
            error.message || "Не удалось отменить заявку",
            "Ошибка",
            2000
          );
        });
    };

    return (
      <div className="tab-content">
        <div className="tab-pane fade active show">
          <div className="table">
            <table className="tableLayoutFixes">
              <thead>
                <tr>
                  <td>Время</td>
                  <td>Инструмент</td>
                  <td>Тип</td>
                  <td>Сторона</td>
                  <td>Цена</td>
                  <td>Объем</td>
                  <td>Исполнено</td>
                </tr>
              </thead>
            </table>
            <Baron>
              {isLoading && <LoadingIndicator />}
              {hasError && <ErrorPlaceholder />}
              {orders && !orders.length && <NoDataPlaceholder />}

              <table className="tableLayoutFixes">
                <tbody>
                  {!hasError &&
                    orders &&
                    orders.map((order, index) => {
                      const onCloseButtonClick = () => {
                        onCancelOrderButtonClick(order);
                      };

                      let side = "";
                      if (order.side === "BUY") {
                        side = "Покупка";
                      } else if (order.side === "SELL") {
                        side = "Продажа";
                      }

                      return (
                        <tr key={index}>
                          <td>{moment(order.time).calendar()}</td>
                          <td>{order.symbol}</td>
                          <td>{order.origType}</td>
                          <td>{side}</td>
                          <td>{order.price}</td>
                          <td>
                            <button
                              disabled={cancelingOrders.includes(order)}
                              className="borderless clear-backgroud cancelOrder"
                              onClick={onCloseButtonClick}
                            >
                              <span className="icon-cross3 text-danger dashboard-instCloseBtn" />
                            </button>{" "}
                            {order.origQty}
                          </td>
                          <td>{order.executedQty}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Baron>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="documentsMoving withdrawAssets">
      <h1>Позиции и заявки</h1>
      <div>
        <ul className="nav nav-tabs boardsTabs">
          <li className="nav-item" onClick={onPositionsTabClick}>
            <span
              className="nav-link text-center active show"
              data-toggle="tab"
            >
              <span>Позиции</span>
            </span>
          </li>
          <li className="nav-item" onClick={onOrdersTabClick}>
            <span className="nav-link text-center show" data-toggle="tab">
              <span>Заявки</span>
            </span>
          </li>

          <li className="closeDealsBtns">
            <button
              disabled={
                !orders ||
                (orders && !orders.length) ||
                cancelAllOrdersRequest.isLoading
              }
              className="btn btn-success"
              onClick={onCancelOrdersButtonClick}
            >
              Снять все заявки
              {cancelAllOrdersRequest.isLoading && (
                <i className="icon-spinner2 uploaderSpinner spinner ml-2"></i>
              )}
            </button>
            <button
              disabled={
                !positions ||
                (positions && !positions.length) ||
                closeAllPositionsRequest.isLoading
              }
              className="btn btn-success"
              onClick={onClosePositionsButtonClick}
            >
              Закрыть все позиции
              {closeAllPositionsRequest.isLoading && (
                <i className="icon-spinner2 uploaderSpinner spinner ml-2"></i>
              )}
            </button>
          </li>
        </ul>
      </div>

      {tabSelected === TAB_POSITIONS && <PositionsTabContent />}
      {tabSelected === TAB_ORDERS && <OrdersTabContent />}
    </div>
  );
}

const LoadingIndicator = () => {
  return (
    <div className="mt-1 text-center spinner-container isLoadingSpinner">
      <span>
        <img src="/images/ui/load-spinner.png" className="spinner" alt="" />
      </span>
    </div>
  );
};

const NoDataPlaceholder = () => {
  return <div className="emptyData">нет данных</div>;
};

const ErrorPlaceholder = () => {
  return (
    <h3 className="emptyData text-center text-danger">
      Ошибка получения данных
    </h3>
  );
};
