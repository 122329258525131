import axios from "axios";
import Config from "../config/config";

export const getOrders = () => {
  return axios({
    method: "get",
    url: `${Config.apiUrl}/v1/trading/orders`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const cancelOrder = (orderId, symbol) => {
  const data = {
    order_id: orderId.toString(),
    symbol,
  };

  return axios({
    method: "delete",
    url: `${Config.apiUrl}/v1/trading/order`,
    withCredentials: true,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const cancelAllOrders = () => {
  return axios({
    method: "delete",
    url: `${Config.apiUrl}/v1/trading/orders`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getPositions = () => {
  return axios({
    method: "get",
    url: `${Config.apiUrl}/v1/trading/positions`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const closePosition = (symbol) => {
  return axios({
    method: "delete",
    url: `${Config.apiUrl}/v1/trading/positions/${symbol}/`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const closeAllPositions = () => {
  return axios({
    method: "delete",
    url: `${Config.apiUrl}/v1/trading/positions`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
