import React from "react";
import { connect } from "react-redux";
import Popup from "../containers/Popup";

class GoogleAuthWrongCodeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpened: props.modalIsOpened,
    };
  }
  
  render() {
    return (
      <Popup
        closePopup={this.props.closeModal}
        popupClass="googleAuth"
      >
        <div className="popup-title mb-3">Код не подходит</div>
        <ol className="mt-2 pl-4">
          <li>
            Возможно, что срок действия кода истек. Попробуйте ввести новый код.
          </li>
          <li>
            Убедитесь, что вы вводите код для аккаунта на{" "}
            {process.env.REACT_APP_PROJECT_DOMAIN}
          </li>
          <li>
            Если код по-прежнему не подходит, обратитесь в техподдержку{" "}
            <a href={`mailto:${this.props.settings.SUPPORT_EMAIL}`} target="_blank">{this.props.settings.SUPPORT_EMAIL}</a>
          </li>
        </ol>
        <div className="popupSubmitBtns d-flex justify-content-end align-items-center">
          <div
            className="popupCancel"
            onClick={this.props.closeModal}
          >
            ОК
          </div>
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings
  };
};

export default connect(mapStateToProps)(GoogleAuthWrongCodeModal);