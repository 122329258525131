import React from 'react';
import { connect } from 'react-redux';

export class ShopContactsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="content shop content-shopConditions shopList">
        <div className="card">
          <div className="card-body">
            <table className="table table-shopOffer table-shopContacts">
              <tbody>
                <tr>
                  <td>ФИО</td>
                  <td>Васеев Евгений Сергеевич</td>
                </tr>
                <tr>
                  <td>Наименование</td>
                  <td>ИП ВАСЕЕВ Е.С.</td>
                </tr>
                <tr>
                  <td>
                    Адрес регистрации
                    <br />
                    Почтовый индекс, страна, город, район, улица, номер дома,
                    корпус или строение, квартира
                  </td>
                  <td>г. Москва, ул. Василия Петушкова, дом 19, кв.94</td>
                </tr>
                <tr>
                  <td>
                    Почтовый адрес
                    <br />
                    Почтовый индекс, страна, город, район, улица, номер дома,
                    корпус или строение, квартира
                  </td>
                  <td>
                    125007, г. Москва, ул. Василия Петушкова, дом 19, кв.94
                  </td>
                </tr>
                <tr>
                  <td>ИНН</td>
                  <td>773371520302</td>
                </tr>
                <tr>
                  <td>ОГРНИП</td>
                  <td>317774600012048</td>
                </tr>
                <tr>
                  <td>Система налогообложения</td>
                  <td>УСН</td>
                </tr>
                <tr>
                  <td>
                    Паспортные данные
                    <br />
                    Номер и серия
                    <br />
                    Кем выдан
                    <br />
                    <br />
                    Дата выдачи
                    <br />
                    Код подразделения
                  </td>
                  <td>
                    <br />
                    4517 988486
                    <br />
                    Отделением УФМС России по гор. Москве по СЗАО района Южное
                    Тушино
                    <br />
                    16/02/2018
                    <br />
                    770-099
                  </td>
                </tr>
                <tr>
                  <td>Банковские реквизиты</td>
                  <td>
                    Р/С 40802810700000001656
                    <br />
                    БИК 044525604
                    <br />
                    БАНК Акционерное Общество «Инвестиционный
                    <br />
                    Банк «ФИНАМ»
                  </td>
                </tr>
                <tr>
                  <td>Телефон</td>
                  <td>8(995)507-00-77</td>
                </tr>
                <tr>
                  <td>e-mail</td>
                  <td>evgeny@vaseev.com</td>
                </tr>
                <tr>
                  <td>Сайт</td>
                  <td>www.vaseev.com</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(ShopContactsPage);
