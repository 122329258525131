import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => (
  <div className="flexableBlock" style={{ paddingTop: 0 }}>
    <div className="page-content">
      <div className="content-wrapper">
        <div className="content d-flex justify-content-center align-items-center">
          <div className="flex-fill">
            <div className="text-center mb-3">
              <h1 className="error-title" style={{ margin: 0 }}>
                404
              </h1>
              <h5>Страница не найдена!</h5>
            </div>
            <div className="row">
              <div className="col-sm-4 offset-sm-4">
                <Link to="/" className="btn btn-primary btn-block">
                  <i className="icon-home4 mr-2"></i> На главную
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
