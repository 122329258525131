import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

export class Popup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  closePopup = () => {
    this.props.closePopup();
  };
  render() {
    return (
      <div className="popup d-flex align-items-center">
        <div className={`popup_inner ${this.props.popupClass || ''}`}>
          <img
            src="/images/ui/cross.png"
            className="popup-crossCancel"
            onClick={this.closePopup}
            alt=""
          />
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default connect()(Popup);
