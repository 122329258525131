import React from 'react';
import { connect } from 'react-redux';
import amplitude from 'amplitude-js';

import { catchPromise } from '../utils/http';
import { getNews } from '../utils/newsHttp';
import News from '../components/News';

export class NewsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      news: [],
      page_num: 1,
      page_total: 1,
      loaded: true,
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    amplitude.getInstance().logEvent('news_page.page_view');

    document
      .getElementsByClassName('content')[0]
      .addEventListener('wheel', this.scrollContent);

    this.loadNews(1);
  }
  loadNews = (page_num) => {
    this.setState({ loaded: true });
    getNews(page_num)
      .then((res) => {
        this.setState(
          {
            news: [...this.state.news, ...res.data.news],
            page_num,
            page_total: res.data.page_total,
            loaded: false,
          },
          () => {
            $(document).ready(function () {
              if (window.location.hash) {
                $('html, body').animate(
                  {
                    scrollTop:
                      $('#news-' + window.location.hash.substring(1)).offset()
                        .top - 50,
                  },
                  100
                );
              }
            });
          }
        );
      })
      .catch((err) => {
        this.setState({ loaded: false });
        catchPromise(err, this.loadNews.bind(this, page_num), this.errorCb);
      });
  };
  handlePageChange = () => {
    this.loadNews(this.state.page_num + 1);
  };

  scrollContent = (e) => {
    const pageScroller = $('.page-content > .clipper > .scroller');
    const pageContentWrap = $('.content-wrapper');

    if (
      pageScroller.scrollTop() + pageScroller.height() ==
        pageContentWrap.height() &&
      !this.state.loaded &&
      this.state.page_num < this.state.page_total
    )
      this.loadNews(this.state.page_num + 1);
  };

  render() {
    return (
      <div className="newsPage col-lg-8 offset-lg-2">
        {!this.state.pageError ? (
          <div>
            <News
              news={this.state.news}
              page_num={this.state.page_num}
              page_total={this.state.page_total}
              handlePageChange={this.handlePageChange}
            />
            {this.state.loaded && (
              <div className="mt-2 mb-2 text-center spinner-container">
                <span>
                  <img
                    src="/images/ui/load-spinner.png"
                    className="spinner"
                    alt=""
                  />
                </span>
              </div>
            )}
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
      </div>
    );
  }
}

export default connect()(NewsPage);
