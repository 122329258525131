import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { currencyFormat, sumColor } from '../utils/currencyUtil';

export default class Transactions extends Component {
  render() {
    const { transaction, currency } = this.props;

    return (
      <tr key={transaction}>
        <td className="transactionTableData">{transaction.date}</td>
        <td className="text-left transactionTableDesc">
          {transaction.description}
        </td>
        <td className="text-left transactionTablePlace">
          {transaction.board || ''}
        </td>
        <td className="transactionTableSum">
          {transaction.amount ? currencyFormat(transaction.amount) : ''}
        </td>
      </tr>
    );
  }
}

Transactions.propTypes = {
  transaction: PropTypes.object,
  currency: PropTypes.string,
};
