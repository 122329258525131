import React from "react";
import "moment/locale/ru";
import Baron from "react-baron/dist/es5";
import { connect } from "react-redux";
import amplitude from 'amplitude-js';
import { currencyFormat } from "../utils/currencyUtil";
import { postFile, createDocument } from "../utils/bonusesHttp";
import { Link, Redirect } from "react-router-dom";
import { history } from "../routers/AppRouter";
import WAValidator from "@getdelta/wallet-address-validator";
import GoogleAuthWrongCodeModal from "./GoogleAuthWrongCodeModal";

export class SpendBonusesCreate extends React.Component {
  constructor(props) {
    super(props);
    this.debouncedValidateWallet = this.debounce(this.validateWallet, 2000);

    this.state = {
      account:
        props.tradingAccounts.trading_accounts.find((acc) => acc.bonus) || {},
      sum: "",
      wallet: "",
      walletError: "",
      walletSuccess: "",
      file: null,
      fileError: "",
      fileUploaded: false,
      isSending: false,
      error: "",
      sumError: "",
      doneMessage: "",
      selectValue: "TRC-20",
      selectIsOpened: false,
      isExpanded: true,
      otp_password: "",
      step: 1,
    };
  }

  componentDidMount() {
    amplitude.getInstance().logEvent('spend_bonuses_create_page.page_view');
  }

  debounce = (func, ms) => {
    let timeout;
    return function() {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, arguments), ms);
    };
  }

  onSumChange = (e) => {
    const sum = e.target.value;
    if (!sum || sum.match(/^[0-9]+$/)) {
      this.setState(() => ({ sum, sumError: "" }));
    }
  };

  onWalletChange = (e) => {
    const wallet = e.target.value.split(" ").join("");
    this.debouncedValidateWallet(wallet);
    this.setState({ wallet });
  };

  validateWallet = (wallet) => {
    const valid = WAValidator.validate(wallet, "tron");
    if (!wallet) {
      this.setState({
        walletError: "",
        walletSuccess: ""
      }) 
    } else if (!valid) {
      this.setState({
        walletError: "Невалидный адрес кошелька. Проверьте кошелек на соответствие условиям",
        walletSuccess: ""
      });

      return false;
    } else {
      this.setState({
        walletError: "",
        walletSuccess: "Кошелек валидный"
      });
    }
  }

  onFileChange = (e) => {
    const file = e.target.files[0];
    const fileSize = (file.size / 1024 / 1024).toFixed(4);
    if (fileSize > 10) {
      this.setState({ error: "Размер файла не может превышать 10МБ" });
      return;
    }
    var formData = new FormData();
    formData.append("file", file, file.name);
    postFile(formData).then((res) => {
      this.setState({
        fileUploaded: true,
        file: { ...res.data, type: file.type },
        fileError: ""
      });
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (!this.validating()) return;
    const data = {
      trading_account_id: +this.state.account.id,
      amount: +this.state.sum,
      wallet_address: this.state.wallet,
      otp_password: this.state.otp_password
    };
    if (this.state.file) data.file = this.state.file.file_id;

    createDocument(data)
      .then((res) => {
        this.setState({
          error: "",
          doneMessage: "Выплата успешна произведена.",
          step: 3,
        });
      })
      .catch((err) => {
        if (err.response.data && err.response.data.message && err.response.data.message[0] && err.response.data.message[0].field === 'file') {
          this.setState({ fileError: "Загрузите файл", doneMessage: "" });
        } else {
          this.setState({ googleAuthError: err.response.data.message, doneMessage: "" });
        }
      });
  };
  
  onOtpChangePassword = (e) => {
    const value = e.target.value
      .split("")
      .filter((c) =>
        ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(c)
      )
      .slice(0, 6)
      .join("");

    this.setState({ otp_password: value });
  }

  validating = () => {
    if (!this.state.sum) {
      this.setState({ sumError: "Заполните сумму", error: "", doneMessage: "" });
      return false;
    }
    if (!this.state.wallet) {
      this.setState({ error: 'Заполните поле "Кошелёк"', doneMessage: "" });
      return false;
    }
    const valid = WAValidator.validate(this.state.wallet, "tron");
    if (!valid) {
      this.setState({
        error: "Адрес кошелька не является валидным",
        doneMessage: "",
      });
      return false;
    }
    if (
      !isNaN(this.state.sum) &&
      +this.state.sum > +this.state.account.balance.trading
    ) {
      this.setState({
        error: "Недостаточно средств для вывода",
        doneMessage: "",
      });
      return false;
    }
    if (!this.state.file) {
      this.setState({ fileError: "Загрузите файл", doneMessage: "" });
      return false;
    }
    return true;
  };

  render() {
    return (
      <React.Fragment>
        {!this.props.tradingAccounts.trading_accounts.find(
          (acc) => acc.bonus
        ) ? (
          <Redirect to="/app" />
        ) : (
          <div className="documentCreatePage">
            <h1 className="title card-content">{this.state.step === 1 ? "Обменять бонусы" : this.state.step === 2 ? "Подтвердите запрос" : "Запрос создан"}</h1>
            {this.state.step === 1 ? (
              <React.Fragment>
                <div className="card card-content">
                  <div className="card-body">
                    <h3 className="mb-0">Условия</h3>
                    <div className={`bonusProgram-container spend-bonuses ${this.state.isExpanded ? 'expanded' : ''} p-0`}>
                      {this.state.isExpanded ? (
                        <React.Fragment>
                            <div className="mt-3"><b>Кошелек</b></div>
                            <ul className="ml-4">
                              <li>
                                Используйте кошелек сети TRC20
                              </li>
                              <li>
                                Не поддерживается конвертация на торговый кошелек Binance
                              </li>
                            </ul>
                            <div className="mt-3"><b>Курс конвертации и комиссия</b></div>
                            <ul className="ml-4">
                              <li>
                                1 VB = 1 USDT
                              </li>
                              <li>
                                От 300 VB — без комиссии
                              </li>
                              <li>
                                До 300 VB — комиссия 1,5 VB
                              </li>
                            </ul>
                        </React.Fragment>
                      ) : null}
                    </div>
                    <div className={`expand ${!this.state.isExpanded ? 'active' : ''}`} onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}>
                      <img src="/images/ui/cross.png" alt="Cross" />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
            <form onSubmit={this.onSubmit}>
              <div className="card card-content bonusesCreate">
                {!this.state.pageError ? (
                  <div>
                    <div className="card-body">
                      <div>
                        <Baron>
                          <h4>Бонусный счет -{'>'} Личный кошелек</h4>
                          {this.state.step === 1 ? (
                            <React.Fragment>
                              <div className="docCreateChoice mt-2">
                                <div className="docCreateChoice-title">Сеть</div>
                                <div className="docCreateChoice-select">
                                  <div className="customSelect">
                                    <div
                                      className="customSelectedItem"
                                      onClick={() => {
                                        this.setState({ selectIsOpened: true });
                                      }}
                                    >
                                      {this.state.selectValue === "TRC-20" &&
                                        "TRC-20"}
                                      <i className="toggle icon-arrow-down5"></i>
                                    </div>
                                    {this.state.selectIsOpened && (
                                      <ul className="customSelectList">
                                        <li
                                          onClick={() => {
                                            this.setState({
                                              selectValue: "TRC-20",
                                              selectIsOpened: false,
                                            });
                                          }}
                                        >
                                          TRC-20
                                        </li>
                                      </ul>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  className="inputUnderLine bonusCreatePayment-sumField_wallet w-75"
                                  placeholder="Адрес для пополнения"
                                  value={this.state.wallet}
                                  onChange={this.onWalletChange}
                                />
                                <div className={`${this.state.walletError ? "text-danger" : "text-success"}`}>
                                  {this.state.walletError ? this.state.walletError : this.state.walletSuccess ? (
                                    <div>
                                      {this.state.walletSuccess}
                                      <img style={{ marginLeft: 5 }} src="/images/ui/check-icon-black.png" className="filterToGreen" alt="Ready" height="12px" />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <input
                                type="text"
                                className="inputUnderLine bonusCreatePayment-sumField mt-2 w-50"
                                placeholder="Сумма баллов для конвертации"
                                value={this.state.sum}
                                onChange={this.onSumChange}
                              />
                              {this.state.sumError ? <div className="text-danger">
                                {this.state.sumError}
                              </div> : null}
                              <div className="bonusCreatePayment-sumDescr">
                                <span>Доступно: </span>
                                {this.state.account.balance &&
                                  this.state.account.balance.trading && (
                                    <span>
                                      {parseFloat(this.state.account.balance.trading).toFixed(2)} VB
                                    </span>
                                  )}
                                <br />
                                {this.state.sum < 300 ? <span>Комиссия 1.5 VB входит в сумму вывода</span> : <span>Без комисссии</span>}
                              </div>
                              <div className="uploader-title mt-4">
                                QR-код кошелька. Файлы JPG (или JPEG), PDF, PNG объемом до 10 МБ.
                                {this.fileUploaded && (
                                  <i className="icon-checkmark3 uploaderTitleCheck"></i>
                                )}
                              </div>
                              <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                                <input
                                  type="file"
                                  id="files__passportField"
                                  className="form-control-uniform-custom"
                                  accept=".png, .jpg, .pdf, .jpeg"
                                  onChange={(e) => this.onFileChange(e)}
                                />
                                {!this.state.fileUploaded && (
                                  <span>
                                    Загрузить
                                    <img
                                      src="/images/ui/uploadIcon.png"
                                      className="uploadIcon"
                                      alt=""
                                    />
                                  </span>
                                )}
                                {this.state.fileError && (
                                  <div className="text-danger text-left" style={{ fontSize: "12px" }}>
                                    {this.state.fileError}
                                  </div>
                                )}
                                {this.state.fileUploaded && (
                                  <span>
                                    Загружено
                                    <i className="icon-checkmark3 uploaderCheck"></i>
                                  </span>
                                )}
                              </div>
                              {this.state.error && (
                                <h6 className="text-center text-danger mt-2">
                                  {this.state.error}
                                </h6>
                              )}
                              {this.state.file &&
                                (this.state.file.type !== "application/pdf" ? (
                                  <img
                                    src={this.state.file.url}
                                    className="mt-2"
                                    style={{ width: "95%" }}
                                  />
                                ) : (
                                  <div className="mt-1">
                                    <a href={this.state.file.url} target="blank">
                                      Посмотреть PDF файл
                                    </a>
                                  </div>
                                ))}
                            </React.Fragment>
                          ) : (
                            <div>
                              {this.state.modalIsOpened ? (
                                <GoogleAuthWrongCodeModal
                                  modalIsOpened={this.state.modalIsOpened}
                                  closeModal={() => this.setState({ modalIsOpened: false })}
                                />
                              ) : null}
                              <div className="mt-4">Перевод на сумму: {this.state.sum} USDT</div>
                              <div className="bonusCreatePayment-sumDescr">
                                {this.state.sum < 300 ? <span>Комиссия 1.5 VB входит в сумму вывода</span> : <span>Без комисссии</span>}
                              </div>
                              <div className="mt-4">
                                Кошелек: {this.state.wallet}<br />
                                Сеть: {this.state.selectValue}
                              </div>
                              {this.state.file && (
                                <div className="d-flex align-items-center mt-4">
                                  {this.state.file.type !== "application/pdf" ? (
                                    <img
                                      src={this.state.file.url}
                                      style={{ width: "50px" }}
                                    />
                                  ) : (
                                    <div className="mt-1">
                                      <a href={this.state.file.url} target="blank">
                                        Посмотреть PDF файл
                                      </a>
                                    </div>
                                  )}
                                  <div className="ml-2 text-secondary">QR-код кошелька загружен</div>
                                </div>)}
                              <div className="mt-4">
                                {this.state.step === 2 ? (
                                  <React.Fragment>
                                    <div className="h3">Введите код подтверждения</div>
                                    <div className="position-relative">
                                      <div>6-значный код из приложения для двухэтапной верификации</div>
                                      <input
                                        className={`inputUnderLine`}
                                        value={this.state.otp_password}
                                        onChange={this.onOtpChangePassword}
                                      />
                                    </div>
                                    {this.state.googleAuthError ? (
                                      <h6 className="text-danger">
                                        {this.state.googleAuthError}
                                      </h6>
                                    ) : null}
                                    <div
                                      className="codeNoMatch p-0"
                                      onClick={() => this.setState({ modalIsOpened: true })}
                                    >
                                      Код не подходит
                                    </div>
                                    {this.state.doneMessage ? (
                                      <h6 className="text-center text-success mt-4">
                                        {this.state.doneMessage}
                                      </h6>
                                    ) : null}
                                  </React.Fragment>
                                ) : this.state.step === 3 ? (
                                  <div className="text-secondary">
                                    <div className="d-flex" style={{ width: "96%" }}>
                                      <img
                                        style={{ position: "relative", top: "3px", marginLeft: "5px", marginRight: "5px" }}
                                        src="/images/ui/warn-icon.png"
                                        width="20px"
                                        height="20px"
                                      />
                                      <div>
                                        <div>Запросы на вывод модерируются. Если потребуется, мы свяжемся с вами для подтверждения.</div>
                                        <div className="mt-1">
                                          Обычно обработка запроса занимает не более 1–2 дней.
                                        </div>
                                        <div className="mt-1">
                                          Статус запроса и историю списаний можно найти в разделе{" "}
                                          <Link to="/app/spend-bonuses">
                                            обмен бонусов
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}
                            {!this.props.user.user.two_factor_auth ? (
                              <div className="d-flex align-items-start text-danger position-relative mt-4 ml-3 pl-2">
                                <img className="forbiddenIcon" src="/images/ui/forbidden.png" style={{ left: "-14px" }} />
                                <div>
                                  Выводить бонусы можно, если подключена двойная аутентификация.<br />
                                  Подключите Google Authentificator в разделе{' '}
                                  <Link to="/app/profile" className="text-danger" style={{ textDecoration: "underline" }}>
                                    профиль
                                  </Link>
                                </div>
                              </div>
                            ) : null}
                        </Baron>
                      </div>
                    </div>
                  </div>
                ) : (
                  <h3 className="text-center text-danger">Произошла ошибка</h3>
                )}
              </div>
            </form>
            {this.state.step !== 3 ? (
              <div className="btnSubmits card-content d-flex justify-content-between align-items-center position-static">
                {this.state.step === 1 ? (
                  <Link to="/app/spend-bonuses" className="btn btn-white">
                    Назад
                  </Link>
                ) : (
                  <button
                    className="btn btn-white"
                    onClick={() => this.setState({ step: 1 })}
                  >
                    Назад
                  </button>
                )}
                
                {this.state.step === 1 ? (
                  <button
                    className="btn btn-success"
                    disabled={!this.props.user.user.two_factor_auth}
                    onClick={() => {
                      if (!this.validating()) return;
                      this.setState({ step: 2, error: "" })
                    }}
                  >
                    Далее
                  </button>
                ) : (
                  <button
                    className="btn btn-success"
                    disabled={(!this.state.isLoadFiles && this.props.isSending) || !this.props.user.user.two_factor_auth || this.state.otp_password.length < 6}
                    onClick={this.onSubmit}
                  >
                    Создать
                    {this.state.isSending && (
                      <i className="icon-spinner2 uploaderSpinner spinner"></i>
                    )}
                  </button>
                )}
              </div>
            ) : null}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  tradingAccounts: state.tradingAccounts,
});

export default connect(mapStateToProps)(SpendBonusesCreate);
