import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import 'react-date-range';
import { Provider } from 'react-redux';
import amplitude from 'amplitude-js'
import AppRouter from './routers/AppRouter';
import configureStore from './store/configureStore';
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'normalize.css/normalize.css';
import 'react-dates/lib/css/_datepicker.css';
import './styles/style.scss';
import './_template/app';
import './_template/main_landing';
import Config from './config/config';


Sentry.init({
    dsn: Config.sentryDsn,
    environment: Config.env
});

amplitude.getInstance().init(Config.amplitudeKey);

const store = configureStore();

const jsx = (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

ReactDOM.render(jsx, document.getElementById('app'));
