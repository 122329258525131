import React from "react";
import "moment/locale/ru";
import Baron from "react-baron/dist/es5";
import amplitude from 'amplitude-js';
import { getBonuseById } from "../utils/bonusesHttp";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";

export class SpendBonusesAdditional extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      data: [],
      isLoading: true,
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    amplitude.getInstance().logEvent('spend_bonuses_additional_page.page_view');

    getBonuseById(this.state.id).then((res) => {
      this.setState({ data: res.data, isLoading: false });
    });
  }
  render() {
    console.log(this.props);
    return (
      <React.Fragment>
        {!this.props.tradingAccounts.trading_accounts.find(
          (acc) => acc.bonus
        ) ? (
          <Redirect to="/app" />
        ) : (
          <div className="documentsMoving">
            {!this.state.pageError ? (
              <div>
                <div className="tab-content">
                  <div className="tab-pane fade active show">
                    {this.state.data && !this.state.isLoading ? (
                      <div>
                        <Baron>
                          <div className="p-4">
                            <h5>Дата создания: {this.state.data.date}</h5>
                            <h5>
                              Наименование документа: {this.state.data.title}
                            </h5>
                            <h5>
                              Кошелек: {this.state.data.masked_wallet_address}
                            </h5>
                            <h5>Сумма: {this.state.data.amount} USD</h5>
                          </div>
                        </Baron>
                      </div>
                    ) : (
                      <div className="emptyDashboard-plug">
                        {this.state.isLoading ? (
                          <div className="mt-1 text-center spinner-container isLoadingSpinner">
                            <span>
                              <img
                                src="/images/ui/load-spinner.png"
                                className="spinner"
                                alt=""
                              />
                            </span>
                          </div>
                        ) : (
                          <div className="emptyData">нет данных</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="btnSubmits card-content d-flex justify-content-between align-items-center position-static ml-0">
                  <Link to="/app/spend-bonuses" className="btn btn-white">
                    Назад
                  </Link>
                </div>
              </div>
            ) : (
              <h3 className="text-center text-danger">Произошла ошибка</h3>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tradingAccounts: state.tradingAccounts,
});

export default connect(mapStateToProps)(SpendBonusesAdditional);
