import React from "react";
import { connect } from "react-redux";
import Countries from "i18n-iso-countries";
Countries.registerLocale(require("i18n-iso-countries/langs/ru.json"));
Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import { Wizard, Steps, Step } from "react-albus";
import Baron from "react-baron/dist/es5";
import amplitude from 'amplitude-js';

import { checkAuth } from "../utils/http";
import { setUser } from "../actions/user";
import { sendAnketaData } from "../utils/anketaHttp";
import { catchPromise } from "../utils/http";
import AnketaFormReady from "./AnketaFormReady";
import AnketaProfile from "./AnketaProfile";
import AnketaAddresses from "./AnketaAddresses";
import AnketaAddData from "./AnketaAddData";
import AnketaDocs from "./AnketaDocs";
import AnketaPersonalData from "./AnketaPersonalData";
import { getAnketa } from "../utils/anketaHttp";
import { setAnketa } from "../actions/anketa";
import AnketaTypeChoose from "./AnketaTypeChoose";
import AnketaCitizenship from "./AnketaCitizenship";
import TechOptions from "./TechOptions";
import AnketaPassportData from "./AnketaPassportData";
import AnketaForeignAdresses from "./AnketaForeignAddresses";
import AnketaForeignBankData from "./AnketaForeignBankData";
import AnketaForeignDocs from "./AnketaForeignDocs";
import { Link, NavLink } from "react-router-dom";
import Popup from "../containers/Popup";

const isCrypto = true;

export class AnketaFormPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      docsModalOpen: false,
      anketaStatus: 0,
      anketaCurrStep: -1,
      userAnketaStatus: this.props.user.form_status,
      pageError: false,
      readyAnketa: {},
      profileData: {},
      addressData: {},
      addData: {},
      docsData: {},
      personalData: {},
      citizenshipData: {},
      passportData: {},
      foreignAddressesData: {},
      foreignBankData: {},
      foreignDocsData: {},
      isResident: true,
      formReady: false,
      formStatus: props.user.form_status,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };

  componentDidMount() {
    amplitude.getInstance().logEvent('anketa_form_page.page_view');

    this.checkUserStatusAnketa().then(() => {
      if (this.props.user.form_status === "NEED_REPLACE") {
        this.getReadyAnketa();
      }
    });
    if (isCrypto) {
      this.setState({ anketaCurrStep: 0 });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.docsModalOpen !== this.state.docsModalOpen) {
      if (this.state.docsModalOpen) {
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        document.querySelector('body').style.overflow = 'unset';
      }
    }
  }

  toggleAnketaType = () => {
    this.setState((state) => ({
      isResident: !state.isResident,
    }));
  };

  checkUserStatusAnketa = () => {
    return checkAuth()
      .then((res) => {
        if (
          this.props.user.form_status !== res.data.form_status
        ) {
          this.props.setUser(res.data);
        }
      })
      .catch((err) => {
        catchPromise(err, this.checkUserStatusAnketa, this.errorCb);
      });
  };

  actionStep = (action, currIndex, stepName, data) => {
    action();
    $(".progressLine-iconContainer").each(function (index, element) {
      $(element).removeClass("progressIcon-prev");
      $(element).removeClass("progressIcon-next");
      if (index < currIndex) {
        $(element).addClass("progressIcon-prev");
        $(element).find(".checkIcon").removeClass("hidden");
        $(element).find(".themeIcon").addClass("hidden");
        $(element).find(".numIcon").addClass("hidden");
      } else if (index == currIndex) {
        $(element).find(".checkIcon").addClass("hidden");
        $(element).find(".themeIcon").removeClass("hidden");
        $(element).find(".numIcon").addClass("hidden");
      } else if (index > currIndex) {
        $(element).addClass("progressIcon-next");
        $(element).find(".checkIcon").addClass("hidden");
        $(element).find(".themeIcon").addClass("hidden");
        $(element).find(".numIcon").removeClass("hidden");
      }
    });
    this.setState({ anketaCurrStep: currIndex });
    switch (stepName) {
      case "profile":
        this.setState({ profileData: data });
        break;
      case "address":
        this.setState({ addressData: data });
        break;
      case "add":
        this.setState({ addData: data });
        break;
      case "docs":
        this.setState({ docsData: data });
        break;
      case "personalData":
        this.setState({ personalData: data });
        break;
      case "citizenshipData":
        this.setState({ citizenshipData: data });
        break;
      case "passportData":
        this.setState({ passportData: data });
        break;
      case "foreignAddresses":
        this.setState({ foreignAddressesData: data });
        break;
      case "foreignBankData":
        this.setState({ foreignBankData: data });
        break;

      default:
        break;
    }
  };
  sendAnketa = (docsData) => {
    this.state.profileData.d__personal__sex =
      this.state.profileData.d__personal__sex === 0;
    if (isCrypto) {
      sendAnketaData(
        Object.assign(
          this.state.personalData,
          this.state.passportData,
          {
            d__address__reg__country:
              this.state.foreignAddressesData.d__address__reg__country,
            d__address__reg__postal_code:
              this.state.foreignAddressesData.d__address__reg__postal_code,
            d__address__reg__region:
              this.state.foreignAddressesData.d__address__reg__region,
            d__address__reg__city:
              this.state.foreignAddressesData.d__address__reg__city,
            d__address__reg__street:
              this.state.foreignAddressesData.d__address__reg__street,
            d__address__reg__house:
              this.state.foreignAddressesData.d__address__reg__house,
            d__address__reg__block:
              this.state.foreignAddressesData.d__address__reg__block,
            d__address__reg__flat:
              this.state.foreignAddressesData.d__address__reg__flat,
          },
          this.state.addData,
          docsData
        )
      )
        .then((res) => {
          this.loadUserData();
        })
        .catch((err) => {
          catchPromise(err, this.sendAnketa.bind(this, docsData), this.errorCb);
        });
    } else if (this.state.isResident) {
      sendAnketaData(
        Object.assign(
          this.state.profileData,
          {
            d__address__reg__postal_code:
              this.state.addressData.d__address__reg__postal_code,
            d__address__reg__region:
              this.state.addressData.d__address__reg__region,
            d__address__reg__city: this.state.addressData.d__address__reg__city,
            d__address__reg__street:
              this.state.addressData.d__address__reg__street,
            d__address__reg__house:
              this.state.addressData.d__address__reg__house,
            d__address__reg__flat: this.state.addressData.d__address__reg__flat,

            d__address__postal__postal_code:
              this.state.addressData.d__address__postal__postal_code,
            d__address__postal__region:
              this.state.addressData.d__address__postal__region,
            d__address__postal__city:
              this.state.addressData.d__address__postal__city,
            d__address__postal__street:
              this.state.addressData.d__address__postal__street,
            d__address__postal__house:
              this.state.addressData.d__address__postal__house,
            d__address__postal__flat:
              this.state.addressData.d__address__postal__flat,

            d__address__live__postal_code:
              this.state.addressData.d__address__live__postal_code,
            d__address__live__region:
              this.state.addressData.d__address__live__region,
            d__address__live__city:
              this.state.addressData.d__address__live__city,
            d__address__live__street:
              this.state.addressData.d__address__live__street,
            d__address__live__house:
              this.state.addressData.d__address__live__house,
            d__address__live__flat:
              this.state.addressData.d__address__live__flat,
          },
          this.state.addData,
          docsData
        )
      )
        .then((res) => {
          this.loadUserData();
        })
        .catch((err) => {
          catchPromise(err, this.sendAnketa.bind(this, docsData), this.errorCb);
        });
    } else {
      sendAnketaData(
        Object.assign(
          this.state.personalData,
          this.state.citizenshipData,
          this.state.passportData,
          this.state.foreignBankData,
          {
            foreign: true,
            d__address__reg__country:
              this.state.foreignAddressesData.d__address__reg__country,
            d__address__reg__postal_code:
              this.state.foreignAddressesData.d__address__reg__postal_code,
            d__address__reg__region:
              this.state.foreignAddressesData.d__address__reg__region,
            d__address__reg__city:
              this.state.foreignAddressesData.d__address__reg__city,
            d__address__reg__street:
              this.state.foreignAddressesData.d__address__reg__street,
            d__address__reg__house:
              this.state.foreignAddressesData.d__address__reg__house,
            d__address__reg__block:
              this.state.foreignAddressesData.d__address__reg__block,
            d__address__reg__flat:
              this.state.foreignAddressesData.d__address__reg__flat,

            d__address__postal__country:
              this.state.foreignAddressesData.d__address__postal__country,
            d__address__postal__postal_code:
              this.state.foreignAddressesData.d__address__postal__postal_code,
            d__address__postal__region:
              this.state.foreignAddressesData.d__address__postal__region,
            d__address__postal__city:
              this.state.foreignAddressesData.d__address__postal__city,
            d__address__postal__street:
              this.state.foreignAddressesData.d__address__postal__street,
            d__address__postal__house:
              this.state.foreignAddressesData.d__address__postal__house,
            d__address__postal__block:
              this.state.foreignAddressesData.d__address__postal__block,
            d__address__postal__flat:
              this.state.foreignAddressesData.d__address__postal__flat,

            d__address__live__country:
              this.state.foreignAddressesData.d__address__live__country,
            d__address__live__postal_code:
              this.state.foreignAddressesData.d__address__live__postal_code,
            d__address__live__region:
              this.state.foreignAddressesData.d__address__live__region,
            d__address__live__city:
              this.state.foreignAddressesData.d__address__live__city,
            d__address__live__street:
              this.state.foreignAddressesData.d__address__live__street,
            d__address__live__house:
              this.state.foreignAddressesData.d__address__live__house,
            d__address__live__block:
              this.state.foreignAddressesData.d__address__live__block,
            d__address__live__flat:
              this.state.foreignAddressesData.d__address__live__flat,
          },
          this.state.addData,
          docsData
        )
      )
        .then((res) => {
          this.loadUserData();
        })
        .catch((err) => {
          catchPromise(err, this.sendAnketa.bind(this, docsData), this.errorCb);
        });
    }
  };
  loadUserData = () => {
    checkAuth()
      .then((resCheck) => {
        this.props.setUser(resCheck.data);
      })
      .catch((err) => {
        catchPromise(err, this.loadUserData, this.errorCb);
      });
  };
  getReadyAnketa = () => {
    getAnketa()
      .then((res) => {
        this.props.setAnketa(res.data);
        this.setState({ pageError: false });
      })
      .catch((err) => {
        catchPromise(err, this.getReadyAnketa, this.errorCb);
      });
  };
  render() {
    return (
      <div className="anketaFormPage">
        {this.state.docsModalOpen && (
          <Popup closePopup={() => this.setState({ docsModalOpen: false })}>
            <div className="anketaDocsGuide">
              <div className="anketaDocsGuide__body">
                <h4 className="font-weight-bold">Как правильно заполнить анкету и вложить документы</h4>
                <div className="mt-2">
                  Все данные из паспорта или его аналогов (например, ID) вносятся
                  один в один как в документе, без сокращений или исправлений.
                </div>
                <div className="mt-2">
                  Постарайтесь заполнить все поля формы, если это возможно.
                  Если не уверены, что писать в том или ином поле, оставьте его пустым. При необходимости, мы уточним информацию позже.
                </div>
                <div className="mt-2">
                  На последнем шаге анкеты потребуется добавить файлы с копиями документов.
                </div>
                <ul className="ml-4 mt-2">
                  <li>Подходят сканы или фотографии</li>
                  <li>Файлы не больше 10 МБ</li>
                  <li>Допустимые форматы: JPG (JPEG), PNG, PDF</li>
                </ul>
                <div className="anketaDocsGuideBlockList">
                  <div className="anketaDocsGuideBlockListItem">
                    <h4 className="m-0 text-danger font-weight-bold">
                      Нет
                    </h4>
                    <div className="mt-2">Размытое или темное изображение</div>
                    <div className="mt-2">Блики света</div>
                    <div className="mt-2">Обрезанные края</div>
                    <div className="mt-2">Фото снято под углом или документ раскрыт не до конца</div>
                  </div>
                  <div className="anketaDocsGuideBlockListItem">
                    <h4 className="m-0 text-success">
                      Да
                    </h4>
                    <div className="mt-2">Четкое, контрастное изображение</div>
                    <div className="mt-2">Все цифры и текст легко читаются</div>
                    <div className="mt-2">Документ на фото или скане целиком</div>
                    <div className="mt-2">Документ максимально раскрыт и прижат на сгибе</div>
                  </div>
                </div>
                <div className="mt-4">
                  Если вам нужна помощь, обратитесь в{" "}
                  <a
                    target="_blank" 
                    href={`mailto:${this.props.settings.SUPPORT_EMAIL}?subject=Нужна помощь в заполнении анкеты`}
                  >
                    техподдержку
                  </a>
                </div>
              </div>
            </div>
          </Popup>
        )}
        <div className="col-lg-8 offset-lg-2 profileForm">
          {this.props.user.form_status === "CREATED" ? (
            <div className="profileFormAlert">
              <h3>Ваша анкета отправлена.</h3>
              <p>
                Анкета ожидает подтверждения
              </p>
            </div>
            )
          : this.props.user.form_status === "NEED_UPDATE" ? (
            <div className="profileFormAlert warn">
              <div className="d-flex align-items-center">
                <img src="/images/ui/alert-triangle.svg" />
                <h3>Ваша анкета требует изменений.</h3>
              </div>
              <p>
                { this.props.anketa.need_update_reason }
                { this.props.anketa.need_update_docs && (
                  <React.Fragment>
                    <br />
                    Обновить документы вы можете по этой <NavLink to="/app/personal-files-update">ссылке</NavLink>.
                  </React.Fragment>
                ) }
              </p>
            </div>
          ) : this.props.user.form_status === "NEED_REPLACE" ? (
            <div className="profileFormAlert warn">
              <div className="d-flex align-items-center">
                <img src="/images/ui/alert-triangle.svg" />
                <h3>Требуется повторная отправка анкеты.</h3>
              </div>
              <p>
                { this.props.anketa.need_update_reason }
              </p>
            </div>
          ) : null}
          {!this.state.pageError ? (
            <div>
              {!(this.props.user.user.roles && this.props.user.user.roles.includes("trader")) && this.props.user.form_status === "NOT_CREATED" && this.state.anketaCurrStep === 0 ? (
                <React.Fragment>
                  <div className={'title card-content mb-0'}>Трейдинг</div>
                  <div className="card card-content trading">
                    <div className="card-body">
                      <Baron>
                        <h3>Подключитесь к крупнейшей в мире криптобирже</h3>
                        <ol className="ml-4">
                          <li>Заполните анкету и отправьте на модерацию</li>
                          <li>При прохождении модерации вы получите полноценный торговый аккаунт</li>
                          <li>Пополните счет и начните торговлю!</li>
                        </ol>
                      </Baron>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
              <div className={'title card-content mb-0'}>Анкета</div>
              {(this.props.user.form_status === "NOT_CREATED") &&
                <p className="anketaFillingRules crypto">
                  <Link to="/" onClick={(e) => {
                    e.preventDefault();
                    this.setState({ docsModalOpen: true });
                  }}>Правила заполнения анкеты</Link>
                </p>
              }
              <div className="card card-content">
                <div className="card-body">
                  <Baron>
                    {this.props.user.form_status === "NOT_CREATED" || this.props.user.form_status === "NEED_REPLACE" ? (
                      // true ? (
                      <Wizard
                        render={({ step, steps }) => (
                          <div>                 
                            {/* {isCrypto ? ( */}
                            <Steps key="isCrypto">
                              {[
                                {
                                  id: "personalData",
                                  render: ({ next, previous }) => (
                                    <AnketaPersonalData
                                      nextPage={1}
                                      prevPage={null}
                                      next={next}
                                      previous={previous}
                                      action={this.actionStep}
                                      data={this.state.personalData}
                                    />
                                  ),
                                },
                                {
                                  id: "passportData",
                                  render: ({ next, previous }) => (
                                    <AnketaPassportData
                                      nextPage={2}
                                      prevPage={0}
                                      next={next}
                                      previous={previous}
                                      action={this.actionStep}
                                      data={this.state.passportData}
                                    />
                                  ),
                                },
                                {
                                  id: "foreignAddresses",
                                  render: ({ next, previous }) => (
                                    <AnketaForeignAdresses
                                      nextPage={3}
                                      prevPage={1}
                                      next={next}
                                      previous={previous}
                                      action={this.actionStep}
                                      data={this.state.foreignAddressesData}
                                    />
                                  ),
                                },
                                {
                                  id: "foreignDocs",
                                  render: ({ previous }) => (
                                    <AnketaForeignDocs
                                      prevPage={2}
                                      previous={previous}
                                      action={this.actionStep}
                                      data={this.state.foreignDocsData}
                                      onSubmit={this.sendAnketa}
                                    />
                                  ),
                                },
                              ].map((item) => {
                                return (
                                  <Step
                                    id={item.id}
                                    render={item.render}
                                    key={item.id}
                                  />
                                );
                              })}
                            </Steps>
                            {/* ) : this.state.isResident ? (
                            <Steps key="isResident">
                                <Step
                                  id="typeChoose"
                                  render={({ next }) => (
                                    <AnketaTypeChoose
                                      toggleAnketaType={this.toggleAnketaType}
                                      isResident={this.state.isResident}
                                      next={next}
                                      action={this.actionStep}
                                    />
                                  )}
                                />
                                {[
                                  {
                                    id: "personData",
                                    render: ({ next, previous }) => (
                                      <AnketaProfile
                                        next={next}
                                        previous={previous}
                                        action={this.actionStep}
                                        data={this.state.profileData}
                                      />
                                    ),
                                  },
                                  {
                                    id: "adressess",
                                    render: ({ next, previous }) => (
                                      <AnketaAddresses
                                        next={next}
                                        previous={previous}
                                        action={this.actionStep}
                                        data={this.state.addressData}
                                      />
                                    ),
                                  },
                                  {
                                    id: "addData",
                                    render: ({ next, previous }) => (
                                      <AnketaAddData
                                        next={next}
                                        previous={previous}
                                        action={this.actionStep}
                                        data={this.state.addData}
                                      />
                                    ),
                                  },
                                  {
                                    id: "docs",
                                    render: ({ previous }) => (
                                      <AnketaDocs
                                        previous={previous}
                                        action={this.actionStep}
                                        data={this.state.docsData}
                                        flType={
                                          this.state.addData.d__extra__fl_type
                                        }
                                        onSubmit={this.sendAnketa}
                                      />
                                    ),
                                  },
                                ].map((item) => {
                                  return (
                                    <Step
                                      id={item.id}
                                      render={item.render}
                                      key={item.id}
                                    />
                                  );
                                })}
                              </Steps>
                            ) : (
                              <Steps key="isNoResident">
                                <Step
                                  id="typeChoose"
                                  render={({ next }) => (
                                    <AnketaTypeChoose
                                      toggleAnketaType={this.toggleAnketaType}
                                      isResident={this.state.isResident}
                                      next={next}
                                      action={this.actionStep}
                                    />
                                  )}
                                />
                                {[
                                  {
                                    id: "personalData",
                                    render: ({ next, previous }) => (
                                      <AnketaPersonalData
                                        next={next}
                                        previous={previous}
                                        action={this.actionStep}
                                        data={this.state.personalData}
                                      />
                                    ),
                                  },
                                  {
                                    id: "citizenshipData",
                                    render: ({ next, previous }) => (
                                      <AnketaCitizenship
                                        next={next}
                                        previous={previous}
                                        action={this.actionStep}
                                        data={this.state.citizenshipData}
                                      />
                                    ),
                                  },
                                  {
                                    id: "passportData",
                                    render: ({ next, previous }) => (
                                      <AnketaPassportData
                                        next={next}
                                        previous={previous}
                                        action={this.actionStep}
                                        data={this.state.passportData}
                                      />
                                    ),
                                  },
                                  {
                                    id: "foreignAddresses",
                                    render: ({ next, previous }) => (
                                      <AnketaForeignAdresses
                                        next={next}
                                        previous={previous}
                                        action={this.actionStep}
                                        data={this.state.foreignAddressesData}
                                      />
                                    ),
                                  },
                                  {
                                    id: "foreignBankData",
                                    render: ({ next, previous }) => (
                                      <AnketaForeignBankData
                                        next={next}
                                        previous={previous}
                                        action={this.actionStep}
                                        data={this.state.foreignBankData}
                                      />
                                    ),
                                  },
                                  {
                                    id: "foreignDocs",
                                    render: ({ previous }) => (
                                      <AnketaForeignDocs
                                        previous={previous}
                                        action={this.actionStep}
                                        data={this.state.foreignDocsData}
                                        onSubmit={this.sendAnketa}
                                      />
                                    ),
                                  },
                                ].map((item) => {
                                  return (
                                    <Step
                                      id={item.id}
                                      render={item.render}
                                      key={item.id}
                                    />
                                  );
                                })}
                              </Steps>
                            )} */}
                          </div>
                        )}
                      />
                    ) : (
                      <AnketaFormReady
                        authStatus={this.props.user.form_status}
                        anketaData={this.props.anketa}
                        getAnketa={this.getReadyAnketa}
                      />
                    )}
                  </Baron>
                </div>
                <div
                  className={`progressSteps ${
                    (this.state.anketaCurrStep !== -1 && this.props.user.form_status === 'NOT_CREATED') ? "visible" : ""
                  }`}
                >
                  {isCrypto
                    ? [
                        "Личные данные",
                        "Документы",
                        "Адреса",
                        "Загрузка документов",
                      ].map((step, index) => (
                        <div
                          key={index}
                          className={`${
                            this.state.anketaCurrStep === index
                              ? "currentStep"
                              : ""
                          }`}
                        >
                          {step}
                        </div>
                      )) : this.state.isResident
                    ? [
                        "Личные данные",
                        "Адреса",
                        "Дополнительные данные",
                        "Загрузка документов",
                      ].map((step, index) => (
                        <div
                          key={index}
                          className={`${
                            this.state.anketaCurrStep === index
                              ? "currentStep"
                              : ""
                          }`}
                        >
                          {step}
                        </div>
                      ))
                    : [
                        "Личные данные",
                        "Гражданство",
                        "Документы",
                        "Адреса",
                        "Банковские данные",
                        "Загрузка документов",
                      ].map((step, index) => (
                        <div
                          key={index}
                          className={`${
                            this.state.anketaCurrStep === index
                              ? "currentStep"
                              : ""
                          }`}
                        >
                          {step}
                        </div>
                      ))}
                </div>
              </div>
            </div>
          ) : (
            <h3 className="text-center text-danger">Произошла ошибка</h3>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    user: state.user,
    anketa: state.anketa,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAnketa: (anketa) => dispatch(setAnketa(anketa)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnketaFormPage);
