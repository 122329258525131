import React from 'react';
import { Link } from 'react-router-dom';

export default class DocDoneSingle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {}
  render() {
    return (
      <div>
        <div className="docPage-uploadDisc">Ознакомьтесь с документами.</div>
        {this.props.files && (
          <div className="docPage-doneContent">
            {this.props.files.map((file, index) => (
              <a key={index} className="btn btnLink mr-2 mb-2" href={file.url}>
                <span>
                  Скачать
                  <img
                    src="/images/ui/uploadIcon.png"
                    className="uploadIcon"
                    alt=""
                  />
                </span>
              </a>
            ))}
          </div>
        )}
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <Link to="/app/documents/moving" className="btn btn-white">
            Отменить
          </Link>
        </div>
      </div>
    );
  }
}
