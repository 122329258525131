import axios from 'axios';
import Config from '../config/config';
import { history } from '../routers/AppRouter';

export const getUpdates = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/users/updates`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const resetNotifications = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/notifications/`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
