import axios from 'axios';
import Config from '../config/config';
import querystring from 'querystring';

export const getBroadcasts = (pageNum) => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/streams/?page_num=${pageNum}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getStreams = ({ page_num, host, tag, category }) => {
  const params = {
    page_num,
    category: category,
  }

  if (host && host.length) params.host = host;
  if (tag && tag.length) params.tag = tag;

  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/streams/`,
    withCredentials: true,
    params,
    paramsSerializer: function (params) {
      return querystring.stringify(params)
    },
  })
}

export const getStreamTags = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/streams/stream-tags-group`,
    withCredentials: true,
  })
}

export const getPresenters = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/streams/presenters`,
    withCredentials: true
  })
}

export const getUsersUpdates = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/users/updates/`,
    withCredentials: true
  })
}

export const getScheduledEvents = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/events/`,
    withCredentials: true,
  })
}
