import React from "react";
import { connect } from "react-redux";
import { getPresenters, getStreams, getStreamTags } from "../../utils/broadcastsHttp";
import { catchPromise } from "../../utils/http";
import BroadcastsFiltersDiscard from "./BroadcastsFiltersDiscard";
import CustomSelect from "./CustomSelect";
import CustomSelectListItem from "./CustomSelectListItem";
import MasterClass from "./MasterClass";
import Baron from 'react-baron/dist/es5';

export class BroadcastsFilterStreams extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageError: false,
      tag_groups: {},
      total: 0,
      streams: [],
      selectedTags: [],
      presenters: [],
      selectedPresenters: [],
      page_num: 1,
      page_total: 1,
      loaded: true,
      category: props.category,
    };
  }

  errorCb = () => {
    this.setState({ pageError: true });
  };

  loadStreams = ({ selectedPresenters, selectedTags, page_num, lazyLoading = false }) => {
    this.setState({ loaded: true });
    if (!lazyLoading) page_num = 1;
    return getStreams({
      page_num: page_num || this.state.page_num,
      host:
        (Array.isArray(selectedPresenters) && selectedPresenters.map((pres) => pres.id)) ||
        this.state.selectedPresenters.map((pres) => pres.id),
      tag:
        (Array.isArray(selectedTags) && selectedTags.map((tag) => tag.id)) ||
        this.state.selectedTags.map((tag) => tag.id),
      category: this.state.category,
    })
      .then((res) => {
        this.setState(
          {
            streams: lazyLoading ? [...this.state.streams, ...res.data.streams] : res.data.streams,
            total: res.data.total,
            loaded: false,
            page_total: res.data.page_total,
            page_num: lazyLoading ? res.data.page_num : 1,
          }
        );
      })
      .catch((err) => {
        this.setState({ loaded: false });
        catchPromise(
          err,
          this.loadStreams.bind(this, { page_num }),
          this.errorCb
        );
      });
  };

  getData = ({ selectedPresenters, selectedTags }) => {
    this.loadStreams({
      selectedPresenters,
      selectedTags,
      page_num: this.state.page_num,
    }).then(() => {
      const pageScroller = $('.broadcasts .broadcastsStreamsWrapper > .clipper > .scroller');
      const pageContentWrap = $(".broadcasts .broadcastsStreamsWrapper .broadcastsMasterClassesContainer");
      
      if (pageScroller.height() > pageContentWrap.height()) this.loadStreamRecursion({ page_num: this.state.page_num + 1, page_total: this.state.page_total, pageScroller, pageContentWrap })
    });
    getStreamTags()
      .then((res) => {
        this.setState({ tag_groups: res.data.tag_groups });
      })
      .catch(() => this.errorCb());

    getPresenters()
      .then((res) => {
        this.setState({ presenters: res.data.presenters });
      })
      .catch(() => this.errorCb());
  };

  loadStreamRecursion ({ page_num, page_total, pageScroller, pageContentWrap }) {
    if (page_num > page_total) return;
    return this.loadStreams({ page_num, lazyLoading: true }).then(() => {
      if (pageScroller.height() <= pageContentWrap.height()) {
        return;
      } else {
        return this.loadStreamRecursion({ page_num: page_num + 1, page_total, pageScroller, pageContentWrap })
      }
    });
  }

  setSelectedTags = (selectedTags, selectedPresenters, discard = false) => {
    const options = { selectedTags, page_num: this.state.page_num };
    if (selectedPresenters) options.selectedPresenters = selectedPresenters;
    this.setState({ selectedTags });
    if (!discard) this.loadStreams(options);
  };
  setSelectedPresenters = (selectedPresenters, selectedTags) => {
    const options = { selectedPresenters, page_num: this.state.page_num };
    if (selectedTags) options.selectedTags = selectedTags;
    this.setState({ selectedPresenters });
    this.loadStreams(options);
  };

  scrollContent = () => {
    const pageScroller = $('.broadcasts .broadcastsStreamsWrapper > .clipper > .scroller');
    const pageContentWrap = $(".broadcasts .broadcastsStreamsWrapper .broadcastsMasterClassesContainer");

    if (
      pageScroller.scrollTop() + pageScroller.height() >=
        pageContentWrap.height() - 150 &&
      !this.state.loaded &&
      this.state.page_num < this.state.page_total
    )
      this.loadStreams({ page_num: this.state.page_num + 1, lazyLoading: true });
  };

  componentDidMount() {
    $('.broadcasts .broadcastsStreamsWrapper > .clipper > .scroller').scroll(() => {
      this.scrollContent();
    });
    this.getData({
      selectedPresenters: this.state.selectedPresenters,
      selectedTags: this.state.selectedTags,
    });
  }

  render() {
    return (
      <div className={this.props.headerHeight}>
        {!this.state.pageError ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#fff",
                width: "100%",
              }}
              className="broadcastsFiltersWrapper"
            >
              {this.state.category !== "WEBINAR" ? <div
                className="broadcastsFiltersContainer"
                ref={this.props.filterDropdowns}
              >
                <div className="broadcastsFilters">
                  <CustomSelect
                    title={"Ведущий"}
                    id={0}
                    selectedTags={this.state.selectedTags}
                    selectedPresenters={this.state.selectedPresenters}
                    setSelectedPresenters={this.setSelectedPresenters}
                  >
                    {this.state.presenters
                      ? this.state.presenters.map((presenter) => {
                          return (
                            <CustomSelectListItem
                              selectedTags={this.state.selectedTags}
                              selectedPresenters={this.state.selectedPresenters}
                              setSelectedPresenters={this.setSelectedPresenters}
                              setSelectedTags={this.setSelectedTags}
                              presenter={presenter}
                              name={`${presenter.first_name} ${presenter.last_name}`}
                              id={0}
                              getData={this.getData}
                              key={`${presenter.first_name} ${presenter.last_name}`}
                            />
                          );
                        })
                      : null}
                  </CustomSelect>
                  {["Стратегия", "Инструмент", "Результат", "Рынок"].map(
                    (tagName, id) => {
                      return (
                        <CustomSelect
                          title={tagName}
                          id={id + 1}
                          selectedTags={this.state.selectedTags}
                          setSelectedTags={this.setSelectedTags}
                          key={tagName}
                          type={tagName}
                        >
                          {this.state.tag_groups[tagName]
                            ? this.state.tag_groups[tagName].map((tag) => {
                                return (
                                  <CustomSelectListItem
                                    selectedTags={this.state.selectedTags}
                                    setSelectedTags={this.setSelectedTags}
                                    tag={{ ...tag, type: tagName }}
                                    name={tag.name}
                                    id={id + 1}
                                    key={tag.name + (id + 1)}
                                    getData={this.getData}
                                  />
                                );
                              })
                            : null}
                        </CustomSelect>
                      );
                    }
                  )}
                </div>
                <BroadcastsFiltersDiscard
                  discardHandler={() => {
                    this.setSelectedTags.call(this, [], [], true);
                    this.setSelectedPresenters.call(this, [], []);
                  }}
                  active={this.state.selectedTags.length || this.state.selectedPresenters.length}
                  total={this.state.total}
                />
              </div>
              : null}
            </div>
            <div className="broadcastsStreamsWrapper">
              <Baron>
                <div className="d-flex justify-content-center">
                  <div className="broadcastsMasterClassesContainer">
                    {this.state.streams.map((stream, index) => {
                      return (
                        <MasterClass
                          stream={stream}
                          selectedTags={this.state.selectedTags}
                          key={stream.title + index}
                          category={this.state.category}
                        />
                      );
                    })}
                    {/*<li className="masterClass-tag masterClass-tagNew">NEW</li>*/}
                  </div>
                </div>
              </Baron>
            </div>
            {this.state.loaded && (
              <div className="mt-2 mb-2 text-center spinner-container">
                <span>
                  <img
                    src="/images/ui/load-spinner.png"
                    className="spinner"
                    alt=""
                  />
                </span>
              </div>
            )}
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
      </div>
    );
  }
}

export default connect()(BroadcastsFilterStreams);
