import { SET_TRADING_ACCOUNT } from '../actions/tradingAccount';

// Expenses Reducer
const reducerDefaultState = {};

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_TRADING_ACCOUNT:
      return action.tradingAccount;
    default:
      return state;
  }
};
