import { SET_FINRES } from '../actions/finres';

// Expenses Reducer
const reducerDefaultState = {
  boards: [],
  total_amount_account: '0.00',
  trading_account: {},
};

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_FINRES:
      return action.finres;
    default:
      return state;
  }
};
