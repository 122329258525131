import React from 'react';
import PropTypes from 'prop-types';
import Baron from 'react-baron/dist/es5';

import { currencyFormat, sumColor } from '../utils/currencyUtil';

const DailyFinresBoard = ({ board, currency, index, openDeals }) => (
  <React.Fragment>
    <tr
      onClick={() => {
        openDeals(index);
      }}
      className={`${index & 1 ? 'evenStroke' : 'oddStroke'}`}
      style={{ cursor: board.deals_count > 0 ? 'pointer' : 'default' }}
    >
      <td className="text-left dailyFinresTable-plosch">
        {board.name.replace(' (MOEX)', '')}
      </td>
      <td className="dailyFinresTable-prosadka">
        {currencyFormat(board.drawdown, null, 0)}
      </td>
      <td className="dailyFinresTable-marja">{currencyFormat(board.pnl)}</td>
      <td className="dailyFinresTable-sdelki">{board.deals_count}</td>
      <td className="dailyFinresTable-commis">{currencyFormat(board.fee)}</td>
      <td className={`dailyFinresTable-finres ${sumColor(board.amount)}`}>
        {currencyFormat(board.amount)}
      </td>
      <td className="dailyFinresTable-arrow">
        {board.visibleDeals && (
          <span className="icon-arrow-up12 text-success"></span>
        )}
      </td>
    </tr>
    {board.visibleDeals && board.deals_count > 0 && (
      <tr className="tableDeals-container">
        <td colSpan="7">
          <Baron>
            <table className="tableDeals">
              <tbody>
                {board.deals.map((deal, index) => (
                  <tr key={index}>
                    <td className="text-left dailyFinresTable-plosch"></td>
                    <td className="dailyFinresTable-prosadka"></td>
                    <td className="dailyFinresTable-marja"></td>
                    <td className="dailyFinresTable-sdelki">
                      {deal.symbol_code}
                    </td>
                    <td className="dailyFinresTable-commis">{deal.fee}</td>
                    <td
                      className={`dailyFinresTable-finres ${sumColor(
                        deal.amount
                      )}`}
                    >
                      {currencyFormat(deal.amount)}
                    </td>
                    <td className="dailyFinresTable-arrow"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Baron>
        </td>
      </tr>
    )}
  </React.Fragment>
);

DailyFinresBoard.propTypes = {
  board: PropTypes.object,
  currency: PropTypes.string,
};

export default DailyFinresBoard;
