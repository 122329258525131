import axios from 'axios';
import Config from '../config/config';

export const getDocumentsStatic = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/static-docs/`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const getDocument = (id) => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/docs/${id}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const getDocumentsMoving = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/docs/`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const createDocument = (file) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/docs/`,
    withCredentials: true,
    data: file,
  });
};
export const uploadFile = (file) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/files/docs/`,
    withCredentials: true,
    data: file,
  });
};
export const getContracts = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/docs/contracts/`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const changeDocument = (dataFile) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/docs/${dataFile.id}/`,
    withCredentials: true,
    data: dataFile,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
