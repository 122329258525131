import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';
import Baron from 'react-baron/dist/es5';

import { catchPromise } from '../../utils/http';
import {
  getDocumentsMoving,
  getDocumentsStatic,
} from '../../utils/documentsHttp';

export class DocumentsMovingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      docs: [],
      staticDocs: [],
      docsFiltered: [],
      docsType: 'simple',
      docFilter: '0',
      docSigned: 0,
      isLoading: true,
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    this.loadDocuments();
    this.loadStaticDocs();
  }
  loadDocuments = () => {
    this.setState({ isLoading: true });
    getDocumentsMoving()
      .then((res) => {
        this.setState({
          docs: res.data.docs,
          docsFiltered: res.data.docs,
          docSigned: res.data.to_sign,
          isLoading: false,
        });
      })
      .catch((err) => {
        catchPromise(err, this.loadDocuments, this.errorCb);
      });
  };
  loadStaticDocs = () => {
    getDocumentsStatic()
      .then((res) => {
        this.setState({ staticDocs: res.data.docs });
      })
      .catch((err) => {
        catchPromise(err, this.loadStaticDocs, this.errorCb);
      });
  };
  setStatusColor = (status) => {
    switch (status) {
      case 'DONE':
        return 'docStatus-success';
      case 'DONE_SINGLE':
        return 'docStatus-success';
      case 'REJECTED':
        return 'docStatus-reject';
      default:
        return '';
    }
  };

  setStatusFilter = (status) => {
    switch (status) {
      case 'DONE': {
        if (
          this.state.docs.filter(
            (doc) =>
              doc.status.code === 'DONE' || doc.status.code === 'DONE_SINGLE'
          ).length > 0
        ) {
          this.setState({
            docsFiltered: this.state.docs.filter(
              (doc) =>
                doc.status.code === 'DONE' || doc.status.code === 'DONE_SINGLE'
            ),
            docsType: 'simple',
          });
        }
        return;
      }
      case 'NEED_SIGN': {
        if (this.state.docs.filter((doc) => doc.need_sign).length > 0) {
          this.setState({
            docsFiltered: this.state.docs.filter((doc) => doc.need_sign),
            docsType: 'simple',
          });
        }
        return;
      }
      case 'REJECTED': {
        if (
          this.state.docs.filter((doc) => doc.status.code === 'REJECTED')
            .length > 0
        ) {
          this.setState({
            docsFiltered: this.state.docs.filter(
              (doc) => doc.status.code === 'REJECTED'
            ),
            docsType: 'simple',
          });
        }
        return;
      }
      case 'STATIC': {
        if (this.state.staticDocs.length > 0) {
          this.setState({
            docsFiltered: this.state.staticDocs,
            docsType: 'static',
          });
        }
        return;
      }
      default: {
        this.setState({ docsFiltered: this.state.docs, docsType: 'simple' });
        return;
      }
    }
  };

  changeDocTypes = (type) => {
    this.setState({ docFilter: type });
  };

  render() {
    return (
      <div className="documentsMoving">
        {!this.state.pageError ? (
          <div>
            <ul className="nav nav-tabs boardsTabs">
              <li
                className="nav-item"
                onClick={() => {
                  this.setStatusFilter('');
                }}
              >
                <span
                  className="nav-link text-center active show"
                  data-toggle="tab"
                >
                  <span>Все</span>
                </span>
              </li>
              <li
                className="nav-item docTab-sign"
                onClick={() => {
                  this.setStatusFilter('NEED_SIGN');
                }}
              >
                <span
                  className={`nav-link text-center ${
                    this.state.docs.filter((doc) => doc.need_sign).length == 0
                      ? 'emptyTab'
                      : ''
                  }`}
                  data-toggle={`${
                    this.state.docs.filter((doc) => doc.need_sign).length > 0
                      ? 'tab'
                      : ''
                  }`}
                >
                  <span className="docTab-sign">
                    <span>Требует действий</span>
                    {this.state.docSigned > 0 && (
                      <span className="docTab-signText">
                        {this.state.docSigned}
                      </span>
                    )}
                  </span>
                </span>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.setStatusFilter('DONE');
                }}
              >
                <span
                  className={`nav-link text-center ${
                    this.state.docs.filter(
                      (doc) =>
                        doc.status.code === 'DONE' ||
                        doc.status.code === 'DONE_SINGLE'
                    ).length == 0
                      ? 'emptyTab'
                      : ''
                  }`}
                  data-toggle={`${
                    this.state.docs.filter(
                      (doc) =>
                        doc.status.code === 'DONE' ||
                        doc.status.code === 'DONE_SINGLE'
                    ).length > 0
                      ? 'tab'
                      : ''
                  }`}
                >
                  <span>Проверены</span>
                </span>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.setStatusFilter('REJECTED');
                }}
              >
                <span
                  className={`nav-link text-center ${
                    this.state.docs.filter(
                      (doc) => doc.status.code === 'REJECTED'
                    ).length == 0
                      ? 'emptyTab'
                      : ''
                  }`}
                  data-toggle={`${
                    this.state.docs.filter(
                      (doc) => doc.status.code === 'REJECTED'
                    ).length > 0
                      ? 'tab'
                      : ''
                  }`}
                >
                  <span>Отклонены</span>
                </span>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.setStatusFilter('STATIC');
                }}
              >
                <span
                  className={`nav-link text-center ${
                    this.state.staticDocs.length == 0 ? 'emptyTab' : ''
                  }`}
                  data-toggle={`${
                    this.state.staticDocs.length > 0 ? 'tab' : ''
                  }`}
                >
                  <span>Статические</span>
                </span>
              </li>
              {this.props.user.user.contract_signed && (
                <li className="closeDealsBtns">
                  <Link to="/app/documents/create" className="btn btn-success">
                    Создать документ
                  </Link>
                </li>
              )}
            </ul>

            <div className="tab-content">
              <div className="tab-pane fade active show">
                {this.state.docs.length > 0 && !this.state.isLoading ? (
                  <div className="table">
                    <table className="tableLayoutFixes">
                      <thead>
                        {this.state.docsType == 'simple' ? (
                          <tr>
                            <td className="text-right docCreate">СОЗДАН</td>
                            <td className="docDescr">ДОКУМЕНТ</td>
                            <td className="docComm">КОММЕНТАРИИ</td>
                            <td className="docStatus">СТАТУС</td>
                          </tr>
                        ) : (
                          <tr>
                            <td
                              className="text-right docCreate"
                              style={{ width: '100px' }}
                            >
                              СОЗДАН
                            </td>
                            <td className="docComm" style={{ width: 'auto' }}>
                              НАЗВАНИЕ
                            </td>
                          </tr>
                        )}
                      </thead>
                    </table>
                    <Baron>
                      <table className="tableLayoutFixes docsTable">
                        <tbody>
                          {this.state.docsType == 'simple'
                            ? this.state.docsFiltered.map((doc, index) => (
                                <tr
                                  key={doc.id}
                                  className={`${
                                    doc.updated ? 'updatedDoc' : ''
                                  }`}
                                >
                                  <td className="text-right docCreate">
                                    {moment(doc.date, 'YYYY-MM-DD').format(
                                      'DD.MM.YYYY'
                                    )}
                                  </td>
                                  <td className="docDescr">
                                    <div className="docDescr-title">
                                      <Link to={`/app/documents/${doc.id}`}>
                                        {doc.title}
                                      </Link>
                                    </div>
                                    <div className="docDescr-text">
                                      {doc.subtitle}
                                    </div>
                                  </td>
                                  <td className="docComm">
                                    <span>{doc.comment}</span>
                                  </td>
                                  <td
                                    className={`docStatus ${this.setStatusColor(
                                      doc.status.code
                                    )}`}
                                  >
                                    <span>{doc.status.name}</span>
                                  </td>
                                </tr>
                              ))
                            : this.state.docsFiltered.map((doc, index) => (
                                <tr key={index}>
                                  <td
                                    className="text-right docCreate"
                                    style={{ width: '100px' }}
                                  >
                                    {moment(doc.date, 'YYYY-MM-DD').format(
                                      'DD.MM.YYYY'
                                    )}
                                  </td>
                                  <td
                                    className="docComm"
                                    style={{ width: 'auto' }}
                                  >
                                    <a href={doc.url} target="_blank">
                                      {doc.name}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </Baron>
                  </div>
                ) : (
                  <div className="emptyDashboard-plug">
                    {this.state.isLoading ? (
                      <div className="mt-1 text-center spinner-container isLoadingSpinner">
                        <span>
                          <img
                            src="/images/ui/load-spinner.png"
                            className="spinner"
                            alt=""
                          />
                        </span>
                      </div>
                    ) : (
                      <div className="emptyData">нет данных</div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(DocumentsMovingPage);
