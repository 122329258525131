import { SET_DAILY_DEALS } from '../actions/dailyDeals';

// Expenses Reducer
const reducerDefaultState = { deals: [], page_size: 1 };

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_DAILY_DEALS:
      return action.dailyDeals;
    default:
      return state;
  }
};
