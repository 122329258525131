import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';
import { DateRangePicker } from 'react-dates';
import Baron from 'react-baron/dist/es5';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4lang_ru_RU from '@amcharts/amcharts4/lang/ru_RU';

import { catchPromise } from '../utils/http';
import { getDealsAnalysisEquity, getBoards } from '../utils/analysisHttp';
import { getAccountBoards } from '../utils/topHttp';

am4core.useTheme(am4themes_animated);

export class EquityAnalysisPage extends React.Component {
  constructor(props) {
    super(props);

    let openedSymbols = '';
    this.state = {
      boardsList: [],
      boardId: 0,
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
      accountFilter: props.tradingAccount.id || '',
      symbols: [
        // {id: 1, name: 'asd', board: 'Акции', checked: false},
        // {id: 2, name: 'ffff', board: 'Валюта', checked: false},
        // {id: 3, name: 'zxczxc', board: 'Фьючерсы', checked: false},
        // {id: 4, name: 'hjhjhjh', board: 'Фьючерсы', checked: false}
      ],
      enableSections: [],
      allSymbols: false,
      allSymbolsValuta: false,
      allSymbolsAkcii: false,
      allSymbolsFuchers: false,
      calendarFocused: null,
      selectIsOpened: false,
      isLoading: true,
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    moment.locale('ru');

    $('body').on('click', (e) => {
      if (this.state.selectIsOpened) {
        if ($(e.target).hasClass('clickSelectHideBlock')) {
        } else
          setTimeout(() => {
            this.setState({ selectIsOpened: false });
            if (this.openedSymbols != JSON.stringify(this.state.symbols))
              this.loadAnalysisFilteredSymbols();
          }, 100);
      }
    });
    if (this.props.tradingAccounts.trading_accounts.length > 0) {      
      if (this.state.accountFilter) {
        this.getAccountBoards();
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.tradingAccount.id !== nextProps.tradingAccount.id) {
      this.setState(
        { accountFilter: nextProps.tradingAccount.id || '' },
        () => {
          if(this.state.accountFilter) this.getAccountBoards();
        }
      );
    }
  }
  createChart = () => {
    let chart = am4core.create('chartdiv', am4charts.XYChart);
    chart.language.locale = am4lang_ru_RU;
    chart.paddingRight = 20;
    chart.data = [];

    // Create value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.renderer.grid.template.disabled = true;
    valueAxis.fontSize = '10px';
    valueAxis.fontWeight = 600;
    valueAxis.renderer.labels.template.fill = am4core.color('#8f98b9');
    // valueAxis.renderer.grid.template.disabled = true;
    let axisTooltip = valueAxis.tooltip;
    axisTooltip.background.fill = am4core.color('#1e62d6');
    axisTooltip.background.strokeWidth = 0;
    axisTooltip.background.cornerRadius = 9;
    axisTooltip.background.pointerLength = 0;
    axisTooltip.dx = -5;
    axisTooltip.fontSize = '10px';
    axisTooltip.fontWeight = 600;

    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.fontSize = '10px';
    dateAxis.fontWeight = 600;
    dateAxis.color = '#8f98b9';
    dateAxis.dateFormatter = new am4core.DateFormatter();
    dateAxis.dateFormatter.dateFormat = 'yyyy-MM-dd';
    // dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.labels.template.fill = am4core.color('#8f98b9');
    // dateAxis.renderer.grid.template.location = 0.5;
    // dateAxis.startLocation = 0.5;
    // dateAxis.endLocation = 0.5;
    dateAxis.dateFormats.setKey('day', 'dd MMM');
    let dateTooltip = dateAxis.tooltip;
    dateTooltip.background.fill = am4core.color('#1e62d6');
    dateTooltip.background.strokeWidth = 0;
    dateTooltip.background.cornerRadius = 9;
    dateTooltip.background.pointerLength = 0;
    dateTooltip.minWidth = 80;
    dateTooltip.dy = 5;
    dateTooltip.fontSize = '12px';
    dateTooltip.fontWeight = 600;

    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.dateX = 'date';
    series.name = 'Сумма';
    series.tooltipText = '{name}: [bold]{valueY}[/]';
    series.strokeWidth = 3;
    series.tensionX = 1;
    series.fill = am4core.color('#4b6ce3');
    series.stroke = am4core.color('#4b6ce3');
    series.tooltip.disabled = true;
    series.connect = true;
    series.stacked = true;

    let bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.radius = 6;
    bullet.circle.strokeWidth = 0;
    bullet.fill = am4core.color('#4b6ce3');
    // bullet.stroke = am4core.color("#4b6ce3");
    let hoverState = bullet.states.create('hover');
    hoverState.properties.scale = 1.5;
    hoverState.properties.fill = am4core.color('#fb005b');

    chart.cursor = new am4charts.XYCursor();

    /* Configure cursor lines */

    chart.cursor.behavior = 'none';
    chart.cursor.xAxis = dateAxis;
    chart.cursor.yAxis = valueAxis;
    chart.cursor.snapToSeries = series;

    chart.cursor.lineX.stroke = am4core.color('#e9eff5');
    chart.cursor.lineX.strokeWidth = 1;
    chart.cursor.lineX.strokeOpacity = 0.9;
    chart.cursor.lineX.strokeDasharray = '';

    chart.cursor.lineY.stroke = am4core.color('#e9eff5');
    chart.cursor.lineY.strokeWidth = 1;
    chart.cursor.lineY.strokeOpacity = 0.9;
    chart.cursor.lineY.strokeDasharray = '';

    this.chart = chart;
  };
  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  getAccountBoards = () => {
    getAccountBoards(this.state.accountFilter || this.props.tradingAccount.id)
    .then((res) => {
      this.setState({
        enableSections: res.data.boards
      }, () => {
        this.onBoardChange('0');
      })
    })
    .catch(err => {
      if(err.response.status == 401) {
        catchPromise(err, this.getAccountBoards, this.errorCb);
      }
      if(err.response.status == 404) {
        this.setState({enableSections: []})
      }
    })
  }

  loadBoards = () => {
    getBoards()
      .then((res) => {
        this.setState({ boardsList: res.data.boards, boardId: 0 });
        this.loadAnalysis({
          accountId: this.state.accountFilter,
          boardId: 0,
          dateStart: this.state.startDate.format('DD-MM-YYYY'),
          dateEnd: this.state.endDate.format('DD-MM-YYYY'),
          symbols: '',
        });
      })
      .catch((err) => {
        catchPromise(err, this.loadBoards, this.errorCb);
      });
  };
  loadAnalysis = (data) => {
    if (this.state.enableSections.length > 0) {
      // if(!this.chart) this.createChart();
      this.setState({ isLoading: true });
      getDealsAnalysisEquity(data)
        .then((res) => {
          let symbols = [];
          for (let i = 0; i < res.data.symbols.length; i++) {
            symbols.push({
              name: res.data.symbols[i].name,
              id: res.data.symbols[i].id,
              board: res.data.symbols[i].board,
              checked: false,
            });
          }
          this.setState({
            symbols,
            allSymbols: false,
            allSymbolsAkcii: false,
            allSymbolsValuta: false,
            isLoading: false,
          });

          if (!this.chart) this.createChart();
          this.updateChartData(res.data.data);
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          catchPromise(err, this.loadAnalysis.bind(this, data), this.errorCb);
        });
      // this.updateChartData([{date: '12-05-2019', value: '12.00'}, {date: '12-06-2019', value: '122.00'}, {date: '12-07-2019', value: '-112.00'}]);
    }
  };
  loadAnalysisFilteredSymbols = () => {
    this.setState({ isLoading: true });
    let symbolsFilter = '';
    for (let i = 0; i < this.state.symbols.length; i++) {
      if (this.state.symbols[i].checked) {
        if (symbolsFilter === '') symbolsFilter += this.state.symbols[i].id;
        else symbolsFilter += ',' + this.state.symbols[i].id;
      }
    }
    // this.updateChartData([{date: '12-05-2019', value: '12.00'}, {date: '12-06-2019', value: '122.00'}, {date: '12-07-2019', value: '-112.00'}]);
    getDealsAnalysisEquity({
      accountId: this.state.accountFilter,
      boardId: this.state.boardId,
      dateStart: this.state.startDate.format('DD-MM-YYYY'),
      dateEnd: this.state.endDate.format('DD-MM-YYYY'),
      symbols: symbolsFilter,
    })
      .then((res) => {
        this.setState({ isLoading: false });
        this.updateChartData(res.data.data);
      })
      .catch((err) => {
        catchPromise(err, this.loadAnalysisFilteredSymbols, this.errorCb);
      });
  };
  onBoardChange = (id) => {
      this.setState({ boardId: id }, () => {
        if(id == 0){
          $('.isBoard').removeClass('active show');
          $('.allBoard').addClass('active show');
        }
      });
      this.loadAnalysis({
        accountId: this.state.accountFilter,
        boardId: id,
        dateStart: this.state.startDate.format('DD-MM-YYYY'),
        dateEnd: this.state.endDate.format('DD-MM-YYYY'),
        symbols: '',
      });
  };
  updateChartData = (data) => {
    let tempChartData = [];
    for (let i = 0; i < data.length; i++) {
      tempChartData.push({
        date: data[i].date,
        value: data[i].value,
      });
    }
    if (data.length === 1) {
      tempChartData.push({
        date: data[0].date,
        value: data[0].value,
      });
    }
    this.chart.data = tempChartData;
  };
  onChangeSymbolFilter = (id) => {
    let tempSymbols = this.state.symbols;
    if (id >= 0) {
      tempSymbols.find((symbol) => symbol.id == id).checked = !tempSymbols.find(
        (symbol) => symbol.id == id
      ).checked;

      if (
        tempSymbols
          .filter((symbol) => symbol.board == 'MOEX_FX')
          .find((symbol) => !symbol.checked)
      )
        this.setState({ allSymbolsValuta: false });
      if (
        !tempSymbols
          .filter((symbol) => symbol.board == 'MOEX_FX')
          .find((symbol) => !symbol.checked)
      )
        this.setState({ allSymbolsValuta: true });

      if (
        tempSymbols
          .filter((symbol) => symbol.board == 'MOEX_STOCK')
          .find((symbol) => !symbol.checked)
      )
        this.setState({ allSymbolsAkcii: false });
      if (
        !tempSymbols
          .filter((symbol) => symbol.board == 'MOEX_STOCK')
          .find((symbol) => !symbol.checked)
      )
        this.setState({ allSymbolsAkcii: true });

      if (
        tempSymbols
          .filter((symbol) => symbol.board == 'MOEX_FUT')
          .find((symbol) => !symbol.checked)
      )
        this.setState({ allSymbolsFuchers: false });
      if (
        !tempSymbols
          .filter((symbol) => symbol.board == 'MOEX_FUT')
          .find((symbol) => !symbol.checked)
      )
        this.setState({ allSymbolsFuchers: true });

      if (tempSymbols.find((symbol) => !symbol.checked))
        this.setState({ allSymbols: false });
      if (!tempSymbols.find((symbol) => !symbol.checked))
        this.setState({ allSymbols: true });

      this.setState({ symbols: tempSymbols });
    } else if (id == -2) {
      this.setState({ allSymbolsValuta: !this.state.allSymbolsValuta }, () => {
        for (let i = 0; i < tempSymbols.length; i++) {
          if (tempSymbols[i].board == 'MOEX_FX')
            tempSymbols[i].checked = this.state.allSymbolsValuta;
        }

        if (tempSymbols.find((symbol) => !symbol.checked))
          this.setState({ allSymbols: false });
        if (!tempSymbols.find((symbol) => !symbol.checked))
          this.setState({ allSymbols: true });

        this.setState({ symbols: tempSymbols });
      });
    } else if (id == -3) {
      this.setState({ allSymbolsAkcii: !this.state.allSymbolsAkcii }, () => {
        for (let i = 0; i < tempSymbols.length; i++) {
          if (tempSymbols[i].board == 'MOEX_STOCK')
            tempSymbols[i].checked = this.state.allSymbolsAkcii;
        }

        if (tempSymbols.find((symbol) => !symbol.checked))
          this.setState({ allSymbols: false });
        if (!tempSymbols.find((symbol) => !symbol.checked))
          this.setState({ allSymbols: true });

        this.setState({ symbols: tempSymbols });
      });
    } else if (id == -4) {
      this.setState(
        { allSymbolsFuchers: !this.state.allSymbolsFuchers },
        () => {
          for (let i = 0; i < tempSymbols.length; i++) {
            if (tempSymbols[i].board == 'MOEX_FUT')
              tempSymbols[i].checked = this.state.allSymbolsFuchers;
          }

          if (tempSymbols.find((symbol) => !symbol.checked))
            this.setState({ allSymbols: false });
          if (!tempSymbols.find((symbol) => !symbol.checked))
            this.setState({ allSymbols: true });

          this.setState({ symbols: tempSymbols });
        }
      );
    } else if (id == -1) {
      this.setState({ allSymbols: !this.state.allSymbols }, () => {
        for (let i = 0; i < tempSymbols.length; i++) {
          tempSymbols[i].checked = this.state.allSymbols;
        }
        this.setState({
          symbols: tempSymbols,
          allSymbolsAkcii: this.state.allSymbols,
          allSymbolsFuchers: this.state.allSymbols,
          allSymbolsValuta: this.state.allSymbols,
        });
      });
    }
  };
  onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate }, () => {
      if (this.state.startDate && this.state.endDate) {
        this.loadAnalysis({
          accountId: this.state.accountFilter,
          boardId: this.state.boardId,
          dateStart: startDate.format('DD-MM-YYYY'),
          dateEnd: endDate.format('DD-MM-YYYY'),
          symbols: '',
        });
      }
    });
  };
  onFocusChange = (calendarFocused) => {
    this.setState({ calendarFocused });
  };
  selectCalendarDates = (range) => {
    switch (range) {
      case 'currentMonth':
        this.onDatesChange({
          startDate: moment().startOf('month'),
          endDate: moment().endOf('month'),
        });
        break;
      case 'prevMonth':
        this.onDatesChange({
          startDate: moment()
            .startOf('month')
            .subtract(1, 'ms')
            .startOf('month'),
          endDate: moment().startOf('month').subtract(1, 'ms').endOf('month'),
        });
        break;
      case 'currentQuarter':
        this.onDatesChange({
          startDate: moment().startOf('quarter'),
          endDate: moment().endOf('quarter'),
        });
        break;
      case 'prevQuarter':
        this.onDatesChange({
          startDate: moment()
            .startOf('quarter')
            .subtract(1, 'ms')
            .startOf('quarter'),
          endDate: moment()
            .startOf('quarter')
            .subtract(1, 'ms')
            .endOf('quarter'),
        });
        break;
      default:
        break;
    }
  };
  calendarBtns = () => {
    return (
      <div className="calendarBtns">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.selectCalendarDates('currentMonth')}
        >
          Текущий месяц
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.selectCalendarDates('prevMonth')}
        >
          Прошлый месяц
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.selectCalendarDates('currentQuarter')}
        >
          Текущий квартал
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.selectCalendarDates('prevQuarter')}
        >
          Прошлый квартал
        </button>
      </div>
    );
  };

  render() {
    return (
      <div className="analysis-equity card-content">
        {!this.state.pageError ? (
          <div>
            <ul className="nav nav-tabs boardsTabs">
              <li
                className="nav-item"
                onClick={() => {
                  this.onBoardChange(0);
                }}
              >
                <span
                  className="allBoard nav-link text-center active show"
                  data-toggle="tab"
                >
                  <span>Все</span>
                </span>
              </li>
              {
                this.state.enableSections.map((section, index) => (
                  <li
                    className="nav-item"
                    onClick={() => {
                      this.onBoardChange(section.id);
                    }}
                  >
                    <span className="isBoard nav-link text-center" data-toggle="tab">
                      <span>{section.title.replace(' MOEX', '')}</span>
                    </span>
                  </li>
                ))
              }
              <li className="closeDealsBtns">
                <div className="analysisEquity-symbolsFilter">
                  <div className="customSelect">
                    <div
                      className="customSelectedItem"
                      onClick={() => {
                        this.setState({ selectIsOpened: true });
                        this.openedSymbols = JSON.stringify(this.state.symbols);
                      }}
                    >
                      Инструменты (
                      {this.state.symbols.filter((symbol) => symbol.checked)
                        .length || this.state.symbols.length}
                      /{this.state.symbols.length})
                    </div>
                    {this.state.selectIsOpened && (
                      <ul className="customSelectList">
                        <Baron>
                          <li
                            className="customSelectList-item clickSelectHideBlock"
                            style={{ borderBottom: '1px solid #f2f3fa' }}
                          >
                            <label className="checkboxContainer clickSelectHideBlock">
                              {' '}
                              Все
                              <input
                                type="checkbox"
                                className="clickSelectHideBlock"
                                checked={this.state.allSymbols}
                                onChange={(e) => this.onChangeSymbolFilter(-1)}
                              />
                              <span className="checkmark clickSelectHideBlock"></span>
                            </label>
                          </li>
                          {this.state.symbols.find(
                            (symbol) => symbol.board == 'MOEX_FX'
                          ) && (
                            <li className="customSelectList-item clickSelectHideBlock">
                              <label className="checkboxContainer clickSelectHideBlock">
                                {' '}
                                Все Валюта
                                <input
                                  type="checkbox"
                                  className="clickSelectHideBlock"
                                  checked={this.state.allSymbolsValuta}
                                  onChange={(e) =>
                                    this.onChangeSymbolFilter(-2)
                                  }
                                />
                                <span className="checkmark clickSelectHideBlock"></span>
                              </label>
                            </li>
                          )}
                          {this.state.symbols.find(
                            (symbol) => symbol.board == 'MOEX_STOCK'
                          ) && (
                            <li className="customSelectList-item clickSelectHideBlock">
                              <label className="checkboxContainer clickSelectHideBlock">
                                {' '}
                                Все Акции
                                <input
                                  type="checkbox"
                                  className="clickSelectHideBlock"
                                  checked={this.state.allSymbolsAkcii}
                                  onChange={(e) =>
                                    this.onChangeSymbolFilter(-3)
                                  }
                                />
                                <span className="checkmark clickSelectHideBlock"></span>
                              </label>
                            </li>
                          )}
                          {this.state.symbols.find(
                            (symbol) => symbol.board == 'MOEX_FUT'
                          ) && (
                            <li className="customSelectList-item clickSelectHideBlock">
                              <label className="checkboxContainer clickSelectHideBlock">
                                {' '}
                                Все Фьючерсы
                                <input
                                  type="checkbox"
                                  className="clickSelectHideBlock"
                                  checked={this.state.allSymbolsFuchers}
                                  onChange={(e) =>
                                    this.onChangeSymbolFilter(-4)
                                  }
                                />
                                <span className="checkmark clickSelectHideBlock"></span>
                              </label>
                            </li>
                          )}
                          {this.state.symbols
                            .filter((symbol) => symbol.board == 'MOEX_FX')
                            .map((symbol, index) => (
                              <li
                                key={symbol.id}
                                className="customSelectList-item"
                                style={{
                                  borderTop:
                                    index == 0 ? '1px solid #f2f3fa' : '',
                                }}
                              >
                                <label className="checkboxContainer clickSelectHideBlock">
                                  {' '}
                                  {symbol.name}
                                  <input
                                    type="checkbox"
                                    className="clickSelectHideBlock"
                                    checked={symbol.checked}
                                    onChange={(e) =>
                                      this.onChangeSymbolFilter(symbol.id)
                                    }
                                  />
                                  <span className="checkmark clickSelectHideBlock"></span>
                                </label>
                              </li>
                            ))}
                          {this.state.symbols
                            .filter((symbol) => symbol.board == 'MOEX_STOCK')
                            .map((symbol, index) => (
                              <li
                                key={symbol.id}
                                className="customSelectList-item"
                                style={{
                                  borderTop:
                                    index == 0 ? '1px solid #f2f3fa' : '',
                                }}
                              >
                                <label className="checkboxContainer clickSelectHideBlock">
                                  {' '}
                                  {symbol.name}
                                  <input
                                    type="checkbox"
                                    className="clickSelectHideBlock"
                                    checked={symbol.checked}
                                    onChange={(e) =>
                                      this.onChangeSymbolFilter(symbol.id)
                                    }
                                  />
                                  <span className="checkmark clickSelectHideBlock"></span>
                                </label>
                              </li>
                            ))}
                          {this.state.symbols
                            .filter((symbol) => symbol.board == 'MOEX_FUT')
                            .map((symbol, index) => (
                              <li
                                key={symbol.id}
                                className="customSelectList-item"
                                style={{
                                  borderTop:
                                    index == 0 ? '1px solid #f2f3fa' : '',
                                }}
                              >
                                <label className="checkboxContainer clickSelectHideBlock">
                                  {' '}
                                  {symbol.name}
                                  <input
                                    type="checkbox"
                                    className="clickSelectHideBlock"
                                    checked={symbol.checked}
                                    onChange={(e) =>
                                      this.onChangeSymbolFilter(symbol.id)
                                    }
                                  />
                                  <span className="checkmark clickSelectHideBlock"></span>
                                </label>
                              </li>
                            ))}
                        </Baron>
                      </ul>
                    )}
                  </div>
                </div>
                <div className="analysisSymbols-datesFilter">
                  <DateRangePicker
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onDatesChange={this.onDatesChange}
                    startDatePlaceholderText="Начало"
                    endDatePlaceholderText="Конец"
                    focusedInput={this.state.calendarFocused}
                    onFocusChange={this.onFocusChange}
                    numberOfMonths={2}
                    isOutsideRange={() => false}
                    hideKeyboardShortcutsPanel={true}
                    // renderCalendarInfo={this.calendarBtns}
                  />
                </div>
              </li>
            </ul>

            <div className="tab-content">
              <div className="tab-pane fade active show">
                {/* <div id="chartdiv" style={{ width: "100%", height: "480px"}}></div> */}
                <div
                  className="chartContainer"
                  style={{
                    display:
                      this.state.symbols.length > 0 && !this.state.isLoading
                        ? 'block'
                        : 'none',
                  }}
                >
                  <div className="chart-logoRemover"></div>
                  <div
                    id="chartdiv"
                    style={{ width: '100%', height: '480px' }}
                  ></div>
                </div>
                {(this.state.symbols.length == 0 || this.state.isLoading) && (
                  <div className="emptyDashboard-plug">
                    {this.state.isLoading ? (
                      <div className="mt-1 text-center spinner-container isLoadingSpinner">
                        <span>
                          <img
                            src="/images/ui/load-spinner.png"
                            className="spinner"
                            alt=""
                          />
                        </span>
                      </div>
                    ) : (
                      <div className="emptyData">нет данных</div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          // <div className="card">
          //     <div className="card-header">
          //         <h2 className="card-title text-center">Статистика по инструментам</h2>
          //     </div>
          //     <div className="card-body">
          //         <div className="row">
          //             <div className="col-lg-6">
          //                 <div className="form-group">
          //                     <div className="btn-group btn-group-toggle btn-group-justified justifiedBtnGroup" data-toggle="buttons">
          //                         <label
          //                             className={`btn btn-primary ${this.state.boardId == 0 ? 'active' : ''}`}
          //                             onClick={() => {this.onBoardChange(0)}}
          //                         >
          //                             Общий результат
          //                         </label>
          //                         {
          //                             this.state.boardsList.map((board, index) => (
          //                                 <label
          //                                     key={board.id}
          //                                     className={`btn btn-primary ${this.state.boardId == board.id ? 'active' : ''}`}
          //                                     onClick={() => {this.onBoardChange(board.id)}}
          //                                 >{board.name}</label>
          //                             ))
          //                         }
          //                     </div>
          //                 </div>
          //             </div>
          //             <div className="col-lg-2">

          //                 <select
          //                     style={{width: '100%'}}
          //                     className="form-control select accountFilter"
          //                     value={this.state.accountFilter}
          //                     onChange={this.onChangeAccountFilter}
          //                 >
          //                     {
          //                         this.props.tradingAccounts.trading_accounts.map((account) => (
          //                             <option
          //                                 key={account.id}
          //                                 value={account.id}
          //                             >{account.name}</option>
          //                         ))
          //                     }
          //                 </select>
          //             </div>
          //             <div className="col-lg-4 text-right">
          //                 <DateRangePicker
          //                     startDate={this.state.startDate}
          //                     endDate={this.state.endDate}
          //                     onDatesChange={this.onDatesChange}
          //                     focusedInput={this.state.calendarFocused}
          //                     onFocusChange={this.onFocusChange}
          //                     numberOfMonths={2}
          //                     isOutsideRange={() => false}
          //                     hideKeyboardShortcutsPanel={true}
          //                     renderCalendarInfo={this.calendarBtns}
          //                 />
          //             </div>
          //         </div>
          //         <div className="row">
          //             <div className="col-12">
          //                 {
          //                     this.state.symbols.map((symbol, index) => (
          //                         <div key={index} className="switchContainer switchContainer-labelLeft mr-2">
          //                             <label className="switch">
          //                                 {symbol.name}
          //                                 <input
          //                                     type="checkbox"
          //                                     checked={symbol.checked}
          //                                     value={symbol.checked}
          //                                     onChange={(e) => this.onChangeSymbolFilter(index)}
          //                                 />
          //                                 <div className="slider"></div>
          //                             </label>
          //                         </div>
          //                     ))
          //                 }
          //                 <button
          //                     className="btn btn-success"
          //                     onClick={() => {this.loadAnalysisFilteredSymbols()}}
          //                     disabled={this.props.tradingAccounts.trading_accounts.length == 0}
          //                 >Применить</button>
          //             </div>
          //         </div>
          //         {
          //             this.state.symbols.length == 0 &&
          //                 <h1 className="text-center">Нет данных</h1>
          //         }
          //         {/* <div className="card chartContainer" style={{display: this.state.symbols.length > 0 ? 'block' : 'none'}}> */}
          //         <div className="chartContainer">
          //             <div className="chart-logoRemover"></div>
          //             {/* <div id="chartdiv" style={{ width: "100%", height: "500px", display: this.state.symbols.length > 0 ? 'block' : 'none'}}></div> */}
          //             <div id="chartdiv" style={{ width: "100%", height: "480px"}}></div>
          //         </div>
          //     </div>
          // </div>
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tradingAccounts: state.tradingAccounts,
    tradingAccount: state.tradingAccount,
  };
};

export default connect(mapStateToProps)(EquityAnalysisPage);
