import React from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import Baron from 'react-baron/dist/es5';
import cookie from 'react-cookies';

import { catchPromise } from '../utils/http';
import { setDailyDeals } from '../actions/dailyDeals';
import { setDailyTradingAccount } from '../actions/dailyTradingAccount';
import { setTradingAccount } from '../actions/tradingAccount';
import { getDailyFinres, getDeals, getLastFinres } from '../utils/finresHttp';
import DailyFinresBoard from '../components/DailyFinresBoard';
import { currencyFormat, sumColor, getCurrency } from '../utils/currencyUtil';

export class DailyFinresPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(),
      calendarFocused: null,
      notDataText: false,
      accountId:
        props.tradingAccounts.trading_accounts.length > 0
          ? props.tradingAccounts.trading_accounts[0].id
          : '',
      dealsPageNum: 1,
      page_size: 100,
      moreBtnVisible: true,
      currTab: 0,
      isLoading: false,
      isLoadingDeals: false,
      lastFinresIsUploaded: false,
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    if (this.props.tradingAccounts.trading_accounts.length > 0) {
      this.checkLastFinres();
    } else {
      this.setState({ isLoading: false });
    }
    $('body').on('click', () => {
      if ($('.customSelectListMonth').hasClass('isShowSelect'))
        setTimeout(() => {
          $('.customSelectListMonth').removeClass('isShowSelect');
        }, 50);
      if ($('.customSelectListYear').hasClass('isShowSelect'))
        setTimeout(() => {
          $('.customSelectListYear').removeClass('isShowSelect');
        }, 50);
    });
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.tradingAccount.name !== nextProps.tradingAccount.name &&
      this.state.lastFinresIsUploaded
    ) {
      this.loadDailyFinres(this.state.date, nextProps.tradingAccount.id);
      window.history.pushState(
        null,
        '',
        `${this.props.location.pathname}?date=${this.state.date.format(
          'DD-MM-YYYY'
        )}&accountName=${nextProps.tradingAccount.name}`
      );
    }
  }

  checkLastFinres = () => {
    let params = new URLSearchParams(window.location.search);
    let accName = '';
    if (!params.has('date')) {
      getLastFinres({ trading_account_id: this.props.tradingAccount.id })
        .then((res) => {
          window.history.pushState(
            null,
            '',
            `${this.props.location.pathname}?date=${
              res.data.date
                ? moment(res.data.date, 'YYYY-MM-DD').format('DD-MM-YYYY')
                : this.state.date.format('DD-MM-YYYY')
            }&accountName=${this.props.tradingAccount.name}`
          );
          this.setState({
            date: res.data.date
              ? moment(res.data.date, 'YYYY-MM-DD')
              : this.state.date,
            accountId: this.props.tradingAccount.id,
          });
          if (res.data.date) {
            this.loadDailyFinres(
              moment(res.data.date, 'YYYY-MM-DD'),
              this.props.tradingAccount.id
            );
          } else {
            this.props.setDailyTradingAccount({
              boards: [],
              currency_code: '',
              name: '',
              total: {},
            });
            this.props.setDailyDeals({ page_size: 1, deals: [] });
            this.setState({ isLoading: false });
          }

          this.setState({ lastFinresIsUploaded: true });
        })
        .catch((err) => {
          catchPromise(err, this.checkLastFinres, this.errorCb);
        });
    } else {
      let accName = '';
      let accId = 0;
      if (!params.has('accountName')) {
        getLastFinres({ date: params.get('date') })
          .then((res) => {
            if (res.data.trading_account_id) {
              const accSearched = this.props.tradingAccounts.trading_accounts.find(
                (acc) => acc.id == res.data.trading_account_id
              );
              accName = accSearched
                ? accSearched.name
                : this.props.tradingAccounts.trading_accounts[0].name;
              accId = this.props.tradingAccounts.trading_accounts.find(
                (acc) => acc.name == accName
              ).id;
              window.history.pushState(
                null,
                '',
                `${this.props.location.pathname}?date=${moment(
                  params.get('date'),
                  'DD-MM-YYYY'
                ).format('DD-MM-YYYY')}&accountName=${accName}`
              );
              this.props.setTradingAccount(
                accSearched || this.props.tradingAccounts.trading_accounts[0]
              );
              cookie.save(
                'accountObj',
                accSearched || this.props.tradingAccounts.trading_accounts[0]
              );
              this.setState({
                date: moment(params.get('date'), 'DD-MM-YYYY'),
                accountId: accId,
              });
              this.loadDailyFinres(
                moment(params.get('date'), 'DD-MM-YYYY'),
                accId
              );
            } else {
              accName = this.props.tradingAccounts.trading_accounts[0].name;
              accId = this.props.tradingAccounts.trading_accounts.find(
                (acc) => acc.name == accName
              ).id;
              window.history.pushState(
                null,
                '',
                `${this.props.location.pathname}?date=${moment(
                  params.get('date'),
                  'DD-MM-YYYY'
                ).format('DD-MM-YYYY')}&accountName=${accName}`
              );
              this.props.setTradingAccount(
                this.props.tradingAccounts.trading_accounts.find(
                  (acc) => acc.id == accId
                )
              );
              cookie.save(
                'accountObj',
                accSearched || this.props.tradingAccounts.trading_accounts[0]
              );
              this.setState({
                date: moment(params.get('date'), 'DD-MM-YYYY'),
                accountId: accId,
              });
              this.loadDailyFinres(
                moment(params.get('date'), 'DD-MM-YYYY'),
                accId
              );
            }
            this.setState({ lastFinresIsUploaded: true });
          })
          .catch((err) => {
            catchPromise(err, this.checkLastFinres, this.errorCb);
          });
      } else {
        const accSearched = this.props.tradingAccounts.trading_accounts.find(
          (acc) => acc.name == params.get('accountName')
        );
        accId = accSearched
          ? accSearched.id
          : this.props.tradingAccounts.trading_accounts[0].id;
        accName = this.props.tradingAccounts.trading_accounts.find(
          (acc) => acc.id == accId
        ).name;

        window.history.pushState(
          null,
          '',
          `${this.props.location.pathname}?date=${moment(
            params.get('date'),
            'DD-MM-YYYY'
          ).format('DD-MM-YYYY')}&accountName=${accName}`
        );
        this.props.setTradingAccount(
          accSearched || this.props.tradingAccounts.trading_accounts[0]
        );
        cookie.save(
          'accountObj',
          accSearched || this.props.tradingAccounts.trading_accounts[0]
        );
        this.setState({
          date: moment(params.get('date'), 'DD-MM-YYYY'),
          accountId: accId,
        });
        this.loadDailyFinres(moment(params.get('date'), 'DD-MM-YYYY'), accId);
        this.setState({ lastFinresIsUploaded: true });
      }
    }
  };

  loadDailyFinres = (date, accountId) => {
    this.setState({ isLoading: true });
    getDailyFinres(date.format('DD-MM-YYYY'), accountId)
      .then((res) => {
        let tempBoards = res.data.trading_account.boards;
        for (let i = 0; i < tempBoards.length; i++) {
          tempBoards[i].visibleDeals = false;
        }

        this.props.setDailyTradingAccount({
          ...res.data.trading_account,
          boards: tempBoards,
        });
        this.setState({ accountId });
        this.loadDeals(1);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        catchPromise(
          err,
          this.loadDailyFinres.bind(this, date, accountId),
          this.errorCb
        );
      });
  };
  loadDeals = (page_num) => {
    this.setState({ isLoadingDeals: true });
    getDeals(
      page_num,
      this.state.date.format('DD-MM-YYYY'),
      this.state.accountId
    )
      .then((res) => {
        this.props.setDailyDeals({
          page_size: res.data.page_size,
          deals: res.data.deals,
        });
        this.setState({
          isLoadingDeals: false,
          isLoading: false,
          dealsPageNum: page_num,
          moreBtnVisible:
            res.data.deals.length < res.data.page_size ? false : true,
        });
      })
      .catch((err) => {
        this.setState({ isLoadingDeals: false, isLoading: false });
        catchPromise(err, this.loadDeals.bind(this, page_num), this.errorCb);
      });
  };
  onDateChange = (date) => {
    if (date) {
      this.setState({ date });
      this.loadDailyFinres(date, this.state.accountId);
      window.history.pushState(
        null,
        '',
        `${this.props.location.pathname}?date=${date.format(
          'DD-MM-YYYY'
        )}&accountName=${
          this.props.tradingAccounts.trading_accounts.find(
            (acc) => acc.id == this.state.accountId
          ).name
        }`
      );
    }
  };
  onFocusChange = ({ focused }) => {
    this.setState({ calendarFocused: focused });
  };

  onChangeAccountId = (e) => {
    const accountId = e.target.value;

    this.setState({ accountId });
    this.loadDailyFinres(this.state.date, accountId);
    window.history.pushState(
      null,
      '',
      `${this.props.location.pathname}?date=${this.state.date.format(
        'DD-MM-YYYY'
      )}&accountName=${
        this.props.tradingAccounts.trading_accounts.find(
          (acc) => acc.id == accountId
        ).name
      }`
    );
  };

  openDeals = (index) => {
    let tempBoards = this.props.dailyTradingAccount.boards;
    for (let i = 0; i < tempBoards.length; i++) {
      if (i == index) tempBoards[i].visibleDeals = !tempBoards[i].visibleDeals;
    }
    this.props.setDailyTradingAccount({
      ...this.props.dailyTradingAccount,
      boards: tempBoards,
    });
  };
  changeSelect = (name, value) => {
    if (value) $('.' + name).addClass('isShowSelect');
    else $('.' + name).removeClass('isShowSelect');
  };
  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    let rows = [];
    for (let i = 0; i < 100; i++) {
      rows.push(i);
    }
    return (
      <div
        className="singleDatePickerSelect"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div className="customSelect customSelectSingleDatepicker">
          <div
            className="customSelectedItem"
            onClick={() => {
              this.changeSelect('customSelectListMonth', true);
            }}
          >
            {month.format('MMMM')}
          </div>
          {
            <ul className="customSelectList customSelectListMonth">
              <Baron>
                {moment.months().map((label, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      onMonthSelect(month, index);
                      this.changeSelect('customSelectListMonth', false);
                    }}
                  >
                    {label}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
        <div className="customSelect customSelectSingleDatepicker customSelectSingleDatepicker-year">
          <div
            className="customSelectedItem"
            onClick={() => {
              this.changeSelect('customSelectListYear', true);
            }}
          >
            {month.year()}
          </div>
          {
            <ul className="customSelectList customSelectListYear">
              <Baron>
                {rows.map((row) => (
                  <li
                    key={row}
                    onClick={() => {
                      onYearSelect(month, moment().year() - row);
                      this.changeSelect('customSelectListYear', false);
                    }}
                  >
                    {moment().year() - row}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="dailyFinres">
        {!this.state.pageError ? (
          <div className="page-content-center">
            <div className="title card-content">Дневной отчет</div>
            <div className="card-content">
              <ul className="nav nav-tabs boardsTabs">
                <li
                  className="nav-item"
                  onClick={() => this.setState({ currTab: 0 })}
                >
                  <span
                    className="nav-link text-center active show"
                    data-toggle="tab"
                  >
                    <span>Отчеты</span>
                  </span>
                </li>
                <li
                  className="nav-item"
                  onClick={() => this.setState({ currTab: 1 })}
                >
                  <span className="nav-link text-center" data-toggle="tab">
                    <span>Сделки</span>
                  </span>
                </li>
                <li className="boardsTabs-rightOptions">
                  <div className="dailyFinres-filterDateTitle">Дата</div>
                  <SingleDatePicker
                    date={this.state.date}
                    placeholder="Дата"
                    onDateChange={this.onDateChange}
                    focused={this.state.calendarFocused}
                    onFocusChange={this.onFocusChange}
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                    hideKeyboardShortcutsPanel={true}
                    renderMonthElement={this.renderMonthElement}
                  />
                  <ReactToPrint
                    trigger={() => (
                      <button className="btn btn-success">Печать</button>
                    )}
                    bodyClass="printClass"
                    content={() => this.componentRef}
                  />
                </li>
              </ul>

              <div className="tab-content">
                <div className="tab-pane fade active show">
                  {
                    <TradingAccountComponent
                      account={this.props.dailyTradingAccount}
                      deals={this.props.dailyDeals.deals}
                      moreBtnVisible={this.state.moreBtnVisible}
                      btnMoreClick={() => {
                        this.loadDeals(this.state.dealsPageNum + 1);
                      }}
                      openDeals={this.openDeals}
                      currTab={this.state.currTab}
                      isLoading={this.state.isLoading}
                      isLoadingDeals={this.state.isLoadingDeals}
                      ref={(el) => (this.componentRef = el)}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
      </div>
    );
  }
}

class TradingAccountComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        {this.props.currTab === 0 && (
          <div className="table">
            <table className="tableLayoutFixes dailyFinres-boardTable">
              <thead>
                <tr className="dailyFinres-tableTitles">
                  <td className="text-left dailyFinresTable-plosch">
                    ПЛОЩАДКА
                  </td>
                  <td className="dailyFinresTable-prosadka">
                    ПРОСАДКА, {getCurrency(this.props.account.currency_code)}
                  </td>
                  <td className="dailyFinresTable-marja">
                    МАРЖА, {getCurrency(this.props.account.currency_code)}
                  </td>
                  <td className="dailyFinresTable-sdelki">СДЕЛОК</td>
                  <td className="dailyFinresTable-commis">
                    КОМИССИЯ, {getCurrency(this.props.account.currency_code)}
                  </td>
                  <td className="dailyFinresTable-finres">
                    ФИНРЕЗ, {getCurrency(this.props.account.currency_code)}
                  </td>
                  <td className="dailyFinresTable-arrow"></td>
                </tr>
              </thead>
            </table>
            {this.props.account.boards.length > 0 && !this.props.isLoading ? (
              <Baron>
                <table className="tableLayoutFixes dailyFinres-boardTable">
                  <tbody>
                    {this.props.account.boards.map((board, index) => (
                      <DailyFinresBoard
                        key={index}
                        board={board}
                        currency={this.props.account.currency_code}
                        index={index}
                        openDeals={this.props.openDeals}
                      />
                    ))}
                    <tr
                      className={`${
                        this.props.account.boards.length & 1
                          ? 'evenStroke'
                          : 'oddStroke'
                      }`}
                    >
                      <td className="text-left dailyFinresTable-plosch">
                        Итого
                      </td>
                      <td className="dailyFinresTable-prosadka"></td>
                      <td className="dailyFinresTable-marja">
                        {currencyFormat(this.props.account.total.pnl)}
                      </td>
                      <td className="dailyFinresTable-sdelki"></td>
                      <td className="dailyFinresTable-commis">
                        {currencyFormat(this.props.account.total.fee)}
                      </td>
                      <td
                        className={`dailyFinresTable-finres ${sumColor(
                          this.props.account.total.amount
                        )}`}
                      >
                        {currencyFormat(this.props.account.total.amount)}
                      </td>
                      <td className="dailyFinresTable-arrow"></td>
                    </tr>
                  </tbody>
                </table>
              </Baron>
            ) : (
              <div className="emptyDashboard-plug">
                {this.props.isLoading ? (
                  <div className="mt-1 text-center spinner-container isLoadingSpinner">
                    <span>
                      <img
                        src="/images/ui/load-spinner.png"
                        className="spinner"
                        alt=""
                      />
                    </span>
                  </div>
                ) : (
                  <div className="emptyData">нет данных</div>
                )}
              </div>
            )}
          </div>
        )}
        {this.props.currTab === 1 && (
          <div className="table">
            <table className="tableLayoutFixes dailyFinres-boardTable tableDealsFull">
              <thead>
                <tr className="dailyFinres-tableTitles">
                  <td className="text-left dailyFinresDealsTable-instr">
                    ИНСТРУМЕНТ
                  </td>
                  <td className="dailyFinresDealsTable-sekcia">СЕКЦИЯ</td>
                  <td className="dailyFinresDealsTable-timeOpen">
                    ВРЕМЯ ОТКРЫТИЯ
                  </td>
                  <td className="dailyFinresDealsTable-costOpen">
                    ЦЕНА ОТКРЫТИЯ
                  </td>
                  <td className="dailyFinresDealsTable-timeClose">
                    ВРЕМЯ ЗАКРЫТИЯ
                  </td>
                  <td className="dailyFinresDealsTable-costClose">
                    ЦЕНА ЗАКРЫТИЯ
                  </td>
                  <td className="dailyFinresDealsTable-operation">ОПЕРАЦИЯ</td>
                  <td className="dailyFinresDealsTable-lots">ОБОРОТ</td>
                  <td className="dailyFinresDealsTable-maxLots">
                    МАКС
                    <br />
                    ЛОТОВ
                  </td>
                  <td className="dailyFinresDealsTable-pribPunkt">
                    ПРИБЫЛЬ
                    <br />В ПУНКТАХ
                  </td>
                  <td className="dailyFinresDealsTable-pribMoney">
                    ПРИБЫЛЬ
                    <br />В ДЕНЬГАХ{' '}
                    {getCurrency(this.props.account.currency_code)}
                  </td>
                  <td className="dailyFinresDealsTable-comm">
                    КОМИССИЯ {getCurrency(this.props.account.currency_code)}
                  </td>
                </tr>
              </thead>
            </table>
            {this.props.deals.length > 0 && !this.props.isLoadingDeals ? (
              <Baron>
                <table className="tableLayoutFixes dailyFinres-boardTable tableDealsFull">
                  <tbody>
                    {this.props.deals.map((deal, index) => (
                      <tr key={index}>
                        <td className="text-left dailyFinresDealsTable-instr">
                          {deal.symbol_code}
                        </td>
                        <td className="dailyFinresDealsTable-sekcia">
                          {deal.board_name.replace(/ MOEX| \(MOEX\)/gi, '')}
                        </td>
                        <td className="dailyFinresDealsTable-timeOpen">
                          {moment(deal.open_time).format('DD.MM.YYYY HH:mm:ss')}
                        </td>
                        <td className="dailyFinresDealsTable-costOpen">
                          {currencyFormat(
                            deal.open_price,
                            '',
                            deal.price_precision,
                            true
                          )}
                        </td>
                        <td className="dailyFinresDealsTable-timeClose">
                          {moment(deal.close_time).format(
                            'DD.MM.YYYY HH:mm:ss'
                          )}
                        </td>
                        <td className="dailyFinresDealsTable-costClose">
                          {currencyFormat(
                            deal.close_price,
                            '',
                            deal.price_precision,
                            true
                          )}
                        </td>
                        <td className="dailyFinresDealsTable-operation">
                          {deal.side}
                        </td>
                        <td className="dailyFinresDealsTable-lots">
                          {currencyFormat(deal.quantity, null, 0)}
                        </td>
                        <td className="dailyFinresDealsTable-maxLots">
                          {currencyFormat(deal.max_quantity, null, 0)}
                        </td>
                        <td className="dailyFinresDealsTable-pribPunkt">
                          {deal.pnl_points}
                        </td>
                        <td className="dailyFinresDealsTable-pribMoney">
                          {currencyFormat(deal.pnl_profit)}
                        </td>
                        <td className="dailyFinresDealsTable-comm">
                          {currencyFormat(deal.fees)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Baron>
            ) : (
              <div className="emptyDashboard-plug">
                {this.props.isLoadingDeals ? (
                  <div className="mt-1 text-center spinner-container isLoadingSpinner">
                    <span>
                      <img
                        src="/images/ui/load-spinner.png"
                        className="spinner"
                        alt=""
                      />
                    </span>
                  </div>
                ) : (
                  <div className="emptyData">нет данных</div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tradingAccounts: state.tradingAccounts,
    tradingAccount: state.tradingAccount,
    dailyDeals: state.dailyDeals,
    dailyTradingAccount: state.dailyTradingAccount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setDailyDeals: (dailyDeals) => dispatch(setDailyDeals(dailyDeals)),
  setDailyTradingAccount: (dailyTradingAccount) =>
    dispatch(setDailyTradingAccount(dailyTradingAccount)),
  setTradingAccount: (tradingAccount) =>
    dispatch(setTradingAccount(tradingAccount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DailyFinresPage);
