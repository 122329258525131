import React from "react";

export const PasswordMask = ({ passwordVisible, setPasswordVisible, isInputOutlined }) => {
  return (
    <div
      className="d-flex align-items-center"
      style={isInputOutlined ? { borderBottom: "1px solid #b9b7c7" } : null}
      onClick={() => setPasswordVisible(!passwordVisible)}
    >
      <img width="15" height={!passwordVisible ? "15" : "13"} src={passwordVisible ? "/images/ui/password-mask-eye-crossed.png" : "/images/ui/password-mask-eye.png"} />
    </div>
  );
};
