import React from 'react';
import Baron from 'react-baron/dist/es5';
import {Link} from 'react-router-dom';
import { history } from '../../routers/AppRouter';

import {catchPromise} from '../../utils/http';
import {getDocument, changeDocument} from '../../utils/documentsHttp';
import DocNeedScan from './DocNeedScan';
import DocNeedSms from './DocNeedSms';
import DocScanCheck from './DocScanCheck';
import DocLoadDiadoc from './DocLoadDiadoc';
import DocSignDiadoc from './DocSignDiadoc';
import DocWaitLetter from './DocWaitLetter';
import DodDiadocCheck from './DocDiadocCheck';
import DocDone from './DocDone';
import DocDoneSingle from './DocDoneSingle';
import DocRejected from './DocRejected';
import Popup from '../../containers/Popup';

export default class DocumentPage extends React.Component {
  constructor(props) {
    super(props);

    this.docsTimer = null;
    this.docstimerCount = 0;
    this.state = {
      key: props.match.params.key,
      document: {
        "statuses": [],
        "info": {},
        "status": {}
      },
      progressLineTitles: [],
      currIndex: 0,
      docsWaitingErr: undefined,
      isShowPopup: false,
      isSending: false,
      error: undefined,
      responseErr: null,
      pageError: false
    };
  }
  errorCb = () => {
    this.setState({pageError: true});
  }
  componentWillUnmount() {
    if(this.docsTimer) clearTimeout(this.docsTimer);
  }
  componentDidMount() {
    this.loadDoc();
  }
  togglePopup = () => {
    this.setState({isShowPopup: !this.state.isShowPopup});
  }
  loadDoc = () => {
    getDocument(this.props.match.params.key)
    .then(res => {
      this.setState({document: res.data});
      this.actionStep(res.data.statuses, res.data.status);
      this.docstimerCount++;
      if((res.data.status.code === 'NEED_SMS' || res.data.status.code === 'NEED_SCAN' || res.data.status.code == 'NEED_AUTH_DIADOC') && res.data.info.files.length == 0 && this.docstimerCount < 10) {
        this.docsTimer = setTimeout(this.loadDoc, 5000);
      } else if(res.data.status.code === 'SIGN_DIADOC' && (!res.data.info.diadoc.sign_files || JSON.stringify(res.data.info.diadoc.sign_files) === '{}')) {
        this.docsTimer = setTimeout(this.loadDoc, 5000);
      }
      if(this.docstimerCount >= 10) {
        this.setState({docsWaitingErr: 'Ошибка подготовки файлов'});
      }
    })
    .catch(err => {
      catchPromise(err, this.loadDoc, this.errorCb);
    });
  }
  actionStep = (statuses, status) => {
    let currIndex = 0;
    for (let i = 0; i < statuses.length; i++) {
      if(statuses[i].code == status.code) {
        currIndex = i;
        this.setState({currIndex});
      }      
    }
    $('.progressLine-iconContainer').each(function(index, element) {
      $(element).removeClass('progressIcon-prev');
      $(element).removeClass('progressIcon-next');
      if(index < currIndex) {
        $(element).addClass('progressIcon-prev');
        $(element).find('.checkIcon').removeClass('hidden');
        $(element).find('.themeIcon').addClass('hidden');
        $(element).find('.numIcon').addClass('hidden');
      }
      else if(index == currIndex) {
        $(element).find('.checkIcon').addClass('hidden');
        $(element).find('.themeIcon').removeClass('hidden');
        $(element).find('.numIcon').addClass('hidden');
      }
      else if (index > currIndex) {
        $(element).addClass('progressIcon-next');
        $(element).find('.checkIcon').addClass('hidden');
        $(element).find('.themeIcon').addClass('hidden');
        $(element).find('.numIcon').removeClass('hidden');
      }
    });
  }
  getStatusTitles = () => {
    let stepTitleList = [];
    for (let i = 0; i < this.state.document.statuses.length; i++) {
      stepTitleList.push(this.state.document.statuses[i].name)
    }
    return stepTitleList;
  }

  onSubmitNeedSms = (docData) => {
    changeDocument(docData)
    .then(res => {
      this.setState({responseErr: null});
      this.togglePopup();
    })
    .catch(err => {
      if(err.response.data && err.response.status != 401) {
        this.setState({responseErr: err.response.data.message});
      }
      else catchPromise(err, this.onSubmitNeedSms.bind(this, docData), this.errorCb);
    });
  }
  onSubmitAuthDiadoc = (docData) => {
    this.setState({isSending: true}, () => {
      changeDocument(docData)
      .then(res => {
        this.setState({responseErr: null, isSending: false});
        history.push(`/app/documents/${this.props.match.params.key}`);
      })
      .catch(err => {
        if(err.response.data && err.response.status != 401) {
          this.setState({responseErr: err.response.data.message, isSending: false});
        }
        else catchPromise(err, this.onSubmitAuthDiadoc.bind(this, docData), this.errorCb);
      });
    });
  }
  onSubmitSignDiadoc = (docData) => {
    this.setState({isSending: true}, () => {
      changeDocument(docData)
      .then(res => {
        this.setState({responseErr: null, isSending: false});
        this.togglePopup();
      })
      .catch(err => {
        if(err.response.data && err.response.status != 401) {
          this.setState({responseErr: err.response.data.message, isSending: false});
        }
        else catchPromise(err, this.onSubmitSignDiadoc.bind(this, docData), this.errorCb);
      });
    });
  }
  onSubmitNeedScan = (idList) => {
    changeDocument({
      id: parseInt(this.props.match.params.key),
      files: idList
    })
    .then(res => {
      this.setState({responseErr: null});
      this.togglePopup();
    })
    .catch(err => {
      if(err.response.data && err.response.status != 401) {
        this.setState({responseErr: err.response.data.message});
      }
      else catchPromise(err, this.onSubmitNeedScan.bind(this, idList), this.errorCb);
    });
  }
  onSubmitNeedLetter = (letterNum) => {
    changeDocument({
      id: parseInt(this.props.match.params.key),
      tracking_number: letterNum
    })
    .then(res => {
      this.setState({responseErr: null});
      this.togglePopup();
    })
    .catch(err => {
      if(err.response.data && err.response.status != 401) {
        this.setState({responseErr: err.response.data.message});
      }
      else catchPromise(err, this.onSubmitNeedLetter.bind(this, letterNum), this.errorCb);
    });
  }

  render() {
    return (
      <div className="documentPage">
        {
          this.state.isShowPopup &&
            <Popup closePopup={this.togglePopup}>
              <div className="docPageSendMessage">
                <div>Ваши документы успешно подписаны!</div>
                <div>После проверки статус документа изменится, вы также получите email.</div>
              </div>
              <div className='popupSubmitBtns d-flex justify-content-between align-items-center'>
                <div></div>
                <Link to='/app/documents/moving' className="popupSubmitBtn popupSubmitBtn-ok">ОК</Link>
              </div>
            </Popup>
        }
        <div className="card card-content">
          {        
            !this.state.pageError ? (
              <div>
                {
                  this.state.document.statuses.length > 0 && 
                  <div>
                    <div className="card-header">
                      <div className="card-title docPage-title">{this.state.document.title}</div>
                      <div className="card-title docPage-subtitle">{this.state.document.subtitle}</div>
                    </div>
                    {
                      this.state.document.status.code != 'REJECTED' ? (
                        <div className="card-body">
                          <div className="documentPage-content">
                            <Baron>
                              <div className="documentCurrent-container">
                                {
                                  this.state.document.cancel_reason != '' && <h5 className="text-center text-danger">{this.state.document.cancel_reason}</h5>
                                }                    
                                {(() => {
                                  switch (this.state.document.status.code) {
                                    case 'NEED_SCAN':
                                      return <DocNeedScan 
                                            files={this.state.document.info.files} 
                                            onSubmit={this.onSubmitNeedScan} 
                                            docsWaitingErr={this.state.docsWaitingErr}
                                            isSending={this.state.isSending}
                                            openPopup={this.togglePopup}
                                          />;
                                    case 'NEED_SMS':
                                      return <DocNeedSms 
                                            docId={this.state.key} 
                                            onSubmit={this.onSubmitNeedSms} 
                                            files={this.state.document.info.files} 
                                            docsWaitingErr={this.state.docsWaitingErr}
                                            isSending={this.state.isSending}
                                            openPopup={this.togglePopup}
                                          />;
                                    // case 'NEED_AUTH_DIADOC':
                                    //   return <DocNeedAuthDiadoc
                                    //         docId={this.state.key}
                                    //         onSubmit={this.onSubmitAuthDiadoc}
                                    //         files={this.state.document.info.files}
                                    //         docsWaitingErr={this.state.docsWaitingErr}
                                    //         isSending={this.state.isSending}
                                    //         openPopup={this.togglePopup}
                                    //       />;
                                    case 'SIGN_DIADOC':
                                      return <DocSignDiadoc
                                            docId={this.state.key}
                                            onSubmit={this.onSubmitSignDiadoc}
                                            files={this.state.document.info.files}
                                            isSending={this.state.isSending}
                                            diadoc={this.state.document.info.diadoc}
                                            docsWaitingErr={this.state.docsWaitingErr}
                                          />;
                                    case 'LOAD_DIADOC':
                                      return <DocLoadDiadoc />;
                                    case 'SCAN_CHECK':
                                      return <DocScanCheck />;
                                    case 'CHECK_DIADOC':
                                      return <DodDiadocCheck />;
                                    case 'WAIT_LETTER':
                                      return <DocWaitLetter trackingNum={this.state.document.info.tracking_number} onSubmit={this.onSubmitNeedLetter} />;
                                    case 'DONE':
                                      return <DocDone files={this.state.document.info.files} />;
                                    case 'DONE_SINGLE':
                                      return <DocDoneSingle files={this.state.document.info.files} />;
                                    default:
                                      return null;
                                  }
                                })()}
                                {
                                  this.state.responseErr && 
                                    <p className="text-danger">{this.state.responseErr || 'Произошла ошибка'}</p>
                                }
                              </div>
                            </Baron>
                          </div>
                        </div>
                      ) : (
                        <div className="card-body">
                          <div className="documentCurrent-container">
                            <DocRejected doc={this.state.document} />
                          </div>
                        </div>
                      )
                    }
                  </div>
                }
              </div>
            ) : (
              <h3 className="text-center text-danger">Произошла ошибка</h3>
            )
          }
          <div className="docPage-progressSteps">
            {              
              this.state.document.statuses.map((status, index) => (                
                <div key={status.code} className={`${status.code === this.state.document.status.code ? 'currentDocStatus' : ''}`}>
                  {status.name}
                </div>
              ))
            }
          </div>
        </div>
      </div>
    )
  }
};
