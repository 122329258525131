import React from "react";

export default class AnketaForeignAdresses extends React.Component {
  constructor(props) {
    super(props);

    const countries = [
      { name: "Россия", code: "RU", mask: "+\\7 (999) 999-99-99" },
      { name: "Азербайджан", code: "AZ", mask: "+\\9\\9\\4 99-999-99-99" },
      { name: "Армения", code: "AM", mask: "+\\3\\7\\4 99-999-999" },
      { name: "Беларусь", code: "BY", mask: "+\\3\\7\\5 (99) 999-99-99" },
      { name: "Казахстан", code: "KZ", mask: "+\\7 (\\799) 999-99-99" },
      { name: "Киргизия", code: "KG", mask: "+\\9\\9\\6 (999) 999-999" },
      { name: "Латвия", code: "LV", mask: "+\\3\\7\\1 99-999-999" },
      { name: "Молдавия", code: "MD", mask: "+\\3\\7\\3 999-99-999" },
      { name: "Таджикистан", code: "TJ", mask: "+\\9\\9\\2 99-999-9999" },
      { name: "Туркменистан", code: "TM", mask: "+\\9\\9\\3 9-999-9999" },
      { name: "Узбекистан", code: "UZ", mask: "+\\9\\9\\8 99-999-999" },
      { name: "Украина", code: "UA", mask: "+\\3\\8 (\\099) 999-99-99" },
    ];

    this.state = {
      prevPage: props.prevPage,
      nextPage: props.nextPage,
      validate: false,
      isSamePostal:
        props.data.isSamePostal != null ? props.data.isSamePostal : true,
      isSameLive: props.data.isSameLive != null ? props.data.isSameLive : true,

      regionRegIdValue: "",
      cityRegIdValue: "",
      streetRegIdValue: "",

      d__address__reg__country: props.data.d__address__reg__country || "RU",
      d__address__reg__postal_code:
        props.data.d__address__reg__postal_code || "",
      d__address__reg__region: props.data.d__address__reg__region || "",
      d__address__reg__area: props.data.d__address__reg__area || "",
      d__address__reg__city: props.data.d__address__reg__city || "",
      d__address__reg__street: props.data.d__address__reg__street || "",
      d__address__reg__house: props.data.d__address__reg__house || "",
      d__address__reg__block: props.data.d__address__reg__block || "",
      d__address__reg__flat: props.data.d__address__reg__flat || "",

      d__address__live__country: props.data.d__address__live__country || "RU",
      d__address__live__postal_code:
        props.data.d__address__live__postal_code || "",
      d__address__live__region: props.data.d__address__live__region || "",
      d__address__live__area: props.data.d__address__live__area || "",
      d__address__live__city: props.data.d__address__live__city || "",
      d__address__live__street: props.data.d__address__live__street || "",
      d__address__live__house: props.data.d__address__live__house || "",
      d__address__live__block: props.data.d__address__live__block || "",
      d__address__live__flat: props.data.d__address__live__flat || "",

      d__address__postal__country:
        props.data.d__address__postal__country || "RU",
      d__address__postal__postal_code:
        props.data.d__address__postal__postal_code || "",
      d__address__postal__region: props.data.d__address__postal__region || "",
      d__address__postal__area: props.data.d__address__postal__area || "",
      d__address__postal__city: props.data.d__address__postal__city || "",
      d__address__postal__street: props.data.d__address__postal__street || "",
      d__address__postal__house: props.data.d__address__postal__house || "",
      d__address__postal__block: props.data.d__address__postal__block || "",
      d__address__postal__flat: props.data.d__address__postal__flat || "",

      countries,

      reg__country: countries.find(
        (c) => c.code === (props.data.d__address__reg__country || "RU")
      ),
      live_country: countries.find(
        (c) => c.code === (props.data.d__address__live__country || "RU")
      ),
      postal_country: countries.find(
        (c) => c.code === (props.data.d__address__postal__country || "RU")
      ),

      reg_selectIsOpened: false,
      postal_selectIsOpened: false,
      live_selectIsOpened: false,
    };
  }

  componentDidMount() {
    $("body").on("click", () => {
      if ($(".customSelectListCountries").hasClass("isShowSelect"))
        setTimeout(() => {
          $(".customSelectListCountries").removeClass("isShowSelect");
        }, 50);
    });
  }

  changeCountry = (index, countryKey, dataKey) => {
    this.setState({
      [countryKey]: this.state.countries[index],
      [dataKey]: this.state.countries[index].code,
    });
  };

  changeSelect = (name, value) => {
    if (value) $("." + name).addClass("isShowSelect");
    else $("." + name).removeClass("isShowSelect");
  };

  action = (action, currIndex, dest) => {
    this.setState({ validate: true });
    if (this.validating() || dest === "prev") {
      const cryptoActionData = {
        d__address__reg__country: this.state.d__address__reg__country,
        d__address__reg__postal_code: this.state.d__address__reg__postal_code,
        d__address__reg__region: this.state.d__address__reg__region,
        d__address__reg__area: this.state.d__address__reg__area,
        d__address__reg__city: this.state.d__address__reg__city,
        d__address__reg__street: this.state.d__address__reg__street,
        d__address__reg__house: this.state.d__address__reg__house,
        d__address__reg__block: this.state.d__address__reg__block,
        d__address__reg__flat: this.state.d__address__reg__flat,
      };

      this.props.action(
        action,
        currIndex,
        "foreignAddresses",
        true
          ? cryptoActionData
          : {
              isSamePostal: this.state.isSamePostal,
              isSameLive: this.state.isSameLive,
              d__address__reg__country: this.state.d__address__reg__country,
              d__address__reg__postal_code:
                this.state.d__address__reg__postal_code,
              d__address__reg__region: this.state.d__address__reg__region,
              d__address__reg__area: this.state.d__address__reg__area,
              d__address__reg__city: this.state.d__address__reg__city,
              d__address__reg__street: this.state.d__address__reg__street,
              d__address__reg__house: this.state.d__address__reg__house,
              d__address__reg__block: this.state.d__address__reg__block,
              d__address__reg__flat: this.state.d__address__reg__flat,

              d__address__postal__country: this.state.isSamePostal
                ? this.state.d__address__reg__country
                : this.state.d__address__postal__country,
              d__address__postal__postal_code: this.state.isSamePostal
                ? this.state.d__address__reg__postal_code
                : this.state.d__address__postal__postal_code,
              d__address__postal__region: this.state.isSamePostal
                ? this.state.d__address__reg__region
                : this.state.d__address__postal__region,
              d__address__postal__area: this.state.isSamePostal
                ? this.state.d__address__reg__area
                : this.state.d__address__postal__area,
              d__address__postal__city: this.state.isSamePostal
                ? this.state.d__address__reg__city
                : this.state.d__address__postal__city,
              d__address__postal__street: this.state.isSamePostal
                ? this.state.d__address__reg__street
                : this.state.d__address__postal__street,
              d__address__postal__house: this.state.isSamePostal
                ? this.state.d__address__reg__house
                : this.state.d__address__postal__house,
              d__address__postal__block: this.state.isSamePostal
                ? this.state.d__address__reg__block
                : this.state.d__address__postal__block,
              d__address__postal__flat: this.state.isSamePostal
                ? this.state.d__address__reg__flat
                : this.state.d__address__postal__flat,

              d__address__live__country: this.state.isSameLive
                ? this.state.d__address__reg__country
                : this.state.d__address__live__country,
              d__address__live__postal_code: this.state.isSameLive
                ? this.state.d__address__reg__postal_code
                : this.state.d__address__live__postal_code,
              d__address__live__region: this.state.isSameLive
                ? this.state.d__address__reg__region
                : this.state.d__address__live__region,
              d__address__live__area: this.state.isSameLive
                ? this.state.d__address__reg__area
                : this.state.d__address__live__area,
              d__address__live__city: this.state.isSameLive
                ? this.state.d__address__reg__city
                : this.state.d__address__live__city,
              d__address__live__street: this.state.isSameLive
                ? this.state.d__address__reg__street
                : this.state.d__address__live__street,
              d__address__live__house: this.state.isSameLive
                ? this.state.d__address__reg__house
                : this.state.d__address__live__house,
              d__address__live__block: this.state.isSameLive
                ? this.state.d__address__reg__block
                : this.state.d__address__live__block,
              d__address__live__flat: this.state.isSameLive
                ? this.state.d__address__reg__flat
                : this.state.d__address__live__flat,
            }
      );
    }
  };
  validating = () => {
    if (true)
      return (
        this.state.d__address__reg__country != "" &&
        this.state.d__address__reg__postal_code != "" &&
        this.state.d__address__reg__region != "" &&
        this.state.d__address__reg__city != "" &&
        this.state.d__address__reg__street != "" &&
        this.state.d__address__reg__house != ""
      );
    else
      return (
        this.state.d__address__reg__country != "" &&
        this.state.d__address__reg__postal_code != "" &&
        this.state.d__address__reg__region != "" &&
        this.state.d__address__reg__city != "" &&
        this.state.d__address__reg__street != "" &&
        this.state.d__address__reg__house != "" &&
        (this.state.isSamePostal ||
          (this.state.d__address__postal__country != "" &&
            this.state.d__address__postal__postal_code != "" &&
            this.state.d__address__postal__region != "" &&
            this.state.d__address__postal__city != "" &&
            this.state.d__address__postal__street != "" &&
            this.state.d__address__postal__house != "")) &&
        (this.state.isSameLive ||
          (this.state.d__address__live__country != "" &&
            this.state.d__address__live__postal_code != "" &&
            this.state.d__address__live__region != "" &&
            this.state.d__address__live__city != "" &&
            this.state.d__address__live__street != "" &&
            this.state.d__address__live__house != ""))
      );
  };

  // События получения данных адреса
  onPostalSameReg = () => {
    if (!this.state.isSamePostal) {
      this.setState({
        d__address__postal__country: this.state.d__address__reg__country,
        d__address__postal__postal_code:
          this.state.d__address__reg__postal_code,
        d__address__postal__region: this.state.d__address__reg__region,
        d__address__postal__area: this.state.d__address__reg__area,
        d__address__postal__city: this.state.d__address__reg__city,
        d__address__postal__street: this.state.d__address__reg__street,
        d__address__postal__house: this.state.d__address__reg__house,
        d__address__postal__block: this.state.d__address__reg__block,
        d__address__postal__flat: this.state.d__address__reg__flat,
      });
    }
    this.setState({
      isSamePostal: !this.state.isSamePostal,
      d__address__postal__country: "RU",
      d__address__postal__postal_code: "",
      d__address__postal__region: "",
      d__address__postal__area: "",
      d__address__postal__city: "",
      d__address__postal__street: "",
      d__address__postal__house: "",
      d__address__postal__block: "",
      d__address__postal__flat: "",
    });
  };
  onLiveSameReg = () => {
    if (!this.state.isSameLive) {
      this.setState({
        d__address__live__country: this.state.d__address__reg__country,
        d__address__live__postal_code: this.state.d__address__reg__postal_code,
        d__address__live__region: this.state.d__address__reg__region,
        d__address__live__area: this.state.d__address__reg__area,
        d__address__live__city: this.state.d__address__reg__city,
        d__address__live__street: this.state.d__address__reg__street,
        d__address__live__house: this.state.d__address__reg__house,
        d__address__live__block: this.state.d__address__reg__block,
        d__address__live__flat: this.state.d__address__reg__flat,
      });
    }
    this.setState({
      isSameLive: !this.state.isSameLive,
      d__address__live__country: "RU",
      d__address__live__postal_code: "",
      d__address__live__region: "",
      d__address__live__area: "",
      d__address__live__city: "",
      d__address__live__street: "",
      d__address__live__house: "",
      d__address__live__block: "",
      d__address__live__flat: "",
    });
  };
  onRegFlatChange = (e) => {
    const d__address__reg__flat = e.target.value;
    this.setState(() => ({ d__address__reg__flat }));
  };
  onLiveFlatChange = (e) => {
    const d__address__live__flat = e.target.value;
    this.setState(() => ({ d__address__live__flat }));
  };
  onRegBlockChange = (e) => {
    const d__address__reg__block = e.target.value;
    this.setState(() => ({ d__address__reg__block }));
  };

  addressChange = (
    e,
    stateName,
    bound,
    locationTitle,
    location,
    clearedFields
  ) => {
    const address = e.target.value;
    // console.log(address);
    this.setState({ [stateName]: address });
    if (clearedFields && clearedFields.length > 0)
      this.clearAddressFields(clearedFields);
    // Таймер для ввода на ~300мс
  };

  clearAddressFields = (fields) => {
    for (let i = 0; i < fields.length; i++) {
      this.setState({ [fields[i]]: "" });
    }
  };

  render() {
    return (
      <div>
        <div className="row">
          <h2 className="col-12 subtitle">
            {true
              ? "Адрес места жительства"
              : "Адреса"}
          </h2>
        </div>
        {
          <div className="row mt-3">
            <div className="col-lg-3">
              <div className="formField-container position-relative">
                <label className={`floating-label country__select`}>
                  Страна
                </label>
                <div className="customSelect">
                  <div
                    className="customSelectedItem"
                    onClick={() => {
                      this.setState({ reg_selectIsOpened: true });
                    }}
                  >
                    {this.state.reg__country.name}
                    <i className="toggle icon-menu-open"></i>
                  </div>
                  {this.state.reg_selectIsOpened && (
                    <ul className="customSelectList">
                      {this.state.countries.map((country, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            this.setState({ reg_selectIsOpened: false });
                            this.changeCountry(
                              index,
                              "reg__country",
                              "d__address__reg__country"
                            );
                          }}
                        >
                          {country.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__postal_code == ""
                      ? "hidden"
                      : ""
                  }`}
                >
                  Индекс
                </label>
                <div className="dadataContainer">
                  <input
                    type="text"
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__reg__postal_code == ""
                        ? "inputValidateWrong"
                        : ""
                    } ${
                      this.state.validate &&
                      this.state.d__address__reg__postal_code != ""
                        ? "inputValidateCorrect"
                        : ""
                    }`}
                    placeholder="Индекс"
                    value={this.state.d__address__reg__postal_code}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        "d__address__reg__postal_code",
                        "postal_code",
                        "",
                        ""
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__region == "" ? "hidden" : ""
                  }`}
                >
                  Область
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__reg__region == ""
                        ? "inputValidateWrong"
                        : ""
                    } ${
                      this.state.validate &&
                      this.state.d__address__reg__region != ""
                        ? "inputValidateCorrect"
                        : ""
                    }`}
                    name="region"
                    type="text"
                    placeholder="Область"
                    value={this.state.d__address__reg__region}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        "d__address__reg__region",
                        "region"
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__area == "" ? "hidden" : ""
                  }`}
                >
                  Район
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine `}
                    name="area"
                    type="text"
                    placeholder="Район"
                    value={this.state.d__address__reg__area}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        "d__address__reg__area",
                        "area",
                        "region_fias_id",
                        this.state.regionRegIdValue
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__city == "" ? "hidden" : ""
                  }`}
                >
                  Город
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__reg__city == ""
                        ? "inputValidateWrong"
                        : ""
                    } ${
                      this.state.validate &&
                      this.state.d__address__reg__city != ""
                        ? "inputValidateCorrect"
                        : ""
                    }`}
                    name="city"
                    type="text"
                    placeholder="Город"
                    value={this.state.d__address__reg__city}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        "d__address__reg__city",
                        "city",
                        "region_fias_id",
                        this.state.regionRegIdValue
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__street == "" ? "hidden" : ""
                  }`}
                >
                  Улица
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__reg__street == ""
                        ? "inputValidateWrong"
                        : ""
                    } ${
                      this.state.validate &&
                      this.state.d__address__reg__street != ""
                        ? "inputValidateCorrect"
                        : ""
                    }`}
                    name="street"
                    type="text"
                    placeholder="Улица"
                    value={this.state.d__address__reg__street}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        "d__address__reg__street",
                        "street",
                        "city_fias_id",
                        this.state.cityRegIdValue
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__house == "" ? "hidden" : ""
                  }`}
                >
                  Дом
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__reg__house == ""
                        ? "inputValidateWrong"
                        : ""
                    } ${
                      this.state.validate &&
                      this.state.d__address__reg__house != ""
                        ? "inputValidateCorrect"
                        : ""
                    }`}
                    name="house"
                    type="text"
                    placeholder="Дом"
                    value={this.state.d__address__reg__house}
                    onChange={(e) => {
                      this.addressChange(e, "d__address__reg__house");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__block == "" ? "hidden" : ""
                  }`}
                >
                  Корпус
                </label>
                <input
                  type="text"
                  className="inputUnderLine"
                  placeholder="Корпус"
                  value={this.state.d__address__reg__block}
                  onChange={this.onRegBlockChange}
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__flat == "" ? "hidden" : ""
                  }`}
                >
                  Квартира
                </label>
                <input
                  type="text"
                  className="inputUnderLine"
                  placeholder="Квартира"
                  value={this.state.d__address__reg__flat}
                  onChange={this.onRegFlatChange}
                />
              </div>
            </div>
          </div>
        }

        <div className="col-md-12 px-0">
          {true ? null : (
            <React.Fragment>
              <div>
                <div className="">
                  <div className="form-check form-check-inline">
                    <label className="checkboxContainer">
                      Почтовый адрес совпадает с местом регистрации
                      <input
                        type="checkbox"
                        name="samePostal"
                        value={this.state.isSamePostal}
                        checked={this.state.isSamePostal}
                        onChange={this.onPostalSameReg}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div>
                {this.state.isSamePostal && (
                  <div className="formField-container position-relative">
                    <input
                      type="text"
                      className="inputUnderLine"
                      placeholder="Почтовый адрес"
                      style={{ marginTop: -10 }}
                      value={`${this.state.reg__country.name} ${this.state.d__address__reg__postal_code} ${this.state.d__address__reg__region} ${this.state.d__address__reg__area} ${this.state.d__address__reg__city} ${this.state.d__address__reg__street} ${this.state.d__address__reg__house} ${this.state.d__address__reg__block} ${this.state.d__address__reg__flat}`}
                      readOnly
                    />
                  </div>
                )}
                {!this.state.isSamePostal && (
                  <div className="row mt-3">
                    <div className="col-lg-3">
                      <div className="formField-container position-relative">
                        <label className={`floating-label country__select`}>
                          Страна
                        </label>
                        <div className="customSelect">
                          <div
                            className="customSelectedItem"
                            onClick={() => {
                              this.setState({ postal_selectIsOpened: true });
                            }}
                          >
                            {this.state.postal_country.name}
                            <i className="toggle icon-menu-open"></i>
                          </div>
                          {this.state.postal_selectIsOpened && (
                            <ul className="customSelectList">
                              {this.state.countries.map((country, index) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    this.setState({
                                      postal_selectIsOpened: false,
                                    });
                                    this.changeCountry(
                                      index,
                                      "postal_country",
                                      "d__address__postal__country"
                                    );
                                  }}
                                >
                                  {country.name}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="formField-container position-relative">
                        <label
                          className={`floating-label ${
                            this.state.d__address__postal__postal_code == ""
                              ? "hidden"
                              : ""
                          }`}
                        >
                          Индекс
                        </label>
                        <div>
                          <input
                            type="text"
                            className={`inputUnderLine ${
                              this.state.validate &&
                              this.state.d__address__postal__postal_code == ""
                                ? "inputValidateWrong"
                                : ""
                            } ${
                              this.state.validate &&
                              this.state.d__address__postal__postal_code != ""
                                ? "inputValidateCorrect"
                                : ""
                            }`}
                            placeholder="Индекс"
                            value={this.state.d__address__postal__postal_code}
                            onChange={(e) => {
                              this.addressChange(
                                e,
                                "d__address__postal__postal_code"
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="formField-container position-relative">
                        <label
                          className={`floating-label ${
                            this.state.d__address__postal__region == ""
                              ? "hidden"
                              : ""
                          }`}
                        >
                          Область
                        </label>
                        <div className="dadataContainer">
                          <input
                            className={`inputUnderLine ${
                              this.state.validate &&
                              this.state.d__address__postal__region == ""
                                ? "inputValidateWrong"
                                : ""
                            } ${
                              this.state.validate &&
                              this.state.d__address__postal__region != ""
                                ? "inputValidateCorrect"
                                : ""
                            }`}
                            name="region"
                            type="text"
                            placeholder="Область"
                            value={this.state.d__address__postal__region}
                            onChange={(e) => {
                              this.addressChange(
                                e,
                                "d__address__postal__region",
                                "region",
                                "",
                                ""
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="formField-container position-relative">
                        <label
                          className={`floating-label ${
                            this.state.d__address__postal__area == ""
                              ? "hidden"
                              : ""
                          }`}
                        >
                          Район
                        </label>
                        <div className="dadataContainer">
                          <input
                            className={`inputUnderLine`}
                            name="area"
                            type="text"
                            placeholder="Район"
                            value={this.state.d__address__postal__area}
                            onChange={(e) => {
                              this.addressChange(e, "d__address__postal__area");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="formField-container position-relative">
                        <label
                          className={`floating-label ${
                            this.state.d__address__postal__city == ""
                              ? "hidden"
                              : ""
                          }`}
                        >
                          Город
                        </label>
                        <div className="dadataContainer">
                          <input
                            className={`inputUnderLine ${
                              this.state.validate &&
                              this.state.d__address__postal__city == ""
                                ? "inputValidateWrong"
                                : ""
                            } ${
                              this.state.validate &&
                              this.state.d__address__postal__city != ""
                                ? "inputValidateCorrect"
                                : ""
                            }`}
                            name="city"
                            type="text"
                            placeholder="Город"
                            value={this.state.d__address__postal__city}
                            onChange={(e) => {
                              this.addressChange(
                                e,
                                "d__address__postal__city",
                                "city",
                                "region_fias_id",
                                this.state.regionPostalIdValue
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="formField-container position-relative">
                        <label
                          className={`floating-label ${
                            this.state.d__address__postal__street == ""
                              ? "hidden"
                              : ""
                          }`}
                        >
                          Улица
                        </label>
                        <div className="dadataContainer">
                          <input
                            className={`inputUnderLine ${
                              this.state.validate &&
                              this.state.d__address__postal__street == ""
                                ? "inputValidateWrong"
                                : ""
                            } ${
                              this.state.validate &&
                              this.state.d__address__postal__street != ""
                                ? "inputValidateCorrect"
                                : ""
                            }`}
                            name="city"
                            type="text"
                            placeholder="Улица"
                            value={this.state.d__address__postal__street}
                            onChange={(e) => {
                              this.addressChange(
                                e,
                                "d__address__postal__street",
                                "street",
                                "city_fias_id",
                                this.state.cityPostalIdValue
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="formField-container position-relative">
                        <label
                          className={`floating-label ${
                            this.state.d__address__postal__house == ""
                              ? "hidden"
                              : ""
                          }`}
                        >
                          Дом
                        </label>
                        <div className="dadataContainer">
                          <input
                            className={`inputUnderLine ${
                              this.state.validate &&
                              this.state.d__address__postal__house == ""
                                ? "inputValidateWrong"
                                : ""
                            } ${
                              this.state.validate &&
                              this.state.d__address__postal__house != ""
                                ? "inputValidateCorrect"
                                : ""
                            }`}
                            name="house"
                            type="text"
                            placeholder="Дом"
                            value={this.state.d__address__postal__house}
                            onChange={(e) => {
                              this.addressChange(
                                e,
                                "d__address__postal__house"
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="formField-container position-relative">
                        <label
                          className={`floating-label ${
                            this.state.d__address__postal__block == ""
                              ? "hidden"
                              : ""
                          }`}
                        >
                          Корпус
                        </label>
                        <input
                          type="text"
                          className="inputUnderLine"
                          placeholder="Корпус"
                          value={this.state.d__address__postal__block}
                          onChange={(e) =>
                            this.setState({
                              d__address__postal__block: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="formField-container position-relative">
                        <label
                          className={`floating-label ${
                            this.state.d__address__postal__flat == ""
                              ? "hidden"
                              : ""
                          }`}
                        >
                          Квартира
                        </label>
                        <input
                          type="text"
                          className="inputUnderLine"
                          placeholder="Квартира"
                          value={this.state.d__address__postal__flat}
                          onChange={(e) =>
                            this.setState({
                              d__address__postal__flat: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
          {true ? null : (
            <React.Fragment>
              <div>
                <div>
                  <div>
                    <div className="form-check form-check-inline">
                      <label className="checkboxContainer">
                        Место фактического жительства совпадает с местом
                        регистрации
                        <input
                          type="checkbox"
                          name="sameLive"
                          value={this.state.isSameLive}
                          checked={this.state.isSameLive}
                          onChange={this.onLiveSameReg}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  {this.state.isSameLive && (
                    <div className="formField-container position-relative">
                      <input
                        type="text"
                        className="inputUnderLine"
                        placeholder="Почтовый адрес"
                        style={{ marginTop: -10 }}
                        value={`${this.state.reg__country.name} ${this.state.d__address__reg__postal_code} ${this.state.d__address__reg__region} ${this.state.d__address__reg__area} ${this.state.d__address__reg__city} ${this.state.d__address__reg__street} ${this.state.d__address__reg__house} ${this.state.d__address__reg__block} ${this.state.d__address__reg__flat}`}
                        readOnly
                      />
                    </div>
                  )}

                  {!this.state.isSameLive && (
                    <div className="row mt-3">
                      <div className="col-lg-3">
                        <div className="formField-container position-relative">
                          <label className={`floating-label country__select`}>
                            Страна
                          </label>
                          <div className="customSelect">
                            <div
                              className="customSelectedItem"
                              onClick={() => {
                                this.setState({ live_selectIsOpened: true });
                              }}
                            >
                              {this.state.live_country.name}
                              <i className="toggle icon-menu-open"></i>
                            </div>
                            {this.state.live_selectIsOpened && (
                              <ul className="customSelectList">
                                {this.state.countries.map((country, index) => (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      this.setState({
                                        live_selectIsOpened: false,
                                      });
                                      this.changeCountry(
                                        index,
                                        "live_country",
                                        "d__address__live__country"
                                      );
                                    }}
                                  >
                                    {country.name}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="formField-container position-relative">
                          <label
                            className={`floating-label ${
                              this.state.d__address__live__postal_code == ""
                                ? "hidden"
                                : ""
                            }`}
                          >
                            Индекс
                          </label>
                          <div className="dadataContainer">
                            <input
                              type="text"
                              className={`inputUnderLine ${
                                this.state.validate &&
                                this.state.d__address__live__postal_code == ""
                                  ? "inputValidateWrong"
                                  : ""
                              } ${
                                this.state.validate &&
                                this.state.d__address__live__postal_code != ""
                                  ? "inputValidateCorrect"
                                  : ""
                              }`}
                              placeholder="Индекс"
                              value={this.state.d__address__live__postal_code}
                              onChange={(e) => {
                                this.addressChange(
                                  e,
                                  "d__address__live__postal_code"
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="formField-container position-relative">
                          <label
                            className={`floating-label ${
                              this.state.d__address__live__region == ""
                                ? "hidden"
                                : ""
                            }`}
                          >
                            Область
                          </label>
                          <div className="dadataContainer">
                            <input
                              className={`inputUnderLine ${
                                this.state.validate &&
                                this.state.d__address__live__region == ""
                                  ? "inputValidateWrong"
                                  : ""
                              } ${
                                this.state.validate &&
                                this.state.d__address__live__region != ""
                                  ? "inputValidateCorrect"
                                  : ""
                              }`}
                              name="region"
                              type="text"
                              placeholder="Область"
                              value={this.state.d__address__live__region}
                              onChange={(e) => {
                                this.addressChange(
                                  e,
                                  "d__address__live__region",
                                  "region",
                                  "",
                                  ""
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="formField-container position-relative">
                          <label
                            className={`floating-label ${
                              this.state.d__address__live__area == ""
                                ? "hidden"
                                : ""
                            }`}
                          >
                            Район
                          </label>
                          <div className="dadataContainer">
                            <input
                              className={`inputUnderLine`}
                              name="area"
                              type="text"
                              placeholder="Район"
                              value={this.state.d__address__live__area}
                              onChange={(e) => {
                                this.addressChange(e, "d__address__live__area");
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="formField-container position-relative">
                          <label
                            className={`floating-label ${
                              this.state.d__address__live__city == ""
                                ? "hidden"
                                : ""
                            }`}
                          >
                            Город
                          </label>
                          <div className="dadataContainer">
                            <input
                              className={`inputUnderLine ${
                                this.state.validate &&
                                this.state.d__address__live__city == ""
                                  ? "inputValidateWrong"
                                  : ""
                              } ${
                                this.state.validate &&
                                this.state.d__address__live__city != ""
                                  ? "inputValidateCorrect"
                                  : ""
                              }`}
                              name="city"
                              type="text"
                              placeholder="Город"
                              value={this.state.d__address__live__city}
                              onChange={(e) => {
                                this.addressChange(
                                  e,
                                  "d__address__live__city",
                                  "city",
                                  "region_fias_id",
                                  this.state.regionLiveIdValue
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="formField-container position-relative">
                          <label
                            className={`floating-label ${
                              this.state.d__address__live__street == ""
                                ? "hidden"
                                : ""
                            }`}
                          >
                            Улица
                          </label>
                          <div className="dadataContainer">
                            <input
                              className={`inputUnderLine ${
                                this.state.validate &&
                                this.state.d__address__live__street == ""
                                  ? "inputValidateWrong"
                                  : ""
                              } ${
                                this.state.validate &&
                                this.state.d__address__live__street != ""
                                  ? "inputValidateCorrect"
                                  : ""
                              }`}
                              name="city"
                              type="text"
                              placeholder="Улица"
                              value={this.state.d__address__live__street}
                              onChange={(e) => {
                                this.addressChange(
                                  e,
                                  "d__address__live__street",
                                  "street",
                                  "city_fias_id",
                                  this.state.cityLiveIdValue
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="formField-container position-relative">
                          <label
                            className={`floating-label ${
                              this.state.d__address__live__house == ""
                                ? "hidden"
                                : ""
                            }`}
                          >
                            Дом
                          </label>
                          <div className="dadataContainer">
                            <input
                              className={`inputUnderLine ${
                                this.state.validate &&
                                this.state.d__address__live__house == ""
                                  ? "inputValidateWrong"
                                  : ""
                              } ${
                                this.state.validate &&
                                this.state.d__address__live__house != ""
                                  ? "inputValidateCorrect"
                                  : ""
                              }`}
                              name="house"
                              type="text"
                              placeholder="Дом"
                              value={this.state.d__address__live__house}
                              onChange={(e) => {
                                this.addressChange(
                                  e,
                                  "d__address__live__house"
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="formField-container position-relative">
                          <label
                            className={`floating-label ${
                              this.state.d__address__live__block == ""
                                ? "hidden"
                                : ""
                            }`}
                          >
                            Корпус
                          </label>
                          <input
                            type="text"
                            className="inputUnderLine"
                            placeholder="Корпус"
                            value={this.state.d__address__live__block}
                            onChange={(e) =>
                              this.setState({
                                d__address__live__block: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="formField-container position-relative">
                          <label
                            className={`floating-label ${
                              this.state.d__address__live__flat == ""
                                ? "hidden"
                                : ""
                            }`}
                          >
                            Квартира
                          </label>
                          <input
                            type="text"
                            className="inputUnderLine"
                            placeholder="Квартира"
                            value={this.state.d__address__live__flat}
                            onChange={this.onLiveFlatChange}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <button
            className="btn btnBack"
            onClick={() => {
              this.action(
                this.props.previous,
                this.state.prevPage || 2,
                "prev"
              );
            }}
          >
            Назад
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              this.action(this.props.next, this.state.nextPage || 4, "next");
            }}
          >
            Далее
          </button>
        </div>
      </div>
    );
  }
}
