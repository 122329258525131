import axios from 'axios';
import * as Sentry from '@sentry/browser';
import Config from '../config/config';
import { history } from '../routers/AppRouter';

export const checkAuth = () => {
  return axios.get(`${Config.apiUrl}/v1/users/me/`, { withCredentials: true });
};

export const refreshToken = () => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/tokens/refresh/`,
    data: {},
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const login = (values) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/tokens/`,
    data: values,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const logout = () => {
  axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/tokens/remove/`,
    data: {},
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      // Хак для рефреша куков чата. Раньше было history.push('/login');
      history.push('/');
      location.reload(true);
    })
    .catch((err) => {
      if (err.response.data.need_refresh) {
        refreshToken()
          .then((res) => {
            logout();
          })
          .catch((err) => {
            // Хак для рефреша куков чата. Раньше было history.push('/login');
            history.push('/');
            location.reload(true);
          });
      } else {
        // Хак для рефреша куков чата. Раньше было history.push('/login');
        history.push('/');
        location.reload(true);
      }
    });
};
export const restorePassword = (values) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/users/restore/password/`,
    data: values,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const restoreEmail = (values) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/users/restore/`,
    data: values,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const restoreSmsCode = (values) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/users/verify-restore-sms/`,
    data: values,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const getLeadsStatus = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/leads/status`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const getLeverages = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/crypto_params/leverages/`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
export const putLeverages = (values) => {
  return axios({
    method: 'put',
    url: `${Config.apiUrl}/v1/crypto_params/leverages/`,
    data: values,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
export const catchPromise = (err, repeatCb, errorCb) => {
  if (err.response) {
    if ((err.response.status === 401 && err.response.data.need_refresh) || err.response.status === 422) {
      refreshToken()
        .then((res) => {
          repeatCb();
        })
        .catch((err) => {
          console.log(err);
          history.push('/');
        });
    } else if ((err.response.status === 401 && !err.response.data.need_refresh) || err.response.status === 422) {
      history.push('/');
    } else {
      Sentry.captureException(err);
      console.log(err);
      errorCb();
    }
  } else {
    Sentry.captureException(err);
    console.log(err);
    errorCb();
  }
};

export const getProjectSettings = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/content/`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}