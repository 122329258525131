import React from 'react';
import { connect } from 'react-redux';

import HeaderLastNewsComponent from '../components/HeaderLastNews';

export class HeaderLastNews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      news: !props.isError ? props.updates.news : [],
      pageError: false,
    };
  }

  render() {
    return !this.props.isError || !this.state.pageError ? (
      <HeaderLastNewsComponent lastNews={this.state.news} />
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    updates: state.updates,
  };
};

export default connect(mapStateToProps)(HeaderLastNews);
