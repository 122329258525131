import axios from 'axios';
import Config from '../config/config';

export const getFinres = (date_start, date_end, accountId, sort_by, order) => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/trading-accounts/transactions/?trading_account_id=${accountId}&date_start=${date_start}&date_end=${date_end}&sort_by=${sort_by}&order=${order}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const getDailyFinres = (date, accountId) => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/trading-accounts/${accountId}/financial-result/?date=${date}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const getDeals = (page_num, date, accountId) => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/trading-accounts/${accountId}/deals/?page_num=${page_num}&date=${date}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const getLastFinres = (data) => {
  const date = data.date ? `date=${data.date}` : '';
  const accId =
    (data.date ? '&' : '') +
    (data.trading_account_id
      ? 'trading_account_id=' + data.trading_account_id
      : '');
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/trading-accounts/last-financial-result/${
      date || accId ? '?' : ''
    }${date}${accId}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const getCryptoFinres = (startDate, endDate, limit) => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/pnl/?date_start=${startDate.format("DD-MM-YYYY")}&date_end=${endDate.format("DD-MM-YYYY")}${limit ? `&limit=${limit}` : ''}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
