import React from "react";

export const PasswordInstructions = ({ passwordErrorTypes }) => {
  const instructions = [
    { text: "8 и более символов латинского алфавита", type: "length" },
    {
      text: "Минимум 1 символ или знак препинания с клавиатуры",
      type: "special",
    },
    { text: "Минимум 1 заглавная буква", type: "uppercase" },
    { text: "Минимум 1 строчная буква", type: "lowercase" },
    { text: "Не должен совпадать с логином", type: "not_match_email" },
  ];

  return (
    <ul className="text-secondary list-unstyled mt-2">
      {instructions.map((instr) => {
        return (
          <li
            className={`mt-1 ${
              passwordErrorTypes
                ? passwordErrorTypes.includes(instr.type)
                  ? "text-danger"
                  : "text-success"
                : ""
            }`}
            style={{ fontSize: 10 }}
          >
            {instr.text}
          </li>
        );
      })}
    </ul>
  );
};
