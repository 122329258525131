import React, { useState } from 'react';

export const CustomSelectListItem = ({ name, selectedItemsLength, setSelectedItemsLength, id, tag, presenter, setSelectedTags, selectedTags, selectedPresenters, setSelectedPresenters }) => {
  const [isAgreeData, setIsAgreeData] = useState(false);
  if (!selectedItemsLength && isAgreeData) setIsAgreeData(false);

  const onIsAgreeDataChange = () => {
    if (tag) {
      if (isAgreeData) {
        const tagIdIndex = selectedTags.findIndex((selectedTag) => {
          return selectedTag.id === tag.id;
        });
        setSelectedTags([...selectedTags.slice(0, tagIdIndex), ...selectedTags.slice(tagIdIndex + 1)]);
        setSelectedItemsLength(selectedItemsLength - 1);
      } else {
        setSelectedTags([...selectedTags, tag]);
        setSelectedItemsLength(selectedItemsLength + 1);
      }
    }
    else if (presenter) {
      if (isAgreeData) {
        const presenterIdIndex = selectedPresenters.findIndex((selectedPresenter) => {
          return selectedPresenter.id === presenter.id;
        });
        setSelectedPresenters([...selectedPresenters.slice(0, presenterIdIndex), ...selectedPresenters.slice(presenterIdIndex + 1)]);
        setSelectedItemsLength(selectedItemsLength - 1);
      } else {
        setSelectedPresenters([...selectedPresenters, presenter]);
        setSelectedItemsLength(selectedItemsLength + 1);
      }
    }
    setIsAgreeData(!isAgreeData);
  }

  return (
    <li className={`customSelectList-item customSelectItem-${id}`} onClick={(e) => {
      e.stopPropagation();
      if (e.target.type !== "checkbox") onIsAgreeDataChange();
    }}>
      <label className={`checkboxContainer customSelectItem-${id}`}>
        <input
          type="checkbox"
          name="samePostal"
          className={`customSelectItem-${id}`}
          value={isAgreeData}
          checked={isAgreeData}
          onChange={(e) => e.preventDefault()}
        />
        <span className={`checkmarkContainer customSelectItem-${id}`}>
          <span className={`checkmark customSelectItem-${id}`}></span>
          <span className={`checkmarkText customSelectItem-${id}`}>{name}</span>
        </span>
      </label>
    </li>
  )
}

export default CustomSelectListItem; 