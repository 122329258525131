import { SET_ANALYSIS_DAYS } from '../actions/analysis';

// Expenses Reducer
const reducerDefaultState = {
  data: [],
  days_total_abs: [],
  days_total_percent: [],
};

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_ANALYSIS_DAYS:
      return action.analysis;
    default:
      return state;
  }
};
