import React, { useCallback, useEffect, useState } from "react";
import "moment/locale/ru";
import Baron from "react-baron/dist/es5";
import { connect } from "react-redux";
import { createDocument } from "../utils/bonusesHttp";
import { Link, Redirect } from "react-router-dom";
import amplitude from 'amplitude-js';

import { history } from "../routers/AppRouter";
import WAValidator from "@getdelta/wallet-address-validator";
import { getCryptoParams } from "../utils/withdrawsHttp";
import { getTradingAccounts } from "../utils/transactionsHttp";
import { setTradingAccounts } from "../actions/tradingAccounts";
import { setTradingAccount } from "../actions/tradingAccount";
import cookie from 'react-cookies';
import GoogleAuthWrongCodeModal from "./GoogleAuthWrongCodeModal";

export const WithdrawAssetsCreate = (props) => {
  const [sum, setSum] = useState("");
  const [sumError, setSumError] = useState("");
  const [sumSuccess, setSumSuccess] = useState("");
  const [wallet, setWallet] = useState("");
  const [walletError, setWalletError] = useState("");
  const [walletSuccess, setWalletSuccess] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState("");
  const [cryptoParams, setCryptoParams] = useState([]);
  const [currencyValue, setCurrencyValue] = useState({});
  const [currencySelectIsOpened, setCurrencySelectIsOpened] = useState(false);
  const [networkValue, setNetworkValue] = useState({});
  const [networkValuesList, setNetworkValuesList] = useState([]);
  const [networkSelectIsOpened, setNetworkSelectIsOpened] = useState(false);
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const [warningHasBeenShown, setWarningHasBeenShown] = useState(false);
  const [nextButtonDisabledSeconds, setNextButtonDisabledSeconds] = useState(0);
  const [step, setStep] = useState(1);
  const [authModalIsOpened, setAuthModalIsOpened] = useState(false);
  const [otp_password, setOtpPassword] = useState("");

  const networkConverter = {
    BEP2: "BNB (BEP2)",
    BEP20: "BSC (BEP20)",
    ERC20: "ETH (ERC20)",
    TRC20: "TRX (TRC20)",
  };

  useEffect(() => {
    amplitude.getInstance().logEvent('withdraw_assets_create_page.page_view');

    getCryptoParams().then((res) => {
      setCryptoParams(res.data.crypto_params);
      setCurrencyValue(res.data.crypto_params[0]);

      const networkValuesList = Object.entries(
        res.data.crypto_params[0].params
      ).map(([key, value]) => ({
        value: key,
        title: networkConverter[key],
        ...value,
      }));
      setNetworkValue(networkValuesList[0]);
      setNetworkValuesList(networkValuesList);
    });
  }, []);

  useEffect(() => {
    if (currencyValue.params) {
      const networkValuesList = Object.entries(currencyValue.params).map(
        ([key, value]) => ({
          value: key,
          title: networkConverter[key],
          ...value,
        })
      );
      setNetworkValue(networkValuesList[0]);
      setNetworkValuesList(networkValuesList);
    }
  }, [currencyValue]);

  useEffect(() => {
   debouncedValidateWallet(wallet, networkValue);
   debouncedValidateSum(sum, props.tradingAccount, networkValue);
  }, [networkValue]);

  useEffect(() => {
    if (nextButtonDisabledSeconds >= 1) {
      setTimeout(() => {
        setNextButtonDisabledSeconds(nextButtonDisabledSeconds - 1);
      }, 1000);
    }
  }, [nextButtonDisabledSeconds]);

  const onOtpChangePassword = (e) => {
    const value = e.target.value
      .split("")
      .filter((c) =>
        ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(c)
      )
      .slice(0, 6)
      .join("");

    setOtpPassword(value);
  }

  const onSubmit = () => {
    if (!validating()) return;
    const data = {
      trading_account_id: +props.tradingAccount.id,
      amount: +sum,
      wallet_address: wallet,
      type: 1,
      network: networkValue.value,
      currency: currencyValue.ticker,
    };
    if (props.user.user.two_factor_auth) {
      data.otp_password = otp_password;
    }

    createDocument(data)
      .then((res) => {
        // history.push(`/app/withdraw-assets/${res.data.id}`);
        setStep(3);
        setError("");
        getTradingAccounts().then((res) => {
          let account = {};
          if (
            cookie.load("accountObj") &&
            res.data.trading_accounts.find(
              (acc) => acc.id === cookie.load("accountObj").id
            )
          ) {
            account = res.data.trading_accounts.find(
              (acc) => acc.id === cookie.load("accountObj").id
            );
          } else {
            res.data.trading_accounts.length > 0 &&
              (account =
                res.data.trading_accounts.find(
                  (tradingAccount) => !tradingAccount.bonus
                ) || res.data.trading_accounts[0]);
          }

          props.setTradingAccounts(res.data);
          props.setTradingAccount(account);
          cookie.save("accountObj", account);
        });
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  const onNextBtnClick = () => {
    if (!validating() || modalIsOpened) return;
    if (currencyValue.ticker !== "BNB" && networkValue.value === "BEP20" && !warningHasBeenShown) {
      setModalIsOpened(true);
      setWarningHasBeenShown(true);
      return;
    }
    setStep(step + 1);
  };

  const validateBEP2 = (value) => {
    return typeof value === "string" && /^(bnb1)[0-9a-z]{38}$/.test(value);
  };

  const debounce = useCallback((func, ms) => {
    let timeout;
    return function() {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, arguments), ms);
    };
  }, [])

  const validateWallet = useCallback((wallet, networkValue) => {
    const networkMapper = {
      TRX: "tron",
      TRC20: "tron",
      ETH: "ethereum",
      BEP20: "ethereum",
      ERC20: "ethereum",
    };
    const valid =
      networkValue.value === "BEP2"
        ? validateBEP2(wallet)
        : WAValidator.validate(wallet, networkMapper[networkValue.value]);
    if (!wallet) {
      setWalletError("");
      setWalletSuccess("");
    } else if (!valid) {
      setWalletError("Невалидный адрес кошелька. Проверьте кошелек на соответствие условиям")
      setWalletSuccess("");
      return false;
    } else {
      setWalletError("");
      setWalletSuccess("Кошелек валидный");
    }
  }, [])

  const validateSum = useCallback((sum, tradingAccount, networkValue) => {
    if (!sum) {
      setSumError("");
      setSumSuccess("");
      return false;
    } else if (
      !isNaN(sum) &&
      ((currencyValue.ticker !== "BNB" &&
        +sum > +tradingAccount.balance.trading) ||
        (currencyValue.ticker === "BNB" &&
          +sum > +tradingAccount.bnb_balance))
    ) {
      setSumError("Недостаточно средств для вывода");
      setSumSuccess("");
      return false;
    }
    if (!isNaN(sum) && +sum - networkValue.withdraw_fee < 0) {
      setSumError("Сумма должна быть больше комиссии");
      setSumSuccess("");
      return false;
    }
    if (!isNaN(sum) && +sum < networkValue.withdraw_min) {
      setSumError("Сумма должна быть больше минимальной суммы");
      setSumSuccess("");
      return false;
    } else {
      setSumError("");
      setSumSuccess("Сумма введена корректна");
      return false;
    }
  }, [])

  const debouncedValidateSum = useCallback(debounce(validateSum, 2000), []);

  const debouncedValidateWallet = useCallback(debounce(validateWallet, 2000), []);

  const onWalletChange = (e) => {
    const wallet = e.target.value.split(" ").join("");
    debouncedValidateWallet(wallet, networkValue);
    setWallet(wallet);
  };

  const onSumChange = (e) => {
    const sum = e.target.value;
    debouncedValidateSum(sum, props.tradingAccount, networkValue);
    if (!sum || sum.match(/^[0-9]+$/)) {
      setSum(sum);
    }
  };

  const validating = () => {
    if (!sum) {
      setError("Заполните сумму");
      return false;
    }
    if (!wallet) {
      setError('Заполните поле "Кошелёк"');
      return false;
    }

    const networkMapper = {
      TRX: "tron",
      TRC20: "tron",
      ETH: "ethereum",
      BEP20: "ethereum",
      ERC20: "ethereum",
    };
    const valid =
      networkValue.value === "BEP2"
        ? validateBEP2(wallet)
        : WAValidator.validate(wallet, networkMapper[networkValue.value]);
    if (!valid) {
      setError("Адрес кошелька не является валидным");
      return false;
    }
    if (
      !isNaN(sum) &&
      ((currencyValue.ticker !== "BNB" &&
        +sum > +props.tradingAccount.balance.trading) ||
        (currencyValue.ticker === "BNB" &&
          +sum > +props.tradingAccount.bnb_balance))
    ) {
      setError("Недостаточно средств для вывода");
      return false;
    }
    if (!isNaN(sum) && +sum - networkValue.withdraw_fee < 0) {
      setError("Сумма должна быть больше комиссии");
      return false;
    }
    if (!isNaN(sum) && +sum < networkValue.withdraw_min) {
      setError("Сумма должна быть больше минимальной суммы");
      return false;
    }
    setError("");
    return true;
  };

  return (
    <React.Fragment>
      {
      !props.tradingAccounts.trading_accounts.find((acc) => !acc.bonus) ? (
        <Redirect to="/app" />
      ) : (
        <div className="documentCreatePage">
          {modalIsOpened ? (
            <div>
              <div className="documentCreatePage-modal">
                Убедитесь, что вы не ввели адрес ERC20 в качестве адреса для
                вывода BEP20. В противном случае вы потеряете деньги.
                <div
                  className="btn btn-success w-80 text-align-center"
                  onClick={() => {
                    setNextButtonDisabledSeconds(5);
                    setModalIsOpened(false);
                  }}
                >
                  Ок
                </div>
              </div>
            </div>
          ) : null}
          {authModalIsOpened ? (
            <GoogleAuthWrongCodeModal
              modalIsOpened={authModalIsOpened}
              closeModal={() => setAuthModalIsOpened(false)}
            />
          ) : null}
          <form onSubmit={onSubmit}>
            <h1 className="title card-content">
              {step === 1
                ? "Создание запроса"
                : step === 2
                ? "Подтвердите запрос"
                : step === 3
                ? "Запрос создан"
                : null}
            </h1>
            <div className="card card-content">
              <div>
                <div className="card-body">
                  <div
                    className={`docCreateContent ${
                      step > 1 ? "readyStep" : ""
                    }`}
                  >
                    <Baron>
                      <h3>Cпотовый счет -&gt; Личный кошелек</h3>
                      {step === 1 ? (
                        <React.Fragment>
                          <div className="row justify-content-between">
                            <div className="docCreateChoice mt-2 col-5">
                              <div className="docCreateChoice-title">
                                Валюта
                              </div>
                              <div className="docCreateChoice-select">
                                <div className="customSelect w-100">
                                  <div
                                    className="customSelectedItem"
                                    onClick={() => {
                                      setCurrencySelectIsOpened(
                                        !currencySelectIsOpened
                                      );
                                    }}
                                  >
                                    {currencyValue.ticker}
                                    <i className="toggle icon-arrow-down5"></i>
                                  </div>
                                  {currencySelectIsOpened && (
                                    <ul className="customSelectList">
                                      <li
                                        onClick={() => {
                                          setCurrencyValue(
                                            cryptoParams.find(
                                              (cryptoParam) =>
                                                cryptoParam.ticker === "BNB"
                                            )
                                          );
                                          setCurrencySelectIsOpened(false);
                                        }}
                                      >
                                        BNB
                                      </li>
                                      <li
                                        onClick={() => {
                                          setCurrencyValue(
                                            cryptoParams.find(
                                              (cryptoParam) =>
                                                cryptoParam.ticker === "USDT"
                                            )
                                          );
                                          setCurrencySelectIsOpened(false);
                                        }}
                                      >
                                        USDT
                                      </li>
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="docCreateChoice mt-2 col-5">
                              <div className="docCreateChoice-title">Сеть</div>
                              <div className="docCreateChoice-select">
                                <div className="customSelect w-100">
                                  <div
                                    className="customSelectedItem"
                                    onClick={() => {
                                      setNetworkSelectIsOpened(
                                        !networkSelectIsOpened
                                      );
                                    }}
                                  >
                                    {networkValue.title}
                                    <i className="toggle icon-arrow-down5"></i>
                                  </div>
                                  {networkSelectIsOpened && (
                                    <ul className="customSelectList">
                                      {console.log(networkValuesList)}
                                      {networkValuesList.map(
                                        ({ title, value }) => {
                                          return (
                                            <li
                                              key={title}
                                              onClick={() => {
                                                setNetworkValue(networkValuesList.find(n => n.value === value));
                                                setNetworkSelectIsOpened(false);
                                              }}
                                            >
                                              {title}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="position-relative col-8">
                              <label
                                className={`floating-label`}
                                style={{ paddingLeft: "0.625rem" }}
                              >
                                Адрес для пополнения
                              </label>
                              <input
                                type="text"
                                className="inputUnderLine w-100"
                                placeholder="Кошелёк..."
                                value={wallet}
                                onChange={onWalletChange}
                                style={{ width: "300px !important" }}
                              />
                              <div className={`${walletError ? "text-danger" : "text-success"}`}>
                                {walletError ? walletError : walletSuccess ? (
                                  <div>
                                    {walletSuccess}
                                    <img style={{ marginLeft: 5 }} src="/images/ui/check-icon-black.png" className="filterToGreen" alt="Ready" height="12px" />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="position-relative col-8">
                              <label
                                className={`floating-label`}
                                style={{ paddingLeft: "0.625rem" }}
                              >
                                Сумма перевода
                              </label>
                              <input
                                type="text"
                                className="inputUnderLine w-100"
                                placeholder="Сумма..."
                                value={sum}
                                onChange={onSumChange}
                                style={{ width: "300px !important" }}
                              />
                              <div className={`${sumError ? "text-danger" : "text-success"}`}>
                                {sumError ? sumError : sumSuccess ? sumSuccess : null}
                              </div>
                              <div
                                className="text-secondary d-flex"
                                style={{ fontSize: "9px", marginTop: "4px" }}
                              >
                                Минимальная сумма: {networkValue.withdraw_min}
                                {currencyValue.ticker}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4 justify-content-between">
                            <div className="col-5 mt-2">
                              <div>Комиссия за перевод</div>
                              <div>
                                {networkValue.withdraw_fee}
                                {currencyValue.ticker}
                              </div>
                              <div
                                className="mt-2 text-secondary"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Рассчитывается автоматически
                              </div>
                            </div>
                            <div className="col-5 mt-2">
                              <div>Пополнение с учетом комиссии</div>
                              <div className="mt-2 h3">
                                {sum - networkValue.withdraw_fee < 0 ? 0 : sum - networkValue.withdraw_fee}
                                {currencyValue.ticker}
                              </div>
                            </div>
                          </div>
                          {!props.user.user.two_factor_auth ? (
                            <div className="position-relative ml-3 pl-2 d-flex align-items-start text-danger mt-4">
                              <img className="forbiddenIcon" src="/images/ui/forbidden.png" style={{ left: "-14px" }} />
                              <div>
                                Внешние переводы недоступны без двухфакторной аутентификации. 
                                Подключите Google Authentificator в разделе{' '}
                                <Link to="/app/profile" className="text-danger" style={{ textDecoration: "underline" }}>
                                  профиль
                                </Link>
                              </div>
                            </div>
                          ) : null}
                        </React.Fragment>
                      ) : step === 2 || step === 3 ? (
                        <React.Fragment>
                          <div className="h3">
                            Перевод на сумму: {sum - networkValue.withdraw_fee}{" "}
                            {currencyValue.ticker}
                          </div>
                          <div>
                            В том числе комиссия {networkValue.withdraw_fee}{" "}
                            {currencyValue.ticker}
                          </div>
                          <div className="mt-2">Кошелек: {wallet}</div>
                          <div>Сеть: {networkValue.title}</div>
                          {props.user.user.two_factor_auth && step === 2 ? (
                            <div className="mt-4">
                              <div className="h3">Введите код подтверждения</div>
                              <div className="position-relative">
                                <div>6-значный код из приложения для двухэтапной верификации</div>
                                <input
                                  className={`inputUnderLine`}
                                  value={otp_password}
                                  onChange={onOtpChangePassword}
                                />
                              </div>
                              <div
                                className="codeNoMatch p-0"
                                onClick={() => setAuthModalIsOpened(true)}
                              >
                                Код не подходит
                              </div>
                            </div>
                          ) : null}
                          {step === 3 ? (
                            <React.Fragment>
                              <div
                                style={{ marginLeft: "-30px" }}
                                className="mt-4 d-flex"
                              >
                                <img
                                  src="/images/ui/warn-icon.png"
                                  width="20px"
                                  height="20px"
                                  style={{ marginRight: "10px" }}
                                />
                                <span>
                                  Запросы на вывод модерируются. Если
                                  потребуется, мы свяжемся с вами для
                                  подтверждения.
                                </span>
                              </div>
                              <div className="mt-2">
                                Зачисление происходит в течение N дней.
                              </div>
                              <div className="mt-2">
                                Статус запрос и историю списаний можно найти в
                                разделе{" "}
                                <a href="/app/withdraw-assets">
                                  внешние переводы
                                </a>
                                .
                              </div>
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>
                      ) : null}
                      {error && (
                        <h6 className="text-center text-danger mt-2">
                          {error}
                        </h6>
                      )}
                    </Baron>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="btnSubmits card-content d-flex justify-content-between align-items-start position-static">
            {step === 1 ? (
              <Link to="/app/withdraw-assets" className="btn btn-white">
                Назад
              </Link>
            ) : step !== 3 ? (
              <div className="btn btn-white" onClick={() => setStep(step - 1)}>
                Назад
              </div>
            ) : null}
            {step === 1 ? (
              <div className="text-right">
                <button
                  className="btn btn-success"
                  disabled={props.isSending || nextButtonDisabledSeconds || !props.user.user.two_factor_auth}
                  onClick={(e) => {
                    onNextBtnClick(e);
                  }}
                >
                  Далее
                  {isSending && (
                    <i className="icon-spinner2 uploaderSpinner spinner"></i>
                  )}
                </button>
                {nextButtonDisabledSeconds ? (
                  <div className="text-danger">
                    Вы сможете продолжить через: {nextButtonDisabledSeconds}
                  </div>
                ) : null}
              </div>
            ) : step === 2 ? (
              <button
                className="btn btn-success"
                disabled={isSending || otp_password.length < 6}
                onClick={(e) => onSubmit(e)}
              >
                Создать
                {isSending && (
                  <i className="icon-spinner2 uploaderSpinner spinner"></i>
                )}
              </button>
            ) : null}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  tradingAccounts: state.tradingAccounts,
  tradingAccount: state.tradingAccount,
});

const mapDispatchToProps = (dispatch) => ({
  setTradingAccounts: (tradingAccounts) =>
    dispatch(setTradingAccounts(tradingAccounts)),
  setTradingAccount: (tradingAccount) =>
    dispatch(setTradingAccount(tradingAccount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawAssetsCreate);
