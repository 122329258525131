import { SET_TRADING_ACCOUNTS } from '../actions/tradingAccounts';

// Expenses Reducer
const reducerDefaultState = {};

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_TRADING_ACCOUNTS:
      return action.tradingAccounts;
    default:
      return state;
  }
};
