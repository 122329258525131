import React from "react";

export const BroadcastsFiltersDiscard = ({ discardHandler, active, total }) => {
  return (
    <div className="broadcastsDiscard">
      <div className="broadcastsMasters-total">Найдено: {total}</div>
      <div
        className={`broadcastsDiscardBtn ${active ? "active" : ""}`}
        onClick={active ? discardHandler : null}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.2378 1.7625C9.15031 0.675 7.65781 0 6.00031 0C2.68531 0 0.0078125 2.685 0.0078125 6C0.0078125 9.315 2.68531 12 6.00031 12C8.79781 12 11.1303 10.0875 11.7978 7.5H10.2378C9.62281 9.2475 7.95781 10.5 6.00031 10.5C3.51781 10.5 1.50031 8.4825 1.50031 6C1.50031 3.5175 3.51781 1.5 6.00031 1.5C7.24531 1.5 8.35531 2.0175 9.16531 2.835L6.75031 5.25H12.0003V0L10.2378 1.7625Z"
            fill={active ? "#1968EF" : "#929292"}
          />
        </svg>
        <span>Сбросить</span>
      </div>
    </div>
  );
};

export default BroadcastsFiltersDiscard;