import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { history } from './AppRouter';
import { checkAuth, getProjectSettings, refreshToken } from '../utils/http';
import { setSettings } from '../actions/settings';

class NotAuthRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuth: undefined,
      loaded: false,
    };
  }

  componentDidMount() {
    var requireSsoToken = false;
    var redirectUrl = null;

    var url = new URL(window.location.href);

    var redirectUE = url.searchParams.get('redirect_ue');

    if (redirectUE) {
      redirectUrl = redirectUE;
      requireSsoToken = true;
    }

    checkAuth()
      .then((res) => {
        if (redirectUrl) {
          if (requireSsoToken) {
            redirectUrl = redirectUrl + '?sso_token=' + res.data.sso_token;
          }
          window.location.assign(redirectUrl);
        } else {
          let params = new URLSearchParams(window.location.search);
          if (!params.has('as_user')) {
            this.setState({ isAuth: true, loaded: true });
            history.push('/app/');
          } else {
            this.setState({ isAuth: false, loaded: true });
          }
        }
      })
      .catch((err) => {
        if (err.response.data.need_refresh) {
          refreshToken()
            .then((res) => {
              if (redirectUrl) {
                if (requireSsoToken) {
                  redirectUrl =
                    redirectUrl + '?sso_token=' + res.data.sso_token;
                }
                window.location.assign(redirectUrl);
              } else {
                this.setState({ isAuth: true, loaded: true });
                history.push('/app/');
              }
            })
            .catch((err) => {
              history.push('/');
              this.setState({ isAuth: false, loaded: true });
            });
        } else {
          this.setState({ isAuth: false, loaded: true });
        }
      })
      .finally(() => {
        getProjectSettings().then(res => this.props.setSettings(res.data.settings));
      })
  }
  render() {
    const { path, exact, component: Component } = this.props;

    return this.state.isAuth === false && this.state.loaded ? (
      <Route
        path={path}
        exact={exact}
        component={(props) => <Component {...props} />}
      />
    ) : null;
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  setSettings: (settings) => dispatch(setSettings(settings))
});

export default connect(mapStateToProps, mapDispatchToProps)(NotAuthRoute);
