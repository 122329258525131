import React from 'react';
import moment from 'moment';

import { catchPromise } from '../utils/http';
import { getDocuments } from '../utils/documentsHttp';

export default class BroadcastsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    this.loadDocuments();
  }
  loadDocuments = () => {
    getDocuments()
      .then((res) => {
        this.setState({ documents: res.data.docs });
      })
      .catch((err) => {
        catchPromise(err, this.loadBroadcasts, this.errorCb);
      });
  };
  render() {
    return (
      <div className="documents">
        {!this.state.pageError ? (
          <div className="card">
            <div className="card-header">
              <h2 className="card-title text-center">Документы</h2>
            </div>
            <div className="card-body">
              <table className="table table-bordered">
                <tbody>
                  {this.state.documents.map((document, index) => (
                    <tr key={index}>
                      <td>
                        <p className="documentName">{document.name}</p>
                        <p className="documentDate">
                          {moment(document.date, 'YYYY-MM-DD').format(
                            'DD.MM.YYYY'
                          )}
                        </p>
                      </td>
                      <td className="documentDownloadBtn">
                        <a href={document.url} className="btn btn-primary">
                          Скачать
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
      </div>
    );
  }
}
