import React, { useEffect, useState } from "react";
import Baron from "react-baron/dist/es5";

export const CustomSelect = ({
  title,
  children,
  id,
  selectedTags,
  selectedPresenters,
  setSelectedPresenters,
  setSelectedTags,
  type,
}) => {
  const [selectIsOpened, setSelectIsOpened] = useState(false);
  const [selectedItemsLength, setSelectedItemsLength] = useState(0);
  const [width, setWidth] = useState(null);
  if (selectedTags.length === 0 && selectedItemsLength && id !== 0)
    setSelectedItemsLength(0);
  if (
    selectedPresenters &&
    selectedPresenters.length === 0 &&
    selectedItemsLength &&
    id === 0
  )
    setSelectedItemsLength(0);

  const resetFilter = () => {
    if (selectedPresenters) {
      setSelectedPresenters([]);
      setSelectedItemsLength(0);
      return;
    }
    setSelectedTags(selectedTags.filter((tag) => tag.type !== type));
    setSelectedItemsLength(0);
  };

  useEffect(() => {
    const closeMenu = (e) => {
      if (!e.target.classList.contains(`customSelectItem-${id}`))
        if (!selectIsOpened)
          setSelectIsOpened(
            e.target.classList.contains(`customSelectItem-${id}`)
          );
        else setSelectIsOpened(false);
    };
    document.body.addEventListener("click", closeMenu);
    setWidth(
      id === 0
        ? 126 + 24
        : id === 1
        ? 126 + 24
        : id === 2
        ? 130 + 24
        : id === 3
        ? 120 + 24
        : id === 4
        ? 120 + 24
        : null
    );
    return () => {
      document.body.removeEventListener("click", closeMenu);
    };
  }, []);

  return (
    <div
      style={{ width }}
      className={`customSelect ${
        selectedItemsLength ? "customSelectActive" : ""
      } ${selectIsOpened ? "isShownList" : ""} customSelectItem-${id}`}
    >
      <div
        className={`customSelectedItem customSelectItem-${id}`}
        onClick={() => setSelectIsOpened(!selectIsOpened)}
      >
        <div className={`customSelectItem-${id}`}>
          {title}
          {selectedItemsLength ? ` (${selectedItemsLength})` : ""}
        </div>
        <CustomSelectDiscard
          resetFilter={resetFilter}
          active={Boolean(selectedItemsLength)}
        />
        <i
          className={`toggle icon-arrow-down5 customSelect-arrowDown customSelectItem-${id}`}
        ></i>
        <i
          className={`toggle icon-arrow-up5 customSelect-arrowUp customSelectItem-${id}`}
        ></i>
      </div>
      <ul className={`customSelectList customSelectItem-${id}`}>
        <Baron>
          {children &&
            React.Children.map(children, (child) =>
              React.cloneElement(child, {
                selectedItemsLength,
                setSelectedItemsLength,
              })
            )}
        </Baron>
      </ul>
    </div>
  );
};

const CustomSelectDiscard = ({ active, resetFilter }) => {
  return (
    <div
      className={`customSelectDiscard ${active ? "active" : ""}`}
      onClick={resetFilter}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="#2E3C47"
          d="M6.35 17.65A7.958 7.958 0 0012 20c4.42 0 7.99-3.58 7.99-8S16.42 4 12 4c-3.73 0-6.84 2.55-7.73 6h2.08C7.17 7.67 9.39 6 12 6c3.31 0 6 2.69 6 6s-2.69 6-6 6c-1.66 0-3.14-.69-4.22-1.78L11 13H4v7l2.35-2.35z"
        ></path>
      </svg>
    </div>
  );
};

export default CustomSelect;
