import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default class HeaderLastNews extends Component {
  render() {
    const { lastNews } = this.props;
    return (
      <div className="page-header page-header-light">
        <div className="page-header-content header-elements-md-inline">
          <div className="mb-1 mt-1 headerNewsNote">
            <h4 className="font-weight-semibold mb-1 text-secondary">
              Последние новости
            </h4>
          </div>
          {lastNews
            ? lastNews.map((newsNote, index) => (
                <div className="mb-1 mt-1 headerNewsNote" key={newsNote.id}>
                  <p className="font-weight-semibold mb-1">
                    <Link
                      to={`/app/news#${newsNote.id}`}
                      className="text-default"
                    >
                      {newsNote.title}
                    </Link>
                  </p>
                  <div className="list-inline list-inline-dotted">
                    <span className="list-inline-item">
                      {moment(newsNote.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                    </span>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    );
  }
}

HeaderLastNews.propTypes = {
  lastNews: PropTypes.array,
};
