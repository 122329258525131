import React from "react";

import { catchPromise } from "../utils/http";
import { uploadFile } from "../utils/anketaHttp";

const isCrypto = true;

export default class AnketaForeignDocs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageError: false,
      validate: false,
      files__passport: props.data.files__passport || "",
      files__passportName: "",
      files__passportLink: "",
      files__passportError: "",
      files__passportUploading: false,
      files__passportValue: "",

      files__reg_place: props.data.files__reg_place || "",
      files__reg_placeName: "",
      files__reg_placeLink: "",
      files__reg_placeError: "",
      files__reg_placeUploading: false,
      files__reg_placeValue: "",

      files__inn: props.data.files__inn || "",
      files__innName: "",
      files__innLink: "",
      files__innError: "",
      files__innUploading: false,
      files__innValue: "",

      files__bank_details: props.data.files__bank_details || "",
      files__bank_detailsName: "",
      files__bank_detailsLink: "",
      files__bank_detailsError: "",
      files__bank_detailsUploading: false,
      files__bank_detailsValue: "",

      files__other: props.data.files__other || "",
      files__otherName: "",
      files__otherLink: "",
      files__otherError: "",
      files__otherUploading: false,
      files__otherValue: "",
    };
  }
  errorCb = () => {
    this.setState({
      files__passportUploading: false,
      files__reg_placeUploading: false,
      files__innUploading: false,
      files__bank_detailsUploading: false,
      files__otherUploading: false,
    });
  };

  action = (action, currIndex, dest) => {
    if (dest === "prev") this.props.action(action, currIndex, "docs", {});
  };

  sendAnketa = () => {
    this.setState({ validate: true });
    if (this.validating()) {
      const cryptoData = {
        files__passport: this.state.files__passport,
        files__reg_place: this.state.files__reg_place,
        files__other: this.state.files__other,
      }

      this.props.onSubmit(isCrypto ? cryptoData : {
        files__passport: this.state.files__passport,
        files__reg_place: this.state.files__reg_place,
        files__inn: this.state.files__inn,
        files__bank_details: this.state.files__bank_details,
        files__other: this.state.files__other,
      });
    }
  };
  validating = () => {
    if (isCrypto) {
      return (
        this.state.files__passport != "" &&
        this.state.files__reg_place != ""
      );
    }
    return (
      this.state.files__passport != "" &&
      this.state.files__reg_place != "" &&
      this.state.files__inn != ""
    );
  };

  onPassportImgChange = (e) => {
    const files__passport = e.target.files[0];
    var formData = new FormData();
    formData.append("file", files__passport, files__passport.name);
    this.setState({ files__passport: "", files__passportUploading: true });

    this.onUploadFile(
      formData,
      files__passport.name,
      "files__passport",
      "files__passportUploading",
      "files__passportError",
      "files__passportName",
      "files__passportLink"
    );
  };

  onRegPlaceChange = (e) => {
    const files__reg_place = e.target.files[0];
    var formData = new FormData();
    formData.append("file", files__reg_place, files__reg_place.name);
    this.setState({ files__reg_place: "", files__reg_placeUploading: true });

    this.onUploadFile(
      formData,
      files__reg_place.name,
      "files__reg_place",
      "files__reg_placeUploading",
      "files__reg_placeError",
      "files__reg_placeName",
      "files__reg_placeLink"
    );
  };

  onBankDetailsChange = (e) => {
    const files__bank_details = e.target.files[0];
    var formData = new FormData();
    formData.append("file", files__bank_details, files__bank_details.name);
    this.setState({
      files__bank_details: "",
      files__bank_detailsUploading: true,
    });

    this.onUploadFile(
      formData,
      files__bank_details.name,
      "files__bank_details",
      "files__bank_detailsUploading",
      "files__bank_detailsError",
      "files__bank_detailsName",
      "files__bank_detailsLink"
    );
  };

  onFilesOtherChange = (e) => {
    const files__other = e.target.files[0];
    var formData = new FormData();
    formData.append("file", files__other, files__other.name);
    this.setState({ files__other: "", files__otherUploading: true });

    this.onUploadFile(
      formData,
      files__other.name,
      "files__other",
      "files__otherUploading",
      "files__otherError",
      "files__otherName",
      "files__otherLink"
    );
  };

  onInnChange = (e) => {
    const files__inn = e.target.files[0];
    var formData = new FormData();
    formData.append("file", files__inn, files__inn.name);
    this.setState({ files__inn: "", files__innUploading: true });

    this.onUploadFile(
      formData,
      files__inn.name,
      "files__inn",
      "files__innUploading",
      "files__innError",
      "files__innName",
      "files__innLink"
    );
  };

  onUploadFile = (
    formData,
    fileName,
    file,
    uploading,
    erroring,
    naming,
    linkField
  ) => {
    uploadFile(formData)
      .then((res) => {
        this.setState({
          [file]: res.data.file_id,
          [naming]: fileName,
          [linkField]: res.data.url,
          [uploading]: false,
          [erroring]: "",
        });
      })
      .catch((err) => {
        if (err.response.data && err.response.status != 401)
          this.setState({ [erroring]: err.response.data.message });
        catchPromise(
          err,
          this.onUploadFile.bind(
            this,
            formData,
            fileName,
            file,
            uploading,
            erroring,
            naming,
            linkField
          ),
          this.errorCb
        );
      });
  };

  onRemoveFile = (file) => {
    this.setState({ [file]: "" });
    document.getElementById(file + "Field").value = "";
  };

  render() {
    return (
      <div className="profileDocs">
        <h2 className="subtitle">Загрузка документов</h2>
        <div className="row">
          <table className="table col-12">
            <tbody>
              <tr>
                <td className="adaptiveCell">
                  <div
                    className={`profileDocs-uploaderTitle ${
                      this.state.files__passport != "" &&
                      this.state.files__passportError == ""
                        ? "text-success"
                        : ""
                    } ${
                      (this.state.validate &&
                        this.state.files__passport == "") ||
                      this.state.files__passportError != ""
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    Паспорт (разворот с фото)
                    {!this.state.files__passportUploading &&
                      this.state.files__passport != "" &&
                      this.state.files__passportError == "" && (
                        <i className="icon-checkmark3 uploaderTitleCheck"></i>
                      )}
                  </div>
                  <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                    <input
                      type="file"
                      id="files__passportField"
                      className="form-control-uniform-custom"
                      accept=".png, .jpg, .pdf, .jpeg"
                      disabled={this.state.files__passportUploading}
                      onChange={(e) => this.onPassportImgChange(e)}
                    />
                    {!this.state.files__passportUploading &&
                      this.state.files__passport == "" && (
                        <span>
                          Загрузить
                          <img
                            src="/images/ui/uploadIcon.png"
                            className="uploadIcon"
                            alt=""
                          />
                        </span>
                      )}
                    {this.state.files__passportUploading &&
                      this.state.files__passport === "" && (
                        <span>
                          Загрузить
                          <i className="icon-spinner2 uploaderSpinner spinner"></i>
                        </span>
                      )}
                    {!this.state.files__passportUploading &&
                      this.state.files__passport != "" && (
                        <span>
                          Загружено
                          <i className="icon-checkmark3 uploaderCheck"></i>
                        </span>
                      )}
                  </div>
                </td>
                <td className="profileDocs-desc">
                  {!this.state.files__passportUploading &&
                    this.state.files__passport != "" && (
                      <span>
                        <a
                          href={this.state.files__passportLink}
                          target="_blank"
                        >
                          {this.state.files__passportName}
                        </a>
                        <i
                          className="icon-cross2 docRemoveFile text-danger"
                          onClick={() => {
                            this.onRemoveFile("files__passport");
                          }}
                        ></i>
                      </span>
                    )}
                  {this.state.validate &&
                    this.state.files__passportError == "" &&
                    this.state.files__passport == "" && (
                      <span className="text-danger">Обязательное поле</span>
                    )}
                  {this.state.files__passportError != "" && (
                    <span className="text-danger">
                      {this.state.files__passportError}
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td className="adaptiveCell">
                  <div
                    className={`profileDocs-uploaderTitle ${
                      this.state.files__reg_place != "" &&
                      this.state.files__reg_placeError == ""
                        ? "text-success"
                        : ""
                    } ${
                      (this.state.validate &&
                        this.state.files__reg_place == "") ||
                      this.state.files__reg_placeError != ""
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    {'Документ, подтверждающий прописку'}
                    {!this.state.files__reg_placeUploading &&
                      this.state.files__reg_place != "" &&
                      this.state.files__reg_placeError == "" && (
                        <i className="icon-checkmark3 uploaderTitleCheck"></i>
                      )}
                  </div>
                  <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                    <input
                      type="file"
                      id="files__reg_placeField"
                      className="form-control-uniform-custom"
                      accept=".png, .jpg, .pdf, .jpeg"
                      disabled={this.state.files__reg_placeUploading}
                      onChange={this.onRegPlaceChange}
                    />
                    {!this.state.files__reg_placeUploading &&
                      this.state.files__reg_place == "" && (
                        <span>
                          Загрузить
                          <img
                            src="/images/ui/uploadIcon.png"
                            className="uploadIcon"
                            alt=""
                          />
                        </span>
                      )}
                    {this.state.files__reg_placeUploading &&
                      this.state.files__reg_place === "" && (
                        <span>
                          Загрузить
                          <i className="icon-spinner2 uploaderSpinner spinner"></i>
                        </span>
                      )}
                    {!this.state.files__reg_placeUploading &&
                      this.state.files__reg_place != "" && (
                        <span>
                          Загружено
                          <i className="icon-checkmark3 uploaderCheck"></i>
                        </span>
                      )}
                  </div>
                </td>
                <td className="profileDocs-desc">
                  {!this.state.files__reg_placeUploading &&
                    this.state.files__reg_place != "" && (
                      <span>
                        <a
                          href={this.state.files__reg_placeLink}
                          target="_blank"
                        >
                          {this.state.files__reg_placeName}
                        </a>
                        <i
                          className="icon-cross2 docRemoveFile text-danger"
                          onClick={() => {
                            this.onRemoveFile("files__reg_place");
                          }}
                        ></i>
                      </span>
                    )}
                  {this.state.validate &&
                    this.state.files__reg_placeError == "" &&
                    this.state.files__reg_place == "" && (
                      <span className="text-danger">Обязательное поле</span>
                    )}
                  {this.state.files__reg_placeError != "" && (
                    <span className="text-danger">
                      {this.state.files__reg_placeError}
                    </span>
                  )}
                </td>
              </tr>
              {true ? null : (
                <tr>
                  <td className="adaptiveCell">
                    <div
                      className={`profileDocs-uploaderTitle ${
                        this.state.files__inn != "" &&
                        this.state.files__innError == ""
                          ? "text-success"
                          : ""
                      } ${
                        (this.state.validate && this.state.files__inn == "") ||
                        this.state.files__innError != ""
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      Документ, подтверждающий постановку на учет в налоговую
                      инспекцию
                      {!this.state.files__innUploading &&
                        this.state.files__inn != "" &&
                        this.state.files__innError == "" && (
                          <i className="icon-checkmark3 uploaderTitleCheck"></i>
                        )}
                    </div>
                    <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                      <input
                        type="file"
                        id="files__innField"
                        className="form-control-uniform-custom"
                        accept=".png, .jpg, .pdf, .jpeg"
                        disabled={this.state.files__innUploading}
                        onChange={this.onInnChange}
                      />
                      {!this.state.files__innUploading &&
                        this.state.files__inn == "" && (
                          <span>
                            Загрузить
                            <img
                              src="/images/ui/uploadIcon.png"
                              className="uploadIcon"
                              alt=""
                            />
                          </span>
                        )}
                      {this.state.files__innUploading &&
                        this.state.files__inn === "" && (
                          <span>
                            Загрузить
                            <i className="icon-spinner2 uploaderSpinner spinner"></i>
                          </span>
                        )}
                      {!this.state.files__innUploading &&
                        this.state.files__inn != "" && (
                          <span>
                            Загружено
                            <i className="icon-checkmark3 uploaderCheck"></i>
                          </span>
                        )}
                    </div>
                  </td>
                  <td className="profileDocs-desc">
                    {!this.state.files__innUploading &&
                      this.state.files__inn != "" && (
                        <span>
                          <a href={this.state.files__innLink} target="_blank">
                            {this.state.files__innName}
                          </a>
                          <i
                            className="icon-cross2 docRemoveFile text-danger"
                            onClick={() => {
                              this.onRemoveFile("files__inn");
                            }}
                          ></i>
                        </span>
                      )}
                    {this.state.validate &&
                      this.state.files__innError == "" &&
                      this.state.files__inn == "" && (
                        <span className="text-danger">Обязательное поле</span>
                      )}
                    {this.state.files__innError != "" && (
                      <span className="text-danger">
                        {this.state.files__innError}
                      </span>
                    )}
                  </td>
                </tr>
              )}
              {true ? null : (
                <tr>
                  <td className="adaptiveCell">
                    <div
                      className={`profileDocs-uploaderTitle ${
                        this.state.files__bank_details != "" &&
                        this.state.files__bank_detailsError == ""
                          ? "text-success"
                          : ""
                      } ${
                        this.state.files__bank_detailsError != ""
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      Банковские реквизиты
                      {!this.state.files__bank_detailsUploading &&
                        this.state.files__bank_details != "" &&
                        this.state.files__bank_detailsError == "" && (
                          <i className="icon-checkmark3 uploaderTitleCheck"></i>
                        )}
                    </div>
                    <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                      <input
                        type="file"
                        id="files__bank_detailsField"
                        className="form-control-uniform-custom"
                        accept=".png, .jpg, .pdf, .jpeg"
                        disabled={this.state.files__bank_detailsUploading}
                        onChange={this.onBankDetailsChange}
                      />
                      {!this.state.files__bank_detailsUploading &&
                        this.state.files__bank_details == "" && (
                          <span>
                            Загрузить
                            <img
                              src="/images/ui/uploadIcon.png"
                              className="uploadIcon"
                              alt=""
                            />
                          </span>
                        )}
                      {this.state.files__bank_detailsUploading &&
                        this.state.files__bank_details === "" && (
                          <span>
                            Загрузить
                            <i className="icon-spinner2 uploaderSpinner spinner"></i>
                          </span>
                        )}
                      {!this.state.files__bank_detailsUploading &&
                        this.state.files__bank_details != "" && (
                          <span>
                            Загружено
                            <i className="icon-checkmark3 uploaderCheck"></i>
                          </span>
                        )}
                    </div>
                  </td>
                  <td className="profileDocs-desc">
                    {!this.state.files__bank_detailsUploading &&
                      this.state.files__bank_details != "" && (
                        <span>
                          <a
                            href={this.state.files__bank_detailsLink}
                            target="_blank"
                          >
                            {this.state.files__bank_detailsName}
                          </a>
                          <i
                            className="icon-cross2 docRemoveFile text-danger"
                            onClick={() => {
                              this.onRemoveFile("files__bank_details");
                            }}
                          ></i>
                        </span>
                      )}
                    {this.state.files__bank_detailsError != "" && (
                      <span className="text-danger">
                        {this.state.files__bank_detailsError}
                      </span>
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <td className="adaptiveCell">
                  <div
                    className={`profileDocs-uploaderTitle ${
                      this.state.files__other != "" &&
                      this.state.files__otherError == ""
                        ? "text-success"
                        : ""
                    } ${
                      this.state.files__otherError != "" ? "text-danger" : ""
                    }`}
                  >
                    Прочие документы
                    {!this.state.files__otherUploading &&
                      this.state.files__other != "" &&
                      this.state.files__otherError == "" && (
                        <i className="icon-checkmark3 uploaderTitleCheck"></i>
                      )}
                  </div>
                  <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                    <input
                      type="file"
                      id="files__otherField"
                      className="form-control-uniform-custom"
                      accept=".png, .jpg, .pdf, .jpeg"
                      disabled={this.state.files__otherUploading}
                      onChange={this.onFilesOtherChange}
                    />
                    {!this.state.files__otherUploading &&
                      this.state.files__other == "" && (
                        <span>
                          Загрузить
                          <img
                            src="/images/ui/uploadIcon.png"
                            className="uploadIcon"
                            alt=""
                          />
                        </span>
                      )}
                    {this.state.files__otherUploading &&
                      this.state.files__other === "" && (
                        <span>
                          Загрузить
                          <i className="icon-spinner2 uploaderSpinner spinner"></i>
                        </span>
                      )}
                    {!this.state.files__otherUploading &&
                      this.state.files__other != "" && (
                        <span>
                          Загружено
                          <i className="icon-checkmark3 uploaderCheck"></i>
                        </span>
                      )}
                  </div>
                </td>
                <td className="profileDocs-desc">
                  {!this.state.files__otherUploading &&
                    this.state.files__other != "" && (
                      <span>
                        <a href={this.state.files__otherLink} target="_blank">
                          {this.state.files__otherName}
                        </a>
                        <i
                          className="icon-cross2 docRemoveFile text-danger"
                          onClick={() => {
                            this.onRemoveFile("files__other");
                          }}
                        ></i>
                      </span>
                    )}
                  {this.state.files__otherError != "" && (
                    <span className="text-danger">
                      {this.state.files__otherError}
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <button
            className="btn btnBack"
            onClick={() => {
              this.action(this.props.previous, 4, "prev");
            }}
          >
            Назад
          </button>
          <button
            className="btn btn-success anketaDocs-submitBtnSend ml-2"
            onClick={this.sendAnketa}
          >
            Отправить
          </button>
        </div>
      </div>
    );
  }
}
