import { SET_DAILY_TRADING_ACCOUNT } from '../actions/dailyTradingAccount';

// Expenses Reducer
const reducerDefaultState = {
  boards: [],
  currency_code: '',
  name: '',
  total: {},
};

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_DAILY_TRADING_ACCOUNT:
      return action.dailyTradingAccount;
    default:
      return state;
  }
};
