import React from 'react';
import { connect } from 'react-redux';

import { catchPromise } from '../utils/http';
import TechTable from '../components/TechTable';
import TechOptions from '../components/TechOptions';
import {getAccountBoards} from '../utils/topHttp';
import {getTechOptions, getTechInstruments} from '../utils/techHttp';

export class TechPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enableSections: [],
      currentBoard: '',
      accId: '',
      instSearch: '',
      instruments: [],
      page: 1,
      page_size: 100,
      pages_total: 1,
      optionTimes: {},
      optionTime: {},
      selectIsOpened: false,
      isLoading: false,
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    if(this.state.accId || this.props.tradingAccount.id) this.getAccountBoards();
    $('body').on('click', () => {
      if (this.state.selectIsOpened) {
        setTimeout(() => this.setState({ selectIsOpened: false }), 100);
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.tradingAccount.name !== nextProps.tradingAccount.name) {
      this.setState(
        { accId: nextProps.tradingAccount.id || '' },
        () => {
          if(this.state.accId) this.getAccountBoards();
        }
      );
    }
  }
  getAccountBoards = () => {
    getAccountBoards(this.state.accId || this.props.tradingAccount.id)
    .then((res) => {
      this.setState({
        enableSections: res.data.boards
      }, () => {
        if (this.state.enableSections.length > 0) 
          this.onBoardChange(this.state.enableSections[0].id, true);
      })
    })
    .catch(err => {
      if(err.response.status == 401) {
        catchPromise(err, this.getAccountBoards, this.errorCb);
      }
      if(err.response.status == 404) {
        this.setState({enableSections: []})
      }
    })
  }
  onBoardChange = (id, isChangedAcc) => {
    this.setState({ currentBoard: id, boardName: this.state.enableSections.find(o => o.id == id).title }, () => {      
      if(isChangedAcc){
        $('.isBoard').removeClass('active show');
        $('.board-' + id).addClass('active show');
      }
      this.loadOptions();
    });
  };
  loadOptions = () => {
    getTechOptions(this.state.accId || this.props.tradingAccount.id, this.state.currentBoard)
    .then((res) => {
      this.setState({
        optionTimes: {...res.data}, 
        optionTime: res.data.board_work_times[0],
        instruments: [], 
        page: 1, 
        page_size: 1, 
        pages_total: 100
      }, () => {
        this.loadInstruments(this.state.page);
      })
    })
    .catch(err => {
      if(err.response.status == 401) {
        catchPromise(err, this.loadOptions, this.errorCb);
      }
    })
  }
  loadInstruments = (page) => {
    this.setState({isLoading: true}, () => {
      getTechInstruments(
        this.state.optionTime.id, 
        this.state.optionTimes.board_work_times.length, 
        this.state.currentBoard, 
        page, 
        this.state.instSearch
      )
      .then((res) => {
        this.setState({
          instruments: res.data.symbols, 
          page: res.data.page, 
          page_size: res.data.page_size, 
          pages_total: res.data.pages_total,
          isLoading: false
        })
      })
      .catch(err => {
        if(err.response.status == 401) {
          catchPromise(err, this.loadInstruments, this.errorCb);
        }
      })
    })
  }
  onInstSearchChange = (e) => {
    const instSearch = e.currentTarget.value;
    this.setState({
      instSearch,
      instruments: [], 
      page: 1, 
      page_size: 1, 
      pages_total: 100
    }, () => {
      this.loadInstruments(this.state.page, this.state.instSearch)
    });
  }

  toggleOptionsTime = (state, val) => {
    this.setState({selectIsOpened: state}, () => {
      if(val) {
        this.setState({
          optionTime: val,
          instruments: [], 
          page: 1, 
          page_size: 1, 
          pages_total: 100
        }, () => {
          this.loadInstruments(this.state.page);
        });
      }
    });
  }
  scrollContent = () => {
    const pageScroller = $('.tech-table .clipper > .scroller');
    const pageContentWrap = $('.tech-table .clipper > .scroller > .table');
    if (
      pageScroller.scrollTop() + pageScroller.height() >= (pageContentWrap.height() - 70) &&
      this.state.page < this.state.pages_total &&
      !this.state.isLoading
    ) {
      this.loadInstruments(this.state.page + 1);
    }
  };

  render() {
    return (
      <div className="row">
        <div className="page-content-center tech">
            {
                !this.state.pageError ? (
                    <div>
                        <h1 className="title card-content">Техническое задание</h1>
                        {
                          this.state.enableSections.length > 0 && !(this.state.enableSections.length == 1 && this.state.enableSections[0].code == 'US_STOCK') ? (
                            <div className="techContainer">
                                <TechTable 
                                  enableSections={this.state.enableSections}
                                  instSearch={this.state.instSearch}
                                  instruments={this.state.instruments}
                                  onBoardChange={this.onBoardChange}
                                  onInstSearchChange={this.onInstSearchChange}
                                  scrollContent={this.scrollContent}
                                />
                                <TechOptions
                                  selectIsOpened={this.state.selectIsOpened}
                                  optionTimes={this.state.optionTimes}
                                  optionTime={this.state.optionTime}
                                  boardName={this.state.boardName}
                                  toggleOptionsTime={this.toggleOptionsTime}
                                />
                            </div>
                          ) : (
                            <div style={{width: '1100px', height: '270px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              <h3>Нет подключенных секций</h3>
                            </div>
                          )
                        }
                    </div>
                ) : (                    
                    <h3 className="text-center text-danger">Произошла ошибка</h3>
                )
            }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tradingAccount: state.tradingAccount,
  };
};

export default connect(mapStateToProps)(TechPage);
