import React from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';

export default class AnketaAddresses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validate: false,
      validateActiveFields: {
        d__extra__inn: false,
        d__extra__snils: false,
        d__extra__ogrnip: false,
        d__bank__bik: false,
        d__bank__acc: false,
      },
      d__extra__inn: props.data.d__extra__inn || '',
      d__extra__fl_type: props.data.d__extra__fl_type || 0,
      d__extra__snils: props.data.d__extra__snils || '',
      d__extra__ogrnip: props.data.d__extra__ogrnip || '',
      d__extra__tax_system: props.data.d__extra__tax_system || 0,
      d__bank__bik: props.data.d__bank__bik || '',
      d__bank__name: props.data.d__bank__name || '',
      d__bank__corr_acc: props.data.d__bank__corr_acc || '',
      d__bank__acc: props.data.d__bank__acc || '',
      errorName: undefined,
      bankAcc_errorName: '',
      selectIsOpened: false,
      selectFlTypeIsOpened: false,
    };
  }

  componentDidMount() {
    $('body').on('click', () => {
      if (this.state.selectIsOpened) {
        setTimeout(
          () =>
            this.setState({
              selectIsOpened: false,
              selectFlTypeIsOpened: false,
            }),
          100
        );
      }
    });
  }

  action = (action, currIndex, dest) => {
    this.setState({ validate: true });
    this.setState({
      validateActiveFields: {
        d__extra__inn: true,
        d__extra__snils: true,
        d__extra__ogrnip: true,
        d__bank__bik: true,
        d__bank__acc: true,
      },
    });

    let addProfileObj = {
      d__extra__fl_type: this.state.d__extra__fl_type,
      d__extra__inn: this.state.d__extra__inn,
    };
    if (this.state.d__extra__fl_type === 0) {
      addProfileObj.d__extra__snils = this.state.d__extra__snils;
    } else {
      addProfileObj.d__extra__ogrnip = this.state.d__extra__ogrnip;
      addProfileObj.d__extra__tax_system = this.state.d__extra__tax_system;
    }
    if (this.validating() && dest === 'next') {
      axios({
        method: 'get',
        url: `https://bik-info.ru/api.html?type=json&bik=${this.state.d__bank__bik}`,
      })
        .then((res) => {
          if (res.data.error) {
            this.setState({ errorName: 'Ошибка при получении данных с банка' });
          } else {
            if (
              this.validateRs(this.state.d__bank__acc, this.state.d__bank__bik)
            ) {
              this.setState({
                d__bank__name: res.data.name.replace(/&quot;/g, '"'),
                d__bank__corr_acc: res.data.ks,
                errorName: undefined,
              });
              this.props.action(action, currIndex, 'add', {
                ...addProfileObj,
                d__bank__bik: this.state.d__bank__bik,
                d__bank__name: this.state.d__bank__name.replace(/&quot;/g, '"'),
                d__bank__corr_acc: this.state.d__bank__corr_acc,
                d__bank__acc: this.state.d__bank__acc,
              });
            }
          }
        })
        .catch((err) => {
          this.setState({ errorName: 'Ошибка при получении данных с банка' });
        });
    }
    if (dest === 'prev') {
      this.props.action(action, currIndex, 'add', {
        ...addProfileObj,
        d__bank__bik: this.state.d__bank__bik,
        d__bank__name: this.state.d__bank__name.replace(/&quot;/g, '"'),
        d__bank__corr_acc: this.state.d__bank__corr_acc,
        d__bank__acc: this.state.d__bank__acc,
      });
    }
  };
  validating = () => {
    if (this.validateRs(this.state.d__bank__acc, this.state.d__bank__bik)) {
      return (
        ((this.state.d__extra__fl_type === 0 &&
          this.state.d__extra__inn.length == 12 &&
          this.state.d__extra__snils.replace(/[-_]/g, '').length == 12) ||
          (this.state.d__extra__fl_type === 1 &&
            this.state.d__extra__ogrnip.length == 15)) &&
        this.state.d__bank__bik.length == 9 &&
        this.state.d__bank__name != '' &&
        this.state.d__bank__corr_acc != ''
      );
    }
  };

  onInnChange = (e) => {
    const d__extra__inn = e.target.value;
    if (
      !d__extra__inn ||
      (d__extra__inn.match(/^[0-9]+$/) && d__extra__inn.length <= 12)
    ) {
      this.setState(() => ({
        d__extra__inn,
        validateActiveFields: {
          ...this.state.validateActiveFields,
          d__extra__inn: false,
        },
      }));
    }
  };
  onFlTypeChange = (value) => {
    const d__extra__fl_type = parseInt(value);
    this.setState(() => ({ d__extra__fl_type, selectFlTypeIsOpened: false }));
  };
  onSnilsChange = (e) => {
    const d__extra__snils = e.target.value;
    this.setState(() => ({
      d__extra__snils,
      validateActiveFields: {
        ...this.state.validateActiveFields,
        d__extra__snils: false,
      },
    }));
  };
  onOgrnipChange = (e) => {
    const d__extra__ogrnip = e.target.value;
    if (
      !d__extra__ogrnip ||
      (d__extra__ogrnip.match(/^[0-9]+$/) && d__extra__ogrnip.length <= 15)
    ) {
      this.setState(() => ({
        d__extra__ogrnip,
        validateActiveFields: {
          ...this.state.validateActiveFields,
          d__extra__ogrnip: false,
        },
      }));
    }
  };
  onTaxChange = (value) => {
    const d__extra__tax_system = parseInt(value);
    this.setState({ d__extra__tax_system, selectIsOpened: false });
  };

  validateRs = (rs, bik) => {
    var result = false;

    if (typeof rs === 'number') {
      rs = rs.toString();
    } else if (typeof rs !== 'string') {
      rs = '';
    }
    if (!rs.length) {
      this.setState({ bankAcc_errorName: 'Р/С пуст' });
    } else if (/[^0-9]/.test(rs)) {
      this.setState({ bankAcc_errorName: 'Р/С может состоять только из цифр' });
    } else if (rs.length !== 20) {
      this.setState({
        bankAcc_errorName: 'Р/С может состоять только из 20 цифр',
      });
    } else {
      var bikRs = bik.toString().slice(-3) + rs;
      var checksum = 0;
      var coefficients = [
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
      ];
      for (var i in coefficients) {
        checksum += coefficients[i] * (bikRs[i] % 10);
      }
      if (checksum % 10 === 0) {
        this.setState({ bankAcc_errorName: '' });
        result = true;
      } else {
        this.setState({ bankAcc_errorName: 'Неправильный расчетный счет' });
      }
    }

    return result;
  };
  onBikChange = (e) => {
    const d__bank__bik = e.target.value;
    if (
      !d__bank__bik ||
      (d__bank__bik.match(/^[0-9]+$/) && d__bank__bik.length <= 9)
    ) {
      this.setState(() => ({
        d__bank__bik,
        validateActiveFields: {
          ...this.state.validateActiveFields,
          d__bank__bik: false,
        },
      }));
      if (d__bank__bik.length === 9) {
        axios({
          method: 'get',
          url: `https://bik-info.ru/api.html?type=json&bik=${d__bank__bik}`,
        })
          .then((res) => {
            if (res.data.error) {
              this.setState({
                errorName: 'Ошибка при получении данных с банка',
              });
            } else {
              this.setState({
                d__bank__name: res.data.name.replace(/&quot;/g, '"'),
                d__bank__corr_acc: res.data.ks,
                errorName: undefined,
              });
            }
          })
          .catch((err) => {
            this.setState({ errorName: 'Ошибка при получении данных с банка' });
          });
      } else {
        this.setState({ d__bank__name: '', d__bank__corr_acc: '' });
      }
    }
  };
  onAccChange = (e) => {
    const d__bank__acc = e.target.value;
    if (
      !d__bank__acc ||
      (d__bank__acc.match(/^[0-9]+$/) && d__bank__acc.length <= 20)
    ) {
      this.setState(() => ({
        d__bank__acc,
        validateActiveFields: {
          ...this.state.validateActiveFields,
          d__bank__acc: false,
        },
      }));
    }
  };

  render() {
    return (
      <div>
        <h2 className="subtitle">Дополнительные данные</h2>
        <div className="row mb-4">
          <div className="col-lg-4">
            <div className="customSelect">
              <div
                className="customSelectedItem"
                onClick={() => {
                  this.setState({ selectFlTypeIsOpened: true });
                }}
              >
                {this.state.d__extra__fl_type == 0 ? 'Физическое лицо' : 'ИП'}
                <i className="toggle icon-menu-open"></i>
              </div>
              {this.state.selectFlTypeIsOpened && (
                <ul className="customSelectList">
                  <li
                    onClick={() => {
                      this.onFlTypeChange(0);
                    }}
                  >
                    Физическое лицо
                  </li>
                  <li
                    onClick={() => {
                      this.onFlTypeChange(1);
                    }}
                  >
                    ИП
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__extra__inn == '' ? 'hidden' : ''
                }`}
              >
                ИНН
              </label>
              <input
                className={`inputUnderLine  ${
                  this.state.validate &&
                  this.state.d__extra__inn.length != 12 &&
                  this.state.validateActiveFields.d__extra__inn
                    ? 'inputValidateCurrentWrong'
                    : ''
                } ${
                  this.state.validate && this.state.d__extra__inn.length != 12
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  this.state.validate && this.state.d__extra__inn.length == 12
                    ? 'inputValidateCorrect'
                    : ''
                }`}
                placeholder="ИНН"
                value={this.state.d__extra__inn}
                onChange={this.onInnChange}
              />
            </div>
          </div>
          {this.state.d__extra__fl_type === 0 && (
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__extra__snils == '' ? 'hidden' : ''
                  }`}
                >
                  СНИЛС
                </label>
                <InputMask
                  className={`inputUnderLine ${
                    this.state.validate &&
                    this.state.d__extra__snils.replace(/[-_]/g, '').length !=
                      12 &&
                    this.state.validateActiveFields.d__extra__snils
                      ? 'inputValidateCurrentWrong'
                      : ''
                  } ${
                    this.state.validate &&
                    this.state.d__extra__snils.replace(/[-_]/g, '').length != 12
                      ? 'inputValidateWrong'
                      : ''
                  } ${
                    this.state.validate &&
                    this.state.d__extra__snils.replace(/[-_]/g, '').length == 12
                      ? 'inputValidateCorrect'
                      : ''
                  }`}
                  placeholder="СНИЛС"
                  mask="999-999-999 99"
                  value={this.state.d__extra__snils}
                  onChange={this.onSnilsChange}
                />
              </div>
            </div>
          )}
          {this.state.d__extra__fl_type === 1 && (
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__extra__ogrnip == '' ? 'hidden' : ''
                  }`}
                >
                  ОГРНИП
                </label>
                <input
                  className={`inputUnderLine ${
                    this.state.validate &&
                    this.state.d__extra__ogrnip.length != 15 &&
                    this.state.validateActiveFields.d__extra__ogrnip
                      ? 'inputValidateCurrentWrong'
                      : ''
                  } ${
                    this.state.validate &&
                    this.state.d__extra__ogrnip.length != 15
                      ? 'inputValidateWrong'
                      : ''
                  } ${
                    this.state.validate &&
                    this.state.d__extra__ogrnip.length == 15
                      ? 'inputValidateCorrect'
                      : ''
                  }`}
                  placeholder="ОГРНИП"
                  value={this.state.d__extra__ogrnip}
                  onChange={this.onOgrnipChange}
                />
              </div>
            </div>
          )}
          <div className="col-lg-4"></div>
          {this.state.d__extra__fl_type === 1 && (
            <div className="col-lg-4 mb-3">
              <label style={{ fontSize: '11px' }}>
                Система налогооблажения
              </label>
            </div>
          )}
          {this.state.d__extra__fl_type === 1 && (
            <div className="col-lg-4">
              <div className="customSelect">
                <div
                  className="customSelectedItem"
                  onClick={() => {
                    this.setState({ selectIsOpened: true });
                  }}
                >
                  {this.state.d__extra__tax_system === 0 ? 'УСН' : 'ОСНО'}
                  <i className="toggle icon-menu-open"></i>
                </div>
                {this.state.selectIsOpened && (
                  <ul className="customSelectList">
                    <li
                      onClick={() => {
                        this.onTaxChange(0);
                      }}
                    >
                      УСН
                    </li>
                    <li
                      onClick={() => {
                        this.onTaxChange(1);
                      }}
                    >
                      ОСНО
                    </li>
                  </ul>
                )}
              </div>
            </div>
          )}
        </div>

        <h2 className="subtitle-add">
          Введите БИК и Р/С. Название банка и Кор. счет подставятся
          автоматически
        </h2>
        <div className="row">
          <div className="col-lg-4">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__bank__bik == '' ? 'hidden' : ''
                }`}
              >
                БИК
              </label>
              <input
                type="text"
                className={`inputUnderLine  ${
                  this.state.validate &&
                  this.state.d__bank__bik.length != 9 &&
                  this.state.validateActiveFields.d__bank__bik
                    ? 'inputValidateCurrentWrong'
                    : ''
                } ${
                  this.state.validate && this.state.d__bank__bik.length != 9
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  this.state.validate && this.state.d__bank__bik.length == 9
                    ? 'inputValidateCorrect'
                    : ''
                }`}
                placeholder="БИК"
                value={this.state.d__bank__bik}
                onChange={this.onBikChange}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__bank__name == '' ? 'hidden' : ''
                }`}
              >
                Банк
              </label>
              <input
                type="text"
                className="inputUnderLine"
                placeholder="Банк"
                readOnly={true}
                value={this.state.d__bank__name}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__bank__acc == '' ? 'hidden' : ''
                }`}
              >
                Номер счета
              </label>
              <input
                type="text"
                className={`inputUnderLine ${
                  this.state.validate &&
                  this.state.bankAcc_errorName != '' &&
                  this.state.validateActiveFields.d__bank__acc
                    ? 'inputValidateCurrentWrong'
                    : ''
                } ${
                  this.state.validate && this.state.bankAcc_errorName != ''
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  this.state.validate && this.state.bankAcc_errorName == ''
                    ? 'inputValidateCorrect'
                    : ''
                }`}
                placeholder="Номер счета"
                value={this.state.d__bank__acc}
                onChange={this.onAccChange}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__bank__corr_acc == '' ? 'hidden' : ''
                }`}
              >
                Корр. счет
              </label>
              <input
                type="text"
                className="inputUnderLine"
                placeholder="Кор. счет"
                readOnly={true}
                value={this.state.d__bank__corr_acc}
              />
            </div>
          </div>
        </div>
        {this.state.errorName && (
          <div className="row">
            <div className="col-12">
              <p className="text-center text-danger">{this.state.errorName}</p>
            </div>
          </div>
        )}

        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <button
            className="btn btnBack"
            onClick={() => {
              this.action(this.props.previous, 1, 'prev');
            }}
          >
            Назад
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              this.action(this.props.next, 3, 'next');
            }}
          >
            Дальше
          </button>
        </div>
      </div>
    );
  }
}
