import axios from 'axios';
import Config from '../config/config';

export const getTradingAccounts = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/trading-accounts/`,
    withCredentials: true,
  });
};
export const getTransactions = (filters) => {
  const filterRequest = `page_num=${filters.page_num}&date_start=${filters.date_start}&date_end=${filters.date_end}&transaction_type=${filters.transaction_type}`;
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/trading-accounts/${filters.account_id}/transactions/?${filterRequest}`,
    withCredentials: true,
  });
};
export const getTransactionTotals = (filters) => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/trading-accounts/${filters.account_id}/total/?date_start=${filters.date_start}&date_end=${filters.date_end}`,
    withCredentials: true,
  });
};
export const getTransactionTypes = (type) => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/transaction-types${type === "trader" ? "?type=trading" : type === "bonus" ? "?type=bonus" : ""}`,
    withCredentials: true,
  });
};
export const accountPayment = (payData) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/payment-requests/`,
    data: payData,
    withCredentials: true,
  });
};
export const innerTransfer = (data) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/inner_transfer/`,
    data,
    withCredentials: true,
  });
};