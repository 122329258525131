import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';

export default class News extends Component {
  render() {
    const { news, page_num, page_total, handlePageChange } = this.props;
    return (
      <div>
        <div className="card-content">
          <h1 className="title">Новости</h1>
        </div>
        <div className="card-content">
          {news.map((newsNote, index) => (
            <div
              className="card newsNote"
              id={`news-${newsNote.id}`}
              key={newsNote.id}
            >
              <div className="card-body">
                <h5 className="font-weight-bold mb-1 news-title">
                  <a href="#" className="text-default">
                    {newsNote.title}
                  </a>
                </h5>
                <div className="mb-3">{ReactHtmlParser(newsNote.text)}</div>
                <div className="text-muted">
                  {moment(newsNote.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

News.propTypes = {
  news: PropTypes.array,
  page_num: PropTypes.number,
  page_total: PropTypes.number,
  handlePageChange: PropTypes.func,
};
