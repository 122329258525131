import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import Baron from 'react-baron/dist/es5';
import moment from 'moment';
import 'moment/locale/ru';

import Config from '../../config/config';

export class ChangeTaxpayerIndividual extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      d__bank__bik: '',
      d__bank__name: '',
      d__bank__corr_acc: '',
      d__bank__acc: '',
      date_started: null,
      contracts: props.contracts || [],
      contract: props.contracts.length > 0 ? props.contracts[0].id : '',
      validate: false,
      validateActiveFields: {
        d__bank__bik: false,
        d__bank__acc: false,
      },
      error: undefined,
      pageError: false,
      bankAcc_errorName: '',
    };
  }

  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    $('body').on('click', () => {
      if ($('.customSelectListMonth').hasClass('isShowSelect'))
        setTimeout(() => {
          $('.customSelectListMonth').removeClass('isShowSelect');
        }, 50);
      if ($('.customSelectListYear').hasClass('isShowSelect'))
        setTimeout(() => {
          $('.customSelectListYear').removeClass('isShowSelect');
        }, 50);
    });
  }
  closePopup = () => {
    this.props.closePopup();
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ validate: true });
    this.setState({
      validateActiveFields: {
        d__bank__bik: true,
        d__bank__acc: true,
      },
    });
    if (this.validating()) {
      if (
        !this.state.d__bank__bik ||
        (this.state.d__bank__bik.match(/^[0-9]+$/) &&
          this.state.d__bank__bik.length <= 9)
      ) {
        axios({
          method: 'get',
          url: `${Config.bikUrl}&bik=${this.state.d__bank__bik}`,
        })
          .then((res) => {
            console.log(res);
            if (res.data.error) {
              this.setState({
                errorName: 'Ошибка при получении данных с банка',
              });
            } else {
              if (
                this.validateRs(
                  this.state.d__bank__acc,
                  this.state.d__bank__bik
                )
              ) {
                this.setState({
                  d__bank__name: res.data.name.replace(/&quot;/g, '"'),
                  d__bank__corr_acc: res.data.ks,
                  errorName: undefined,
                });
                this.props.onSubmit({
                  code: 'CHANGE_FL_TYPE_TO_INDIVIDUAL',
                  contract_id: this.state.contract,
                  date_started: this.state.date_started.format('YYYY-MM-DD'),
                  bank: {
                    bik: this.state.d__bank__bik,
                    corr_acc: res.data.ks,
                    name: res.data.name.replace(/&quot;/g, '"'),
                    acc: this.state.d__bank__acc,
                  },
                });
              }
            }
          })
          .catch((err) => {
            this.setState({ errorName: 'Ошибка при получении данных с банка' });
          });
      }
    }
  };
  validating = () => {
    if (this.validateRs(this.state.d__bank__acc, this.state.d__bank__bik)) {
      return (
        this.state.d__bank__bik.length == 9 &&
        this.state.d__bank__name != '' &&
        this.state.d__bank__corr_acc != '' &&
        this.state.contract != '' &&
        this.state.date_started != null
      );
    } else return false;
  };
  validateRs = (rs, bik) => {
    var result = false;

    if (typeof rs === 'number') {
      rs = rs.toString();
    } else if (typeof rs !== 'string') {
      rs = '';
    }
    if (!rs.length) {
      this.setState({ bankAcc_errorName: 'Р/С пуст' });
    } else if (/[^0-9]/.test(rs)) {
      this.setState({ bankAcc_errorName: 'Р/С может состоять только из цифр' });
    } else if (rs.length !== 20) {
      this.setState({
        bankAcc_errorName: 'Р/С может состоять только из 20 цифр',
      });
    } else {
      var bikRs = bik.toString().slice(-3) + rs;
      var checksum = 0;
      var coefficients = [
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
      ];
      for (var i in coefficients) {
        checksum += coefficients[i] * (bikRs[i] % 10);
      }
      if (checksum % 10 === 0) {
        this.setState({ bankAcc_errorName: '' });
        result = true;
      } else {
        this.setState({ bankAcc_errorName: 'Неверно указан р/с' });
      }
    }

    return result;
  };
  onBikChange = (e) => {
    const d__bank__bik = e.target.value;
    if (
      !d__bank__bik ||
      (d__bank__bik.match(/^[0-9]+$/) && d__bank__bik.length <= 9)
    ) {
      this.setState({
        d__bank__bik,
        validateActiveFields: {
          ...this.state.validateActiveFields,
          d__bank__bik: false,
        },
      });
      if (d__bank__bik.length === 9) {
        axios({
          method: 'get',
          url: `${Config.bikUrl}&bik=${d__bank__bik}`,
        })
          .then((res) => {
            if (res.data.error) {
              this.setState({
                errorName: 'Ошибка при получении данных с банка',
              });
            } else {
              this.setState({
                d__bank__name: res.data.name.replace(/&quot;/g, '"'),
                d__bank__corr_acc: res.data.ks,
                errorName: undefined,
              });
            }
          })
          .catch((err) => {
            this.setState({ errorName: 'Ошибка при получении данных с банка' });
          });
      } else {
        this.setState({ d__bank__name: '', d__bank__corr_acc: '' });
      }
    }
  };

  onAccChange = (e) => {
    const d__bank__acc = e.target.value;
    if (
      !d__bank__acc ||
      (d__bank__acc.match(/^[0-9]+$/) && d__bank__acc.length <= 20)
    ) {
      this.setState(() => ({
        d__bank__acc,
        validateActiveFields: {
          ...this.state.validateActiveFields,
          d__bank__acc: false,
        },
      }));
    }
  };
  onDateStartedChange = (date_started) => {
    this.setState(() => ({ date_started }));
  };
  onContractChange = (e) => {
    const contract = e.target.value;
    this.setState({ contract });
  };
  changeSelect = (name, value) => {
    if (value) $('.' + name).addClass('isShowSelect');
    else $('.' + name).removeClass('isShowSelect');
  };
  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    let rows = [];
    for (let i = 0; i < 100; i++) {
      rows.push(i);
    }
    return (
      <div
        className="singleDatePickerSelect"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div className="customSelect customSelectSingleDatepicker">
          <div
            className="customSelectedItem"
            onClick={() => {
              this.changeSelect('customSelectListMonth', true);
            }}
          >
            {month.format('MMMM')}
          </div>
          {
            <ul className="customSelectList customSelectListMonth">
              <Baron>
                {moment.months().map((label, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      onMonthSelect(month, index);
                      this.changeSelect('customSelectListMonth', false);
                    }}
                  >
                    {label}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
        <div className="customSelect customSelectSingleDatepicker customSelectSingleDatepicker-year">
          <div
            className="customSelectedItem"
            onClick={() => {
              this.changeSelect('customSelectListYear', true);
            }}
          >
            {month.year()}
          </div>
          {
            <ul className="customSelectList customSelectListYear">
              <Baron>
                {rows.map((row) => (
                  <li
                    key={row}
                    onClick={() => {
                      onYearSelect(month, moment().year() - row);
                      this.changeSelect('customSelectListYear', false);
                    }}
                  >
                    {moment().year() - row}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {!this.state.pageError ? (
          <div className="docCreateTaxpayer docCreateTaxpayer-individual row">
            {this.state.contracts.length > 0 && (
              <div className="contractNameDocCancel col-12">{`Договор №${this.state.contracts[0].number} от ${this.state.contracts[0].date}`}</div>
            )}
            <div className="col-6">
              <input
                type="text"
                className={`inputUnderLine ${
                  this.state.validate &&
                  this.state.d__bank__bik.length != 9 &&
                  this.state.validateActiveFields.d__bank__bik
                    ? 'inputValidateCurrentWrong'
                    : ''
                } ${
                  this.state.validate && this.state.d__bank__bik.length != 9
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  this.state.validate && this.state.d__bank__bik.length == 9
                    ? 'inputValidateCorrect'
                    : ''
                }`}
                value={this.state.d__bank__bik}
                placeholder="БИК"
                onChange={this.onBikChange}
              />
            </div>
            <div className="col-6">
              <input
                type="text"
                className="inputUnderLine"
                readOnly={true}
                placeholder="Банк"
                value={this.state.d__bank__name}
              />
            </div>
            <div className="col-6">
              <input
                type="text"
                className={`inputUnderLine ${
                  this.state.validate &&
                  this.state.bankAcc_errorName != '' &&
                  this.state.validateActiveFields.d__bank__acc
                    ? 'inputValidateCurrentWrong'
                    : ''
                } ${
                  this.state.validate && this.state.bankAcc_errorName != ''
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  this.state.validate && this.state.bankAcc_errorName == ''
                    ? 'inputValidateCorrect'
                    : ''
                }`}
                value={this.state.d__bank__acc}
                placeholder="Р/С"
                onChange={this.onAccChange}
              />
            </div>
            <div className="col-6">
              <input
                type="text"
                className="inputUnderLine"
                readOnly={true}
                placeholder="Кор. счет"
                value={this.state.d__bank__corr_acc}
              />
            </div>
            <div className="col-6 dateUnderline" style={{ position: 'static' }}>
              <SingleDatePicker
                date={this.state.date_started}
                onDateChange={this.onDateStartedChange}
                placeholder="Дата начала"
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                numberOfMonths={1}
                isOutsideRange={() => false}
                hideKeyboardShortcutsPanel={true}
                renderMonthElement={this.renderMonthElement}
              />
            </div>
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <Link to="/app/documents/moving" className="btn btn-white">
            Отменить
          </Link>
          <button
            className="btn btn-success"
            disabled={!this.state.isLoadFiles && this.props.isSending}
            onClick={(e) => this.onSubmit(e)}
          >
            Создать
            {this.props.isSending && (
              <i className="icon-spinner2 uploaderSpinner spinner"></i>
            )}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ChangeTaxpayerIndividual);
