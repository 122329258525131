import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import amplitude from 'amplitude-js';

import { setUpdates } from "../actions/updates";
import { getAppFiles } from "../utils/appFilesHttp";
import { resetNotifications } from "../utils/updatesHttp";
import { NavLink } from "react-router-dom";

class DashboardEmptyPage extends React.Component {
  constructor(props) {
    super(props);
    this.terminalRef = React.createRef(null),
    this.bonusRef = React.createRef(null),
    this.tradingRef = React.createRef(null),

    this.state = {
      items: [
        {
          headerStatus: "",
          type: "terminal",
          title: "Терминал",
          text: "Тизерный текст про терминал и его возможности",
          ref: this.terminalRef,
        },
        {
          headerStatus: "",
          type: "trading",
          title: "Трейдинг",
          text: this.props.settings.TRADING_DESCRIPTION,
          ref: this.tradingRef
        },
      ],
      lastVersion: null,
      descSet: false
    };
  }

  componentDidMount() {
    amplitude.getInstance().logEvent('dashboard_empty_page.page_view');

    this.setItems();

    if (this.props.updates.notifications) {
      setTimeout(() => {
        resetNotifications().then(() => {
          this.props.setUpdates({ ...this.props.updates, notifications: 0 });
        });
      }, 1000);
    }

    getAppFiles().then((res) => {
      this.setState({
        lastVersion: res.data.versions[0],
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.setItems();
    }
    if (!this.state.descSet && Object.keys(this.props.settings).length) {
      this.setItemsTexts();
    }
  }

  setItemsTexts() {
    const tempItems = [...this.state.items];
    if (tempItems.find(({ type }) => type === "bonus")) tempItems.find(({ type }) => type === "bonus").text = this.props.settings.BONUS_DESCRIPTION;
    if (tempItems.find(({ type }) => type === "trading")) tempItems.find(({ type }) => type === "trading").text = this.props.settings.TRADING_DESCRIPTION;
    this.setState({ items: tempItems, descSet: true }, () => {
      tempItems.forEach((item) => {
        if (item.ref.current) {
          const div = document.createElement('div');
          div.innerHTML = item.text;
          const scripts = div.getElementsByTagName('script');
          let i = scripts.length;
          while (i--) {
            scripts[i].parentNode.removeChild(scripts[i]);
          }
          item.ref.current.innerHTML = div.innerHTML;
        }
      })
    });
  }

  setItems() {
    let tempItems = [...this.state.items];

    if (this.props.user.user) {
      tempItems[0].headerStatus = "success";
      tempItems[0].href = "/app/app-files";
      if (
        this.props.user.products_disabled &&
        this.props.user.products_disabled.includes("terminal")
      ) {
        tempItems[0].deleted = true;
      }
      if (this.props.user.form_status === "CREATED") {
        tempItems[1].headerStatus = "waiting";
      } else if (this.props.user.form_status === "APPROVED") {
        tempItems[1].headerStatus = "success";
        tempItems[1].href = "/app/trading-settings";
      } else if (
        this.props.user.form_status === "NEED_UPDATE" ||
        this.props.user.form_status === "NEED_REPLACE"
      ) {
        tempItems[1].headerStatus = "warn";
        tempItems[1].href = "/app/profile-form";
      } else if (this.props.user.form_status === "NOT_CREATED") {
        tempItems[1].href = "/app/profile-form";
      }
      if (
        (this.props.user.products_disabled &&
          this.props.user.products_disabled.includes("trader")) ||
        this.props.user.form_status === "REJECTED"
      ) {
        tempItems[1].deleted = true;
      }
    }

    tempItems = tempItems.filter((item) => !item.deleted);

    this.setState({ items: tempItems });
  }

  render() {
    return (
      <div className="dashboardEmptyPage">
        <div className="dashboardEmptyPageBlockList">
          {this.state.items
            .filter((item) => item.title !== "Терминал")
            .map(({ headerStatus, title, text, href, ref }) => {
              return (
                <a
                  key={title}
                  className="dashboardEmptyPageBlockItem"
                  href={href}
                >
                  {headerStatus === "success" ? (
                    <div className="dashboardEmptyPageBlockItemHeader text-success">
                      Готово к работе
                      <img
                        className="filterToGreen ml-2"
                        src="/images/ui/check-icon-black.png"
                        height="12"
                      />
                    </div>
                  ) : headerStatus === "warn" ? (
                    <div className="dashboardEmptyPageBlockItemHeader text-danger">
                      Требуются действия
                      <img
                        className="filterToRed ml-2"
                        src="/images/ui/warn-icon-black.png"
                        height="12"
                      />
                    </div>
                  ) : headerStatus === "waiting" ? (
                    <div className="dashboardEmptyPageBlockItemHeader text-secondary">
                      На модерации
                      <img
                        className="filterToSecondary ml-2"
                        src="/images/ui/hourglass-icon.png"
                        height="12"
                      />
                    </div>
                  ) : (
                    <div className="dashboardEmptyPageBlockItemHeader"></div>
                  )}
                  <div className="dashboardEmptyPageBlockItemBody">
                    <h2>{title}</h2>
                    {text && <div ref={ref} className="mt-2" />}
                  </div>
                </a>
              );
            })}
        </div>
        <div className="dashboardEmptyPageBlockList">
          {this.state.items
            .filter((item) => item.title === "Терминал")
            .map(({ headerStatus, title, text, href }) => {
              return (
                <div
                  key={title}
                  className="dashboardEmptyPageBlockItem terminal"
                >
                  {headerStatus === "success" ? (
                    <div className="dashboardEmptyPageBlockItemHeader text-success">
                      Готово к работе
                      <img
                        className="filterToGreen ml-2"
                        src="/images/ui/check-icon-black.png"
                        height="12"
                      />
                    </div>
                  ) : headerStatus === "warn" ? (
                    <div className="dashboardEmptyPageBlockItemHeader text-danger">
                      Требуются действия
                      <img
                        className="filterToRed ml-2"
                        src="/images/ui/warn-icon-black.png"
                        height="12"
                      />
                    </div>
                  ) : headerStatus === "waiting" ? (
                    <div className="dashboardEmptyPageBlockItemHeader text-secondary">
                      На модерации
                      <img
                        className="filterToSecondary ml-2"
                        src="/images/ui/hourglass-icon.png"
                        height="12"
                      />
                    </div>
                  ) : (
                    <div className="dashboardEmptyPageBlockItemHeader"></div>
                  )}
                  <div className="dashboardEmptyPageBlockItemBody">
                    <div>
                      <h2>Терминал EasyScalp Crypto</h2>
                      {this.state.lastVersion ? (<React.Fragment>
                        <div className="dashboardEmptyPageBlockItemBodyText">
                          <ul>
                            <li>Загрузите актуальную версию терминала</li>
                            <li>При входе в терминал введите <div className="semibold">адрес сервера, логин и пароль от личного кабинета</div></li>
                          </ul>
                          <a href="/app/profile">Посмотреть настройки для входа</a>
                        </div>
                        <div className="dashboardEmptyPageBlockItemButtonsBlock">

                          <a href={this.state.lastVersion.url_download}>
                            <div className="dashboardEmptyPageBlockItemButtonsItem downloads">
                              {this.props.settings.COLOR_LOGO_URL && (
                                <img src={this.props.settings.COLOR_LOGO_URL} />
                              )}
                              <div className="text-left">
                                <div className="title">
                                  {this.props.settings.TERMINAL_NAME_PREFIX || "easy_scalp_crypto_"}{this.state.lastVersion.version}
                                </div>
                                <div className="subtitle">
                                  {moment(
                                      this.state.lastVersion.date,
                                      'YYYY-MM-DD'
                                  ).format('DD.MM.YYYY')}
                                </div>
                              </div>
                            </div>
                          </a>

                          <NavLink to="/app/app-files" activeClassName="active">
                            <div className="dashboardEmptyPageBlockItemButtonsItem more">
                              Подробнее
                            </div>
                          </NavLink>
                        </div>
                      </React.Fragment>) : <p>Когда версия будет доступна, вы увидите здесь кнопку для загрузки.<br/>
                        Следите за новостями!</p>}
                    </div>
                    <div className="dashboardEmptyPageBlockItemBodyImg">
                      <img src="/images/ui/tech-bg-2.png" alt="Терминал EasyScalp Crypto" />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    user: state.user,
    updates: state.updates,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUpdates: (updates) => dispatch(setUpdates(updates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardEmptyPage);
