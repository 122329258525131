import React from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { history } from './AppRouter';
import Baron from 'react-baron/dist/es5';

import { checkAuth, refreshToken, catchPromise, getProjectSettings } from '../utils/http';
import { getTradingAccounts } from '../utils/transactionsHttp';
import { getUpdates } from '../utils/updatesHttp';
import Header from '../containers/Header';
import HeaderNews from '../containers/HeaderLastNews';
import Sidebar from '../containers/Sidebar';
import { setUser } from '../actions/user';
import { setTradingAccounts } from '../actions/tradingAccounts';
import { setLastNews } from '../actions/lastNews';
import { setUpdates } from '../actions/updates';
import NotFoundPage from '../components/NotFoundPage';
import { setSettings } from '../actions/settings';

export class AuthRoute extends React.Component {
  constructor(props) {
    super(props);
    this.scrollParent = React.createRef();
    this.state = {
      userData: {},
      isAuth: undefined,
      loaded: false,
      pageError: false,
    };
  }

  errorCb = () => {};

  componentDidMount() {
    const redirectToAddress =
      window.location.pathname + window.location.search.replace('&', '-amp;');

    checkAuth()
      .then((resCheck) => {
        this.checkChatWidget(resCheck.data.sso_token);

        this.props.setUser(resCheck.data);
        this.setState({ userData: resCheck.data });
        if (process.env.BUILD_TYPE === 'production') {
          Sentry.configureScope((scope) => {
            scope.setUser({
              email: resCheck.data.user.email,
              id: resCheck.data.user.id,
            });
          });
        }
        getTradingAccounts()
          .then((res) => {
            this.props.setTradingAccounts(res.data);
            this.setState({ isAuth: true, loaded: true });
          })
          .catch((err) => {
            if (err.response.data.need_refresh) {
              refreshToken()
                .then((res) => {
                  getTradingAccounts().then((res) => {
                    this.props.setTradingAccounts(res.data);
                  });
                })
                .catch((err) => {
                  history.push('/?redirect_url=' + redirectToAddress);
                  this.setState({ isAuth: false, loaded: true });
                });
            }
            this.setState({ loaded: true, pageError: false, isAuth: true })
          })
          .finally(() => {
            getProjectSettings().then(res => this.props.setSettings(res.data.settings));
          })
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          if ((err.response.status === 401 && err.response.data.need_refresh) || err.response.status === 422) {
            refreshToken()
              .then((res) => {
                document.location.reload(true);
              })
              .catch((err) => {
                history.push('/?redirect_url=' + redirectToAddress);
              });
          } else if (
            (err.response.status === 401 &&
            !err.response.data.need_refresh) || err.response.status === 422
          ) {
            history.push('/?redirect_url=' + redirectToAddress);
          } else {
            console.log(err);
            this.setState({ isAuth: false, loaded: true, pageError: true });
          }
        } else {
          console.log(err);
          this.setState({ isAuth: false, loaded: true, pageError: true });
        }
      });
  }
  checkChatWidget = (ssoToken) => {
    if (!process.env.REACT_APP_USERECHO_DOMAIN) return;
    if (window.UE) {
      if (!window._ues.params) {
        window._ues.params = {};
      }
      window._ues.params.sso_token = ssoToken;
      if (window.UI) {
        window.UI.Widget.init(window._ues);
      }
    } else {
      window._ues = {
        host: process.env.REACT_APP_USERECHO_DOMAIN,
        forum: '3',
        lang: 'ru',
        tab_show: false,
        params: {
          sso_token: ssoToken,
        },
      };
      var _ue = document.createElement('script');
      _ue.type = 'text/javascript';
      _ue.async = true;
      _ue.id = 'script_userecho';
      _ue.src =
        ('https:' == document.location.protocol ? 'https://' : 'http://') +
        'cdn.userecho.com/js/widget-1.4.gz.js';
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(_ue, s);
    }
  };
  loadUpdatedData = () => {
    getUpdates()
      .then((res) => {
        this.props.setUpdates(res.data);
      })
      .catch((err) => {
        catchPromise(err, this.loadUpdatedData, this.errorCb);
      });
  };

  render() {
    const { path, exact, component: Component } = this.props;

    {
      this.state.isAuth && this.loadUpdatedData();
    }
    return this.state.isAuth === true &&
      this.state.loaded && this.props.checkFunction && !this.props.checkFunction(this.props.user) ? (
      <Redirect to="/app" />
    ) : this.state.isAuth === true &&
      this.state.loaded &&
      !this.state.pageError ? (
      <Route
        path={path}
        exact={exact}
        component={(props) => (
          <div
            className={`flexableBlock ${
              this.state.userData.admin_mode ? 'asUser' : ''
            }`}
          >
            {/* <Header /> */}
            {this.state.userData.admin_mode && (
              <div className="text-center asUser-panel">
                <h4>
                  Сейчас Вы просматриваете пользователя{' '}
                  {this.state.userData.user.email}
                </h4>
              </div>
            )}
            <div className={`page-content ${history.location.pathname.includes('/app/broadcasts') ? 'broadcasts-page-content' : ''}`}>
              <Sidebar />
              <Baron>
                <div className="content-wrapper">
                  {/* <HeaderNews /> */}
                  <div className="content">
                    <Component {...props} />
                  </div>
                  {/*<Footer />*/}
                </div>
              </Baron>
            </div>
          </div>
        )}
      />
    ) : (
      <div>
        {this.state.loaded && this.state.pageError ? (
          <div>
            <Route
              path={path}
              exact={exact}
              component={(props) => (
                <div className="flexableBlock">
                  {/* <Header /> */}
                  <div className="page-content">
                    <Sidebar />
                    <div className="content-wrapper">
                      <HeaderNews isError={true} />
                      <div className="content">
                        <h3 className="text-center text-danger">
                          Произошла ошибка
                        </h3>
                      </div>
                      {/*<Footer />*/}
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setTradingAccounts: (tradingAccounts) =>
    dispatch(setTradingAccounts(tradingAccounts)),
  setLastNews: (lastNews) => dispatch(setLastNews(lastNews)),
  setUpdates: (updates) => dispatch(setUpdates(updates)),
  setSettings: (settings) => dispatch(setSettings(settings))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
