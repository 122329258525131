import React from "react";
import { Redirect } from "react-router";
import amplitude from 'amplitude-js';

import { confirmRegister } from "../utils/userHttp";

export default class ConfirmRegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      succesRegister: null,
      message: null
    }
  }

  componentDidMount() {
    amplitude.getInstance().logEvent('confirm_register_page.page_view');

    confirmRegister({
      registration_confirmation_token: this.props.match.params.registration_confirmation_token,
    }).then(() => {
      this.setState({ isLoading: false, succesRegister: true, message: "Ваша учетная запись подтверждена, введите email и пароль для входа на сайт" });
    }).catch((err) => {
      this.setState({ isLoading: false, succesRegister: false, message: err.response.data.message });
    });
  }

  render() {
    return (
      <div className="body landingPage">
        {!this.state.isLoading ? (
          <Redirect to={{
            pathname: '/',
            state: { succesRegister: this.state.succesRegister, message: this.state.message }
          }} />
        ) : null}
      </div>
    )
  }
}
