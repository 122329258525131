import { SET_SETTINGS } from "../actions/settings";

// Expenses Reducer
const reducerDefaultState = {};

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_SETTINGS:
      return action.settings;
    default:
      return state;
  }
};
