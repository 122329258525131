import React from "react";
import InputMask from "react-input-mask";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import Baron from "react-baron/dist/es5";
import "moment/locale/ru";

export default class AnketaPassportData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevPage: props.prevPage,
      nextPage: props.nextPage,
      validate: false,
      validateActiveFields: {
        d__foreign__passport__series: false,
        d__foreign__passport__number: false,
        d__foreign__passport__department_code: false,
      },
      d__foreign__passport__series:
        props.data.d__foreign__passport__series || "",
      d__foreign__passport__number:
        props.data.d__foreign__passport__number || "",
      d__foreign__passport__issued_by:
        props.data.d__foreign__passport__issued_by || "",
      d__foreign__passport__issued_date: props.data
        .d__foreign__passport__issued_date
        ? moment(
            props.data.d__foreign__passport__issued_date,
            "YYYY-MM-DD"
          )
        : null,
      d__foreign__passport__department_code:
        props.data.d__foreign__passport__department_code || "",
      d__foreign__passport__valid_until: props.data
        .d__foreign__passport__valid_until
        ? moment(
            props.data.d__foreign__passport__valid_until,
            "YYYY-MM-DD"
          )
        : null,
      selectIsOpened: false,
    };
  }

  componentDidMount() {
    $("body").on("click", () => {
      if (this.state.selectIsOpened) {
        setTimeout(() => this.setState({ selectIsOpened: false }), 100);
      }
      if ($(".customSelectListMonth").hasClass("isShowSelect"))
        setTimeout(() => {
          $(".customSelectListMonth").removeClass("isShowSelect");
        }, 50);
      if ($(".customSelectListYear").hasClass("isShowSelect"))
        setTimeout(() => {
          $(".customSelectListYear").removeClass("isShowSelect");
        }, 50);
    });
  }

  action = (action, currIndex, dest) => {
    this.setState({ validate: true });
    this.setState({
      validateActiveFields: {
        d__foreign__passport__series: true,
        d__foreign__passport__number: true,
        d__foreign__passport__department_code: true,
      },
    });
    if (this.validating() || dest === "prev") {
      this.props.action(action, currIndex, "passportData", {
        d__foreign__passport__series:
          this.state.d__foreign__passport__series,
        d__foreign__passport__number:
          this.state.d__foreign__passport__number,
        d__foreign__passport__issued_by:
          this.state.d__foreign__passport__issued_by,
        d__foreign__passport__issued_date:
          this.state.d__foreign__passport__issued_date &&
          this.state.d__foreign__passport__issued_date.format(
            "YYYY-MM-DD"
          ),
        d__foreign__passport__department_code:
          this.state.d__foreign__passport__department_code,
        d__foreign__passport__valid_until:
          this.state.d__foreign__passport__valid_until &&
          this.state.d__foreign__passport__valid_until.format(
            "YYYY-MM-DD"
          ),
      });
    }
  };
  validating = () => {
    return (
      this.state.d__foreign__passport__series.length != "" &&
      this.state.d__foreign__passport__number.length != "" &&
      this.state.d__foreign__passport__issued_by != "" &&
      this.state.d__foreign__passport__issued_date != null &&
      this.state.d__foreign__passport__issued_date != "" &&
      // this.state.d__foreign__passport__valid_until != null &&
      // this.state.d__foreign__passport__valid_until != "" &&
    (this.state.d__foreign__passport__valid_until === null ||
        (this.state.d__foreign__passport__valid_until && this.state.d__foreign__passport__valid_until >
        this.state.d__foreign__passport__issued_date))
    );
  };
  onPassportSeriaChange = (e) => {
    const d__foreign__passport__series = e.target.value;
    if (
      !d__foreign__passport__series ||
      d__foreign__passport__series.match(/^[0-9A-Za-z-а-яА-Я]+$/)
    ) {
      this.setState(() => ({
        d__foreign__passport__series,
        validateActiveFields: {
          ...this.state.validateActiveFields,
          d__foreign__passport__series: false,
        },
      }));
    }
  };
  onPassportNumChange = (e) => {
    const d__foreign__passport__number = e.target.value;
    if (
      !d__foreign__passport__number ||
      d__foreign__passport__number.match(/^[0-9A-Za-z]+$/)
    ) {
      this.setState(() => ({
        d__foreign__passport__number,
        validateActiveFields: {
          ...this.state.validateActiveFields,
          d__foreign__passport__number: false,
        },
      }));
    }
  };
  onPassportWhosChange = (e) => {
    const d__foreign__passport__issued_by = e.target.value;
    this.setState(() => ({ d__foreign__passport__issued_by }));
  };
  onPassportDateChange = (d__foreign__passport__issued_date) => {
    this.setState(() => ({ d__foreign__passport__issued_date }));
  };
  onPassortValidUntilChange = (d__foreign__passport__valid_until) => {
    console.log(d__foreign__passport__valid_until);
    this.setState(() => ({ d__foreign__passport__valid_until }));
  };
  onPassportCodeWhereChange = (e) => {
    const d__foreign__passport__department_code = e.target.value;
    this.setState(() => ({
      d__foreign__passport__department_code,
      validateActiveFields: {
        ...this.state.validateActiveFields,
        d__foreign__passport__department_code: false,
      },
    }));
  };
  changeSelect = (name, value) => {
    if (value) $('.' + name).addClass('isShowSelect');
    else $('.' + name).removeClass('isShowSelect');
  };
  renderMonthElement = ({ month, onMonthSelect, onYearSelect, isValidUntil }) => {
    let rows = [];
    for (let i = 0; i < 100; i++) {
      rows.push(i);
    }

    const addToYear = isValidUntil ? 50 : 0;

    return (
      <div
        className="singleDatePickerSelect"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="customSelect customSelectSingleDatepicker">
          <div
            className="customSelectedItem"
            onClick={() => {
              this.changeSelect("customSelectListMonth", true);
            }}
          >
            {month.format("MMMM")}
          </div>
          {
            <ul className="customSelectList customSelectListMonth">
              <Baron>
                {moment.months().map((label, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      onMonthSelect(month, index);
                      this.changeSelect("customSelectListMonth", false);
                    }}
                  >
                    {label}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
        <div className="customSelect customSelectSingleDatepicker customSelectSingleDatepicker-year">
          <div
            className="customSelectedItem"
            onClick={() => {
              this.changeSelect("customSelectListYear", true);
            }}
          >
            {month.year()}
          </div>
          {
            <ul className="customSelectList customSelectListYear">
              <Baron>
                {rows.map((row) => (
                  <li
                    key={row}
                    onClick={() => {
                      onYearSelect(month, moment().year() + addToYear - row);
                      this.changeSelect("customSelectListYear", false);
                    }}
                  >
                    {moment().year() + addToYear - row}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <h2 className="subtitle mt-3">Паспорт</h2>
        <div style={{ display: "flex" }}>
          <div className="col-lg-4 pl-0">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__foreign__passport__series == ""
                    ? "hidden"
                    : ""
                }`}
              >
                Серия
              </label>
              <input
                className={`inputUnderLine ${
                  this.state.validate &&
                  this.state.d__foreign__passport__series == "" &&
                  this.state.validateActiveFields
                    .d__foreign__passport__series
                    ? "inputValidateCurrentWrong"
                    : ""
                } ${
                  this.state.validate &&
                  this.state.d__foreign__passport__series == ""
                    ? "inputValidateWrong"
                    : ""
                } ${
                  this.state.validate &&
                  this.state.d__foreign__passport__series != ""
                    ? "inputValidateCorrect"
                    : ""
                }`}
                placeholder="Серия"
                value={this.state.d__foreign__passport__series}
                onChange={this.onPassportSeriaChange}
              />
            </div>
          </div>
          <div className="col-lg-4 pl-0">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__foreign__passport__number == ""
                    ? "hidden"
                    : ""
                }`}
              >
                Номер
              </label>
              <input
                className={`inputUnderLine ${
                  this.state.validate &&
                  this.state.d__foreign__passport__number == "" &&
                  this.state.validateActiveFields
                    .d__foreign__passport__number
                    ? "inputValidateCurrentWrong"
                    : ""
                } ${
                  this.state.validate &&
                  this.state.d__foreign__passport__number == ""
                    ? "inputValidateWrong"
                    : ""
                } ${
                  this.state.validate &&
                  this.state.d__foreign__passport__number != ""
                    ? "inputValidateCorrect"
                    : ""
                }`}
                placeholder="Номер"
                value={this.state.d__foreign__passport__number}
                onChange={this.onPassportNumChange}
              />
            </div>
          </div>
          <div className="col-lg-4 px-0">
            <div className="formField-container">
              <label
                className="docCreate-dateLabel"
                style={{
                  display: "inline-block",
                  width: "85px",
                  opacity: "0.8",
                  letterSpacing: "0.06px",
                  fontWeight: "normal",
                }}
              >
                Дата выдачи
              </label>
              <div
                className={`dateBubble ${
                  this.state.validate && 
                  this.state.d__foreign__passport__issued_date === null 
                    ? 'inputDateValidateWrong' 
                    : ''
                } ${
                  this.state.validate &&
                  this.state.d__foreign__passport__issued_date != null
                    ? 'inputDateValidateCorrect'
                    : ''
                } ${
                  this.state.validate &&
                  this.state.d__foreign__passport__issued_date != null
                    ? 'inputDateValidateCorrect'
                    : ''
                } ${
                  this.state.focusedPassport ? 'inputDateValidateFocused' : ''
                }`}
                style={{
                  position: "static",
                  display: "inline-block",
                  width: "85px",
                  marginLeft: "5px",
                }}
              >
                <SingleDatePicker
                  date={this.state.d__foreign__passport__issued_date}
                  placeholder=""
                  onDateChange={this.onPassportDateChange}
                  focused={this.state.focusedPassport}
                  onFocusChange={() =>
                    this.setState({
                      focusedPassport: !this.state.focusedPassport,
                    })
                  }
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  hideKeyboardShortcutsPanel={true}
                  renderMonthElement={(e) => this.renderMonthElement({ ...e, isValidUntil: false })}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="col-lg-12 px-0">
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  this.state.d__foreign__passport__issued_by == ""
                    ? "hidden"
                    : ""
                }`}
              >
                Кем выдан
              </label>
              <input
                type="text"
                className={`inputUnderLine ${
                  this.state.validate &&
                  this.state.d__foreign__passport__issued_by == ""
                    ? "inputValidateWrong"
                    : ""
                } ${
                  this.state.validate &&
                  this.state.d__foreign__passport__issued_by != ""
                    ? "inputValidateCorrect"
                    : ""
                }`}
                placeholder="Кем выдан"
                value={this.state.d__foreign__passport__issued_by}
                onChange={this.onPassportWhosChange}
              />
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-lg-6 pl-0">
            <div className="formField-container position-relative col-lg-12 px-0 d-flex align-items-center">
              <label
                className="docCreate-dateLabel"
                style={{
                  display: "inline-block",
                  opacity: "0.8",
                  letterSpacing: "0.06px",
                  fontWeight: "normal",
                }}
              >
                срок действия <br /> (при наличии)
              </label>
              <div
                className="dateBubble"
                style={{
                  position: "static",
                  display: "inline-block",
                  flex: 1,
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              >
                <SingleDatePicker
                  date={this.state.d__foreign__passport__valid_until}
                  placeholder=""
                  onDateChange={this.onPassortValidUntilChange}
                  focused={this.state.focusedPassportValidUntil}
                  onFocusChange={() =>
                    this.setState({
                      focusedPassportValidUntil:
                        !this.state.focusedPassportValidUntil,
                    })
                  }
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  hideKeyboardShortcutsPanel={true}
                  renderMonthElement={(e) => this.renderMonthElement({ ...e, isValidUntil: true })}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 px-0">
            <div className="formField-container position-relative col-lg-12 px-0">
              <label
                className={`floating-label ${
                  this.state.d__foreign__passport__department_code == ""
                    ? "hidden"
                    : ""
                }`}
              >
                Код подразделения (при наличии)
              </label>
              <InputMask
                className={`inputUnderLine`}
                placeholder="Код подразделения (при наличии)"
                value={this.state.d__foreign__passport__department_code}
                onChange={this.onPassportCodeWhereChange}
              />
            </div>
          </div>
        </div>
        <div>
          {this.state.d__foreign__passport__valid_until &&
            this.state.d__foreign__passport__issued_date &&
            this.state.d__foreign__passport__valid_until <=
              this.state.d__foreign__passport__issued_date && (
              <div className="text-danger col-lg-12 pl-0">
                Срок действия паспорта должен быть позже его выдачи
              </div>
            )}
        </div>
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <button
            className="btn btnBack"
            onClick={() => {
              this.action(this.props.previous, this.state.prevPage || 1, "prev");
            }}
          >
            Назад
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              this.action(this.props.next, this.state.nextPage || 3);
            }}
          >
            Далее
          </button>
        </div>
      </div>
    );
  }
}
