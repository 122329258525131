import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';
import { DateRangePicker } from 'react-dates';
import Baron from 'react-baron/dist/es5';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { catchPromise } from '../utils/http';
import { getDealsAnalysisSymbols, getBoards } from '../utils/analysisHttp';
import { setDealsAnalysisSymbols } from '../actions/analysis';
import {
  sumBgColor,
  currencyFormat,
  sumTotalBgColor,
} from '../utils/currencyUtil';
import { getAccountBoards } from '../utils/topHttp';

am4core.useTheme(am4themes_animated);
export class DealsAnalysisSymbolsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      boardsList: [],
      boardId: '',
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
      accountFilter: props.tradingAccount.id || '',
      enableSections: [],
      calendarFocused: null,
      isLoading: false,
      pageError: false,
    };

    this.analysisTable = React.createRef();
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    moment.locale('ru');
    
    if (this.state.accountFilter) {
      this.getAccountBoards();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.tradingAccount.id !== nextProps.tradingAccount.id) {
      this.setState(
        { accountFilter: nextProps.tradingAccount.id || '' },
        () => {
          if(this.state.accountFilter) this.getAccountBoards();
        }
      );
    }
  }
  createChart = () => {
    let chart = am4core.create('chartdiv', am4charts.XYChart);
    chart.paddingRight = 0;

    chart.data = [];

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.fontSize = '8px';
    valueAxis.renderer.labels.template.fill = am4core.color('#8f98b9');
    valueAxis.renderer.grid.template.disabled = true;
    // valueAxis.renderer.labels.template.disabled = true;
    valueAxis.numberFormatter.numberFormat = '#';
    // valueAxis.cursorTooltipEnabled = false;
    let axisTooltip = valueAxis.tooltip;
    axisTooltip.background.fill = am4core.color('#1e62d6');
    axisTooltip.background.strokeWidth = 0;
    axisTooltip.background.cornerRadius = 9;
    axisTooltip.background.pointerLength = 0;
    axisTooltip.dx = -5;
    axisTooltip.fontSize = '10px';
    valueAxis.title.text = '';

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'symbol';
    categoryAxis.title.text = '';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    // categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    // categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.fontSize = '8px';
    categoryAxis.renderer.labels.template.fill = am4core.color('#8f98b9');
    categoryAxis.cursorTooltipEnabled = false;
    let categoryTooltip = categoryAxis.tooltip;
    categoryTooltip.background.fill = am4core.color('#1e62d6');
    categoryTooltip.background.strokeWidth = 0;
    categoryTooltip.background.cornerRadius = 9;
    categoryTooltip.background.pointerLength = 0;
    categoryTooltip.minWidth = 80;
    categoryTooltip.dy = 5;
    categoryTooltip.fontSize = '8px';
    /* Decorate axis tooltip content */
    // categoryTooltip.adapter.add("getTooltipText", (text) => {
    //     return text;
    // });
    // chart.xAxes[0].tooltip.background.fill = am4core.color("#ffffff");

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'sum';
    series.dataFields.categoryX = 'symbol';
    series.columns.template.width = am4core.percent(100);
    series.name = 'Процент';
    series.tooltip.disabled = true;
    series.tooltipText = '{valueY}[/]';
    series.stacked = true;

    let columnTemplate = series.columns.template;
    columnTemplate.tooltipText = '{categoryX}: {valueY}[/]';
    columnTemplate.fillOpacity = 1;
    columnTemplate.strokeOpacity = 0;
    columnTemplate.fill = am4core.color('#3fa764');
    let shadow = new am4core.DropShadowFilter();
    shadow.height = 100;
    shadow.dx = -2;
    shadow.dy = 0;
    shadow.opacity = 0.25;
    columnTemplate.filters.push(shadow);
    columnTemplate.adapter.add('fill', function (fill, target) {
      if (target.dataItem && parseInt(target.dataItem.valueY) < 0) {
        return am4core.color('#de645d');
      } else {
        return fill;
      }
    });

    let range = categoryAxis.axisRanges.create();
    range.value = '';
    range.label.text = '';

    chart.cursor = new am4charts.XYCursor();

    /* Configure cursor lines */
    chart.cursor.lineX.stroke = am4core.color('#e1e2e8');
    chart.cursor.lineX.strokeWidth = 1;
    chart.cursor.lineX.strokeOpacity = 0.9;
    chart.cursor.lineX.strokeDasharray = '';

    chart.cursor.lineY.stroke = am4core.color('#e1e2e8');
    chart.cursor.lineY.strokeWidth = 1;
    chart.cursor.lineY.strokeOpacity = 0.9;
    chart.cursor.lineY.strokeDasharray = '';

    this.chart = chart;
  };
  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  getAccountBoards = () => {
    getAccountBoards(this.state.accountFilter || this.props.tradingAccount.id)
    .then((res) => {
      this.setState({
        enableSections: res.data.boards
      }, () => {
        if(this.state.enableSections.length > 0) {
          this.onBoardChange(this.state.enableSections[0].id, true);
        }
      })
    })
    .catch(err => {
      if(err.response.status == 401) {
        catchPromise(err, this.getAccountBoards, this.errorCb);
      }
      if(err.response.status == 404) {
        this.setState({enableSections: []})
      }
    })
  }

  loadBoards = () => {
    getBoards()
      .then((res) => {
        this.setState({
          boardsList: res.data.boards,
          boardId: res.data.boards.length > 0 ? res.data.boards[0].id : '',
        });
        if (this.props.tradingAccount.id) {
          this.loadAnalysis({
            accountId: this.state.accountFilter,
            boardId: res.data.boards.length > 0 ? res.data.boards[0].id : '',
            dateStart: this.state.startDate.format('DD-MM-YYYY'),
            dateEnd: this.state.endDate.format('DD-MM-YYYY'),
          });
          if (res.data.boards.length > 0)
            this.onBoardChange(res.data.boards[0].id);
        }
      })
      .catch((err) => {
        catchPromise(err, this.loadBoards, this.errorCb);
      });
  };
  loadAnalysis = (data) => {
    if (this.state.accountFilter) {
      if (this.state.enableSections.length > 0) {
        this.setState({ isLoading: true });
        getDealsAnalysisSymbols(data)
          .then((res) => {
            this.setState({ isLoading: false });
            this.props.setDealsAnalysisSymbols(res.data);
            if (!this.chart) this.createChart();
            this.updateChartData(
              res.data.symbols,
              res.data.symbols_total_abs,
              res.data.sum_symbols_total_abs
            );
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            catchPromise(err, this.loadAnalysis.bind(this, data), this.errorCb);
          });
      }
    }
  };
  onBoardChange = (id, isChangedAcc) => {
      this.setState({ boardId: id }, () => {
        if(isChangedAcc){
          $('.isBoard').removeClass('active show');
          $('.board-' + id).addClass('active show');
        }
      });
      this.loadAnalysis({
        accountId: this.state.accountFilter,
        boardId: id,
        dateStart: this.state.startDate.format('DD-MM-YYYY'),
        dateEnd: this.state.endDate.format('DD-MM-YYYY'),
      });
  };
  updateChartData = (symbols, totals, totalSum) => {
    let tempChartData = [];
    for (let i = 0; i < symbols.length; i++) {
      tempChartData.push({
        symbol: symbols[i],
        sum: totals[i],
      });
    }
    tempChartData.push({
      symbol: 'Сумма',
      sum: totalSum,
    });
    this.chart.data = tempChartData;
    this.updateTdWidths();
  };
  updateTdWidths() {
    this.analysisTable.current.style.tableLayout = "auto";
    const tds = document.getElementsByClassName('analysisTableTd');
    [...tds].forEach(td => {
      td.style.width = null;
    });
    let max = 0;
    [...tds].forEach(td => {
      if (parseFloat(getComputedStyle(td).width) > max) {
        max = parseFloat(getComputedStyle(td).width);
      }
    });
    [...tds].forEach(td => {
      td.style.width = `${max}px`;
    });
    this.analysisTable.current.style.tableLayout = "fixed";
  }
  onChangeAccountFilter = (e) => {
    const accountFilter = e.target.value;
    this.setState({ accountFilter });
    this.loadAnalysis({
      accountId: accountFilter,
      boardId: this.state.boardId,
      dateStart: this.state.startDate.format('DD-MM-YYYY'),
      dateEnd: this.state.endDate.format('DD-MM-YYYY'),
    });
  };
  onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate }, () => {
      if (this.state.startDate && this.state.endDate) {
        this.loadAnalysis({
          accountId: this.state.accountFilter,
          boardId: this.state.boardId,
          dateStart: startDate.format('DD-MM-YYYY'),
          dateEnd: endDate.format('DD-MM-YYYY'),
        });
      }
    });
  };
  onFocusChange = (calendarFocused) => {
    this.setState({ calendarFocused });
  };
  selectCalendarDates = (range) => {
    switch (range) {
      case 'currentMonth':
        this.onDatesChange({
          startDate: moment().startOf('month'),
          endDate: moment().endOf('month'),
        });
        break;
      case 'prevMonth':
        this.onDatesChange({
          startDate: moment()
            .startOf('month')
            .subtract(1, 'ms')
            .startOf('month'),
          endDate: moment().startOf('month').subtract(1, 'ms').endOf('month'),
        });
        break;
      case 'currentQuarter':
        this.onDatesChange({
          startDate: moment().startOf('quarter'),
          endDate: moment().endOf('quarter'),
        });
        break;
      case 'prevQuarter':
        this.onDatesChange({
          startDate: moment()
            .startOf('quarter')
            .subtract(1, 'ms')
            .startOf('quarter'),
          endDate: moment()
            .startOf('quarter')
            .subtract(1, 'ms')
            .endOf('quarter'),
        });
        break;
      default:
        break;
    }
  };
  calendarBtns = () => {
    return (
      <div className="calendarBtns">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.selectCalendarDates('currentMonth')}
        >
          Текущий месяц
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.selectCalendarDates('prevMonth')}
        >
          Прошлый месяц
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.selectCalendarDates('currentQuarter')}
        >
          Текущий квартал
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.selectCalendarDates('prevQuarter')}
        >
          Прошлый квартал
        </button>
      </div>
    );
  };

  render() {
    return (
      <div className="analysis-symbols card-content">
        {!this.state.pageError ? (
          <div>
            <ul className="nav nav-tabs boardsTabs">
              {
                this.state.enableSections.map((section, index) => (
                  <li
                    className="nav-item"
                    onClick={() => {
                      this.onBoardChange(section.id);
                    }}
                  >
                    <span className={`isBoard nav-link text-center board-${section.id}`} data-toggle="tab">
                      <span>{section.title.replace(' MOEX', '')}</span>
                    </span>
                  </li>
                ))
              }
              <li className="closeDealsBtns">
                <div className="analysisSymbols-datesFilter dateUnderline">
                  <DateRangePicker
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onDatesChange={this.onDatesChange}
                    startDatePlaceholderText="Начало"
                    endDatePlaceholderText="Конец"
                    focusedInput={this.state.calendarFocused}
                    onFocusChange={this.onFocusChange}
                    numberOfMonths={2}
                    isOutsideRange={() => false}
                    hideKeyboardShortcutsPanel={true}
                    // renderCalendarInfo={this.calendarBtns}
                  />
                </div>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane fade active show"
                style={{ overflowX: 'auto' }}
              >
                <div
                  className="table"
                  style={{
                    // width:
                    //   this.props.dealsAnalysisSymbols.symbols_total_abs &&
                    //   this.props.dealsAnalysisSymbols.symbols_total_abs.length >
                    //     12
                    //     ? 100 +
                    //       this.props.dealsAnalysisSymbols.symbols_total_abs
                    //         .length *
                    //         3 +
                    //       '%'
                    //     : '100%',
                  }}
                >
                  <div className="analysisSymbolsTable-titles">
                    <table className="tableLayoutFixes">
                      <thead>
                        <tr>
                          <td>ДАТА</td>
                          {this.props.dealsAnalysisSymbols.symbols.map(
                            (symbol, index) => (
                              <td key={index} className="text-center analysisTableTd">
                                {symbol}
                              </td>
                            )
                          )}
                          <td className="text-center analysisTableTd">СУММА</td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {
                    <div>
                      <div
                        className="analysis-symbols-data"
                        style={{
                          display:
                            this.props.dealsAnalysisSymbols.symbols.length >
                              0 && !this.state.isLoading
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <Baron>
                          <div style={{
                            height: 400,
                            width: "fit-content"
                          }}>
                            <table className="tableLayoutFixes analysisSymbolsTable-data" ref={this.analysisTable}>
                              <tbody>
                                {this.props.dealsAnalysisSymbols.data.map(
                                  (analys, index) => (
                                    <tr key={index}>
                                      <td className="analysisDaysTable-dataDate">
                                        {analys.date}
                                      </td>
                                      {analys.values.map((value, index) => (
                                        <td
                                          key={index}
                                          className={`text-right analysisTableTd ${
                                            value ? sumBgColor(value) : ''
                                          }`}
                                          style={{ paddingRight: '6px' }}
                                        >
                                          {value ? currencyFormat(value) : ''}
                                        </td>
                                      ))}
                                      <td
                                        className={`text-right analysisTableTd ${sumTotalBgColor(
                                          analys.sum
                                        )}`}
                                        style={{
                                          fontWeight: 'bold',
                                          paddingRight: '6px',
                                        }}
                                      >
                                        {currencyFormat(analys.sum)}
                                      </td>
                                    </tr>
                                  )
                                )}
                                <tr>
                                  <td
                                    className="analysisDaysTable-dataDate"
                                    style={{ fontWeight: 'bold' }}
                                  >
                                    Итого
                                  </td>
                                  {this.props.dealsAnalysisSymbols.symbols_total_abs.map(
                                    (value, index) => (
                                      <td
                                        key={index}
                                        className={`text-right ${
                                          value ? sumTotalBgColor(value) : ''
                                        }`}
                                        style={{
                                          fontWeight: 'bold',
                                          paddingRight: '6px',
                                        }}
                                      >
                                        {value ? currencyFormat(value) : ''}
                                      </td>
                                    )
                                  )}
                                  <td
                                    className={`text-right ${
                                      this.props.dealsAnalysisSymbols
                                        .sum_symbols_total_abs
                                        ? sumTotalBgColor(
                                            this.props.dealsAnalysisSymbols
                                              .sum_symbols_total_abs
                                          )
                                        : ''
                                    }`}
                                    style={{
                                      fontWeight: 'bold',
                                      paddingRight: '6px',
                                    }}
                                  >
                                    {currencyFormat(
                                      this.props.dealsAnalysisSymbols
                                        .sum_symbols_total_abs
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          {/* <div className="card chartContainer" style={{display: this.props.dealsAnalysisSymbols.symbols.length > 0 ? 'block' : 'none'}}> */}
                            <div
                              className="chartContainer"
                              style={{ paddingLeft: '32px' }}
                            >
                              <div className="chart-logoRemover"></div>
                              {/* <div id="chartdiv" style={{ width: "100%", height: "250px", display: this.props.dealsAnalysisSymbols.symbols.length > 0 ? 'block' : 'none'}}></div> */}
                              <div
                                id="chartdiv"
                                style={{ width: '100%', height: '250px' }}
                              ></div>
                            </div>
                          </div>
                        </Baron>
                      </div>
                      {(this.props.dealsAnalysisSymbols.symbols.length == 0 ||
                        this.state.isLoading) && (
                        <div className="emptyDashboard-plug">
                          {this.state.isLoading ? (
                            <div className="mt-1 text-center spinner-container isLoadingSpinner">
                              <span>
                                <img
                                  src="/images/ui/load-spinner.png"
                                  className="spinner"
                                  alt=""
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="emptyData">нет данных</div>
                          )}
                        </div>
                      )}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dealsAnalysisSymbols: state.dealsAnalysisSymbols,
    tradingAccounts: state.tradingAccounts,
    tradingAccount: state.tradingAccount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setDealsAnalysisSymbols: (dealsAnalysisSymbols) =>
    dispatch(setDealsAnalysisSymbols(dealsAnalysisSymbols)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealsAnalysisSymbolsPage);
