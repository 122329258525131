import React from 'react';
import { Link } from 'react-router-dom';

export default class DocRejected extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {}
  render() {
    return (
      <div>
        <h2 className="text-center font-weight-black text-danger">
          <i className="icon-close2 cancelTypeDoc-icon"></i>
        </h2>
        <h5>Документ был отклонен. Причина: {this.props.doc.cancel_reason}</h5>
        <div>
          {this.props.doc.status.code === 'REJECTED' &&
            this.props.doc.initiator === 'trader' && (
              <Link
                to={`/app/documents/moving?code=${this.props.doc.type_code}`}
              >
                Перейти к созданию документа
              </Link>
            )}
        </div>
      </div>
    );
  }
}
