import React from 'react';
import { connect } from 'react-redux';
import Baron from 'react-baron/dist/es5';

import { history } from '../../routers/AppRouter';
import { catchPromise } from '../../utils/http';
import { createDocument, getContracts } from '../../utils/documentsHttp';
import DocumentCreatePayment from './DocumentCreatePayment';
import ContractTermination from './ContractTermination';
import ChangeTaxpayerIndividual from './ChangeTaxpayerIndividual';
import ChangeTaxpayerEnterpreneur from './ChangeTaxpayerEnterpreneur';
import ChangeAnketaForm from './docAnketa/ChangeAnketaForm';

export class DocumentCreatePopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      d__extra__fl_type: 'ENTERPRENEUR',
      currentForm: '',
      contracts: [],
      selectIsOpened: false,
      error: undefined,
      isSending: false,
      pageError: false,
    };
  }

  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    this.loadContracts();
    $('body').on('click', () => {
      if (this.state.selectIsOpened) {
        setTimeout(() => this.setState({ selectIsOpened: false }), 100);
      }
    });
  }
  loadContracts = () => {
    if (this.state.contracts.length == 0) {
      getContracts()
        .then((res) => {
          this.setState({ contracts: res.data.contracts });

          let params = new URLSearchParams(window.location.search);
          if (params.has('code'))
            this.setState({ currentForm: params.get('code') });
        })
        .catch((err) => {
          catchPromise(err, this.loadContracts, this.errorCb);
        });
    }
  };
  onDocChange = (value) => {
    const currentForm = value;
    window.history.pushState(
      null,
      '',
      `${window.location.pathname}?code=${currentForm}`
    );
    this.setState({ selectIsOpened: false, currentForm });
  };
  onSubmitPay = (id) => {
    history.push(`/app/documents/${id}`);
  };
  onSubmitTermination = (data) => {
    this.setState({ isSending: true });
    createDocument(data)
      .then((res) => {
        this.setState({ isSending: false });
        history.push(`/app/documents/${res.data.doc_id}`);
      })
      .catch((err) => {
        catchPromise(
          err,
          this.onSubmitTermination.bind(this, data),
          this.errorCb
        );
      });
  };
  onSubmitTaxIndividual = (data) => {
    this.setState({ isSending: true });
    createDocument(data)
      .then((res) => {
        this.setState({ isSending: false });
        history.push(`/app/documents/${res.data.doc_id}`);
      })
      .catch((err) => {
        catchPromise(
          err,
          this.onSubmitTaxIndividual.bind(this, data),
          this.errorCb
        );
      });
  };
  onSubmitTaxEnterpreneur = (data) => {
    this.setState({ isSending: true });
    createDocument(data)
      .then((res) => {
        this.setState({ isSending: false });
        history.push(`/app/documents/${res.data.doc_id}`);
      })
      .catch((err) => {
        catchPromise(
          err,
          this.onSubmitTaxEnterpreneur.bind(this, data),
          this.errorCb
        );
      });
  };
  onSubmitAnketa = (data) => {
    this.setState({ isSending: true });
    createDocument(data)
      .then((res) => {
        this.setState({ isSending: false });
        history.push(`/app/documents/${res.data.doc_id}`);
      })
      .catch((err) => {
        catchPromise(err, this.onSubmitAnketa.bind(this, data), this.errorCb);
      });
  };

  render() {
    return (
      <div className="documentCreatePage">
        <form onSubmit={this.onSubmit}>
          <h1 className="title card-content">Создание документа</h1>
          <div className="card card-content">
            {!this.state.pageError ? (
              <div>
                <div className="card-body">
                  <div className="docCreateChoice">
                    <div className="docCreateChoice-title">Документ</div>
                    <div className="docCreateChoice-select">
                      <div className="customSelect">
                        <div
                          className="customSelectedItem"
                          onClick={() => {
                            this.setState({ selectIsOpened: true });
                          }}
                        >
                          {this.state.currentForm === 'CHANGE_PERSONAL_FORM' &&
                            'Изменение анкетных данных'}
                          {this.state.currentForm === 'CHANGE_FL_TYPE_TO_IP' &&
                            'Переход на ИП'}
                          {this.state.currentForm ===
                            'CHANGE_FL_TYPE_TO_INDIVIDUAL' &&
                            'Переход на Физ. лицо'}
                          {this.state.currentForm === 'CANCEL_CONTRACT' &&
                            'Расторжение договора'}
                          {this.state.currentForm === 'GET_MONEY' &&
                            'Поручение на выплату'}
                          <i className="toggle icon-arrow-down5"></i>
                        </div>
                        {this.state.selectIsOpened && (
                          <ul className="customSelectList">
                            <li
                              onClick={() => {
                                this.onDocChange('CHANGE_PERSONAL_FORM');
                              }}
                            >
                              Изменение анкетных данных
                            </li>
                            {this.props.user.user.fl_type === 'individual' && (
                              <li
                                onClick={() => {
                                  this.onDocChange('CHANGE_FL_TYPE_TO_IP');
                                }}
                              >
                                Переход на ИП
                              </li>
                            )}
                            {this.props.user.user.fl_type === 'ip' && (
                              <li
                                onClick={() => {
                                  this.onDocChange(
                                    'CHANGE_FL_TYPE_TO_INDIVIDUAL'
                                  );
                                }}
                              >
                                Переход на Физ. лицо
                              </li>
                            )}
                            <li
                              onClick={() => {
                                this.onDocChange('CANCEL_CONTRACT');
                              }}
                            >
                              Расторжение договора
                            </li>
                            <li
                              onClick={() => {
                                this.onDocChange('GET_MONEY');
                              }}
                            >
                              Поручение на выплату
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="docCreateContent">
                    <Baron>
                      {(() => {
                        switch (this.state.currentForm) {
                          case 'CHANGE_PERSONAL_FORM':
                            return (
                              <ChangeAnketaForm
                                onSubmit={this.onSubmitAnketa}
                                contracts={this.state.contracts}
                                isSending={this.state.isSending}
                              />
                            );
                          case 'CHANGE_FL_TYPE_TO_IP': {
                            if (this.props.user.user.fl_type === 'individual') {
                              return (
                                <ChangeTaxpayerEnterpreneur
                                  onSubmit={this.onSubmitTaxEnterpreneur}
                                  contracts={this.state.contracts}
                                  isSending={this.state.isSending}
                                />
                              );
                            } else return null;
                          }
                          case 'CHANGE_FL_TYPE_TO_INDIVIDUAL': {
                            if (this.props.user.user.fl_type === 'ip') {
                              return (
                                <ChangeTaxpayerIndividual
                                  onSubmit={this.onSubmitTaxIndividual}
                                  contracts={this.state.contracts}
                                  isSending={this.state.isSending}
                                />
                              );
                            } else return null;
                          }
                          case 'CANCEL_CONTRACT':
                            return (
                              <ContractTermination
                                onSubmit={this.onSubmitTermination}
                                contracts={this.state.contracts}
                                isSending={this.state.isSending}
                              />
                            );
                          case 'GET_MONEY':
                            return (
                              <DocumentCreatePayment
                                onSubmit={this.onSubmitPay}
                                accounts={
                                  this.props.tradingAccounts.trading_accounts
                                }
                                contracts={this.state.contracts}
                                isSending={this.state.isSending}
                              />
                            );
                          default:
                            null;
                        }
                      })()}
                    </Baron>
                  </div>
                </div>
              </div>
            ) : (
              <h3 className="text-center text-danger">Произошла ошибка</h3>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  tradingAccounts: state.tradingAccounts,
});

export default connect(mapStateToProps)(DocumentCreatePopup);
