import React from 'react';
import { Link } from 'react-router-dom';

import { catchPromise } from '../../utils/http';
import { getPhoneCode } from '../../utils/utilsHttp';

export default class DocNeedSms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      docId: props.docId,
      sms_code: '',
      sms_id: undefined,
      regState: 0,
      timer: 0,
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };

  onGetPhoneCode = () => {
    getPhoneCode({ mode: 'doc_sign' })
      .then((res) => {
        this.setState({
          sms_id: res.data.sms_id,
          regState: 1,
          timer: 60,
          error: undefined,
        });

        setInterval(() => {
          if (this.state.timer > 0)
            this.setState({ timer: this.state.timer - 1 });
        }, 1000);
      })
      .catch((err) => {
        catchPromise(err, this.onGetPhoneCode, this.errorCb);
      });
  };
  sendStep = () => {
    if (!this.state.sms_code) {
      this.setState({ error: 'Поля с * обязательны для заполнения.' });
      return;
    }

    this.props.onSubmit({
      id: parseInt(this.state.docId),
      sms_code: parseInt(this.state.sms_code),
      sms_id: this.state.sms_id,
    });
  };
  onSmsChange = (e) => {
    const sms_code = e.target.value;
    this.setState(() => ({ sms_code }));
  };

  render() {
    return (
      <div className="docPage-needSms">
        <div className="docPage-uploadDisc">
          1 Скачайте документы и ознакомьтесь.
        </div>
        <div>
          {this.props.files.length > 0 ? (
            <div className="docNeedScan-links">
              {
                <div>
                  {this.props.files.map((file, index) => (
                    <a
                      key={index}
                      className="btn btnLink mr-2 mb-2"
                      href={file.url}
                    >
                      <span>
                        Скачать
                        <img
                          src="/images/ui/uploadIcon.png"
                          className="uploadIcon"
                          alt=""
                        />
                      </span>
                    </a>
                  ))}
                  <div className="docPage-uploadDisc">
                    2 Подтвердите кодом из смс
                  </div>
                  <div className="">
                    {this.state.regState != 0 ? (
                      <div className="">
                        <input
                          type="text"
                          className="inputUnderLine"
                          placeholder="Код"
                          value={this.state.sms_code}
                          onChange={this.onSmsChange}
                        />
                      </div>
                    ) : null}
                    {this.state.regState != 0 ? (
                      <div className="mt-2">
                        {this.state.timer > 0 && (
                          <div>
                            Время до повторной отправки кода: {this.state.timer}
                          </div>
                        )}
                        {this.state.timer == 0 && (
                          <div
                            className="text-primary repeatSendSms"
                            onClick={() => {
                              this.onGetPhoneCode();
                            }}
                          >
                            Отправить код повторно
                          </div>
                        )}
                      </div>
                    ) : null}
                    {this.state.error && (
                      <h6 className="text-center text-danger">
                        {this.state.error}
                      </h6>
                    )}
                    <div className="">
                      {this.state.regState === 0 && (
                        <button
                          onClick={() => {
                            this.onGetPhoneCode();
                          }}
                          className="btn btnLink"
                        >
                          Выслать код
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              }
            </div>
          ) : !this.props.docsWaitingErr ? (
            <div className="docNeedScan-links">
              <span>
                <i className="icon-spinner2 spinner mr-2"></i> Пожалуйста,
                подождите, документы подготавливаются
              </span>
            </div>
          ) : (
            <div className="docNeedScan-links text-danger">
              {this.props.docsWaitingErr}
            </div>
          )}
        </div>
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <Link to="/app/documents/moving" className="btn btn-white">
            Отменить
          </Link>
          <button
            className="btn btn-success"
            disabled={this.state.regState != 1 && this.props.isSending}
            onClick={this.sendStep}
          >
            Отправить
            {this.props.isSending && (
              <i className="icon-spinner2 uploaderSpinner spinner"></i>
            )}
          </button>
        </div>
      </div>
    );
  }
}
