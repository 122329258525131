import React from "react";
import { connect } from "react-redux";

class GoogleAuthAddSave extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevPage: props.prevPage,
      nextPage: props.nextPage,
      otp_token: this.props.otp_token || null,
    };
  }

  action = (action, currIndex) => {
    this.props.action(action, currIndex);
  };

  render() {
    return (
      <div className="googleAuthAddSave">
        <div className="row">
          <h2 className="col-12 subtitle">Сохраните резервный ключ</h2>
        </div>
        <div>
          Перепишите на бумагу или сохраните в надежном месте ключ с этой
          страницы. Он понадобится для восстановления Google Authenticator, если
          вы потеряете доступ к своему мобильному устройству.
        </div>
        <div className="mt-4 text-center">
          <img src="/images/ui/pencil.png" alt="Pencil" className="pencil" />
          <div className="mt-3 mb-2">{this.state.otp_token}</div>
          <div className="m-auto text-secondary w-75">
            Для восстановления доступа при потере устройства и резервного ключа
            обратитесь в техподдержку {this.props.settings.SUPPORT_EMAIL}
          </div>
        </div>

        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <button
            className="btn btnBack"
            onClick={() => {
              this.action(this.props.previous, this.state.prevPage, "prev");
            }}
          >
            Назад
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              this.action(this.props.next, this.state.nextPage, "next");
            }}
          >
            Далее
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings
  };
};

export default connect(mapStateToProps)(GoogleAuthAddSave);