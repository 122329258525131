import React from "react";
import { connect } from "react-redux";
import Countries from "i18n-iso-countries";
Countries.registerLocale(require("i18n-iso-countries/langs/ru.json"));
Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import { Wizard, Steps, Step } from "react-albus";
import Baron from "react-baron/dist/es5";
import amplitude from 'amplitude-js';

import GoogleAuthRemoveVerify from "./GoogleAuthRemoveVerify";
import GoogleAuthRemoveReady from "./GoogleAuthRemoveReady";

const isCrypto = true;

export class GoogleAuthRemovePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anketaCurrStep: -1,
      foreignAddressesData: {},
    };
  }

  componentDidMount() {
    amplitude.getInstance().logEvent('google_auth_remove_page.page_view');

    if (isCrypto) {
      this.setState({ anketaCurrStep: 0 });
    }
  }

  actionStep = (action, currIndex, stepName, data) => {
    action();
    $(".progressLine-iconContainer").each(function (index, element) {
      $(element).removeClass("progressIcon-prev");
      $(element).removeClass("progressIcon-next");
      if (index < currIndex) {
        $(element).addClass("progressIcon-prev");
        $(element).find(".checkIcon").removeClass("hidden");
        $(element).find(".themeIcon").addClass("hidden");
        $(element).find(".numIcon").addClass("hidden");
      } else if (index == currIndex) {
        $(element).find(".checkIcon").addClass("hidden");
        $(element).find(".themeIcon").removeClass("hidden");
        $(element).find(".numIcon").addClass("hidden");
      } else if (index > currIndex) {
        $(element).addClass("progressIcon-next");
        $(element).find(".checkIcon").addClass("hidden");
        $(element).find(".themeIcon").addClass("hidden");
        $(element).find(".numIcon").removeClass("hidden");
      }
    });
    this.setState({ anketaCurrStep: currIndex });
  };

  render() {
    return (
      <div className="col-lg-8 offset-lg-2 profileForm">
        <div>
          <div className={`title card-content ${isCrypto ? "mb-0" : ""}`}>
            Отключение Google Authenticator
          </div>
          <div className="card card-content">
            <div className="card-body">
              <Baron>
                <Wizard
                  render={({ step, steps }) => (
                    <div>
                      <Steps key="isCrypto">
                        {[
                          {
                            id: "verify",
                            render: ({ next, previous }) => (
                              <GoogleAuthRemoveVerify
                                nextPage={1}
                                next={next}
                                previous={previous}
                                action={this.actionStep}
                              />
                            ),
                          },
                          {
                            id: "ready",
                            render: ({ next, previous }) => (
                              <GoogleAuthRemoveReady
                                nextPage={null}
                                prevPage={1}
                                next={next}
                                previous={previous}
                                action={this.actionStep}
                              />
                            ),
                          },
                        ].map((item) => {
                          return (
                            <Step
                              id={item.id}
                              render={item.render}
                              key={item.id}
                            />
                          );
                        })}
                      </Steps>
                    </div>
                  )}
                />
              </Baron>
            </div>
            <div
              className={`progressSteps ${
                this.state.anketaCurrStep !== -1 && "visible"
              }`}
            >
              {["Подтвердите отключение", "Готово"].map((step, index) => (
                <div
                  key={index}
                  className={`${
                    this.state.anketaCurrStep === index ? "currentStep" : ""
                  }`}
                >
                  {step}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GoogleAuthRemovePage;
