import React from 'react';
import {Link} from 'react-router-dom';
import {getMyDssSign} from "../../utils/utilsHttp";
import {catchPromise} from "../../utils/http";

export default class DocSignDiadoc extends React.Component {
    constructor(props) {
        super(props);

          this.state = {
            docId: props.docId,
            signType: 0,
            signType1: 0,
            mydss_task_id: undefined,
            errorText: '',
            pageError: false,
            isPressed: false
        };
    }
    errorCb = () => {
        this.setState({pageError: true});
    }

    componentDidMount() {
        
    }

    onSubmit = () => {
        this.props.onSubmit({
            id: parseInt(this.state.docId),
            mydss_task_id: this.state.mydss_task_id,
        });
    }

    onDssSign = () => {
        this.setState({isPressed: true}, () => {
            getMyDssSign({'doc_id': parseInt(this.state.docId)})
            .then((res) => {
                this.setState({regState: 1, error: undefined, mydss_task_id: res.data.mydss_task_id, isPressed: false, signType1: 1});
            })
            .catch(err => {
                this.setState({errorText: err.response.data.message});
                catchPromise(err, this.onDssSign, this.errorCb);
            });
        });
    }

    render() {
      return (
              <div>
                  {
                      this.props.diadoc["sign_files"] ?
                          <div>
                                <div className="docPage-uploadDisc">1 Скачайте документы и ознакомьтесь.</div>
                                <div className="docNeedScan-links">
                                {
                                  <div>
                                    {
                                        this.props.files.map((file, index) => (
                                            <a key={index} className="btn btnLink mr-2 mb-2" href={file.url}>
                                                <span>Скачать<img src="/images/ui/uploadIcon.png" className="uploadIcon" alt="" /></span>
                                            </a>
                                        ))
                                    }
                                  </div>
                                }
                                </div>
                                <div className="docPage-uploadDisc">2 Подпишите документы.</div>
                                <div className="docNeedScan-links">
                                    {/*<div key="sign-mydss" className="btn btnLink mr-2 mb-2" onClick={() => {this.setState({signType: 1})}}>*/}
                                    {/*    <span>MyDSS</span>*/}
                                    {/*</div>*/}
                                    <div key="sign-manual" className="btn btnLink mr-2 mb-2" onClick={() => {this.setState({signType: 2, mydss_task_id: undefined})}}>
                                        <span>Диадок</span>
                                    </div>
                                </div>
                                {
                                    this.state.signType == 1 &&
                                    <div>
                                        {
                                            this.state.signType1 == 0 ? (
                                                <div>
                                                    <div className="docPage-uploadDisc">Нажмите кнопку "Подписать", после чего Вы получите уведомление в приложении MyDSS. После подтверждения подписи нажмите внизу кнопку "Отправить".</div>
                                                    <button 
                                                        key="sign-mydss-get" 
                                                        onClick={() => {this.onDssSign()}} 
                                                        className="btn btnLink" 
                                                        style={{width: 'auto'}}
                                                        disabled={this.state.isPressed}
                                                    >
                                                        Подписать
                                                        {this.state.isPressed && <i className="icon-spinner2 uploaderSpinner spinner"></i>}  
                                                    </button>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="docPage-uploadDisc">Подпишите документ в приложении MyDSS.</div>
                                                </div>
                                            )
                                        }
                                    </div>
                                }
                                {
                                    this.state.signType == 2 &&
                                    <div>
                                        <div className="docPage-uploadDisc">Перейдите по ссылке, подпишите и отправьте документы в Диадоке. После этого нажмите внизу кнопку "Отправить".</div>
                                        <a key="sign-manual-login" className="btn btnLink mr-2 mb-2" href={this.props.diadoc['draft_page_url']} target="_blank">
                                            <span>Войти</span>
                                        </a>
                                    </div>
                                }

                          </div>
                          :
                          (
                              !this.props.docsWaitingErr ?
                              <div className="docNeedScan-links">
                                  <span><i className="icon-spinner2 spinner mr-2"></i> Пожалуйста, подождите, пока черновик документа создается в Диадок</span>
                              </div> :
                              <div className="docNeedScan-links text-danger">{this.props.docsWaitingErr}</div>

                          )
                  }
                  {
                        this.state.errorText &&
                        <div className="text-center text-danger" style={{fontSize: '18px', marginTop: '10px'}}>
                            {this.state.errorText}
                        </div>
                  }

                  <div className='btnSubmits card-content d-flex justify-content-between align-items-center'>
                      <Link to='/app/documents/moving' className="btn btn-white">Отменить</Link>
                      <button
                          className="btn btn-success"
                          disabled={this.props.isSending}
                          onClick={(e) => this.onSubmit(e)}
                      >
                          Отправить
                          {this.props.isSending && <i className="icon-spinner2 uploaderSpinner spinner"></i>}
                      </button>
                  </div>
              </div>
      )
    }
}