import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/ru';
import { catchPromise } from '../utils/http';
import { getFinres } from '../utils/finresHttp';
import { setFinres } from '../actions/finres';
import Finres from '../components/Finres';

export class FinresPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
      accountId: props.tradingAccount.id || '',
      calendarFocused: null,
      dateRangeCurr: 'dateRangeMonth',
      sort_by: 'date',
      sortAsc: false,
      isLoading: false,
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    moment.locale('ru');
    if (this.props.tradingAccount.id) {
      this.getFinresLoad(this.state.startDate, this.state.endDate);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.tradingAccount.id !== nextProps.tradingAccount.id) {
      this.setState({ accountId: nextProps.tradingAccount.id || '' }, () =>
        this.getFinresLoad(this.state.startDate, this.state.endDate)
      );
    }
  }

  getFinresLoad = (startDate, endDate) => {
    this.setState({ isLoading: true });
    getFinres(
      startDate.format('DD-MM-YYYY'),
      endDate.format('DD-MM-YYYY'),
      this.state.accountId,
      this.state.sort_by,
      this.state.sortAsc ? 'ASC' : 'DESC'
    )
      .then((res) => {
        this.props.setFinres(res.data);
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        catchPromise(
          err,
          this.getFinresLoad.bind(this, startDate, endDate),
          this.errorCb
        );
      });
  };
  onDatesChange = ({ startDate, endDate, dateRange }) => {
    this.setState({ startDate, endDate, dateRangeCurr: dateRange }, () => {
      if (this.state.startDate && this.state.endDate) {
        this.getFinresLoad(startDate, endDate);
      }
    });
  };
  onFocusChange = (calendarFocused) => {
    this.setState({ calendarFocused });
  };
  onChangeSort = (sort_by) => {
    if (!this.state.isLoading) {
      const sortAsc =
        sort_by == this.state.sort_by ? !this.state.sortAsc : false;
      this.setState({ sort_by, sortAsc }, () => {
        this.getFinresLoad(this.state.startDate, this.state.endDate);
      });
    }
  };

  selectCalendarDates = (dateRangeCurr) => {
    switch (dateRangeCurr) {
      case 'dateRangeWeek':
        this.onDatesChange({
          startDate: moment().startOf('week'),
          endDate: moment().endOf('week'),
          dateRange: dateRangeCurr,
        });
        break;
      case 'dateRangeMonth':
        this.onDatesChange({
          startDate: moment().startOf('month'),
          endDate: moment().endOf('month'),
          dateRange: dateRangeCurr,
        });
        break;
      case 'dateRangeQuarter':
        this.onDatesChange({
          startDate: moment().startOf('quarter'),
          endDate: moment().endOf('quarter'),
          dateRange: dateRangeCurr,
        });
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div className="row">
        <div className="page-content-center finres">
          {!this.state.pageError ? (
            <Finres
              finres={this.props.finres}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              sort_by={this.state.sort_by}
              sortAsc={this.state.sortAsc}
              dateRangeCurr={this.state.dateRangeCurr}
              onChangeAccountFilter={this.onChangeAccountFilter}
              onDatesChange={this.onDatesChange}
              onChangeSort={this.onChangeSort}
              calendarFocused={this.state.calendarFocused}
              onFocusChange={this.onFocusChange}
              selectCalendarDates={this.selectCalendarDates}
              isLoading={this.state.isLoading}
            />
          ) : (
            <h3 className="text-center text-danger">Произошла ошибка</h3>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    finres: state.finres,
    tradingAccount: state.tradingAccount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setFinres: (finres) => dispatch(setFinres(finres)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinresPage);
