import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/ru";
import Baron from "react-baron/dist/es5";
import amplitude from 'amplitude-js';

import { getBonuseById, getBonuses } from "../utils/bonusesHttp";

export class SpendBonuses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bonuses: [],
      filteredBonuses: [],
      isLoading: true,
      pageError: false,
      statusFilter: -1
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    amplitude.getInstance().logEvent('spend_bonuses_page.page_view');
    this.loadBonuses();
  }
  loadBonuses = () => {
    getBonuses().then((res) => {
      this.setState({
        bonuses: res.data.bonus_withdraw_requests_list,
        filteredBonuses: res.data.bonus_withdraw_requests_list,
        isLoading: false,
      });
    });
  };
  setStatusColor = (status) => {
    switch (status) {
      case 3:
        return "docStatus-success";
      case 4:
      case 10:
        return "docStatus-reject";
      default:
        return "";
    }
  };
  statusTitle = (status) => {
    switch (status) {
      case 0:
        return "Новый";
      case 1:
        return "В работе";
      case 2:
        return "На исполнении";
      case 3:
        return "Исполнен";
      case 4:
        return "Отклонён";
      case 10:
        return "Ошибка";
    }
  };

  setStatusFilter = (val) => {
    this.setState({ statusFilter: val, filteredBonuses: this.state.bonuses.filter(bonus => bonus.status === val || val === -1) })
  }

  render() {
    return (
      <React.Fragment>
        {!this.props.tradingAccounts.trading_accounts.find(
          (acc) => acc.bonus
        ) ? (
          <Redirect to="/app" />
        ) : (
          <div className="documentsMoving spendBonuses">
            {!this.state.pageError ? (
              <div>
                <ul className="nav nav-tabs boardsTabs">
                  <li
                    className="nav-item"
                    onClick={() => {
                      this.setStatusFilter(-1);
                    }}
                  >
                    <span
                      className={`nav-link text-center ${this.state.statusFilter === -1 ? 'active' : ''} show`}
                      data-toggle="tab"
                    >
                      <span>Все</span>
                    </span>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => {
                      this.setStatusFilter(3);
                    }}
                  >
                    <span
                      className={`nav-link text-center ${this.state.statusFilter === 3 ? 'active' : ''} show`}
                      data-toggle="tab"
                    >
                      <span>Исполнен</span>
                    </span>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => {
                      this.setStatusFilter(4);
                    }}
                  >
                    <span
                      className={`nav-link text-center ${this.state.statusFilter === 4 ? 'active' : ''} show`}
                      data-toggle="tab"
                    >
                      <span>Отклонен</span>
                    </span>
                  </li>
                  <li className="closeDealsBtns">
                    <Link
                      to="/app/spend-bonuses/create"
                      className="btn btn-success"
                    >
                      Обменять бонусы
                    </Link>
                  </li>
                </ul>

                <div className="tab-content">
                  <div className="tab-pane fade active show">
                    {this.state.bonuses.length > 0 && !this.state.isLoading ? (
                      <div className="table">
                        <table className="tableLayoutFixes">
                          <thead>
                            <tr>
                              <td className="text-right docCreate">СОЗДАН</td>
                              <td className="docDescr">ДОКУМЕНТ</td>
                              <td className="docComm">КОММЕНТАРИИ</td>
                              <td className="docStatus">СТАТУС</td>
                            </tr>
                          </thead>
                        </table>
                        <Baron>
                          <table className="tableLayoutFixes docsTable">
                            <tbody>
                              {this.state.filteredBonuses.map((doc, index) => (
                                <tr
                                  key={doc.id}
                                  className={`${
                                    doc.updated ? "updatedDoc" : ""
                                  }`}
                                >
                                  <td className="text-right docCreate">
                                    {moment(doc.date, "YYYY-MM-DD").format(
                                      "DD.MM.YYYY"
                                    )}
                                  </td>
                                  <td className="docDescr">
                                    <div className="docDescr-title">
                                      <Link to={`/app/spend-bonuses/${doc.id}`}>
                                        {doc.title}
                                      </Link>
                                    </div>
                                    <div className="docDescr-text">
                                      {doc.subtitle}
                                    </div>
                                  </td>
                                  <td className="docComm">
                                    <span>{doc.comment}</span>
                                  </td>
                                  <td
                                    className={`docStatus ${this.setStatusColor(
                                      doc.status
                                    )}`}
                                  >
                                    <span>{this.statusTitle(doc.status)}</span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Baron>
                      </div>
                    ) : (
                      <div className="emptyDashboard-plug">
                        {this.state.isLoading ? (
                          <div className="mt-1 text-center spinner-container isLoadingSpinner">
                            <span>
                              <img
                                src="/images/ui/load-spinner.png"
                                className="spinner"
                                alt=""
                              />
                            </span>
                          </div>
                        ) : (
                          <div className="emptyData">нет данных</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <h3 className="text-center text-danger">Произошла ошибка</h3>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tradingAccounts: state.tradingAccounts,
});

export default connect(mapStateToProps)(SpendBonuses);
