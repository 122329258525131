import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import generalReducer from '../reducers/general';
import userReducer from '../reducers/user';
import tradingAccountsReducer from '../reducers/tradingAccounts';
import tradingAccountReducer from '../reducers/tradingAccount';
import newsReducer from '../reducers/news';
import lastNewsReducer from '../reducers/lastNews';
import broadcastsReducer from '../reducers/broadcasts';
import finresReducer from '../reducers/finres';
import updatesReducer from '../reducers/updates';
import anketaReducer from '../reducers/anketa';
import transactionsReducer from '../reducers/transactions';
import dailyTradingAccountReducer from '../reducers/dailyTradingAccount';
import dailyDealsReducer from '../reducers/dailyDeals';
import dealsAnalysisDaysReducer from '../reducers/analysisDays';
import dealsAnalysisSymbolsReducer from '../reducers/analysisSymbols';
import settingsReducer from '../reducers/settings';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      general: generalReducer,
      user: userReducer,
      tradingAccounts: tradingAccountsReducer,
      tradingAccount: tradingAccountReducer,
      news: newsReducer,
      lastNews: lastNewsReducer,
      broadcasts: broadcastsReducer,
      finres: finresReducer,
      updates: updatesReducer,
      anketa: anketaReducer,
      transactions: transactionsReducer,
      dailyTradingAccount: dailyTradingAccountReducer,
      dailyDeals: dailyDealsReducer,
      dealsAnalysisDays: dealsAnalysisDaysReducer,
      dealsAnalysisSymbols: dealsAnalysisSymbolsReducer,
      settings: settingsReducer
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
};
