import React from "react";
import { getExistingOtp, getNewOtp } from "../utils/googleAuthHttp";

export default class GoogleAuthAddDownload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevPage: props.prevPage,
      nextPage: props.nextPage,
      otp_token: null,
      qr_code: null,
    };
  }

  componentDidMount() {
    getNewOtp()
      .then((res) => {
        this.setState({
          otp_token: res.data.otp_token,
          qr_code: res.data.qr_code,
        });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          getExistingOtp().then((res) => {
            this.setState({
              otp_token: res.data.otp_token,
              qr_code: res.data.qr_code,
            });
          });
        }
      });
  }

  action = (action, currIndex, dest) => {
    if (this.state.otp_token || dest === "prev") {
      this.props.action(action, currIndex, "qr", {
        otp_token: this.state.otp_token,
      });
    }
  };

  render() {
    return (
      <div className="googleAuthAddQR">
        <div className="row">
          <h2 className="col-12 subtitle">Отсканируйте QR-код</h2>
        </div>
        <div>
          Отсканируйте QR-код с этой страницы через установленное мобильное
          приложение.
        </div>
        <div className="mt-4 text-center">
          <img src={`data:image/png;base64,${this.state.qr_code}`} alt="QR Code" />
          <div className="mt-2 mb-3">{this.state.otp_token}</div>
          <div className="w-75 m-auto">
            Если вы не можете отсканировать QR-код, введите этот код вручную в
            приложении
          </div>
        </div>

        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <button
            className="btn btnBack"
            onClick={() => {
              this.action(this.props.previous, this.state.prevPage, "prev");
            }}
          >
            Назад
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              this.action(this.props.next, this.state.nextPage, "next");
            }}
          >
            Далее
          </button>
        </div>
      </div>
    );
  }
}
