import React from 'react';
import { Link } from 'react-router-dom';

export default class DocLoadDiadoc extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {}
  render() {
    return (
      <div>
        <div className="docPage-uploadDisc">
          Подписанные обеими сторонами документы подгружаются к нам. Позже документы будут доступны для скачивания.
        </div>
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <Link to="/app/documents/moving" className="btn btn-white">
            Отменить
          </Link>
        </div>
      </div>
    );
  }
}
