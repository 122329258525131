
const getApiUrl = env => {
    if (env.MODE === 'developer-local') {
        return `http://apidev.${process.env.REACT_APP_API_DOMAIN}:5001`
    } else {
        if (env.BUILD_TYPE === 'production') {
            return `https://${process.env.REACT_APP_API_DOMAIN}`
        } else if (env.BUILD_TYPE === 'development') {
            return `https://tapi.${process.env.REACT_APP_API_DOMAIN}`
        }
    }
}

const createConfig = (env = process.env) => {
    return ({
      amplitudeKey: env.REACT_APP_AMPLITUDE_KEY,
      apiUrl: getApiUrl(env),
      binanceFuturesSocketBaseUrl: env.REACT_APP_USE_TEST_BINANCE_WS === '1' ? 'wss://stream.binancefuture.com' : 'wss://fstream.binance.com',
      sentryDsn: env.REACT_APP_SENTRY_DSN,
      env: env.BUILD_TYPE,
      bikUrl:
        env.BUILD_TYPE === 'production' || env.BUILD_TYPE === 'stage'
          ? 'https://bik-info.ru/api.html?type=json'
          : 'http://bik-info.ru/api.html?type=json',
    })
  }

module.exports = createConfig;


