import React from "react";
import { Link } from "react-router-dom";

export default class GoogleAuthAddDownload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevPage: props.prevPage,
      nextPage: props.nextPage,
    };
  }

  action = (action, currIndex) => {
    this.props.action(action, currIndex);
  };

  render() {
    return (
      <div className="googleAuthAddDownload">
        <div className="row">
          <h2 className="col-12 subtitle">Скачайте приложение</h2>
        </div>
        <div>
          Загрузите и установите приложение Google Authenticator на свое
          мобильное устройство.
        </div>
        <div className="row mt-4">
          <div className="col-6 text-center">
            <a
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              target="_blank"
            >
              <img src="/images/ui/apple.svg" alt="App Store" />
              <div className="mt-1 text-secondary">Скачать из</div>
              <div className="font-weight-bold">App Store</div>
            </a>
          </div>
          <div className="col-6 text-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              target="_blank"
            >
              <img src="/images/ui/google-play.png" alt="Google Play" />
              <div className="mt-1 text-secondary">Скачать из</div>
              <div className="font-weight-bold">Google Play</div>
            </a>
          </div>
        </div>

        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <Link to="/app/profile">
            <button className="btn btnBack">Назад</button>
          </Link>
          <button
            className="btn btn-success"
            onClick={() => {
              this.action(this.props.next, this.state.nextPage, "next");
            }}
          >
            Далее
          </button>
        </div>
      </div>
    );
  }
}
