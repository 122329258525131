import React from "react";
import Baron from "react-baron/dist/es5";

import LiveDataOrder from "./LiveDataOrder";
import { currencyFormat, sumColor } from "../utils/currencyUtil";

const LiveDataPosition = ({
  position,
  currency,
  boardFilter,
  openOrders,
  closePosition,
  cancelOrders,
  cancelOrder,
  indexPosition,
  isMobile,
}) => {
  const isPositionVisible = boardFilter != 0 ? position.visibleOrders : position.isOpenedInAllSection; 

  return (
    <React.Fragment>
      {!isMobile ? (
        <React.Fragment>
          <tr
            className={`${
              position.orders.length > 0 ? "positionWithOrders" : ""
            } ${position.visibleOrders ? "openedOrders" : ""} ${
              indexPosition & 1 ? "evenStroke" : "oddStroke"
            }`}
            onClick={(e) => {
              if (boardFilter != 0) {
                openOrders(position);
              } else {
                openOrders(position, true);
              }
            }}
          >
            {boardFilter === "0" && (
              <td className="text-right posPlosh">{position.board_name}</td>
            )}
            <td className="text-right posUpdate">{position.updated}</td>
            <td className="text-right posInst">{position.symbol}</td>
            <td className="posPosit">
              {position.quantity}
              {parseInt(position.quantity) != 0 && (
                <span
                  className={`icon-cross3 text-danger dashboard-instCloseBtn  ${
                    position.isBlockedClosing ? "disabledCloseBtn" : ""
                  }`}
                  onClick={() => {
                    closePosition(position);
                  }}
                ></span>
              )}
            </td>
            <td className="posOrders">
              <span>{position.orders.length}</span>
              {position.orders.length != 0 && (
                <span
                  className={`icon-cross3 text-danger dashboard-instCloseBtn  ${
                    position.isBlockedClosingOrders ? "disabledCloseBtn" : ""
                  }`}
                  onClick={() => {
                    cancelOrders(position);
                  }}
                ></span>
              )}
            </td>
            <td
              className={`text-right posFinres ${sumColor(
                position.finres,
                currency
              )}`}
            >
              {currencyFormat(position.finres)}
            </td>
            <td className="text-right posCommis">
              {currencyFormat(position.fee)}
            </td>
            <td className="text-right posDrawdown">{position.drawdown}</td>
            <td className="openedOrders-arrow posArrow">
              {position.visibleOrders && (
                <span className="icon-arrow-up12 text-success"></span>
              )}
            </td>
          </tr>
          {position.orders.length > 0 && position.visibleOrders ? (
            <tr className="tableOrders-container">
              <td
                colSpan={`${boardFilter == "0" ? "9" : "8"}`}
                style={{ paddingTop: "0px !important" }}
              >
                <Baron>
                  <table className="tableOrders">
                    <thead>
                      <tr>
                        <td>ВРЕМЯ</td>
                        <td>ЦЕНА</td>
                        <td>ОБЪЕМ</td>
                        <td>ТИП</td>
                        <td className="closeOrder-icon"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {position.orders.map((order, index) =>
                        order.status === "active" ? (
                          <LiveDataOrder
                            key={index}
                            order={order}
                            currency={currency}
                            cancelOrder={cancelOrder}
                          />
                        ) : null
                      )}
                    </tbody>
                  </table>
                </Baron>
              </td>
            </tr>
          ) : null}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="dashboard-mobile-data-table">
            <table
              className={`${
                !(position.orders.length > 0 && isPositionVisible)
                  ? "invisibleOrders"
                  : ""
              }`}
              onClick={(e) => {
                if (boardFilter != 0) openOrders(position);
                else openOrders(position, true);
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <h5>Инструмент</h5>
                    <h4>{position.symbol}</h4>
                  </td>
                  <td>
                    <h5>Площадка</h5>
                    <h4>{position.board_name.replace(" MOEX", "")}</h4>
                  </td>
                  <td>
                    <h5>Обновлено</h5>
                    <h4>{position.updated}</h4>
                  </td>
                  <td>
                    <h5>Позиции</h5>
                    <h4
                      className="d-flex justify-content-between align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      {position.quantity}
                      {Number(position.quantity) ? (
                        <div
                          onClick={() => {
                            closePosition(position);
                          }}
                        >
                          <img src="/images/ui/dashboard-close-icon.svg" />
                        </div>
                      ) : null}
                    </h4>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h5>ФинРез</h5>
                    <h4 className="posFinres">
                      <div
                        className={`posFinres-circle ${
                          position.finres > 0 ? "green" : "red"
                        }`}
                      ></div>
                      {currencyFormat(position.finres, "RUR", 2, true)}
                    </h4>
                  </td>
                  <td>
                    <h5>Комиссия</h5>
                    <h4>{Number(position.fee).toFixed(2)}</h4>
                  </td>
                  <td>
                    <h5>Просадка</h5>
                    <h4>{position.drawdown}</h4>
                  </td>
                  <td>
                    <h5>Заявки</h5>
                    <h4
                      className="d-flex justify-content-between align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      {position.orders.length}
                      {position.orders.length ? (
                        <div
                          onClick={() => {
                            cancelOrders(position);
                          }}
                        >
                          <img src="/images/ui/dashboard-close-icon.svg" />
                        </div>
                      ) : null}
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
            {position.orders.length > 0 && isPositionVisible ? (
              <div className="tableOrders-mobile-container">
                <div style={{ paddingTop: "0px !important" }}>
                  <table className="tableOrders-mobile">
                    <thead>
                      <tr>
                        <td>Время</td>
                        <td>Цена, ₽</td>
                        <td>Объем</td>
                        <td>Тип</td>
                        <td className="closeOrder-icon"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {position.orders.map((order, index) =>
                        order.status === "active" ? (
                          <LiveDataOrder
                            key={index}
                            order={order}
                            currency={currency}
                            cancelOrder={cancelOrder}
                            isMobile={isMobile}
                          />
                        ) : null
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default LiveDataPosition;
