import React from "react";
import { connect } from "react-redux";
import { getScheduledEvents } from "../utils/broadcastsHttp";

import { catchPromise } from "../utils/http";

import Baron from 'react-baron/dist/es5';

export class BroadcastsSchedulePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: true,
      pageError: false,
      events: {},
      last_event_schedule_update: null,
    };
  }

  errorCb = () => {
    this.setState({ pageError: true });
  };

  componentDidMount() {
    this.setState({ loaded: true });
    getScheduledEvents()
      .then((res) => {
        this.setState({
          events: res.data.events,
          last_event_schedule_update: res.data.last_event_schedule_update,
          loaded: false,
        });
      })
      .catch((err) => {
        this.setState({ loaded: false });
        catchPromise(
          err,
          this.loadStreams.bind(this, { page_num }),
          this.errorCb
        );
      });
  }

  render() {
    return (
      <div className="h-68 d-flex justify-content-center">
        <div className="broadcastsStreamsWrapper">
          <Baron>
            <div className="broadcastsScheduleContainer">
              {!this.state.pageError ? (
                <div style={{ display: "grid", width: "100%" }}>
                  <ul className="broadcastsSchedule">
                    {["Понедельник", "Вторник", "Среда", "Четверг", "Пятница"].map(
                      (day) => {
                        const daysAbbr = {
                          Понедельник: "ПН",
                          Вторник: "ВТ",
                          Среда: "СР",
                          Четверг: "ЧТ",
                          Пятница: "ПТ",
                        };

                        return (
                          <li className="broadcastsSchedule-day">
                            <div className="broadcastsSchedule-dayName">
                              {daysAbbr[day]}
                            </div>
                            <ul className="broadcastsSchedule-dayEventList">
                              {(this.state.events[day] || []).map((event) => {
                                let time = event.time;
                                if (time[0] === "0") time = time.slice(1);
                                time = time.split(":")[0] + ":" + time.split(":")[1];

                                return (
                                  <li className="broadcastsSchedule-dayEvent">
                                    <div className="broadcastsSchedule-dayEventDesc">
                                      <div className="broadcastsSchedule-dayEventTime">
                                        {time}
                                      </div>
                                      <div className="broadcastsSchedule-dayEventAuthor">
                                        {event.presenter_full_name}
                                      </div>
                                    </div>
                                    <div className="broadcastsSchedule-dayEventTitle">
                                        {event.name}
                                    </div>         
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        );
                      }
                    )}
                  </ul>
                  {this.state.loaded && (
                    <div className="mt-2 mb-2 text-center spinner-container">
                      <span>
                        <img
                          src="/images/ui/load-spinner.png"
                          className="spinner"
                          alt=""
                        />
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <h3 className="text-center text-danger">Произошла ошибка</h3>
              )}
            </div>
          </Baron>
        </div>
      </div>
    );
  }
}

export default connect()(BroadcastsSchedulePage);
