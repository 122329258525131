import axios from 'axios';
import Config from '../config/config';

export const getBoards = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/boards`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const getDealsAnalysisDays = (data) => {
  const { accountId, boardId, weeksNum } = data;
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/analysis/daily?trading_account_id=${accountId}&board_id=${boardId}&weeks_num=${weeksNum}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const getDealsAnalysisSymbols = (data) => {
  const { accountId, boardId, dateStart, dateEnd } = data;
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/analysis/symbols?trading_account_id=${accountId}&board_id=${boardId}&date_start=${dateStart}&date_end=${dateEnd}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const getDealsAnalysisEquity = (data) => {
  const { accountId, boardId, dateStart, dateEnd, symbols } = data;
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/analysis/equity?trading_account_id=${accountId}&board_id=${boardId}&date_start=${dateStart}&date_end=${dateEnd}&symbols=${symbols}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
