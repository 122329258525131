import React from 'react';
import InputMask from 'react-input-mask';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import 'moment/locale/ru';
import Baron from 'react-baron/dist/es5';

export default class ChangeAnketaProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    $('body').on('click', () => {
      if ($('.customSelectListMonth').hasClass('isShowSelect'))
        setTimeout(() => {
          $('.customSelectListMonth').removeClass('isShowSelect');
        }, 50);
      if ($('.customSelectListYear').hasClass('isShowSelect'))
        setTimeout(() => {
          $('.customSelectListYear').removeClass('isShowSelect');
        }, 50);
    });
  }

  onLastNameChange = (e) => {
    const d__personal__last_name = e.target.value;
    this.props.onValueChange('d__personal__last_name', d__personal__last_name);
  };
  onFirstNameChange = (e) => {
    const d__personal__first_name = e.target.value;
    this.props.onValueChange(
      'd__personal__first_name',
      d__personal__first_name
    );
  };
  onThirdNameChange = (e) => {
    const d__personal__middle_name = e.target.value;
    this.props.onValueChange(
      'd__personal__middle_name',
      d__personal__middle_name
    );
  };

  onContractChange = (e) => {
    const contract_id = e.target.value;
    this.props.onValueChange('contract_id', contract_id);
  };

  onPassportSeriaChange = (e) => {
    const d__personal__passport__series = e.target.value;
    if (
      !d__personal__passport__series ||
      (d__personal__passport__series.match(/^[0-9]+$/) &&
        d__personal__passport__series.length <= 4)
    ) {
      this.props.onValueChange(
        'd__personal__passport__series',
        d__personal__passport__series
      );
      this.props.onValueValidateCurrentChange('d__personal__passport__series');
    }
  };
  onPassportNumChange = (e) => {
    const d__personal__passport__number = e.target.value;
    if (
      !d__personal__passport__number ||
      (d__personal__passport__number.match(/^[0-9]+$/) &&
        d__personal__passport__number.length <= 6)
    ) {
      this.props.onValueChange(
        'd__personal__passport__number',
        d__personal__passport__number
      );
      this.props.onValueValidateCurrentChange('d__personal__passport__number');
    }
  };
  onPassportWhosChange = (e) => {
    const d__personal__passport__issued_by = e.target.value;
    this.props.onValueChange(
      'd__personal__passport__issued_by',
      d__personal__passport__issued_by
    );
  };
  onPassportDateChange = (d__personal__passport__issued_date) => {
    this.props.onValueChange(
      'd__personal__passport__issued_date',
      d__personal__passport__issued_date
    );
  };
  onPassportCodeWhereChange = (e) => {
    const d__personal__passport__department_code = e.target.value;
    this.props.onValueChange(
      'd__personal__passport__department_code',
      d__personal__passport__department_code
    );
    this.props.onValueValidateCurrentChange(
      'd__personal__passport__department_code'
    );
  };

  changeSelect = (name, value) => {
    if (value) $('.' + name).addClass('isShowSelect');
    else $('.' + name).removeClass('isShowSelect');
  };
  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    let rows = [];
    for (let i = 0; i < 100; i++) {
      rows.push(i);
    }
    return (
      <div
        className="singleDatePickerSelect"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div className="customSelect customSelectSingleDatepicker">
          <div
            className="customSelectedItem"
            onClick={() => {
              this.changeSelect('customSelectListMonth', true);
            }}
          >
            {month.format('MMMM')}
          </div>
          {
            <ul className="customSelectList customSelectListMonth">
              <Baron>
                {moment.months().map((label, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      onMonthSelect(month, index);
                      this.changeSelect('customSelectListMonth', false);
                    }}
                  >
                    {label}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
        <div className="customSelect customSelectSingleDatepicker customSelectSingleDatepicker-year">
          <div
            className="customSelectedItem"
            onClick={() => {
              this.changeSelect('customSelectListYear', true);
            }}
          >
            {month.year()}
          </div>
          {
            <ul className="customSelectList customSelectListYear">
              <Baron>
                {rows.map((row) => (
                  <li
                    key={row}
                    onClick={() => {
                      onYearSelect(month, moment().year() - row);
                      this.changeSelect('customSelectListYear', false);
                    }}
                  >
                    {moment().year() - row}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
      </div>
    );
  };

  render() {
    let {
      d__personal__last_name,
      d__personal__first_name,
      d__personal__middle_name,
      d__personal__birth_date,
      d__personal__birth_place,
      d__personal__sex,
      d__personal__passport__series,
      d__personal__passport__number,
      d__personal__passport__issued_date,
      d__personal__passport__department_code,
      d__personal__passport__issued_by,
      validate,
      validateActiveFields,
      isReadableProfile,
    } = this.props;
    return (
      <div>
        <div className="anketaChange-subtitleContainer d-flex justify-content-between align-items-center">
          <div className="anketaChange-subtitle">Личные данные</div>
          <span
            className="addressHandWriting"
            onClick={() => {
              this.props.setReadableBlock('isReadableProfile');
            }}
          >
            {isReadableProfile ? (
              <span className="text-danger addressHandWriting-reset">
                Отменить
              </span>
            ) : (
              <span className="text-primary addressHandWriting-change">
                Изменить
              </span>
            )}
          </span>
        </div>
        <div className="row">
          <div className="col-lg-6 inputFields-left">
            <div className="position-relative formField-container">
              <label
                className={`floating-label ${
                  d__personal__last_name == '' ? 'hidden' : ''
                }`}
              >
                Фамилия
              </label>
              <input
                type="text"
                className={`inputUnderLine ${
                  validate && d__personal__last_name == ''
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  validate && d__personal__last_name != ''
                    ? 'inputValidateCorrect'
                    : ''
                } ${!isReadableProfile ? "anketaReadOnlyFields" : ""}`}
                placeholder="Фамилия"
                readOnly={!isReadableProfile}
                value={d__personal__last_name}
                onChange={this.onLastNameChange}
              />
            </div>
            <div className="position-relative formField-container">
              <label
                className={`floating-label ${
                  d__personal__first_name == '' ? 'hidden' : ''
                }`}
              >
                Имя
              </label>
              <input
                type="text"
                className={`inputUnderLine ${
                  validate && d__personal__first_name == ''
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  validate && d__personal__first_name != ''
                    ? 'inputValidateCorrect'
                    : ''
                } ${!isReadableProfile ? "anketaReadOnlyFields" : ""}`}
                placeholder="Имя"
                readOnly={!isReadableProfile}
                value={d__personal__first_name}
                onChange={this.onFirstNameChange}
              />
            </div>
            <div className="position-relative formField-container">
              <label
                className={`floating-label ${
                  d__personal__middle_name == '' ? 'hidden' : ''
                }`}
              >
                Отчество
              </label>
              <input
                type="text"
                className="inputUnderLine"
                placeholder="Отчество"
                readOnly={!isReadableProfile}
                value={d__personal__middle_name}
                onChange={this.onThirdNameChange}
              />
            </div>
          </div>
          <div className="col-lg-6 inputFields-right">
            <div className="formField-container" style={{ height: '28px' }}>
              <label
                className="docCreate-dateLabel"
                style={{
                  display: 'inline-block',
                  width: '97px',
                  opacity: '0.4',
                }}
              >
                Дата рождения
              </label>
              <div
                style={{
                  textAlign: 'center',
                  position: 'static',
                  display: 'inline-block',
                  width: '80px',
                  marginLeft: '8px',
                  padding: '5px 6px 6px 6px',
                  height: '26px',
                  border: 'none',
                  backgroundColor: '#f2f2f5',
                  color: '#bfc3c7',
                  fontSize: '12px',
                  outline: 'none',
                  borderRadius: '4px',
                }}
              >
                {moment(d__personal__birth_date).format('DD.MM.YYYY')}
              </div>
            </div>
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  d__personal__birth_place == '' ? 'hidden' : ''
                }`}
              >
                Место рождения
              </label>
              <input
                type="text"
                className="inputUnderLine"
                readOnly={true}
                value={d__personal__birth_place || ''}
              />
            </div>
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  d__personal__sex == '' ? 'hidden' : ''
                }`}
              >
                Пол
              </label>
              <input
                type="text"
                className="inputUnderLine"
                readOnly={true}
                value={d__personal__sex ? 'Мужской' : 'Женский'}
              />
            </div>
          </div>
        </div>

        <div className="anketaChange-subtitle anketaChange-subtitleContainer">
          Паспорт РФ:
        </div>
        <div>
          <div
            style={{
              width: '60px',
              display: 'inline-block',
            }}
          >
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  d__personal__passport__series == '' ? 'hidden' : ''
                }`}
              >
                Серия
              </label>
              <input
                type="text"
                className={`inputUnderLine ${
                  validate &&
                  d__personal__passport__series.length != 4 &&
                  validateActiveFields.d__personal__passport__series
                    ? 'inputValidateCurrentWrong'
                    : ''
                } ${
                  validate && d__personal__passport__series.length != 4
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  validate && d__personal__passport__series.length == 4
                    ? 'inputValidateCorrect'
                    : ''
                } ${!isReadableProfile ? "anketaReadOnlyFields" : ""}`}
                placeholder="Серия"
                value={d__personal__passport__series}
                readOnly={!isReadableProfile}
                onChange={this.onPassportSeriaChange}
              />
            </div>
          </div>
          <div
            style={{
              width: '80px',
              display: 'inline-block',
              marginLeft: '30px',
            }}
          >
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  d__personal__passport__number == '' ? 'hidden' : ''
                }`}
              >
                Номер
              </label>
              <input
                type="text"
                className={`inputUnderLine ${
                  validate &&
                  d__personal__passport__number.length != 6 &&
                  validateActiveFields.d__personal__passport__number
                    ? 'inputValidateCurrentWrong'
                    : ''
                } ${
                  validate && d__personal__passport__number.length != 6
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  validate && d__personal__passport__number.length == 6
                    ? 'inputValidateCorrect'
                    : ''
                } ${!isReadableProfile ? "anketaReadOnlyFields" : ""}`}
                placeholder="Номер"
                readOnly={!isReadableProfile}
                value={d__personal__passport__number}
                onChange={this.onPassportNumChange}
              />
            </div>
          </div>
          <div
            style={{
              width: '235px',
              display: 'inline-block',
              marginLeft: '30px',
            }}
          >
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  d__personal__passport__issued_by == '' ? 'hidden' : ''
                }`}
              >
                Кем выдан
              </label>
              <input
                type="text"
                className={`inputUnderLine ${
                  validate && d__personal__passport__issued_by == ''
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  validate && d__personal__passport__issued_by != ''
                    ? 'inputValidateCorrect'
                    : ''
                } ${!isReadableProfile ? "anketaReadOnlyFields" : ""}`}
                placeholder="Кем выдан"
                readOnly={!isReadableProfile}
                value={d__personal__passport__issued_by}
                onChange={this.onPassportWhosChange}
              />
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              position: 'static',
              width: '170px',
              display: 'inline-block',
            }}
          >
            <div className="formField-container">
              <label
                className="docCreate-dateLabel"
                style={{
                  display: 'inline-block',
                  width: '85px',
                  opacity: !isReadableProfile ? '0.4' : '1',
                }}
              >
                Дата выдачи
              </label>
              <div
                className="dateBubble"
                style={{
                  position: 'static',
                  display: 'inline-block',
                  width: '80px',
                  marginLeft: '5px',
                }}
              >
                <SingleDatePicker
                  disabled={!isReadableProfile}
                  date={d__personal__passport__issued_date}
                  onDateChange={this.onPassportDateChange}
                  focused={this.state.focused}
                  onFocusChange={({ focused }) => this.setState({ focused })}
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  hideKeyboardShortcutsPanel={true}
                  renderMonthElement={this.renderMonthElement}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: '130px',
              display: 'inline-block',
              marginLeft: '30px',
            }}
          >
            <div className="formField-container position-relative">
              <label
                className={`floating-label ${
                  d__personal__passport__department_code == '' ? 'hidden' : ''
                }`}
              >
                Код подразделения
              </label>
              <InputMask
                className={`inputUnderLine profile-codeCompanyField ${
                  validate &&
                  d__personal__passport__department_code.replace(/[-_]/g, '')
                    .length != 6 &&
                  validateActiveFields.d__personal__passport__department_code
                    ? 'inputValidateCurrentWrong'
                    : ''
                } ${
                  validate &&
                  d__personal__passport__department_code.replace(/[-_]/g, '')
                    .length != 6
                    ? 'inputValidateWrong'
                    : ''
                } ${
                  validate &&
                  d__personal__passport__department_code.replace(/[-_]/g, '')
                    .length == 6
                    ? 'inputValidateCorrect'
                    : ''
                } ${!isReadableProfile ? "anketaReadOnlyFields" : ""}`}
                mask="999-999"
                placeholder="Код подразделения"
                readOnly={!isReadableProfile}
                value={d__personal__passport__department_code}
                onChange={this.onPassportCodeWhereChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
