import React from "react";
import PropTypes from "prop-types";

import { currencyFormat, sumColor } from "../utils/currencyUtil";

const CryptoFinresBoard = ({ pnl, lastPnlBalance, pnlsPrev, index }) => {
  const pnlPerc = (pnl) => {
    if (pnlsPrev.length === 0) return 0;

    const val =
      ((+lastPnlBalance +
        pnlsPrev.reduce(
          (acc, currPnl) => +acc + +currPnl.pos_pnl + +currPnl.neg_pnl + +currPnl.commission,
          0
        ) +
        +pnl.pos_pnl +
        +pnl.neg_pnl +
        +pnl.commission) /
        lastPnlBalance) *
      100 -
      100;

    return (isNaN(val) || Math.abs(val) == Infinity) ? "N/A" : val;
  };

  const formatNumber = (strNum) => {
    let num = strNum;
    let symbol = "";

    if (strNum.includes("%")) {
      symbol = strNum[strNum.length - 1];
      num = num.slice(0, -2);
    }

    let [intPart, floatPart] = num.split(",");

    console.log(strNum, num, symbol, intPart, floatPart);

    if (floatPart) {
      floatPart = floatPart.slice(0, 2);
      num = intPart + "," + floatPart;
    } else {
      num = intPart;
    }

    return symbol ? `${num} ${symbol}` : num;
  }

  return (
    <React.Fragment>
      <tr className={`${index & 1 ? "evenStroke" : "oddStroke"}`}>
        <td className="text-left">{pnl.date}</td>
        <td className={`${sumColor(+pnl.pos_pnl + +pnl.neg_pnl + +pnl.commission)}`}>
          {formatNumber(currencyFormat(+pnl.pos_pnl + +pnl.neg_pnl + +pnl.commission, "", 8, false, true))}
        </td>
        <td>
          {formatNumber(currencyFormat(
            pnlsPrev.reduce(
              (acc, currPnl) => acc + +currPnl.pos_pnl + +currPnl.neg_pnl + +currPnl.commission,
              0
            ) +
            +pnl.pos_pnl +
            +pnl.neg_pnl +
            +pnl.commission,
            "",
            8,
            false,
            true
          ))}
        </td>
        <td className={`${pnlPerc(pnl) === "N/A" ? "" : sumColor(pnlPerc(pnl))}`}>
          {!isNaN(pnlPerc(pnl)) ? formatNumber(currencyFormat(pnlPerc(pnl), "%", 8, false, true)) : pnlPerc(pnl)}
        </td>
        <td>{formatNumber(currencyFormat(pnl.balance, "", 8, false, true))}</td>
        <td>{formatNumber(currencyFormat(pnl.transfer, "", 8, false, true))}</td>
        <td>{formatNumber(currencyFormat(pnl.commission, "", 8, false, true))}</td>
      </tr>
    </React.Fragment>
  );
};

CryptoFinresBoard.propTypes = {
  pnl: PropTypes.object,
  lastPnlTransfer: PropTypes.string,
  pnlsPrev: PropTypes.array,
  index: PropTypes.number,
};

export default CryptoFinresBoard;
