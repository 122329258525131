import { SET_HIDE_TRADERS_BALANCES } from '../actions/general';

// Expenses Reducer
const reducerDefaultState = {
    hideTradersBalances: false,
};

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_HIDE_TRADERS_BALANCES:
      return {
          ...state,
          hideTradersBalances: action.hideTradersBalances
      };
    default:
      return state;
  }
};
