import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Route } from 'react-router-dom';

import DealsAnalysisDaysPage from './DealsAnalysisDaysPage';
import DealsAnalysisSymbolsPage from './DealsAnalysisSymbolsPage';
import EquityAnalysisPage from './EquityAnalysisPage';

export class AnalysisPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="analysis">
        <div className="analysis-navLinks">
          <NavLink
            to="/app/analysis/daily"
            className="nav-link"
            activeClassName="active"
          >
            <span className="sidebar-title">По дням</span>
          </NavLink>
          <NavLink
            to="/app/analysis/symbols"
            className="nav-link"
            activeClassName="active"
          >
            <span className="sidebar-title">По инструментам</span>
          </NavLink>
          <NavLink
            to="/app/analysis/equity"
            className="nav-link"
            activeClassName="active"
          >
            <span className="sidebar-title">Эквити</span>
          </NavLink>
        </div>
        <div>
          <Route
            path={'/app/analysis/daily'}
            exact={true}
            component={DealsAnalysisDaysPage}
          />
          <Route
            path={'/app/analysis/symbols'}
            exact={true}
            component={DealsAnalysisSymbolsPage}
          />
          <Route
            path={'/app/analysis/equity'}
            exact={true}
            component={EquityAnalysisPage}
          />
        </div>
      </div>
    );
  }
}

export default connect()(AnalysisPage);
