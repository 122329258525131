import React, { Component } from 'react';

export default class TestTextPage extends Component {
  render() {
    return (
      <ul>
        <li style={{ fontWeight: '400', marginBottom: '10px' }}>
          font-weight 400: рыбатекст поможет дизайнеру, верстальщику, вебмастеру
          сгенерировать несколько абзацев более менее осмысленного текста рыбы
          на русском языке 1234567890.00
        </li>
        <li style={{ fontWeight: '500', marginBottom: '10px' }}>
          font-weight 500: рыбатекст поможет дизайнеру, верстальщику, вебмастеру
          сгенерировать несколько абзацев более менее осмысленного текста рыбы
          на русском языке 1234567890.00
        </li>
        <li style={{ fontWeight: '600', marginBottom: '10px' }}>
          font-weight 600: рыбатекст поможет дизайнеру, верстальщику, вебмастеру
          сгенерировать несколько абзацев более менее осмысленного текста рыбы
          на русском языке 1234567890.00
        </li>
        <li style={{ fontWeight: '700', marginBottom: '10px' }}>
          font-weight 700: рыбатекст поможет дизайнеру, верстальщику, вебмастеру
          сгенерировать несколько абзацев более менее осмысленного текста рыбы
          на русском языке 1234567890.00
        </li>
        <li style={{ fontWeight: '800', marginBottom: '10px' }}>
          font-weight 800: рыбатекст поможет дизайнеру, верстальщику, вебмастеру
          сгенерировать несколько абзацев более менее осмысленного текста рыбы
          на русском языке 1234567890.00
        </li>
        <li style={{ fontWeight: '900', marginBottom: '10px' }}>
          font-weight 900: рыбатекст поможет дизайнеру, верстальщику, вебмастеру
          сгенерировать несколько абзацев более менее осмысленного текста рыбы
          на русском языке 1234567890.00
        </li>
      </ul>
    );
  }
}
