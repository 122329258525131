import React, { useEffect, useState } from "react";
import "moment/locale/ru";
import { connect } from "react-redux";
import Baron from "react-baron/dist/es5";
import amplitude from 'amplitude-js';

import { getLeverages, putLeverages } from "../utils/http";

export const LeveragePage = (props) => {
  const [leverages, setLeverages] = useState(null);
  const [openedLeverage, setOpenedLeverage] = useState(null);
  const [filteredLeverages, setFilterevLeverages] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    amplitude.getInstance().logEvent('leverage_page.page_view');

    setIsLoading(true);
    getLeverages()
      .then((res) => {
        setLeverages(res.data.leverages);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (leverages)
      setFilterevLeverages(
        leverages
          .filter((l) =>
            l.symbol.toLowerCase().includes(searchValue.toLowerCase())
          )
          .sort((a, b) => (a.symbol < b.symbol ? -1 : 1))
      );
  }, [leverages]);

  const onSubmit = (symbol, leverage) => {
    const data = {
      symbol,
      leverage,
    };

    putLeverages(data).then((res) => {
      if (leverages && openedLeverage) {
        const tempLeverages = [...leverages];
        const openedLeverageIndex = tempLeverages.findIndex(
          (l) => l.symbol === openedLeverage.symbol
        );
        tempLeverages[openedLeverageIndex] = openedLeverage;

        setLeverages(tempLeverages);
      }
    });
  };

  const changeLeverage = (step) => {
    if (
      openedLeverage.leverage + step >= openedLeverage.min_leverage &&
      openedLeverage.leverage + step <= openedLeverage.max_leverage
    ) {
      setOpenedLeverage({
        ...openedLeverage,
        leverage: openedLeverage.leverage + step,
      });
    }
  };

  const search = () => {
    setFilterevLeverages(
      leverages
        .filter((l) =>
          l.symbol.toLowerCase().includes(searchValue.toLowerCase())
        )
        .sort((a, b) => (a.symbol < b.symbol ? -1 : 1))
    );
  };

  const getMaxPosition = (leverage) => {
    const leverageValue = leverage.leverage;
    let maxPosition;

    leverage.brackets.reduceRight(
      (prev, item) => {
        if (item.leverage >= leverageValue && prev.leverage < leverageValue) {
          maxPosition = item.max_position;
        }
        return item;
      },
      { leverage: -1 }
    );

    return maxPosition;
  };

  const onLeverageInput = (e) => {
    const value = e.target.value
      .split("")
      .filter((char) => {
        if (![0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(+char)) return false;
        return true;
      })
      .join("");
    console.log(openedLeverage);

    if (+value > openedLeverage.max_leverage) {
      setOpenedLeverage({
        ...openedLeverage,
        leverage: openedLeverage.max_leverage,
      });
    } else if (+value < openedLeverage.min_leverage) {
      setOpenedLeverage({
        ...openedLeverage,
        leverage: openedLeverage.min_leverage,
      });
    } else {
      setOpenedLeverage({
        ...openedLeverage,
        leverage: +value,
      });
    }
  };

  const formatLeverage = (leverage) => {
    let formattedString = "";

    for (let i = leverage.length - 1; i >= 0; i--) {
      if ((leverage.length - i) % 3 === 0) {
        formattedString = " " + leverage[i] + formattedString;
      } else {
        formattedString = leverage[i] + formattedString;
      }
    }

    return formattedString.trim();
  };

  return (
    <div className="row">
      <div className="page-content-center leverage">
        <h1 className="title card-content">Плечи</h1>
        <div className="row card-content">
          <input
            type="text"
            className="form-control col-4"
            placeholder="Поиск"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                search();
              }
            }}
          />
          <button
            type="button"
            className="btn btn-primary ml-2"
            onClick={search}
          >
            Найти
          </button>
        </div>
        <div className="d-flex card-content mt-4">
          <div className="tab-content flex-grow-3">
            <div className="tab-pane fade active show">
              <div className="table">
                <table className="tableLayoutFixes">
                  <thead>
                    <tr>
                      <td>ИНСТРУМЕНТ</td>
                      <td>ПЛЕЧО</td>
                      <td>
                        МАКСИМАЛЬНОЕ
                        <br />
                        ПЛЕЧО
                      </td>
                    </tr>
                  </thead>
                </table>
                {filteredLeverages && filteredLeverages.length > 0 ? (
                  <Baron>
                    <table className="tableLayoutFixes docsTable">
                      <tbody>
                        {filteredLeverages.map((leverage, index) => (
                          <tr
                            key={leverage.symbol}
                            onClick={() => setOpenedLeverage(leverage)}
                            style={{
                              backgroundColor:
                                openedLeverage &&
                                openedLeverage.symbol === leverage.symbol
                                  ? "antiquewhite"
                                  : null,
                            }}
                          >
                            <td>{leverage.symbol}</td>
                            <td>{leverage.leverage}</td>
                            <td>{leverage.max_leverage}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Baron>
                ) : isLoading ? (
                  <div className="d-flex justify-content-center align-items-center py-4">
                    <div className="mt-1 text-center spinner-container isLoadingSpinner">
                      <span>
                        <img
                          src="/images/ui/load-spinner.png"
                          className="spinner"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center py-4">
                    Нет данных по данному поисковому значению
                  </div>
                )}
              </div>
            </div>
          </div>
          {openedLeverage ? (
            <div className="tab-content ml-2 flex-grow-2 align-self-start leverageFormContainer">
              <div className="tab-pane fade active show pt-2 px-4">
                <h2 style={{ paddingTop: 10 }}>{openedLeverage.symbol}</h2>
                <div>Настроить кредитное плечо</div>
                <div className="leverageForm">
                  <button
                    type="button"
                    className="btn-primary"
                    onClick={() => changeLeverage(-1)}
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={openedLeverage.leverage + "x"}
                    onChange={onLeverageInput}
                  />
                  <button
                    type="button"
                    className="btn-primary"
                    onClick={() => changeLeverage(1)}
                  >
                    +
                  </button>
                </div>
                <div className="mt-3">
                  Максимальная позиция при текущем кредитном плече:{" "}
                  <span className="text-warning">
                    {String(getMaxPosition(openedLeverage)).length > 18
                      ? "Без ограничений"
                      : formatLeverage(String(getMaxPosition(openedLeverage)))}
                  </span>{" "}
                  USDT
                </div>
                <button
                  type="button"
                  className="btn btn-primary mt-3 w-100"
                  onClick={() =>
                    onSubmit(openedLeverage.symbol, openedLeverage.leverage)
                  }
                >
                  Подтвердить
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tradingAccounts: state.tradingAccounts,
  tradingAccount: state.tradingAccount,
});

export default connect(mapStateToProps)(LeveragePage);
