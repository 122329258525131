import React, { Component } from "react";
import PropTypes from "prop-types";
import { DateRangePicker } from "react-dates";
import Baron from "react-baron/dist/es5";

import { currencyFormat, sumColor } from "../utils/currencyUtil";
import CryptoFinresBoard from "./CryptoFinresBoard";
import { CryptoFinresTableTitle } from "./CryptoFinresTableTitle";

export default class CryptoFinres extends Component {
  constructor(props) {
    super(props);

    this.tableTitlesInfo = [
      { className: "text-right", title: "Дата", infoModalContent: null },
      {
        className: "", title: "Прибыль и убыток за день, USDT", infoModalContent: (
          <React.Fragment>
            <p>
              Суточный PnL.
            </p>
            <p className="mt-2">
              = Прибыль - (Убыток + Комиссия биржи + Стоимость финансирования + Нереализованный PnL)
            </p>
          </React.Fragment>
        )
      },
      {
        className: "", title: "Совокупная прибыль и убыток, USDT", infoModalContent: <React.Fragment>
          <p>
            Сумма PnL всех дней, начиная с 1-го дня выбранного периода
          </p>
        </React.Fragment>
      },
      {
        className: "", title: "Совокупная прибыль и убыток, %", infoModalContent: <React.Fragment>
          <p>
            Процент доходности в рамках выбранного периода.
          </p>
          <p className="mt-2">
            = (Входящий баланс в 1-й день периода + Сумма PnL за все дни периода) / Входящий баланс в 1-й день периода x 100 – 100.
          </p>
          <p className="mt-2">
            В некоторых случаях может быть N/A
          </p>
        </React.Fragment>
      },
      {
        className: "", title: "Сумма переводов, USDT", infoModalContent: <React.Fragment>
          <p>
            Сумма пополнений и списаний со счета по неторговым операциям, за день
          </p>
        </React.Fragment>
      },
      {
        className: "", title: "Баланс на начало дня, USDT", infoModalContent: <React.Fragment>
          <p>
            Баланс фьючерсного счета на начало дня
          </p>
        </React.Fragment>
      }, {
        className: "", title: "Комиссия биржи, USDT", infoModalContent: <React.Fragment>
          <p>
            Торговая комиссия, удержанная биржей, за день
          </p>
        </React.Fragment>
      },
    ]

    this.state = {
      infoModalOpened: false,
    };
  }

  render() {
    const {
      pnls,
      startDate,
      endDate,
      dateRangeCurr,
      onDatesChange,
      calendarFocused,
      onFocusChange,
      selectCalendarDates,
      isLoading,
    } = this.props;

    return (
      <div>
        <h1 className="d-flex title card-content">
          Финансовый результат на фьючерсном счете
          <span className="finresPlatform">USD-M</span>
        </h1>
        <div className="card card-content">
          <div
            className={`card-body p-0 finresFilters ${dateRangeCurr}`}
          >
            <div className="row tableFilters-container">
              <div className="col-lg-12 mb-4 text-secondary" style={{ fontSize: "10px" }}>
                Возможны задержки и погрешность в расчетах на стороне поставщика
                данных. Данные предоставляются в ознакомительных целях.
              </div>
              <div className="col-lg-6">
                <p>Период</p>
                <div className="finresFilters-datePeriod mb-3">
                  <div className="tableFilters-datesRangeWeek">
                    <span
                      className="activePeriodWeek"
                      onClick={() => {
                        selectCalendarDates("dateRangeWeek");
                      }}
                    >
                      неделя
                    </span>
                  </div>
                  <div className="tableFilters-datesRangeMonth">
                    <span
                      className="activePeriodMonth"
                      onClick={() => {
                        selectCalendarDates("dateRangeMonth");
                      }}
                    >
                      месяц
                    </span>
                  </div>
                  <div className="tableFilters-datesRange30days">
                    <span
                      className="activePeriod30days"
                      onClick={() => {
                        selectCalendarDates("dateRange30days");
                      }}
                    >
                      последние 30 дней
                    </span>
                  </div>
                  <div className="tableFilters-datesRange90days">
                    <span
                      className="activePeriod90days"
                      onClick={() => {
                        selectCalendarDates("dateRange90days");
                      }}
                    >
                      последние 90 дней
                    </span>
                  </div>
                </div>
                <p className="inresFilters-title-customPeriod">
                  Свой период
                </p>
                <div className="form-group">
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={({ startDate, endDate }) => {
                      onDatesChange({
                        startDate,
                        endDate,
                        dateRange: "dateRangeCustom",
                      });
                    }}
                    startDatePlaceholderText="Начало"
                    endDatePlaceholderText="Конец"
                    focusedInput={calendarFocused}
                    onFocusChange={onFocusChange}
                    numberOfMonths={2}
                    minimumNights={0}
                    isOutsideRange={() => false}
                    hideKeyboardShortcutsPanel={true}
                  />
                </div>
              </div>
              <div className="col-lg-6 pr-3">
                <div className="finresTotalsContainer">
                  <div className="row">
                    <p className="col-8 pl-0 font-weight-bold">
                      <div className="d-inline-flex position-relative">
                        Анализ прибыли и убытка
                        <div
                          onMouseEnter={() =>
                            this.setState({ infoModalOpened: true })
                          }
                          onMouseLeave={() =>
                            this.setState({ infoModalOpened: false })
                          }
                        >
                          <img
                            src="/images/ui/warn-icon.png"
                            width="15px"
                            height="15px"
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                          />
                        </div>
                        {this.state.infoModalOpened && (
                          <div className="infoModal">
                            <p>
                              Торговые издержки (стоимость финансирования и сбор при ликвидации)
                              не включаются в расчет PnL.
                            </p>
                            <p className="mt-2">
                              При расчете PnL аккаунта учитывается торговая комиссия биржи,
                              а также нереализованный PnL.
                            </p>
                          </div>
                        )}
                      </div>
                    </p>
                  </div>
                  <div className="row totalData">
                    <p className="col-8 pl-0 totalSubTitle">Общая прибыль, USDT</p>
                    <p
                      className={`col-4 pr-0 text-right ${sumColor(
                        pnls.reduce((acc, currPnl) => acc + +currPnl.pos_pnl, 0)
                      )}`}
                    >
                      {currencyFormat(
                        pnls.reduce((acc, currPnl) => acc + +currPnl.pos_pnl, 0),
                        '',
                        2,
                        false,
                        true
                      )}
                    </p>
                  </div>
                  <div className="row totalData">
                    <p className="col-8 pl-0 totalSubTitle">Общий убыток, USDT</p>
                    <p
                      className={`col-4 pr-0 text-right ${sumColor(
                        pnls.reduce((acc, currPnl) => acc + +currPnl.neg_pnl, 0)
                      )}`}
                    >
                      {currencyFormat(
                        pnls.reduce((acc, currPnl) => acc + +currPnl.neg_pnl, 0),
                        '',
                        2,
                        false,
                        true
                      )}
                    </p>
                  </div>
                  <div className="row totalData">
                    <p className="col-8 pl-0 totalSubTitle">
                      Чистая прибыль / убыток, USDT
                    </p>
                    <p
                      className={`col-4 pr-0 text-right ${sumColor(
                        pnls.reduce(
                          (acc, currPnl) =>
                            acc + +currPnl.neg_pnl + +currPnl.pos_pnl,
                          0
                        )
                      )}`}
                    >
                      {currencyFormat(
                        pnls.reduce(
                          (acc, currPnl) =>
                            acc + +currPnl.pos_pnl,
                          0
                        ) + pnls.reduce((acc, currPnl) => acc + +currPnl.neg_pnl, 0),
                        '',
                        2,
                        false,
                        true
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {pnls.length > 0 && !isLoading ? (
              <div className="finresTableContainer dailyFinres">
                <table className="tableLayoutFixes dailyFinres-boardTable">
                  <thead>
                    <tr className="dailyFinres-tableTitles">
                      {this.tableTitlesInfo.map(({ title, className, infoModalContent }) => (
                        <CryptoFinresTableTitle key={title} className={className} infoModalContent={infoModalContent}>{title}</CryptoFinresTableTitle>
                      ))}
                    </tr>
                  </thead>
                </table>
                {pnls.length > 0 && !isLoading ? (
                  <Baron>
                    <table className="tableLayoutFixes dailyFinres-boardTable">
                      <tbody>
                        {pnls.map((pnl, index) => (
                          <CryptoFinresBoard
                            key={index}
                            pnlsPrev={pnls.slice(index + 1)}
                            lastPnlBalance={pnls[pnls.length - 1].balance}
                            pnl={pnl}
                            index={index}
                          />
                        ))}
                      </tbody>
                    </table>
                  </Baron>
                ) : (
                  <div className="emptyDashboard-plug">
                    {isLoading ? (
                      <div className="mt-1 text-center spinner-container isLoadingSpinner">
                        <span>
                          <img
                            src="/images/ui/load-spinner.png"
                            className="spinner"
                            alt=""
                          />
                        </span>
                      </div>
                    ) : (
                      <div className="emptyData">нет данных</div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="emptyDashboard-plug">
                {isLoading ? (
                  <div className="mt-1 text-center spinner-container isLoadingSpinner">
                    <span>
                      <img
                        src="/images/ui/load-spinner.png"
                        className="spinner"
                        alt=""
                      />
                    </span>
                  </div>
                ) : (
                  <div className="emptyData">нет данных</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CryptoFinres.propTypes = {
  pnls: PropTypes.array,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onDatesChange: PropTypes.func,
  calendarFocused: PropTypes.string,
  onFocusChange: PropTypes.func,
  records: PropTypes.number
};
