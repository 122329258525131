import React from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { connect } from "react-redux";
import moment from "moment";

import Config from "../config/config";
import { history } from "../routers/AppRouter";
import { refreshToken, catchPromise } from "../utils/http";
import LiveDataAccount from "./LiveDataAccount";
import { currencyFormat, sumColor } from "../utils/currencyUtil";
import { getTops, getAccountBoards } from "../utils/topHttp";

const isCrypto = true;

export class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.isUnmountPage = false;

    this.socket = new WebSocket(Config.socketUrl);
    this.state = {
      isMobile: window.innerWidth < 500,
      socketError: false,
      enableSections: [],
      refreshCount: 0,
      currentAcc: {},
      accountName: this.props.tradingAccount.name || "",
      accountIsDemo: this.props.tradingAccount.demo || false,
      topShown: true,
      top: { 1: [], 2: [], 3: [], 4: [] },
      top_demo: { 1: [], 2: [], 3: [], 4: [] },
      bpTop: { 1: [], 2: [], 3: [], 4: [] },
      accounts: [],
      accountSum: 0,
      totalPositionsNum: 0,
      sort_by: "",
      sortAsc: false,
      pageError: false,
      temp: {
        action: "snapshot",
        boards: {
          1: {
            name: "Фьючерсы MOEX",
          },
          2: {
            name: "Валюта MOEX",
          },
          3: {
            name: "Акции MOEX",
          },
          4: {
            name: "Акции US",
          },
        },
        trading_accounts: {
          M180016: {
            name: "M180002",
            currency: "RUR",
            info: {
              1: {
                drawdown: "1000",
              },
              2: {
                drawdown: "300",
              },
            },
          },
          TRADINGACC1: {
            name: "M180021",
            currency: "USD",
            info: {},
          },
        },
        positions: [
          {
            id: "SiZ8_FUT_MOEX_1f010360-0f08-4119-8bde-566940301307@7620290_M180002",
            board_id: "1",
            account: "M180002",
            symbol: "USD000UTSTOM",
            updated: "22:23:05",
            quantity: "-1",
            finres: "0",
            fee: "0",
          },
          {
            id: "SRZ8_FUT_MOEX_1f010360-0f08-4119-8bde-566940301307@7620290_M180002",
            board_id: "2",
            account: "M180002",
            symbol: "USD000UTSTOMASDF",
            updated: "02:23:05",
            quantity: "0",
            finres: "10",
            fee: "0",
          },
          {
            id: "RIZ8_FUT_MOEX_1f010360-0f08-4119-8bde-566940301307@7620290_M180002",
            board_id: "3",
            account: "M180002",
            symbol: "RIZ8",
            updated: "22:23:05",
            quantity: "0",
            finres: "21110",
            fee: "0",
          },
          {
            id: "USD000UTSTOM_FX_MOEX_67044435-c1eb-45f5-905a-05d757b94bfe@M20495900010_M180021",
            board_id: "2",
            account: "M180021",
            symbol: "USDRUB_TOM",
            updated: "12:25:06",
            quantity: "0",
            finres: "0",
            fee: "0",
          },
          {
            id: "USD000UTSTOM_FX_MOEX_67044435-c1eb-45f5-905a-05d757b94bfe@M20495900010_M180021",
            board_id: "2",
            account: "M180021",
            symbol: "USDRUB_TOM",
            updated: "12:24:06",
            quantity: "0",
            finres: "0",
            fee: "0",
          },
          {
            id: "USD000UTSTOM_FX_MOEX_67044435-c1eb-45f5-905a-05d757b94bfe@M20495900010_M180021",
            board_id: "3",
            account: "M180021",
            symbol: "USDRUB_TOM",
            updated: "12:26:06",
            quantity: "0",
            finres: "0",
            fee: "0",
          },
        ],
        orders: [
          {
            id: "f7ef2b20-c842-413b-8e81-a1f8e6b9d479",
            position_id:
              "SiZ8_FUT_MOEX_1f010360-0f08-4119-8bde-566940301307@7620290_M180002",
            time: "1:22:39",
            price: "300.1055",
            quantity: "1.5",
            type: "stop",
            status: "removed",
          },
          {
            id: "f7ef2b20-c842-413b-8e81-a1f8e6b",
            position_id:
              "SiZ8_FUT_MOEX_1f010360-0f08-4119-8bde-566940301307@7620290_DEMOACC1",
            time: "2:11:39",
            price: "300.012",
            quantity: "123121.5",
            type: "stop",
            status: "active",
          },
          {
            id: "f7ef2b20-c842-413b-8e81-a1f8",
            position_id:
              "SiZ8_FUT_MOEX_1f010360-0f08-4119-8bde-566940301307@7620290_M180002",
            time: "3:11:39",
            price: "123.1052",
            quantity: "231.5",
            type: "stop",
            status: "active",
          },
          {
            id: "f7ef2b20-c842-413b-8e81-a1f8123123",
            position_id:
              "SiZ8_FUT_MOEX_1f010360-0f08-4119-8bde-566940301307@7620290_M180002",
            time: "4:11:39",
            price: "123.10343",
            quantity: "231.5",
            type: "stop",
            status: "active",
          },
        ],
        info: null,
        top: {
          1: [
            {
              sum_rur: 132453,
              sum_usd: 2045,
            },
          ],
          2: [
            {
              sum_rur: 132453,
              sum_usd: 2045,
            },
            {
              sum_rur: 12664,
              sum_usd: 205,
            },
          ],
          3: [
            {
              sum_rur: 132453,
              sum_usd: 2045,
            },
            {
              sum_rur: 12664,
              sum_usd: 205,
            },
            {
              sum_rur: 6543,
              sum_usd: 101,
            },
          ],
          4: [
            {
              sum_rur: 132453,
              sum_usd: 2045,
            },
            {
              sum_rur: 12664,
              sum_usd: 205,
            },
            {
              sum_rur: 6543,
              sum_usd: 101,
            },
          ],
        },
        top_demo: {
          1: [
            {
              sum_rur: 111,
              sum_usd: 22,
            },
          ],
          2: [
            {
              sum_rur: 111,
              sum_usd: 11,
            },
            {
              sum_rur: 222,
              sum_usd: 33,
            },
          ],
          3: [
            {
              sum_rur: 11,
              sum_usd: 22,
            },
            {
              sum_rur: 33,
              sum_usd: 11,
            },
            {
              sum_rur: 1111,
              sum_usd: 44444,
            },
          ],
          4: [
            {
              sum_rur: 11,
              sum_usd: 22,
            },
            {
              sum_rur: 33,
              sum_usd: 11,
            },
            {
              sum_rur: 1111,
              sum_usd: 44444,
            },
          ],
        },
        bp: {
          1: [
            { account: "DEMOACC1", buying_power: 1658302 },
            { account: "M180021", buying_power: 3438 },
          ],
          2: [
            { account: "DEMOACC1", buying_power: 99498 },
            { account: "M180021", buying_power: 3438 },
          ],
          3: [
            { account: "DEMOACC1", buying_power: 331660 },
            { account: "M180021", buying_power: 3438 },
          ],
          4: [
            { account: "DEMOACC1", buying_power: 331660 },
            { account: "M180021", buying_power: 3438 },
          ],
        },
      },
      updateTemp: {
        positions: [
          {
            id: "SiZ8_FUT_MOEX_1f010360-0f08-4119-8bde-566940301307@7620290_DEMOACC1",
            board_id: "1",
            account: "DEMOACC1",
            symbol: "USD000UTSTOM",
            updated: "22:23:05",
            quantity: "-1",
            finres: "0",
            fee: "0",
          },
          {
            id: "SRZ8_FUT_MOEX_1f010360-0f08-4119-8bde-566940301307@7620290_TRADINGACC1",
            board_id: "2",
            account: "TRADINGACC1",
            symbol: "USD000UTSTOMASDF",
            updated: "17:23:05",
            quantity: "0",
            finres: "10",
            fee: "0",
          },
          {
            id: "RIZ8_FUT_MOEX_1f010360-0f08-4119-8bde-566940301307@7620290_TRADINGACC1",
            board_id: "1",
            account: "TRADINGACC1",
            symbol: "RIZ8",
            updated: "22:23:05",
            quantity: "0",
            finres: "21110",
            fee: "0",
          },
          {
            id: "USD000UTSTOM_FX_MOEX_67044435-c1eb-45f5-905a-05d757b94bfe@M20495900010_TRADINGACC1",
            board_id: "2",
            account: "TRADINGACC1",
            symbol: "USDRUB_TOM",
            updated: "12:23:06",
            quantity: "0",
            finres: "100",
            fee: "0",
          },
        ],
        orders: [
          {
            id: "f7ef2b20-c842-413b-8e81-a1f8e6b9d479",
            position_id:
              "SiZ8_FUT_MOEX_1f010360-0f08-4119-8bde-566940301307@7620290_M180002",
            time: "01:22:39",
            price: "300.10",
            quantity: "1.5",
            type: "stop",
            status: "removed",
          },
          {
            id: "f7ef2b20-c842-413b-8e81-a1f8e6b",
            position_id:
              "SiZ8_FUT_MOEX_1f010360-0f08-4119-8bde-566940301307@7620290_M180002",
            time: "02:11:39",
            price: "300.10",
            quantity: "123121.5",
            type: "stop",
            status: "removed",
          },
          {
            id: "f7ef2b20-c842-413b-8e81-a1f8",
            position_id:
              "SiZ8_FUT_MOEX_1f010360-0f08-4119-8bde-566940301307@7620290_M180002",
            time: "03:11:39",
            price: "123.10",
            quantity: "231.5",
            type: "stop",
            status: "removed",
          },
          {
            id: "f7ef2b20-c842-413b-8e81-a1f8123123",
            position_id:
              "SiZ8_FUT_MOEX_1f010360-0f08-4119-8bde-566940301307@7620290_M180002",
            time: "04:11:39",
            price: "123.10",
            quantity: "231.5",
            type: "stop",
            status: "active",
          },
        ],
        info: [
          {
            type: "info",
            message: "Заявка выставлена",
            title: "",
            account: "DEMOACC1",
          },
        ],
        top: {
          1: [
            {
              sum_rur: 65,
              sum_usd: 1,
            },
            {
              sum_rur: 6500,
              sum_usd: 100,
            },
            {
              sum_rur: 6500,
              sum_usd: 100,
            },
          ],
          2: [
            {
              sum_rur: 65,
              sum_usd: 1,
            },
            {
              sum_rur: 65,
              sum_usd: 1,
            },
            {
              sum_rur: 6500,
              sum_usd: 100,
            },
          ],
          3: [
            {
              sum_rur: 65,
              sum_usd: 1,
            },
            {
              sum_rur: 65,
              sum_usd: 1,
            },
            {
              sum_rur: 6500,
              sum_usd: 100,
            },
          ],
          4: [
            {
              sum_rur: 65,
              sum_usd: 1,
            },
            {
              sum_rur: 65,
              sum_usd: 1,
            },
            {
              sum_rur: 6500,
              sum_usd: 100,
            },
          ],
        },
        top_demo: {
          1: [
            {
              sum_rur: 123123,
              sum_usd: 123123,
            },
          ],
          2: [
            {
              sum_rur: 33433,
              sum_usd: 2323,
            },
            {
              sum_rur: 123,
              sum_usd: 123,
            },
          ],
          3: [
            {
              sum_rur: 333,
              sum_usd: 323,
            },
            {
              sum_rur: 123,
              sum_usd: 33,
            },
            {
              sum_rur: 123123,
              sum_usd: 3333,
            },
          ],
          4: [
            {
              sum_rur: 333,
              sum_usd: 323,
            },
            {
              sum_rur: 123,
              sum_usd: 33,
            },
            {
              sum_rur: 123123,
              sum_usd: 3333,
            },
          ],
        },
        bp: {
          1: [
            { account: "DEMOACC1", buying_power: 165 },
            { account: "M180021", buying_power: 3438 },
          ],
          2: [
            { account: "DEMOACC1", buying_power: 99498 },
            { account: "M180021", buying_power: 3438 },
          ],
          3: [
            { account: "DEMOACC1", buying_power: 331660 },
            { account: "M180021", buying_power: 3438 },
          ],
          4: [
            { account: "DEMOACC1", buying_power: 331660 },
            { account: "M180021", buying_power: 3438 },
          ],
        },
      },
      boards: {
        1: { name: "Фьючерсы MOEX" },
        2: { name: "Валюта  MOEX" },
        3: { name: "Акции  MOEX" },
        4: { name: "Акции  US" },
      },
      textJson: "",
      boardFilter: "0",
    };
  }

  errorCb = () => {
    this.setState({ pageError: true });
  };

  componentDidMount() {
    // console.log(this.socket);
    window.onresize = () => {
      const isMobile = window.innerWidth < 500 ? true : false;
      this.setState({ isMobile });
    };

    if (this.props.tradingAccount.id) this.getAccountBoards();
    // getAccountBoards().then((res) => {
    //   console.log(res);
    // });
    this.openSocket();
    // if (process.env.BUILD_TYPE === "production") this.openSocket();
    // else this.snapshotAccountList(this.state.temp);

    document
      .getElementsByClassName("content")[0]
      .addEventListener("wheel", this.scrollTops);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.tradingAccount.name !== nextProps.tradingAccount.name) {
      this.setState(
        {
          accountName: nextProps.tradingAccount.name || "",
          accountIsDemo: nextProps.tradingAccount.demo,
          currentAcc: nextProps.tradingAccount,
        },
        () => {
          this.getAccountBoards();
        }
      );
    }
  }

  getAccountBoards = () => {
    console.log(
      "get boards by this id",
      this.state.currentAcc.id,
      this.props.tradingAccount.id
    );
    getAccountBoards(this.state.currentAcc.id || this.props.tradingAccount.id)
      .then((res) => {
        this.setState(
          {
            enableSections: res.data.boards,
          },
          () => {
            console.log("enabled boards", this.state.enableSections);
            const tempAcc = this.state.accounts.find(
              (acc) => acc.name == this.state.accountName
            );
            if (
              tempAcc &&
              tempAcc.boards.find((board) => board.id == this.state.boardFilter)
                .positions.length == 0
            ) {
              this.changeAccountBoard("0");
            }
          }
        );
      })
      .catch((err) => {
        if (err.response.status == 401) {
          catchPromise(err, this.getAccountBoards, this.errorCb);
        }
        if (err.response.status == 404) {
          this.setState({ enableSections: [] });
        }
      });
  };

  getTops = () => {
    getTops()
      .then((res) => {
        this.setState({
          top: res.data.top,
          top_demo: res.data.top_demo,
          bpTop: res.data.bp,
        });
      })
      .catch((err) => {
        catchPromise(err, this.getTops, this.errorCb);
      });
  };

  openSocket = () => {
    this.socket.addEventListener("open", this.socketOpenListener);
    this.socket.addEventListener("message", this.socketMessageListener);
    this.socket.addEventListener("error", this.socketErrorListener);
    this.socket.addEventListener("close", this.socketCloseListener);
  };
  socketOpenListener = () => {
    // console.log('socket open');
    //this.socket.send('Сокет подключен');
  };

  socketMessageListener = (evt) => {
    console.log("socket message");
    console.log(evt.data);
    this.setState({ socketError: false });
    const socketData = JSON.parse(evt.data);

    if (socketData.action === "snapshot") {
      this.snapshotAccountList(socketData);
    } else if (socketData.action === "update") {
      this.updateAccountList(socketData);
    } else if (socketData.action === "clear") {
      this.clearAccountList();
    }
  };
  socketErrorListener = (errorEvent) => {
    console.log("Socket error");
    this.setState({ socketError: true });
  };
  socketCloseListener = (closeEvent) => {
    console.log("Close error");

    let refreshTime = 1000;
    if (this.state.refreshCount < 10) {
      this.setState({ refreshCount: this.state.refreshCount + 1 }, () => {
        if (this.state.refreshCount == 2) refreshTime = 3000;
        if (this.state.refreshCount > 2) refreshTime = 10000;
        console.log(this.state.refreshCount);
        setTimeout(() => {
          this.socket = new WebSocket(Config.socketUrl);
          this.openSocket();
        }, refreshTime);
      });
    }
  };

  componentWillUnmount() {
    window.onresize = null;
    this.isUnmountPage = true;
    this.socket.close();
  }

  clearAccountList = () => {
    this.setState({ accounts: [] });
  };

  snapshotAccountList = (currObj) => {
    console.log("socket data", currObj);
    let tempTop = {};
    let tempTopDemo = {};
    let tempBpTop = {};
    let tempAcc = [];
    let accSum = 0;
    // счета
    Object.keys(currObj.trading_accounts).map((key) => {
      tempAcc.push({
        name: currObj.trading_accounts[key].name,
        currency: currObj.trading_accounts[key].currency,
        boards: [],
        info: currObj.trading_accounts[key].info,
        isBlockedClosingPositions: false,
        isBlockedClosingOrders: false,
        positions: [],
      });
    });
    // Общая доска
    for (let i = 0; i < tempAcc.length; i++) {
      const elem = tempAcc[i];
      elem.boards.push({
        id: "0",
        name: "Все",
        positions: [],
      });
    }
    // доски
    Object.keys(currObj.boards).map((key) => {
      for (let i = 0; i < tempAcc.length; i++) {
        const elem = tempAcc[i];
        elem.boards.push({
          id: key,
          name: currObj.boards[key].name,
          positions: [],
        });
      }
    });
    // просадки для досок
    for (let i = 0; i < tempAcc.length; i++) {
      for (let j = 0; j < tempAcc[i].boards.length; j++) {
        Object.keys(tempAcc[i].info).map((key) => {
          if (key === tempAcc[i].boards[j].id) {
            tempAcc[i].boards[j].drawdown = tempAcc[i].info[key].drawdown;
          }
        });
      }
    }
    // позиции
    for (let i = 0; i < currObj.positions.length; i++) {
      const elem = currObj.positions[i];
      for (let j = 0; j < tempAcc.length; j++) {
        for (let k = 0; k < tempAcc[j].boards.length; k++) {
          if (
            elem.account === tempAcc[j].name &&
            elem.board_id === tempAcc[j].boards[k].id
          ) {
            tempAcc[j].boards[k].positions.push({
              ...elem,
              orders: [],
              visibleOrders: false,
              board_name: tempAcc[j].boards[k].name,
              drawdown: tempAcc[j].boards[k].drawdown,
              isBlockedClosingOrders: false,
            });
          }
        }
      }
    }
    // ордеры
    for (let i = 0; i < currObj.orders.length; i++) {
      const elem = currObj.orders[i];
      for (let j = 0; j < tempAcc.length; j++) {
        for (let k = 0; k < tempAcc[j].boards.length; k++) {
          for (let l = 0; l < tempAcc[j].boards[k].positions.length; l++) {
            const elemAccountOrder = tempAcc[j].boards[k].positions[l];
            if (elem.position_id === elemAccountOrder.id) {
              tempAcc[j].boards[k].positions[l].orders.push(elem);
            }
          }
        }
      }
    }
    // Позиции для общей доски
    for (let j = 0; j < tempAcc.length; j++) {
      let allPositions = [];
      for (let k = 0; k < tempAcc[j].boards.length; k++) {
        allPositions = allPositions.concat(tempAcc[j].boards[k].positions);
      }
      tempAcc[j].boards.filter((board) => board.id === "0")[0].positions =
        allPositions;
    }
    // итого каждого бордера
    let allTotalPositions = 0;
    for (let i = 0; i < tempAcc.length; i++) {
      let accFinresSum = 0;
      let accFeeSum = 0;
      let accQuantitySum = 0;
      let accTotalPositions = 0;
      for (let j = 0; j < tempAcc[i].boards.length; j++) {
        let boardFinresSum = 0;
        let boardFeeSum = 0;
        let boardQuantitySum = 0;
        let boardOrdersSum = 0;
        for (let k = 0; k < tempAcc[i].boards[j].positions.length; k++) {
          boardFinresSum += parseFloat(
            tempAcc[i].boards[j].positions[k].finres
          );
          boardFeeSum += parseFloat(tempAcc[i].boards[j].positions[k].fee);
          boardQuantitySum += Math.abs(
            parseFloat(tempAcc[i].boards[j].positions[k].quantity)
          );
          boardOrdersSum += tempAcc[i].boards[j].positions[k].orders.length;
        }
        tempAcc[i].boards[j].totalFinres = boardFinresSum;
        tempAcc[i].boards[j].totalFee = boardFeeSum;
        tempAcc[i].boards[j].totalQuantity = boardQuantitySum;
        tempAcc[i].boards[j].totalOrders = boardOrdersSum;
        accTotalPositions += tempAcc[i].boards[j].positions.length;
        allTotalPositions += tempAcc[i].boards[j].positions.length;

        accFinresSum += boardFinresSum;
        accFeeSum += boardFeeSum;
        accQuantitySum += boardQuantitySum;
      }
      tempAcc[i].totalFinres = accFinresSum;
      tempAcc[i].totalFee = accFeeSum;
      tempAcc[i].totalQuantity = accQuantitySum;
      tempAcc[i].totalPositions = accTotalPositions;

      accSum += accFinresSum;
    }

    tempTop = currObj.top;
    tempTopDemo = currObj.top_demo || this.state.top_demo;
    tempBpTop = currObj.bp;

    console.log("sorting socket data", tempAcc);
    this.setState({
      accounts: this.sortAccount(tempAcc),
      top: tempTop,
      top_demo: tempTopDemo,
      bpTop: tempBpTop,
      accountSum: accSum,
      totalPositionsNum: allTotalPositions,
      boards: currObj.boards,
    });
  };

  updateAccountList = (newAcc) => {
    // console.log('updating ' + JSON.stringify(newAcc));
    let accSum = 0;
    let topSum = {};
    let topDemoSum = {};
    let bpTopSum = {};
    let updatedAcc = this.state.accounts;
    for (let i = 0; i < updatedAcc.length; i++) {
      updatedAcc[i].isBlockedClosingPositions = false;
      updatedAcc[i].isBlockedClosingOrders = false;
      updatedAcc[i].boards[0] = {
        id: "0",
        name: "Все",
        positions: [],
      };
      for (let j = 0; j < updatedAcc[i].boards.length; j++) {
        // Обновление позиций
        if (newAcc.positions) {
          for (let k = 0; k < newAcc.positions.length; k++) {
            let samePosition = false;
            for (let l = 0; l < updatedAcc[i].boards[j].positions.length; l++) {
              if (
                newAcc.positions[k].account === updatedAcc[i].name &&
                newAcc.positions[k].board_id === updatedAcc[i].boards[j].id &&
                newAcc.positions[k].id ===
                  updatedAcc[i].boards[j].positions[l].id
              ) {
                samePosition = true;
                updatedAcc[i].boards[j].positions[l] = {
                  ...newAcc.positions[k],
                  orders: updatedAcc[i].boards[j].positions[l].orders,
                  visibleOrders:
                    updatedAcc[i].boards[j].positions[l].visibleOrders,
                  isBlockedClosing: false,
                  isBlockedClosingOrders: false,
                  board_name: updatedAcc[i].boards[j].name,
                  drawdown: updatedAcc[i].boards[j].drawdown,
                };
              }
            }
            if (
              newAcc.positions[k].account === updatedAcc[i].name &&
              newAcc.positions[k].board_id === updatedAcc[i].boards[j].id
            ) {
              if (!samePosition)
                updatedAcc[i].boards[j].positions.push({
                  ...newAcc.positions[k],
                  orders: [],
                  visibleOrders: false,
                  isBlockedClosing: false,
                  board_name: updatedAcc[i].boards[j].name,
                  drawdown: updatedAcc[i].boards[j].drawdown,
                });
            }
          }
        }
        // обновление ордеров
        if (newAcc.orders) {
          for (let k = 0; k < updatedAcc[i].boards[j].positions.length; k++) {
            for (let l = 0; l < newAcc.orders.length; l++) {
              let sameOrder = false;
              for (
                let m = updatedAcc[i].boards[j].positions[k].orders.length - 1;
                m >= 0;
                m--
              ) {
                if (
                  newAcc.orders[l].id ===
                    updatedAcc[i].boards[j].positions[k].orders[m].id &&
                  newAcc.orders[l].position_id ===
                    updatedAcc[i].boards[j].positions[k].id
                ) {
                  if (newAcc.orders[l].status === "active") {
                    updatedAcc[i].boards[j].positions[k].orders[m] = {
                      ...newAcc.orders[l],
                      isBlockedClosing: false,
                    };
                  } else if (newAcc.orders[l].status === "removed") {
                    updatedAcc[i].boards[j].positions[k].orders.splice(m, 1);
                  }
                  sameOrder = true;
                }
              }
              if (
                !sameOrder &&
                newAcc.orders[l].position_id ===
                  updatedAcc[i].boards[j].positions[k].id &&
                newAcc.orders[l].status === "active"
              ) {
                updatedAcc[i].boards[j].positions[k].orders.push({
                  ...newAcc.orders[l],
                  isBlockedClosing: false,
                });
              }
            }
          }
        }
      }
    }
    // Позиции для общей доски
    for (let j = 0; j < updatedAcc.length; j++) {
      let allPositions = [];
      for (let k = 0; k < updatedAcc[j].boards.length; k++) {
        allPositions = allPositions.concat(updatedAcc[j].boards[k].positions);
      }
      updatedAcc[j].boards.filter((board) => board.id === "0")[0].positions =
        allPositions;
    }
    // итого каждого бордера
    let allTotalPositions = 0;
    for (let i = 0; i < updatedAcc.length; i++) {
      let accFinresSum = 0;
      let accFeeSum = 0;
      let accQuantitySum = 0;
      let accTotalPositions = 0;
      for (let j = 0; j < updatedAcc[i].boards.length; j++) {
        let boardFinresSum = 0;
        let boardFeeSum = 0;
        let boardQuantitySum = 0;
        let boardOrdersSum = 0;
        for (let k = 0; k < updatedAcc[i].boards[j].positions.length; k++) {
          boardFinresSum += parseFloat(
            updatedAcc[i].boards[j].positions[k].finres
          );
          boardFeeSum += parseFloat(updatedAcc[i].boards[j].positions[k].fee);
          boardQuantitySum += Math.abs(
            parseFloat(updatedAcc[i].boards[j].positions[k].quantity)
          );
          boardOrdersSum += updatedAcc[i].boards[j].positions[k].orders.length;
        }
        updatedAcc[i].boards[j].totalFinres = boardFinresSum;
        updatedAcc[i].boards[j].totalFee = boardFeeSum;
        updatedAcc[i].boards[j].totalQuantity = boardQuantitySum;
        updatedAcc[i].boards[j].totalOrders = boardOrdersSum;
        accTotalPositions += updatedAcc[i].boards[j].positions.length;
        allTotalPositions += updatedAcc[i].boards[j].positions.length;

        accFinresSum += boardFinresSum;
        accFeeSum += boardFeeSum;
        accQuantitySum += boardQuantitySum;
      }
      updatedAcc[i].totalFinres = accFinresSum;
      updatedAcc[i].totalFee = accFeeSum;
      updatedAcc[i].totalQuantity = accQuantitySum;
      updatedAcc[i].totalPositions = accTotalPositions;

      accSum += accFinresSum;
    }

    if (newAcc.top != undefined || newAcc.top != null) topSum = newAcc.top;
    else topSum = this.state.top;

    if (newAcc.top_demo != undefined || newAcc.top_demo != null)
      topDemoSum = newAcc.top_demo;
    else topDemoSum = this.state.top_demo;

    if (newAcc.bp != undefined || newAcc.bp != null) bpTopSum = newAcc.bp;
    else bpTopSum = this.state.bpTop;

    // console.log(updatedAcc);
    this.setState({
      accounts: this.sortAccount(updatedAcc),
      accountSum: accSum,
      top: topSum,
      top_demo: topDemoSum,
      bpTop: bpTopSum,
      totalPositionsNum: allTotalPositions,
    });
  };
  openOrders = (position, isAllSection = false) => {
    let path =
      window.event.path ||
      (window.event.composedPath && window.event.composedPath());
    if (
      position.orders.length > 0 &&
      !path[0].classList.contains("dashboard-instCloseBtn")
    ) {
      for (let i = 0; i < this.state.accounts.length; i++) {
        if (position.account === this.state.accounts[i].name) {
          for (let j = 0; j < this.state.accounts[i].boards.length; j++) {
            if (position.board_id === this.state.accounts[i].boards[j].id) {
              for (
                let k = 0;
                k < this.state.accounts[i].boards[j].positions.length;
                k++
              ) {
                if (
                  position.id ===
                  this.state.accounts[i].boards[j].positions[k].id
                ) {
                  if (!isAllSection) {
                    this.state.accounts[i].boards[j].positions[
                      k
                    ].visibleOrders =
                      !this.state.accounts[i].boards[j].positions[k]
                        .visibleOrders;
                  } else
                    this.state.accounts[i].boards[j].positions[
                      k
                    ].isOpenedInAllSection =
                      !this.state.accounts[i].boards[j].positions[k]
                        .isOpenedInAllSection;
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      this.setState({ accounts: this.state.accounts });
    }
  };
  onTextJsonChange = (e) => {
    const textJson = e.target.value;
    this.setState({ textJson });
  };

  getSnapshot = () => {
    this.socket.send(`{"command": "GetSnapshot"}`);
  };
  closePosition = (position) => {
    if (!position.isBlockedClosing) {
      this.socket.send(`{"command": "ClosePosition", "id": "${position.id}"}`);

      for (let i = 0; i < this.state.accounts.length; i++) {
        if (position.account === this.state.accounts[i].name) {
          for (let j = 0; j < this.state.accounts[i].boards.length; j++) {
            if (position.board_id === this.state.accounts[i].boards[j].id) {
              for (
                let k = 0;
                k < this.state.accounts[i].boards[j].positions.length;
                k++
              ) {
                if (
                  position.id ===
                  this.state.accounts[i].boards[j].positions[k].id
                ) {
                  this.state.accounts[i].boards[j].positions[
                    k
                  ].isBlockedClosing = true;
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      this.setState({ accounts: this.state.accounts });
    }
  };
  cancelOrders = (position) => {
    if (!position.isBlockedClosingOrders) {
      this.socket.send(`{"command": "CancelOrders", "id": "${position.id}"}`);

      for (let i = 0; i < this.state.accounts.length; i++) {
        if (position.account === this.state.accounts[i].name) {
          for (let j = 0; j < this.state.accounts[i].boards.length; j++) {
            if (position.board_id === this.state.accounts[i].boards[j].id) {
              for (
                let k = 0;
                k < this.state.accounts[i].boards[j].positions.length;
                k++
              ) {
                if (
                  position.id ===
                  this.state.accounts[i].boards[j].positions[k].id
                ) {
                  this.state.accounts[i].boards[j].positions[
                    k
                  ].isBlockedClosingOrders = true;
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      this.setState({ accounts: this.state.accounts });
    }
  };
  cancelOrder = (order) => {
    this.socket.send(`{"command": "CancelOrder", "id": "${order.id}"}`);

    for (let i = 0; i < this.state.accounts.length; i++) {
      for (let j = 0; j < this.state.accounts[i].boards.length; j++) {
        for (
          let k = 0;
          k < this.state.accounts[i].boards[j].positions.length;
          k++
        ) {
          if (
            order.position_id ===
            this.state.accounts[i].boards[j].positions[k].id
          ) {
            for (
              let l = 0;
              l < this.state.accounts[i].boards[j].positions[k].orders.length;
              l++
            ) {
              if (
                order.id ===
                this.state.accounts[i].boards[j].positions[k].orders[l].id
              ) {
                this.state.accounts[i].boards[j].positions[k].orders[
                  l
                ].isBlockedClosing = true;
              }
            }
          }
        }
      }
    }
    this.setState({ accounts: this.state.accounts });
  };
  closeAllPositions = () => {
    this.socket.send(
      `{"command": "CloseAllPositions", "id": "${this.state.accountName}"}`
    );

    for (let i = 0; i < this.state.accounts.length; i++) {
      if (this.state.accountName === this.state.accounts[i].name) {
        this.state.accounts[i].isBlockedClosingPositions = true;
        break;
      }
    }
    this.setState({ accounts: this.state.accounts });
  };
  cancelAllOrders = () => {
    // console.log(`{"command": "CancelAllOrders", "id": "${this.state.accountName}"}`);
    this.socket.send(
      `{"command": "CancelAllOrders", "id": "${this.state.accountName}"}`
    );

    for (let i = 0; i < this.state.accounts.length; i++) {
      if (this.state.accountName === this.state.accounts[i].name) {
        this.state.accounts[i].isBlockedClosingOrders = true;
        break;
      }
    }
    this.setState({ accounts: this.state.accounts });
  };

  changeAccountBoard = (id) => {
    if (
      this.state.accounts.find((obj) => obj.name === this.state.accountName)
        .boards[id] &&
      this.state.accounts.find((obj) => obj.name === this.state.accountName)
        .boards[id].positions.length > 0
    )
      this.setState({ boardFilter: id });
  };

  scrollTops = (e) => {
    let isUnderTable = false;
    let path = e.path || (e.composedPath && e.composedPath());
    for (let i = 0; i < path.length; i++) {
      if (path[i].classList) {
        if (path[i].classList.contains("dashboardTable")) isUnderTable = true;
      }
    }
    if (!isUnderTable) {
      if (e.deltaY < 0) {
        this.setState({ topShown: true });
      } else if (e.deltaY > 0) {
        this.setState({ topShown: false });
      }
    }
  };
  onChangeSort = (sort_by) => {
    const sortAsc = sort_by == this.state.sort_by ? !this.state.sortAsc : false;
    this.setState({ sort_by, sortAsc }, () => {
      const newAccounts = this.sortAccount(this.state.accounts);
      this.setState({ accounts: newAccounts });
    });
  };
  sortAccount = (newAccounts) => {
    let sortedAccounts = newAccounts;
    for (let i = 0; i < sortedAccounts.length; i++) {
      for (let j = 0; j < sortedAccounts[i].boards.length; j++) {
        if (this.state.sort_by == "board_name")
          sortedAccounts[i].boards[j].positions = sortedAccounts[i].boards[
            j
          ].positions.sort(this.sortCompareBoardName);
        else if (this.state.sort_by == "finres")
          sortedAccounts[i].boards[j].positions = sortedAccounts[i].boards[
            j
          ].positions.sort(this.sortCompareFinres);
        else if (this.state.sort_by == "updated")
          sortedAccounts[i].boards[j].positions = sortedAccounts[i].boards[
            j
          ].positions.sort(this.sortCompareUpdated);
      }
    }
    return sortedAccounts;
  };

  sortCompareBoardName = (a, b) => {
    const valueA = a.board_name.toUpperCase();
    const valueB = b.board_name.toUpperCase();

    let comparison = 0;
    if (valueA > valueB) {
      comparison = this.state.sortAsc ? 1 : -1;
    } else if (valueA < valueB) {
      comparison = this.state.sortAsc ? -1 : 1;
    }
    return comparison;
  };
  sortCompareFinres = (a, b) => {
    const valueA = a.finres;
    const valueB = b.finres;

    return !this.state.sortAsc ? valueB - valueA : valueA - valueB;
  };
  sortCompareUpdated = (a, b) => {
    const valueA = moment(a.updated, "HH:mm:ss");
    const valueB = moment(b.updated, "HH:mm:ss");

    let comparison = 0;
    if (moment(valueA).isAfter(valueB)) {
      comparison = this.state.sortAsc ? 1 : -1;
    } else if (!moment(valueA).isAfter(valueB)) {
      comparison = this.state.sortAsc ? -1 : 1;
    }
    return comparison;
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isMobile ? (
          <div>
            <NotificationContainer />
            <div
              className={`dashboard ${
                this.state.topShown ? "" : "dashboard-hidingTop"
              }`}
            >
              {/* <div className="">
                        <button type="button" onClick={() => {this.updateAccountList(this.state.updateTemp)}}>update</button>
                        <input 
                            type="text" 
                            value={this.state.textJson}
                            onChange={this.onTextJsonChange} />
                        <button type="button" onClick={() => {this.clearAccountList()}}>clear</button>
                    </div> */}
              {isCrypto ? null : (
                <React.Fragment>
                  <h3 className="dashboard-title">Лидеры</h3>
                  <div className="row d-flex justify-content-center tops-container flex-nowrap">
                    {["1", "3", "2", "4"].map((top) => (
                      <div
                        className={`top-card ${
                          top == "4" ? "" : "topCard-rightMargin"
                        }`}
                      >
                        <div className="card">
                          <h2 className="text-center mt-1">
                            {this.state.boards[top].name.replace(" MOEX", "")}
                          </h2>
                          <div className="table">
                            <div>
                              {[0, 1, 2].map((topNum) => (
                                <React.Fragment>
                                  <div className="top_ru_currency">
                                    {this.state[
                                      this.state.accountIsDemo ? "top_demo" : "top"
                                    ][top][topNum]
                                      ? currencyFormat(
                                          this.state[
                                            this.state.accountIsDemo
                                              ? "top_demo"
                                              : "top"
                                          ][top][topNum][
                                            top == 4 ? "sum_usd" : "sum_rur"
                                          ],
                                          top == 4 ? "USD" : "RUR",
                                          0,
                                          true
                                        )
                                      : currencyFormat(
                                          0,
                                          top == 4 ? "USD" : "RUR",
                                          0,
                                          true
                                        )}
                                  </div>
                                  <div>
                                    {this.state[
                                      this.state.accountIsDemo ? "top_demo" : "top"
                                    ][top][topNum]
                                      ? currencyFormat(
                                          this.state[
                                            this.state.accountIsDemo
                                              ? "top_demo"
                                              : "top"
                                          ][top][topNum][
                                            top == 4 ? "sum_rur" : "sum_usd"
                                          ],
                                          top == 4 ? "RUR" : "USD",
                                          0,
                                          true
                                        )
                                      : currencyFormat(
                                          0,
                                          top == 4 ? "RUR" : "USD",
                                          0,
                                          true
                                        )}
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              )}
              {!this.state.socketError ? (
                <div>
                  {
                    <div>
                      {this.state.enableSections.length > 0 ? (
                        <div>
                          <div>
                            <div>
                              <div className="boardsTabs-tab">
                                <h3 className="boardsTabs-title">Все</h3>
                                <div className="boardsTabs-finres">
                                  <div
                                    className={`boardsTabs-circle ${
                                      this.state.accounts.find(
                                        (obj) =>
                                          obj.name === this.state.accountName
                                      ) &&
                                      this.state.accounts.find(
                                        (obj) =>
                                          obj.name === this.state.accountName
                                      ).totalPositions > 0
                                        ? this.state.accounts.find(
                                            (obj) =>
                                              obj.name ===
                                              this.state.accountName
                                          ).boards["0"].totalFinres >= 0
                                          ? "green"
                                          : "red"
                                        : "green"
                                    }`}
                                  ></div>
                                  <div className="boardsTabs-sum">
                                    {currencyFormat(
                                      this.state.accounts.find(
                                        (obj) =>
                                          obj.name === this.state.accountName
                                      ) &&
                                        this.state.accounts.find(
                                          (obj) =>
                                            obj.name === this.state.accountName
                                        ).totalPositions > 0
                                        ? this.state.accounts.find(
                                            (obj) =>
                                              obj.name ===
                                              this.state.accountName
                                          ).boards["0"].totalFinres
                                        : 0,
                                      "RUR",
                                      2,
                                      true
                                    )}
                                  </div>
                                </div>
                                <div
                                  onClick={() =>
                                    this.setState({
                                      allSectionIsClosed:
                                        !this.state.allSectionIsClosed,
                                    })
                                  }
                                  style={
                                    this.state.allSectionIsClosed
                                      ? { transform: "rotate(180deg)" }
                                      : null
                                  }
                                >
                                  <img src="/images/ui/arrow-up.svg" />
                                </div>
                              </div>
                              <div
                                className={
                                  !this.state.isMobile ? "tab-content" : ""
                                }
                              >
                                <div
                                  className="tab-pane fade active show"
                                  id="basic-rounded-tab1"
                                >
                                  <div className="table dashboardTable">
                                    {!this.state.allSectionIsClosed &&
                                    this.state.accounts.find(
                                      (obj) =>
                                        obj.name === this.state.accountName
                                    ) &&
                                    this.state.accounts.find(
                                      (obj) =>
                                        obj.name === this.state.accountName
                                    ).totalPositions > 0 &&
                                    this.state.accounts
                                      .find(
                                        (obj) =>
                                          obj.name === this.state.accountName
                                      )
                                      .boards.filter(
                                        (board) => board.id == 0
                                      )[0].positions.length ? (
                                      <LiveDataAccount
                                        account={this.state.accounts.find(
                                          (obj) =>
                                            obj.name === this.state.accountName
                                        )}
                                        openOrders={this.openOrders}
                                        closePosition={this.closePosition}
                                        cancelOrders={this.cancelOrders}
                                        cancelOrder={this.cancelOrder}
                                        boardFilter={this.state.boardFilter}
                                        sort_by={this.state.sort_by}
                                        sortAsc={this.state.sortAsc}
                                        onChangeSort={this.onChangeSort}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <button
                                disabled={
                                  this.state.accounts.find(
                                    (obj) => obj.name === this.state.accountName
                                  ) &&
                                  this.state.accounts.find(
                                    (obj) => obj.name === this.state.accountName
                                  ).totalPositions > 0
                                    ? this.state.accounts
                                        .find(
                                          (obj) =>
                                            obj.name === this.state.accountName
                                        )
                                        .boards.find((obj) => obj.id === "0")
                                        .totalOrders > 0
                                      ? false
                                      : true
                                    : true
                                }
                                className="btn btn-success"
                                onClick={() => {
                                  this.cancelAllOrders();
                                }}
                              >
                                Снять все заявки
                              </button>
                              <button
                                disabled={
                                  this.state.accounts.find(
                                    (obj) => obj.name === this.state.accountName
                                  ) &&
                                  this.state.accounts.find(
                                    (obj) => obj.name === this.state.accountName
                                  ).totalPositions > 0
                                    ? this.state.accounts.find(
                                        (obj) =>
                                          obj.name === this.state.accountName
                                      ).totalQuantity > 0
                                      ? false
                                      : true
                                    : true
                                }
                                className="btn btn-success"
                                style={{ marginTop: 8 }}
                                onClick={() => {
                                  this.closeAllPositions();
                                }}
                              >
                                Закрыть все позиции
                              </button>
                            </div>
                            {this.state.accounts.find(
                              (obj) => obj.name === this.state.accountName
                            ) &&
                            this.state.accounts.find(
                              (obj) => obj.name === this.state.accountName
                            ).totalPositions > 0
                              ? this.state.enableSections.map((section) => {
                                  return (
                                    <div>
                                      <div className="boardsTabs-tab">
                                        <h3 className="boardsTabs-title">
                                          {section.title.replace(" MOEX", "")}
                                        </h3>
                                        <div className="boardsTabs-finres">
                                          <div
                                            className={`boardsTabs-circle ${
                                              this.state.accounts.find(
                                                (obj) =>
                                                  obj.name ===
                                                  this.state.accountName
                                              ).boards[section.id].totalFinres >
                                              0
                                                ? "green"
                                                : "red"
                                            }`}
                                          ></div>
                                          <div className="boardsTabs-sum">
                                            {currencyFormat(
                                              this.state.accounts.find(
                                                (obj) =>
                                                  obj.name ===
                                                  this.state.accountName
                                              ).boards[section.id].totalFinres,
                                              "RUR",
                                              2,
                                              true
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          onClick={() =>
                                            this.setState({
                                              [`${section.code}isClosed`]:
                                                !this.state[
                                                  `${section.code}isClosed`
                                                ],
                                            })
                                          }
                                          style={
                                            this.state[
                                              `${section.code}isClosed`
                                            ]
                                              ? { transform: "rotate(180deg)" }
                                              : null
                                          }
                                        >
                                          <img src="/images/ui/arrow-up.svg" />
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          !this.state.isMobile
                                            ? "tab-content"
                                            : ""
                                        }
                                      >
                                        <div
                                          className="tab-pane fade active show"
                                          id="basic-rounded-tab1"
                                        >
                                          <div className="table dashboardTable">
                                            {!this.state[
                                              `${section.code}isClosed`
                                            ] &&
                                            this.state.accounts
                                              .find(
                                                (obj) =>
                                                  obj.name ===
                                                  this.state.accountName
                                              )
                                              .boards.filter(
                                                (board) =>
                                                  board.id == section.id
                                              )[0].positions.length ? (
                                              <LiveDataAccount
                                                account={this.state.accounts.find(
                                                  (obj) =>
                                                    obj.name ===
                                                    this.state.accountName
                                                )}
                                                openOrders={this.openOrders}
                                                closePosition={
                                                  this.closePosition
                                                }
                                                cancelOrders={this.cancelOrders}
                                                cancelOrder={this.cancelOrder}
                                                boardFilter={section.id}
                                                sort_by={this.state.sort_by}
                                                sortAsc={this.state.sortAsc}
                                                onChangeSort={this.onChangeSort}
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div
                            style={{
                              width: "1000px",
                              height: "270px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <h3>Нет подключенных секций</h3>
                          </div>
                        </div>
                      )}
                    </div>
                  }
                </div>
              ) : (
                <div className="row">
                  <div
                    style={{
                      width: "1000px",
                      height: "270px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3 className="text-danger">Ошибка загрузки данных</h3>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <NotificationContainer />
            <div
              className={`dashboard ${
                this.state.topShown ? "" : "dashboard-hidingTop"
              }`}
            >
              {/* <div className="">
                        <button type="button" onClick={() => {this.updateAccountList(this.state.updateTemp)}}>update</button>
                        <input 
                            type="text" 
                            value={this.state.textJson}
                            onChange={this.onTextJsonChange} />
                        <button type="button" onClick={() => {this.clearAccountList()}}>clear</button>
                    </div> */}
              {isCrypto ? null : (
                <div className="row d-flex justify-content-center tops-container">
                  {["1", "3", "2", "4"].map((top) => (
                    <div
                      className={`top-card ${
                        top == "4" ? "" : "topCard-rightMargin"
                      }`}
                    >
                      <div className="card">
                        <h2 className="text-center mt-1">
                          {this.state.boards[top].name.replace(" MOEX", "")}
                        </h2>
                        <div className="table">
                          <table>
                            <tbody>
                              {[0, 1, 2].map((topNum) => (
                                <tr>
                                  <td className="text-right topDashboard-index">
                                    {topNum + 1}
                                  </td>
                                  <td className="text-right topDashboard-rur">
                                    {this.state[
                                      this.state.accountIsDemo
                                        ? "top_demo"
                                        : "top"
                                    ][top][topNum]
                                      ? currencyFormat(
                                          this.state[
                                            this.state.accountIsDemo
                                              ? "top_demo"
                                              : "top"
                                          ][top][topNum][
                                            top == 4 ? "sum_usd" : "sum_rur"
                                          ],
                                          top == 4 ? "USD" : "RUR",
                                          0
                                        )
                                      : currencyFormat(
                                          0,
                                          top == 4 ? "USD" : "RUR",
                                          0
                                        )}
                                  </td>
                                  <td className="topDashboard-usd">
                                    {this.state[
                                      this.state.accountIsDemo
                                        ? "top_demo"
                                        : "top"
                                    ][top][topNum]
                                      ? currencyFormat(
                                          this.state[
                                            this.state.accountIsDemo
                                              ? "top_demo"
                                              : "top"
                                          ][top][topNum][
                                            top == 4 ? "sum_rur" : "sum_usd"
                                          ],
                                          top == 4 ? "RUR" : "USD",
                                          0
                                        )
                                      : currencyFormat(
                                          0,
                                          top == 4 ? "RUR" : "USD",
                                          0
                                        )}
                                  </td>
                                </tr>
                              ))}
                              <tr className="topCard-ps">
                                <td className="text-right topDashboard-index">
                                  ПС
                                </td>
                                <td className="text-right topDashboard-rur">
                                  {(this.state.bpTop[top].find(
                                    (bp) => bp.account === this.state.accountName
                                  ) &&
                                    this.state.accounts.find(
                                      (obj) => obj.name === this.state.accountName
                                    ) &&
                                    (currencyFormat(
                                      this.state.bpTop[top].find(
                                        (bp) =>
                                          bp.account === this.state.accountName
                                      ).buying_power,
                                      this.state.accounts.find(
                                        (obj) =>
                                          obj.name === this.state.accountName
                                      ).currency,
                                      0
                                    ) ||
                                      "-")) ||
                                    "-"}
                                </td>
                                <td className="topDashboard-usd"></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {!this.state.socketError ? (
                <div>
                  {
                    <div>
                      {this.state.enableSections.length > 0 ? (
                        <div>
                          {this.state.accounts.find(
                            (obj) => obj.name === this.state.accountName
                          ) &&
                          this.state.accounts.find(
                            (obj) => obj.name === this.state.accountName
                          ).totalPositions > 0 ? (
                            <div>
                              <div>
                                <ul className="nav nav-tabs boardsTabs">
                                  <li
                                    className="nav-item"
                                    onClick={() => {
                                      this.changeAccountBoard("0");
                                    }}
                                  >
                                    <span
                                      className={`nav-link text-center ${
                                        this.state.boardFilter == "0"
                                          ? "active show"
                                          : ""
                                      }`}
                                      data-toggle={`${
                                        this.state.accounts
                                          .find(
                                            (obj) =>
                                              obj.name ===
                                              this.state.accountName
                                          )
                                          .boards.find((obj) => obj.id === "0")
                                          .positions.length > 0
                                          ? "tab"
                                          : ""
                                      }`}
                                    >
                                      <div className="boardsTabs-title">
                                        Все
                                      </div>
                                      <div
                                        className={`boardsTabs-sum ${sumColor(
                                          this.state.accounts.find(
                                            (obj) =>
                                              obj.name ===
                                              this.state.accountName
                                          ).boards["0"].totalFinres
                                        )}`}
                                      >
                                        {currencyFormat(
                                          this.state.accounts.find(
                                            (obj) =>
                                              obj.name ===
                                              this.state.accountName
                                          ).boards["0"].totalFinres
                                        )}
                                      </div>
                                    </span>
                                  </li>
                                  {this.state.enableSections.map(
                                    (section, index) => (
                                      <li
                                        key={index}
                                        className={`nav-item ${
                                          !this.state.accounts
                                            .find(
                                              (obj) =>
                                                obj.name ===
                                                this.state.accountName
                                            )
                                            .boards.find(
                                              (obj) => obj.id === section.id
                                            ) ||
                                          this.state.accounts
                                            .find(
                                              (obj) =>
                                                obj.name ===
                                                this.state.accountName
                                            )
                                            .boards.find(
                                              (obj) => obj.id === section.id
                                            ).positions.length == 0
                                            ? "navItem-empty"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          this.changeAccountBoard(section.id);
                                        }}
                                      >
                                        <span
                                          className={`nav-link text-center  ${
                                            this.state.boardFilter == section.id
                                              ? "active show"
                                              : ""
                                          }`}
                                          data-toggle={`${
                                            this.state.accounts
                                              .find(
                                                (obj) =>
                                                  obj.name ===
                                                  this.state.accountName
                                              )
                                              .boards.find(
                                                (obj) => obj.id === section.id
                                              ) &&
                                            this.state.accounts
                                              .find(
                                                (obj) =>
                                                  obj.name ===
                                                  this.state.accountName
                                              )
                                              .boards.find(
                                                (obj) => obj.id === section.id
                                              ).positions.length > 0
                                              ? "tab"
                                              : ""
                                          }`}
                                        >
                                          <div className="boardsTabs-title">
                                            {section.title.replace(" MOEX", "")}
                                          </div>
                                          <div
                                            className={`boardsTabs-sum ${sumColor(
                                              this.state.accounts
                                                .find(
                                                  (obj) =>
                                                    obj.name ===
                                                    this.state.accountName
                                                )
                                                .boards.find(
                                                  (obj) => obj.id === section.id
                                                ) &&
                                                this.state.accounts
                                                  .find(
                                                    (obj) =>
                                                      obj.name ===
                                                      this.state.accountName
                                                  )
                                                  .boards.find(
                                                    (obj) =>
                                                      obj.id === section.id
                                                  ).totalFinres
                                            )}`}
                                          >
                                            {this.state.accounts
                                              .find(
                                                (obj) =>
                                                  obj.name ===
                                                  this.state.accountName
                                              )
                                              .boards.find(
                                                (obj) => obj.id === section.id
                                              ) &&
                                            this.state.accounts
                                              .find(
                                                (obj) =>
                                                  obj.name ===
                                                  this.state.accountName
                                              )
                                              .boards.find(
                                                (obj) => obj.id === section.id
                                              ).positions.length > 0 ? (
                                              <span>
                                                {currencyFormat(
                                                  this.state.accounts
                                                    .find(
                                                      (obj) =>
                                                        obj.name ===
                                                        this.state.accountName
                                                    )
                                                    .boards.find(
                                                      (obj) =>
                                                        obj.id === section.id
                                                    ).totalFinres
                                                )}
                                              </span>
                                            ) : (
                                              <span></span>
                                            )}
                                          </div>
                                        </span>
                                      </li>
                                    )
                                  )}
                                  <li className="closeDealsBtns">
                                    <button
                                      disabled={
                                        this.state.accounts
                                          .find(
                                            (obj) =>
                                              obj.name ===
                                              this.state.accountName
                                          )
                                          .boards.find((obj) => obj.id === "0")
                                          .totalOrders > 0
                                          ? false
                                          : true
                                      }
                                      className="btn btn-success"
                                      onClick={() => {
                                        this.cancelAllOrders();
                                      }}
                                    >
                                      Снять все заявки
                                    </button>
                                    <button
                                      disabled={
                                        this.state.accounts.find(
                                          (obj) =>
                                            obj.name === this.state.accountName
                                        ).totalQuantity > 0
                                          ? false
                                          : true
                                      }
                                      className="btn btn-success"
                                      onClick={() => {
                                        this.closeAllPositions();
                                      }}
                                    >
                                      Закрыть все позиции
                                    </button>
                                  </li>
                                </ul>

                                <div className="tab-content">
                                  <div
                                    className="tab-pane fade active show"
                                    id="basic-rounded-tab1"
                                  >
                                    <div className="table dashboardTable">
                                      {
                                        <LiveDataAccount
                                          account={this.state.accounts.find(
                                            (obj) =>
                                              obj.name ===
                                              this.state.accountName
                                          )}
                                          openOrders={this.openOrders}
                                          closePosition={this.closePosition}
                                          cancelOrders={this.cancelOrders}
                                          cancelOrder={this.cancelOrder}
                                          boardFilter={this.state.boardFilter}
                                          sort_by={this.state.sort_by}
                                          sortAsc={this.state.sortAsc}
                                          onChangeSort={this.onChangeSort}
                                        />
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <div
                                style={{ width: "1000px", marginLeft: "10px" }}
                              >
                                <div>
                                  <ul className="nav nav-tabs boardsTabs">
                                    <li className="nav-item">
                                      <span
                                        className="nav-link text-center active"
                                        data-toggle=""
                                      >
                                        <div className="boardsTabs-title">
                                          Все
                                        </div>
                                        <div>
                                          <span></span>
                                        </div>
                                      </span>
                                    </li>
                                    {this.state.enableSections.map(
                                      (section, index) => (
                                        <li
                                          className="nav-item navItem-empty"
                                          key={index}
                                        >
                                          <span
                                            className="nav-link text-center"
                                            data-toggle=""
                                          >
                                            <div className="boardsTabs-title">
                                              {section.title.replace(
                                                " MOEX",
                                                ""
                                              )}
                                            </div>
                                            <div>
                                              <span></span>
                                            </div>
                                          </span>
                                        </li>
                                      )
                                    )}
                                    <li className="closeDealsBtns">
                                      <button
                                        disabled={true}
                                        className="btn btn-success"
                                      >
                                        Снять все заявки
                                      </button>
                                      <button
                                        disabled={true}
                                        className="btn btn-success"
                                      >
                                        Закрыть все позиции
                                      </button>
                                    </li>
                                  </ul>

                                  <div className="tab-content">
                                    <div
                                      className="tab-pane fade active show emptyDashboard-plug"
                                      id="basic-rounded-tab1"
                                    >
                                      <div className="table">
                                        <table className="tableLayoutFixes">
                                          <thead>
                                            <tr>
                                              <td className="text-right posPlosh">
                                                ПЛОЩАДКА
                                              </td>
                                              <td className="text-right posUpdate">
                                                ОБНОВЛЕНО
                                              </td>
                                              <td className="text-right posInst">
                                                ИНСТРУМЕНТ
                                              </td>
                                              <td className="posPosit">
                                                ПОЗИЦИИ
                                              </td>
                                              <td className="posOrders">
                                                ЗАЯВКИ
                                              </td>
                                              <td className="text-right posFinres">
                                                ФИНРЕЗ
                                              </td>
                                              <td className="text-right posCommis">
                                                КОМИССИЯ
                                              </td>
                                              <td className="text-right posDrawdown">
                                                ПРОСАДКА
                                              </td>
                                              <td className="posArrow"></td>
                                            </tr>
                                          </thead>
                                        </table>
                                      </div>
                                      <div className="emptyData">
                                        нет данных
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="row">
                          <div
                            style={{
                              width: "1000px",
                              height: "270px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <h3>Нет подключенных секций</h3>
                          </div>
                        </div>
                      )}
                    </div>
                  }
                </div>
              ) : (
                <div className="row">
                  <div
                    style={{
                      width: "1000px",
                      height: "270px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3 className="text-danger">Ошибка загрузки данных</h3>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    tradingAccount: state.tradingAccount,
    tradingAccounts: state.tradingAccounts,
  };
};

export default connect(mapStateToProps)(DashboardPage);

{
  /* <div>
  <button
    disabled={
      this.state.accounts
        .find((obj) => obj.name === this.state.accountName)
        .boards.find((obj) => obj.id === "0").totalOrders > 0
        ? false
        : true
    }
    className="btn btn-success"
    onClick={() => {
      this.cancelAllOrders();
    }}
  >
    Снять все заявки
  </button>
  <button
    disabled={
      this.state.accounts.find((obj) => obj.name === this.state.accountName)
        .totalQuantity > 0
        ? false
        : true
    }
    className="btn btn-success"
    onClick={() => {
      this.closeAllPositions();
    }}
  >
    Закрыть все позиции
  </button>
</div> */
}

// {this.state.enableSections.map(
//   (section, index) => (
//     <li
//       key={index}
//       className={`nav-item ${
//         !this.state.accounts
//           .find(
//             (obj) =>
//               obj.name ===
//               this.state.accountName
//           )
//           .boards.find(
//             (obj) => obj.id === section.id
//           ) ||
//         this.state.accounts
//           .find(
//             (obj) =>
//               obj.name ===
//               this.state.accountName
//           )
//           .boards.find(
//             (obj) => obj.id === section.id
//           ).positions.length == 0
//           ? "navItem-empty"
//           : ""
//       }`}
//       onClick={() => {
//         this.changeAccountBoard(section.id);
//       }}
//     >
//       <span
//         className={`nav-link text-center  ${
//           this.state.boardFilter == section.id
//             ? "active show"
//             : ""
//         }`}
//         data-toggle={`${
//           this.state.accounts
//             .find(
//               (obj) =>
//                 obj.name ===
//                 this.state.accountName
//             )
//             .boards.find(
//               (obj) => obj.id === section.id
//             ) &&
//           this.state.accounts
//             .find(
//               (obj) =>
//                 obj.name ===
//                 this.state.accountName
//             )
//             .boards.find(
//               (obj) => obj.id === section.id
//             ).positions.length > 0
//             ? "tab"
//             : ""
//         }`}
//       >
//         <div className="boardsTabs-title">
//           {section.title.replace(" MOEX", "")}
//         </div>
//         <div
//           className={`boardsTabs-sum ${sumColor(
//             this.state.accounts
//               .find(
//                 (obj) =>
//                   obj.name ===
//                   this.state.accountName
//               )
//               .boards.find(
//                 (obj) => obj.id === section.id
//               ) &&
//               this.state.accounts
//                 .find(
//                   (obj) =>
//                     obj.name ===
//                     this.state.accountName
//                 )
//                 .boards.find(
//                   (obj) =>
//                     obj.id === section.id
//                 ).totalFinres
//           )}`}
//         >
//           {this.state.accounts
//             .find(
//               (obj) =>
//                 obj.name ===
//                 this.state.accountName
//             )
//             .boards.find(
//               (obj) => obj.id === section.id
//             ) &&
//           this.state.accounts
//             .find(
//               (obj) =>
//                 obj.name ===
//                 this.state.accountName
//             )
//             .boards.find(
//               (obj) => obj.id === section.id
//             ).positions.length > 0 ? (
//             <span>
//               {currencyFormat(
//                 this.state.accounts
//                   .find(
//                     (obj) =>
//                       obj.name ===
//                       this.state.accountName
//                   )
//                   .boards.find(
//                     (obj) =>
//                       obj.id === section.id
//                   ).totalFinres
//               )}
//             </span>
//           ) : (
//             <span></span>
//           )}
//         </div>
//       </span>
//     </li>
//   )
// )}
