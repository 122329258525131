export const currencyFormat = (sum, currency, precision = 2, isMobile = false, shorten = false) => {
  let curr = '';
  const seperator = shorten ? ',' : '.';
  switch (currency) {
    case 'RUR':
      curr = '₽';
      break;
    case 'USD':
      curr = '$';
      break;
    default:
      curr = currency;
  }
  var value = parseFloat(sum);

  value = parseFloat(sum).toFixed(precision);
  var parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  if (shorten && parts[1]) {
    for (let i = parts[1].length - 1; i >= 0; i--) {
      if (parts[1][i] === "0") {
        parts[1] = parts[1].slice(0, -1);
      } else {
        break;
      }
    }
  }

  let result = curr;
  if (currency === 'USD' || isMobile) result = curr + ' ' + parts.join('.');
  else result = parts.join(seperator) + ' ' + curr;

  if (!currency) result = parts.join(seperator);
  
  return result[result.length - 1] === seperator 
    ? result.slice(0, -1) 
    : isNaN(result[result.length - 1]) && result[result.length - 3] === seperator
    ? result.slice(0, -3) + result.slice(-2) 
    : result;
};
export const getCurrency = (currency) => {
  switch (currency) {
    case 'RUR':
      return '₽';
    case 'USD':
      return '$';
    default:
      return currency;
  }
};
export const sumColor = (sum) => {
  return parseFloat(sum).toFixed(2) >= 0 ? 'textSum-plus' : 'textSum-minus';
};
export const sumBgColor = (sum) => {
  return parseFloat(sum).toFixed(2) >= 0 ? 'bgSum-plus' : 'bgSum-minus';
};
export const sumTotalBgColor = (sum) => {
  return parseFloat(sum).toFixed(2) >= 0
    ? 'bgSumTotal-plus'
    : 'bgSumTotal-minus';
};
