import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { setSettings } from '../actions/settings';
import { getProjectSettings } from '../utils/http';

class NotAuthRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    getProjectSettings().then(res => this.props.setSettings(res.data.settings));
  }
  render() {
    const { path, exact, component: Component } = this.props;

    return (
      <Route
        path={path}
        exact={exact}
        component={(props) => <Component {...props} />}
      />
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  setSettings: (settings) => dispatch(setSettings(settings))
});

export default connect(mapStateToProps, mapDispatchToProps)(NotAuthRoute);
