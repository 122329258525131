import React from 'react';
import { Link } from 'react-router-dom';

export default class DocWaitLetter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {}
  render() {
    return (
      <div>
        <div className="docPage-uploadDisc">
          Дождитесь, пока мы получим и проверим Ваше письмо.
        </div>
        {this.props.trackingNum && (
          <a
            href={`https://www.pochta.ru/tracking#${this.props.trackingNum}`}
            target="_blank"
          >
            Посмотреть статус письма
          </a>
        )}

        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <Link to="/app/documents/moving" className="btn btn-white">
            Отменить
          </Link>
        </div>
      </div>
    );
  }
}
