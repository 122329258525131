import React from 'react';
import axios from 'axios';

export default class AnketaAddresses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      validate: false,
      isSamePostal:
        props.data.isSamePostal != null ? props.data.isSamePostal : true,
      isSameLive: props.data.isSameLive != null ? props.data.isSameLive : true,

      regionRegIdValue: '',
      cityRegIdValue: '',
      streetRegIdValue: '',

      dadataFocusPostalCodeReg: false,
      dadataFocusRegionReg: false,
      dadataFocusAreaReg: false,
      dadataFocusCityReg: false,
      dadataFocusStreetReg: false,
      dadataFocusHouseReg: false,

      d__address__reg__postal_code:
        props.data.d__address__reg__postal_code || '',
      d__address__reg__region: props.data.d__address__reg__region || '',
      d__address__reg__area: props.data.d__address__reg__area || '',
      d__address__reg__city: props.data.d__address__reg__city || '',
      d__address__reg__street: props.data.d__address__reg__street || '',
      d__address__reg__house: props.data.d__address__reg__house || '',
      d__address__reg__flat: props.data.d__address__reg__flat || '',

      regionPostalIdValue: '',
      cityPostalIdValue: '',
      streetPostalIdValue: '',

      dadataFocusPostalCodePostal: false,
      dadataFocusRegionPostal: false,
      dadataFocusAreaPostal: false,
      dadataFocusCityPostal: false,
      dadataFocusStreetPostal: false,
      dadataFocusHousePostal: false,

      d__address__postal__postal_code:
        props.data.d__address__postal__postal_code || '',
      d__address__postal__region: props.data.d__address__postal__region || '',
      d__address__postal__area: props.data.d__address__postal__area || '',
      d__address__postal__city: props.data.d__address__postal__city || '',
      d__address__postal__street: props.data.d__address__postal__street || '',
      d__address__postal__house: props.data.d__address__postal__house || '',
      d__address__postal__flat: props.data.d__address__postal__flat || '',

      regionLiveIdValue: '',
      cityLiveIdValue: '',
      streetLiveIdValue: '',

      dadataFocusPostalCodeLive: false,
      dadataFocusRegionLive: false,
      dadataFocusAreaLive: false,
      dadataFocusCityLive: false,
      dadataFocusStreetLive: false,
      dadataFocusHouseLive: false,

      d__address__live__postal_code:
        props.data.d__address__live__postal_code || '',
      d__address__live__region: props.data.d__address__live__region || '',
      d__address__live__area: props.data.d__address__live__area || '',
      d__address__live__city: props.data.d__address__live__city || '',
      d__address__live__street: props.data.d__address__live__street || '',
      d__address__live__house: props.data.d__address__live__house || '',
      d__address__live__flat: props.data.d__address__live__flat || '',
    };
  }

  action = (action, currIndex, dest) => {
    this.setState({ validate: true });
    if (this.validating() || dest === 'prev') {
      this.props.action(action, currIndex, 'address', {
        isSamePostal: this.state.isSamePostal,
        isSameLive: this.state.isSameLive,
        d__address__reg__postal_code: this.state.d__address__reg__postal_code,
        d__address__reg__region: this.state.d__address__reg__region,
        d__address__reg__area: this.state.d__address__reg__area,
        d__address__reg__city: this.state.d__address__reg__city,
        d__address__reg__street: this.state.d__address__reg__street,
        d__address__reg__house: this.state.d__address__reg__house,
        d__address__reg__flat: this.state.d__address__reg__flat,
        d__address__postal__postal_code: this.state.isSamePostal
          ? this.state.d__address__reg__postal_code
          : this.state.d__address__postal__postal_code,
        d__address__postal__region: this.state.isSamePostal
          ? this.state.d__address__reg__region
          : this.state.d__address__postal__region,
        d__address__postal__area: this.state.isSamePostal
          ? this.state.d__address__reg__area
          : this.state.d__address__postal__area,
        d__address__postal__city: this.state.isSamePostal
          ? this.state.d__address__reg__city
          : this.state.d__address__postal__city,
        d__address__postal__street: this.state.isSamePostal
          ? this.state.d__address__reg__street
          : this.state.d__address__postal__street,
        d__address__postal__house: this.state.isSamePostal
          ? this.state.d__address__reg__house
          : this.state.d__address__postal__house,
        d__address__postal__flat: this.state.isSamePostal
          ? this.state.d__address__reg__flat
          : this.state.d__address__postal__flat,
        d__address__live__postal_code: this.state.isSameLive
          ? this.state.d__address__reg__postal_code
          : this.state.d__address__live__postal_code,
        d__address__live__region: this.state.isSameLive
          ? this.state.d__address__reg__region
          : this.state.d__address__live__region,
        d__address__live__area: this.state.isSameLive
          ? this.state.d__address__reg__area
          : this.state.d__address__live__area,
        d__address__live__city: this.state.isSameLive
          ? this.state.d__address__reg__city
          : this.state.d__address__live__city,
        d__address__live__street: this.state.isSameLive
          ? this.state.d__address__reg__street
          : this.state.d__address__live__street,
        d__address__live__house: this.state.isSameLive
          ? this.state.d__address__reg__house
          : this.state.d__address__live__house,
        d__address__live__flat: this.state.isSameLive
          ? this.state.d__address__reg__flat
          : this.state.d__address__live__flat,
      });
    }
  };
  validating = () => {
    return (
      this.state.d__address__reg__postal_code.length == 6 &&
      this.state.d__address__reg__region != '' &&
      this.state.d__address__reg__city != '' &&
      this.state.d__address__reg__street != '' &&
      this.state.d__address__reg__house != '' &&
      (this.state.isSamePostal ||
        (this.state.d__address__postal__postal_code.length == 6 &&
          this.state.d__address__postal__region != '' &&
          this.state.d__address__postal__city != '' &&
          this.state.d__address__postal__street != '' &&
          this.state.d__address__postal__house != '')) &&
      (this.state.isSameLive ||
        (this.state.d__address__live__postal_code.length == 6 &&
          this.state.d__address__live__region != '' &&
          this.state.d__address__live__city != '' &&
          this.state.d__address__live__street != '' &&
          this.state.d__address__live__house != ''))
    );
  };

  dotify = (address) => {
    if (address) {
      var shortTypes = [
        'аобл',
        'респ',
        'вл',
        'г',
        'д',
        'двлд',
        'днп',
        'дор',
        'дп',
        'жт',
        'им',
        'к',
        'кв',
        'км',
        'комн',
        'кп',
        'лпх',
        'м',
        'мкр',
        'наб',
        'нп',
        'обл',
        'оф',
        'п',
        'пгт',
        'пер',
        'пл',
        'платф',
        'рзд',
        'рп',
        'с',
        'сл',
        'снт',
        'ст',
        'стр',
        'тер',
        'туп',
        'ул',
        'х',
        'ш',
      ];
      var words = address.split(' ');
      var dottedWords = words.map(function (word) {
        if (shortTypes.indexOf(word) !== -1) {
          return word + '.';
        } else {
          return word;
        }
      });
      return dottedWords.join(' ');
    } else return address;
  };

  // События получения данных адреса
  onPostalSameReg = () => {
    if (!this.state.isSamePostal) {
      this.setState({
        d__address__postal__postal_code: this.state
          .d__address__reg__postal_code,
        d__address__postal__region: this.state.d__address__reg__region,
        d__address__postal__area: this.state.d__address__reg__area,
        d__address__postal__city: this.state.d__address__reg__city,
        d__address__postal__street: this.state.d__address__reg__street,
        d__address__postal__house: this.state.d__address__reg__house,
        d__address__postal__flat: this.state.d__address__reg__flat,
      });
    }
    this.setState({
      isSamePostal: !this.state.isSamePostal,
      d__address__postal__postal_code: '',
      d__address__postal__region: '',
      d__address__postal__area: '',
      d__address__postal__city: '',
      d__address__postal__street: '',
      d__address__postal__house: '',
      d__address__postal__flat: '',
    });
  };
  onLiveSameReg = () => {
    if (!this.state.isSameLive) {
      this.setState({
        d__address__live__postal_code: this.state.d__address__reg__postal_code,
        d__address__live__region: this.state.d__address__reg__region,
        d__address__live__area: this.state.d__address__reg__area,
        d__address__live__city: this.state.d__address__reg__city,
        d__address__live__street: this.state.d__address__reg__street,
        d__address__live__house: this.state.d__address__reg__house,
        d__address__live__flat: this.state.d__address__reg__flat,
      });
    }
    this.setState({
      isSameLive: !this.state.isSameLive,
      d__address__live__postal_code: '',
      d__address__live__region: '',
      d__address__live__area: '',
      d__address__live__city: '',
      d__address__live__street: '',
      d__address__live__house: '',
      d__address__live__flat: '',
    });
  };

  // События редактирования деталей адреса
  onRegPostalChange = (e) => {
    const d__address__reg__postal_code = e.target.value;
    if (
      !d__address__reg__postal_code ||
      (d__address__reg__postal_code.match(/^[0-9]+$/) &&
        d__address__reg__postal_code.length <= 6)
    ) {
      this.setState(() => ({ d__address__reg__postal_code }));
    }
  };
  onRegRegionChange = (e) => {
    const d__address__reg__region = e.target.value;
    this.setState(() => ({ d__address__reg__region }));
  };
  onRegAreaChange = (e) => {
    const d__address__reg__area = e.target.value;
    this.setState(() => ({ d__address__reg__area }));
  };
  onRegCityChange = (e) => {
    const d__address__reg__city = e.target.value;
    this.setState(() => ({ d__address__reg__city }));
  };
  onRegStreetChange = (e) => {
    const d__address__reg__street = e.target.value;
    this.setState(() => ({ d__address__reg__street }));
  };
  onRegHouseChange = (e) => {
    const d__address__reg__house = e.target.value;
    this.setState(() => ({ d__address__reg__house }));
  };
  onRegFlatChange = (e) => {
    const d__address__reg__flat = e.target.value;
    this.setState(() => ({ d__address__reg__flat }));
  };

  onPostalPostalChange = (e) => {
    const d__address__postal__postal_code = e.target.value;
    if (
      !d__address__postal__postal_code ||
      (d__address__postal__postal_code.match(/^[0-9]+$/) &&
        d__address__postal__postal_code.length <= 6)
    ) {
      this.setState(() => ({ d__address__postal__postal_code }));
    }
  };
  onPostalRegionChange = (e) => {
    const d__address__postal__region = e.target.value;
    this.setState(() => ({ d__address__postal__region }));
  };
  onPostalAreaChange = (e) => {
    const d__address__postal__area = e.target.value;
    this.setState(() => ({ d__address__postal__area }));
  };
  onPostalCityChange = (e) => {
    const d__address__postal__city = e.target.value;
    this.setState(() => ({ d__address__postal__city }));
  };
  onPostalStreetChange = (e) => {
    const d__address__postal__street = e.target.value;
    this.setState(() => ({ d__address__postal__street }));
  };
  onPostalHouseChange = (e) => {
    const d__address__postal__house = e.target.value;
    this.setState(() => ({ d__address__postal__house }));
  };
  onPostalFlatChange = (e) => {
    const d__address__postal__flat = e.target.value;
    this.setState(() => ({ d__address__postal__flat }));
  };

  onLivePostalChange = (e) => {
    const d__address__live__postal_code = e.target.value;
    if (
      !d__address__live__postal_code ||
      (d__address__live__postal_code.match(/^[0-9]+$/) &&
        d__address__live__postal_code.length <= 6)
    ) {
      this.setState(() => ({ d__address__live__postal_code }));
    }
  };
  onLiveRegionChange = (e) => {
    const d__address__live__region = e.target.value;
    this.setState(() => ({ d__address__live__region }));
  };
  onLiveAreaChange = (e) => {
    const d__address__live__area = e.target.value;
    this.setState(() => ({ d__address__live__area }));
  };
  onLiveCityChange = (e) => {
    const d__address__live__city = e.target.value;
    this.setState(() => ({ d__address__live__city }));
  };
  onLiveStreetChange = (e) => {
    const d__address__live__street = e.target.value;
    this.setState(() => ({ d__address__live__street }));
  };
  onLiveHouseChange = (e) => {
    const d__address__live__house = e.target.value;
    this.setState(() => ({ d__address__live__house }));
  };
  onLiveFlatChange = (e) => {
    const d__address__live__flat = e.target.value;
    this.setState(() => ({ d__address__live__flat }));
  };

  addressChange = (
    e,
    stateName,
    bound,
    locationTitle,
    location,
    clearedFields
  ) => {
    const address = e.target.value;
    // console.log(address);
    this.setState({ [stateName]: address });
    if (clearedFields && clearedFields.length > 0)
      this.clearAddressFields(clearedFields);
    // Таймер для ввода на ~300мс
    axios({
      method: 'post',
      url: `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
      data: {
        query: address,
        from_bound: { value: bound },
        to_bound: { value: bound },
        locations: [{ [locationTitle]: location }],
        restrict_value: true,
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token 7e579cbc4450c2a92c1748e310c877ad0dfcc44c',
      },
    })
      .then((res) => {
        // console.log(res);
        this.setState({ suggestions: res.data.suggestions });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onFocusDadata = (name) => {
    setTimeout(() => {
      this.setState(() => ({ [name]: true }));
    }, 300);
  };
  onBlurDadata = (name) => {
    setTimeout(() => {
      this.setState(() => ({ [name]: false }));
    }, 300);
  };

  clearAddressFields = (fields) => {
    for (let i = 0; i < fields.length; i++) {
      this.setState({ [fields[i]]: '' });
    }
  };

  render() {
    return (
      <div>
        <div className="row">
          <h2 className="col-12 subtitle">Адреса</h2>
        </div>
        {
          <div className="row mt-3">
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__postal_code == ''
                      ? 'hidden'
                      : ''
                  }`}
                >
                  Индекс
                </label>
                <div className="dadataContainer">
                  <input
                    type="text"
                    className="inputUnderLine"
                    placeholder="Индекс"
                    value={this.state.d__address__reg__postal_code}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__reg__postal_code',
                        'postal_code',
                        '',
                        ''
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusPostalCodeReg');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusPostalCodeReg');
                    }}
                  />
                  {this.state.dadataFocusPostalCodeReg &&
                    this.state.suggestions.length > 0 && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__reg__street: this.dotify(
                                  suggestion.data.street_with_type
                                ),
                                streetRegIdValue:
                                  suggestion.data.street_fias_id,
                                suggestions: [],
                                d__address__reg__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__reg__postal_code ||
                                  '',
                                d__address__reg__region: this.dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionRegIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__reg__city: this.dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityRegIdValue: suggestion.data.city_fias_id,
                              });
                            }}
                          >
                            {this.dotify(suggestion.unrestricted_value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__region == '' ? 'hidden' : ''
                  }`}
                >
                  Регион
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__reg__region == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate &&
                      this.state.d__address__reg__region != ''
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    name="region"
                    type="text"
                    placeholder="Регион"
                    value={this.state.d__address__reg__region}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__reg__region',
                        'region',
                        '',
                        '',
                        [
                          'regionRegIdValue',
                          'd__address__reg__area',
                          'd__address__reg__city',
                          'cityRegIdValue',
                          'd__address__reg__street',
                          'streetRegIdValue',
                          'd__address__reg__house',
                        ]
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusRegionReg');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusRegionReg');
                    }}
                  />
                  {this.state.dadataFocusRegionReg &&
                    this.state.suggestions.length > 0 && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__reg__region: this.dotify(
                                  suggestion.value
                                ),
                                regionRegIdValue:
                                  suggestion.data.region_fias_id,
                                suggestions: [],
                                d__address__reg__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__reg__postal_code ||
                                  '',
                              });
                              this.clearAddressFields([
                                'd__address__reg__area',
                                'd__address__reg__city',
                                'cityRegIdValue',
                                'd__address__reg__street',
                                'streetRegIdValue',
                                'd__address__reg__house',
                              ]);
                            }}
                          >
                            {this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__area == '' ? 'hidden' : ''
                  }`}
                >
                  Район
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine `}
                    name="area"
                    type="text"
                    placeholder="Район"
                    value={this.state.d__address__reg__area}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__reg__area',
                        'area',
                        'region_fias_id',
                        this.state.regionRegIdValue
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusAreaReg');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusAreaReg');
                    }}
                  />
                  {this.state.dadataFocusAreaReg &&
                    this.state.suggestions.length > 0 && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__reg__area: this.dotify(
                                  suggestion.value
                                ),
                                suggestions: [],
                              });
                            }}
                          >
                            {this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__city == '' ? 'hidden' : ''
                  }`}
                >
                  Город
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__reg__city == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate &&
                      this.state.d__address__reg__city != ''
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    name="city"
                    type="text"
                    placeholder="Город"
                    value={this.state.d__address__reg__city}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__reg__city',
                        'city',
                        'region_fias_id',
                        this.state.regionRegIdValue,
                        [
                          'cityRegIdValue',
                          'd__address__reg__street',
                          'streetRegIdValue',
                          'd__address__reg__house',
                        ]
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusCityReg');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusCityReg');
                    }}
                  />
                  {this.state.dadataFocusCityReg &&
                    this.state.suggestions.length > 0 && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__reg__city: this.dotify(
                                  suggestion.value
                                ),
                                cityRegIdValue: suggestion.data.city_fias_id,
                                suggestions: [],
                                d__address__reg__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__reg__postal_code ||
                                  '',
                                d__address__reg__region: this.dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionRegIdValue:
                                  suggestion.data.region_fias_id,
                              });
                              this.clearAddressFields([
                                'd__address__reg__street',
                                'streetRegIdValue',
                                'd__address__reg__house',
                              ]);
                            }}
                          >
                            {this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__street == '' ? 'hidden' : ''
                  }`}
                >
                  Улица
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__reg__street == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate &&
                      this.state.d__address__reg__street != ''
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    name="street"
                    type="text"
                    placeholder="Улица"
                    value={this.state.d__address__reg__street}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__reg__street',
                        'street',
                        'city_fias_id',
                        this.state.cityRegIdValue,
                        ['streetRegIdValue', 'd__address__reg__house']
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusStreetReg');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusStreetReg');
                    }}
                  />
                  {this.state.dadataFocusStreetReg &&
                    this.state.suggestions.length > 0 &&
                    !(
                      this.state.d__address__reg__city &&
                      !this.state.cityRegIdValue
                    ) && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__reg__street: this.dotify(
                                  suggestion.value
                                ),
                                streetRegIdValue:
                                  suggestion.data.street_fias_id,
                                suggestions: [],
                                d__address__reg__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__reg__postal_code ||
                                  '',
                                d__address__reg__region: this.dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionRegIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__reg__city: this.dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityRegIdValue: suggestion.data.city_fias_id,
                              });
                              this.clearAddressFields([
                                'd__address__reg__house',
                              ]);
                            }}
                          >
                            {!this.state.cityRegIdValue &&
                            !this.state.d__address__reg__city
                              ? this.dotify(suggestion.unrestricted_value)
                              : this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__house == '' ? 'hidden' : ''
                  }`}
                >
                  Дом
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__reg__house == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate &&
                      this.state.d__address__reg__house != ''
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    name="house"
                    type="text"
                    placeholder="Дом"
                    value={this.state.d__address__reg__house}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__reg__house',
                        'house',
                        'street_fias_id',
                        this.state.streetRegIdValue
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusHouseReg');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusHouseReg');
                    }}
                  />
                  {this.state.dadataFocusHouseReg &&
                    this.state.suggestions.length > 0 &&
                    !(
                      this.state.d__address__reg__house &&
                      !this.state.cityRegIdValue
                    ) &&
                    this.state.d__address__reg__street && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__reg__house: this.dotify(
                                  suggestion.value
                                ),
                                suggestions: [],
                                d__address__reg__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__reg__postal_code ||
                                  '',
                                d__address__reg__region: this.dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionRegIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__reg__city: this.dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityRegIdValue: suggestion.data.city_fias_id,
                                d__address__reg__street: this.dotify(
                                  suggestion.data.street_with_type
                                ),
                                streetRegIdValue:
                                  suggestion.data.street_fias_id,
                              });
                            }}
                          >
                            {this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__reg__flat == '' ? 'hidden' : ''
                  }`}
                >
                  Квартира
                </label>
                <input
                  type="text"
                  className="inputUnderLine"
                  placeholder="Квартира"
                  value={this.state.d__address__reg__flat}
                  onChange={this.onRegFlatChange}
                />
              </div>
            </div>
          </div>
        }

        <div className="row">
          <div className="col-md-12">
            <div className="">
              <div className="form-check form-check-inline">
                <label className="checkboxContainer">
                  Почтовый адрес совпадает с местом регистрации
                  <input
                    type="checkbox"
                    name="samePostal"
                    value={this.state.isSamePostal}
                    checked={this.state.isSamePostal}
                    onChange={this.onPostalSameReg}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          {this.state.isSamePostal && (
            <div className="col-lg-12">
              <div className="formField-container position-relative">
                <input
                  type="text"
                  className="inputUnderLine"
                  placeholder="Почтовый адрес"
                  value={`${this.state.d__address__reg__postal_code} ${this.state.d__address__reg__region} ${this.state.d__address__reg__area} ${this.state.d__address__reg__city} ${this.state.d__address__reg__street} ${this.state.d__address__reg__house} ${this.state.d__address__reg__flat}`}
                  readOnly={true}
                />
              </div>
            </div>
          )}
        </div>

        {!this.state.isSamePostal && (
          <div className="row mt-3">
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__postal__postal_code == ''
                      ? 'hidden'
                      : ''
                  }`}
                >
                  Индекс
                </label>
                <div className="dadataContainer">
                  <input
                    type="text"
                    className="inputUnderLine"
                    placeholder="Индекс"
                    value={this.state.d__address__postal__postal_code}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__postal__postal_code',
                        'postal_code',
                        '',
                        ''
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusPostalCodePostal');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusPostalCodePostal');
                    }}
                  />
                  {this.state.dadataFocusPostalCodePostal &&
                    this.state.suggestions.length > 0 && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__postal__street: this.dotify(
                                  suggestion.data.street_with_type
                                ),
                                streetPostalIdValue:
                                  suggestion.data.street_fias_id,
                                suggestions: [],
                                d__address__postal__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__postal__postal_code ||
                                  '',
                                d__address__postal__region: this.dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionPostalIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__postal__city: this.dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityPostalIdValue: suggestion.data.city_fias_id,
                              });
                            }}
                          >
                            {this.dotify(suggestion.unrestricted_value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__postal__region == '' ? 'hidden' : ''
                  }`}
                >
                  Регион
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__postal__region == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate &&
                      this.state.d__address__postal__region != ''
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    name="region"
                    type="text"
                    placeholder="Регион"
                    value={this.state.d__address__postal__region}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__postal__region',
                        'region',
                        '',
                        '',
                        [
                          'regionPostalIdValue',
                          'd__address__postal__area',
                          'd__address__postal__city',
                          'cityPostalIdValue',
                          'd__address__postal__street',
                          'streetPostalIdValue',
                          'd__address__postal__house',
                        ]
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusRegionPostal');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusRegionPostal');
                    }}
                  />
                  {this.state.dadataFocusRegionPostal &&
                    this.state.suggestions.length > 0 && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__postal__region: this.dotify(
                                  suggestion.value
                                ),
                                regionPostalIdValue:
                                  suggestion.data.region_fias_id,
                                suggestions: [],
                                d__address__postal__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__postal__postal_code ||
                                  '',
                              });
                              this.clearAddressFields([
                                'd__address__postal__city',
                                'cityPostalIdValue',
                                'd__address__postal__street',
                                'streetPostalIdValue',
                                'd__address__postal__house',
                              ]);
                            }}
                          >
                            {this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__postal__area == '' ? 'hidden' : ''
                  }`}
                >
                  Район
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine`}
                    name="area"
                    type="text"
                    placeholder="Район"
                    value={this.state.d__address__postal__area}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__postal__area',
                        'area',
                        'region_fias_id',
                        this.state.regionPostalIdValue
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusAreaPostal');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusAreaPostal');
                    }}
                  />
                  {this.state.dadataFocusAreaPostal &&
                    this.state.suggestions.length > 0 && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__postal__area: this.dotify(
                                  suggestion.value
                                ),
                                suggestions: [],
                              });
                            }}
                          >
                            {this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__postal__city == '' ? 'hidden' : ''
                  }`}
                >
                  Город
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__postal__city == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate &&
                      this.state.d__address__postal__city != ''
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    name="city"
                    type="text"
                    placeholder="Город"
                    value={this.state.d__address__postal__city}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__postal__city',
                        'city',
                        'region_fias_id',
                        this.state.regionPostalIdValue,
                        [
                          'cityPostalIdValue',
                          'd__address__postal__street',
                          'streetPostalIdValue',
                          'd__address__postal__house',
                        ]
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusCityPostal');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusCityPostal');
                    }}
                  />
                  {this.state.dadataFocusCityPostal &&
                    this.state.suggestions.length > 0 && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__postal__city: this.dotify(
                                  suggestion.value
                                ),
                                cityPostalIdValue: suggestion.data.city_fias_id,
                                suggestions: [],
                                d__address__postal__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__postal__postal_code ||
                                  '',
                                d__address__postal__region: this.dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionPostalIdValue:
                                  suggestion.data.region_fias_id,
                              });
                              this.clearAddressFields([
                                'd__address__postal__street',
                                'streetPostalIdValue',
                                'd__address__postal__house',
                              ]);
                            }}
                          >
                            {this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__postal__street == '' ? 'hidden' : ''
                  }`}
                >
                  Улица
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__postal__street == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate &&
                      this.state.d__address__postal__street != ''
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    name="city"
                    type="text"
                    placeholder="Улица"
                    value={this.state.d__address__postal__street}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__postal__street',
                        'street',
                        'city_fias_id',
                        this.state.cityPostalIdValue,
                        ['streetPostalIdValue', 'd__address__postal__house']
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusStreetPostal');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusStreetPostal');
                    }}
                  />
                  {this.state.dadataFocusStreetPostal &&
                    this.state.suggestions.length > 0 &&
                    !(
                      this.state.d__address__postal__city &&
                      !this.state.cityPostalIdValue
                    ) && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__postal__street: this.dotify(
                                  suggestion.value
                                ),
                                streetPostalIdValue:
                                  suggestion.data.street_fias_id,
                                suggestions: [],
                                d__address__postal__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__postal__postal_code ||
                                  '',
                                d__address__postal__region: this.dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionPostalIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__postal__city: this.dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityPostalIdValue: suggestion.data.city_fias_id,
                              });
                              this.clearAddressFields([
                                'd__address__postal__house',
                              ]);
                            }}
                          >
                            {!this.state.cityPostalIdValue &&
                            !this.state.d__address__postal__city
                              ? this.dotify(suggestion.unrestricted_value)
                              : this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__postal__house == '' ? 'hidden' : ''
                  }`}
                >
                  Дом
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__postal__house == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate &&
                      this.state.d__address__postal__house != ''
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    name="house"
                    type="text"
                    placeholder="Дом"
                    value={this.state.d__address__postal__house}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__postal__house',
                        'house',
                        'street_fias_id',
                        this.state.streetPostalIdValue
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusHousePostal');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusHousePostal');
                    }}
                  />
                  {this.state.dadataFocusHousePostal &&
                    this.state.suggestions.length > 0 &&
                    !(
                      this.state.d__address__postal__house &&
                      !this.state.cityPostalIdValue
                    ) &&
                    this.state.d__address__postal__street && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__postal__house: this.dotify(
                                  suggestion.value
                                ),
                                suggestions: [],
                                d__address__postal__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__postal__postal_code ||
                                  '',
                                d__address__postal__region: this.dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionPostalIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__postal__city: this.dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityPostalIdValue: suggestion.data.city_fias_id,
                                d__address__postal__street: this.dotify(
                                  suggestion.data.street_with_type
                                ),
                                streetPostalIdValue:
                                  suggestion.data.street_fias_id,
                              });
                            }}
                          >
                            {this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__postal__flat == '' ? 'hidden' : ''
                  }`}
                >
                  Квартира
                </label>
                <input
                  type="text"
                  className="inputUnderLine"
                  placeholder="Квартира"
                  value={this.state.d__address__postal__flat}
                  onChange={this.onPostalFlatChange}
                />
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <div className="mb-1">
              <div className="form-check form-check-inline">
                <label className="checkboxContainer">
                  Место фактического жительства совпадает с местом регистрации
                  <input
                    type="checkbox"
                    name="sameLive"
                    value={this.state.isSameLive}
                    checked={this.state.isSameLive}
                    onChange={this.onLiveSameReg}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          {this.state.isSameLive && (
            <div className="col-lg-12">
              <div className="formField-container position-relative">
                <input
                  type="text"
                  className="inputUnderLine"
                  placeholder="Почтовый адрес"
                  value={`${this.state.d__address__reg__postal_code} ${this.state.d__address__reg__region} ${this.state.d__address__reg__area} ${this.state.d__address__reg__city} ${this.state.d__address__reg__street} ${this.state.d__address__reg__house} ${this.state.d__address__reg__flat}`}
                  readOnly={true}
                />
              </div>
            </div>
          )}
        </div>

        {!this.state.isSameLive && (
          <div className="row mt-3">
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__live__postal_code == ''
                      ? 'hidden'
                      : ''
                  }`}
                >
                  Индекс
                </label>
                <div className="dadataContainer">
                  <input
                    type="text"
                    className="inputUnderLine"
                    placeholder="Индекс"
                    value={this.state.d__address__live__postal_code}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__live__postal_code',
                        'postal_code',
                        '',
                        ''
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusPostalCodeLive');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusPostalCodeLive');
                    }}
                  />
                  {this.state.dadataFocusPostalCodeLive &&
                    this.state.suggestions.length > 0 && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__live__street: this.dotify(
                                  suggestion.data.street_with_type
                                ),
                                streetLiveIdValue:
                                  suggestion.data.street_fias_id,
                                suggestions: [],
                                d__address__live__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__postal__postal_code ||
                                  '',
                                d__address__live__region: this.dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionLiveIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__live__city: this.dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityLiveIdValue: suggestion.data.city_fias_id,
                              });
                            }}
                          >
                            {this.dotify(suggestion.unrestricted_value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__live__region == '' ? 'hidden' : ''
                  }`}
                >
                  Регион
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__live__region == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate &&
                      this.state.d__address__live__region != ''
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    name="region"
                    type="text"
                    placeholder="Регион"
                    value={this.state.d__address__live__region}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__live__region',
                        'region',
                        '',
                        '',
                        [
                          'regionLiveIdValue',
                          'd__address__live__area',
                          'd__address__live__city',
                          'cityLiveIdValue',
                          'd__address__live__street',
                          'streetLiveIdValue',
                          'd__address__live__house',
                        ]
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusRegionLive');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusRegionLive');
                    }}
                  />
                  {this.state.dadataFocusRegionLive &&
                    this.state.suggestions.length > 0 && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__live__region: this.dotify(
                                  suggestion.value
                                ),
                                regionLiveIdValue:
                                  suggestion.data.region_fias_id,
                                suggestions: [],
                                d__address__live__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__live__postal_code ||
                                  '',
                              });
                              this.clearAddressFields([
                                'd__address__live__city',
                                'cityLiveIdValue',
                                'd__address__live__street',
                                'streetLiveIdValue',
                                'd__address__live__house',
                              ]);
                            }}
                          >
                            {this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__live__area == '' ? 'hidden' : ''
                  }`}
                >
                  Район
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine`}
                    name="area"
                    type="text"
                    placeholder="Район"
                    value={this.state.d__address__live__area}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__live__area',
                        'area',
                        'region_fias_id',
                        this.state.regionLiveIdValue
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusAreaLive');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusAreaLive');
                    }}
                  />
                  {this.state.dadataFocusAreaLive &&
                    this.state.suggestions.length > 0 && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__live__area: this.dotify(
                                  suggestion.value
                                ),
                                suggestions: [],
                              });
                            }}
                          >
                            {this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__live__city == '' ? 'hidden' : ''
                  }`}
                >
                  Город
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__live__city == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate &&
                      this.state.d__address__live__city != ''
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    name="city"
                    type="text"
                    placeholder="Город"
                    value={this.state.d__address__live__city}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__live__city',
                        'city',
                        'region_fias_id',
                        this.state.regionLiveIdValue,
                        [
                          'cityLiveIdValue',
                          'd__address__live__street',
                          'streetLiveIdValue',
                          'd__address__live__house',
                        ]
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusCityLive');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusCityLive');
                    }}
                  />
                  {this.state.dadataFocusCityLive &&
                    this.state.suggestions.length > 0 && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__live__city: this.dotify(
                                  suggestion.value
                                ),
                                cityLiveIdValue: suggestion.data.city_fias_id,
                                suggestions: [],
                                d__address__live__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__live__postal_code ||
                                  '',
                                d__address__live__region: this.dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionLiveIdValue:
                                  suggestion.data.region_fias_id,
                              });
                              this.clearAddressFields([
                                'd__address__live__street',
                                'streetLiveIdValue',
                                'd__address__live__house',
                              ]);
                            }}
                          >
                            {this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__live__street == '' ? 'hidden' : ''
                  }`}
                >
                  Улица
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__live__street == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate &&
                      this.state.d__address__live__street != ''
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    name="city"
                    type="text"
                    placeholder="Улица"
                    value={this.state.d__address__live__street}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__live__street',
                        'street',
                        'city_fias_id',
                        this.state.cityLiveIdValue,
                        ['streetLiveIdValue', 'd__address__live__house']
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusStreetLive');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusStreetLive');
                    }}
                  />
                  {this.state.dadataFocusStreetLive &&
                    this.state.suggestions.length > 0 &&
                    !(
                      this.state.d__address__live__city &&
                      !this.state.cityLiveIdValue
                    ) && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__live__street: this.dotify(
                                  suggestion.value
                                ),
                                streetLiveIdValue:
                                  suggestion.data.street_fias_id,
                                suggestions: [],
                                d__address__live__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__live__postal_code ||
                                  '',
                                d__address__live__region: this.dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionLiveIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__live__city: this.dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityLiveIdValue: suggestion.data.city_fias_id,
                              });
                              this.clearAddressFields([
                                'd__address__live__house',
                              ]);
                            }}
                          >
                            {!this.state.cityLiveIdValue &&
                            !this.state.d__address__live__city
                              ? this.dotify(suggestion.unrestricted_value)
                              : this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__live__house == '' ? 'hidden' : ''
                  }`}
                >
                  Дом
                </label>
                <div className="dadataContainer">
                  <input
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.d__address__live__house == ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate &&
                      this.state.d__address__live__house != ''
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    name="house"
                    type="text"
                    placeholder="Дом"
                    value={this.state.d__address__live__house}
                    onChange={(e) => {
                      this.addressChange(
                        e,
                        'd__address__live__house',
                        'house',
                        'street_fias_id',
                        this.state.streetLiveIdValue
                      );
                    }}
                    onFocus={(e) => {
                      this.onFocusDadata('dadataFocusHouseLive');
                    }}
                    onBlur={(e) => {
                      this.onBlurDadata('dadataFocusHouseLive');
                    }}
                  />
                  {this.state.dadataFocusHouseLive &&
                    this.state.suggestions.length > 0 &&
                    !(
                      this.state.d__address__live__house &&
                      !this.state.cityLiveIdValue
                    ) &&
                    this.state.d__address__live__street && (
                      <ul className="dadataList">
                        {this.state.suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({
                                d__address__live__house: this.dotify(
                                  suggestion.value
                                ),
                                suggestions: [],
                                d__address__live__postal_code:
                                  suggestion.data.postal_code ||
                                  this.state.d__address__live__postal_code ||
                                  '',
                                d__address__live__region: this.dotify(
                                  suggestion.data.region_with_type
                                ),
                                regionLiveIdValue:
                                  suggestion.data.region_fias_id,
                                d__address__live__city: this.dotify(
                                  suggestion.data.city_with_type
                                ),
                                cityLiveIdValue: suggestion.data.city_fias_id,
                                d__address__live__street: this.dotify(
                                  suggestion.data.street_with_type
                                ),
                                streetLiveIdValue:
                                  suggestion.data.street_fias_id,
                              });
                            }}
                          >
                            {this.dotify(suggestion.value)}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="formField-container position-relative">
                <label
                  className={`floating-label ${
                    this.state.d__address__live__flat == '' ? 'hidden' : ''
                  }`}
                >
                  Квартира
                </label>
                <input
                  type="text"
                  className="inputUnderLine"
                  placeholder="Квартира"
                  value={this.state.d__address__live__flat}
                  onChange={this.onLiveFlatChange}
                />
              </div>
            </div>
          </div>
        )}

        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <button
            className="btn btnBack"
            onClick={() => {
              this.action(this.props.previous, 0, 'prev');
            }}
          >
            Назад
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              this.action(this.props.next, 2, 'next');
            }}
          >
            Далее
          </button>
        </div>
      </div>
    );
  }
}
