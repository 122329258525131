import React, { Component } from 'react';
import Baron from 'react-baron/dist/es5';

export default class Rating extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    componentDidMount() {
        const { scrollContent } = this.props;
        $(".ratingTable .clipper > .scroller").scroll(() => {
            scrollContent();
        });
    }
    render() {
        const {
            ratingList
        } = this.props;
        return (
            <div className="ratingTable">
                <table className="table">
                    <thead>
                        <tr className="rating-tableTitles">
                            <td className="ratingTableNum">#</td>
                            <td className="ratingTableName">Имя</td>
                            <td className="ratingTablePoints">Баллы</td>
                        </tr>
                    </thead>
                </table>
                <Baron>
                    {
                        ratingList.length > 0 ? (
                            <table className="table mainTable">
                                <tbody>
                                    {
                                        ratingList.map((rating, index) => (
                                            <tr key={index} className={`rating-${rating.place} ${rating.is_me ? 'rating-me' : ''}`}>
                                                <td className="ratingTableNum"><span>{rating.place}</span></td>
                                                <td className='ratingTableName'>
                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <span style={{display: "inline-block"}}>{rating.rating_nickname}</span>
                                                        <div className="ratingTableAchievements">
                                                            {rating.achievements.map(achievement => {
                                                                const text = achievement.name[0];
                                                                const bgColor = text === "1" ?
                                                                    "#FFD339" : text === "2"
                                                                        ? "#D9E4EB" : text === "3"
                                                                            ? "#FFAD4C" : null;
                                                                const borderColor = text === "1" ?
                                                                    "#E6C75A" : text === "2"
                                                                        ? "#B1C6D4" : text === "3"
                                                                            ? "#C98D46" : null;

                                                                return (
                                                                    <div style={{
                                                                        backgroundColor: bgColor,
                                                                        borderColor: borderColor
                                                                    }}>
                                                                        {text}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="ratingTablePoints">{rating.rating_points}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        ) : (
                                <div style={{ width: '100%', height: '270px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <span className="emptyData">нет данных</span>
                                </div>
                            )
                    }
                </Baron>
            </div>
        );
    }
}