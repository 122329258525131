import axios from 'axios';
import Config from '../config/config';

export const getTechOptions = (accId, boardId) => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/trading-tasks/info?trading_account_id=${accId}&board_id=${boardId}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const getTechInstruments = (timeId, optionTimesCount, board_id, page, search) => {
    let endPoint = `${Config.apiUrl}/v1/trading-tasks/symbols?page=${page}`;
    if(search) endPoint += `&search=${search}`;
    if(optionTimesCount > 1) endPoint += `&board_work_time_id=${timeId}`
    else endPoint += `&board_id=${board_id}`
  return axios({
    method: 'get',
    url: endPoint,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};