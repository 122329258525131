import axios from 'axios';
import Config from '../config/config';

export const SET_USER = 'SET_USER';

export const setUser = (user) => ({
  type: SET_USER,
  user,
});
export const getUser = (user) => ({
  type: SET_USER,
  user,
});

// export const getUser = () => {
//     return (dispatch) => {
//         return axios.get(`${Config.apiUrl}/v1/users/me/`).then((res)=>{
//             dispatch(getUserSuccess(res.data))
//         });
//     }
// }
