import React from 'react';
import { connect } from 'react-redux';
import amplitude from 'amplitude-js';

import { checkAuth, catchPromise } from '../utils/http';
import { uploadFile } from '../utils/anketaHttp';
import { setUser } from '../actions/user';
import { sendAnketaDataUpdate } from '../utils/anketaHttp';

const isCrypto = true; // leave it here! undefined export!

export class PersonalFilesUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageError: false,
      validate: false,
      files__passport: '',
      files__passportName: '',
      files__passportLink: '',
      files__passportError: '',
      files__passportUploading: false,
      files__passportValue: '',

      files__reg_place: '',
      files__reg_placeName: '',
      files__reg_placeLink: '',
      files__reg_placeError: '',
      files__reg_placeUploading: false,
      files__reg_placeValue: '',

      files__inn: '',
      files__innName: '',
      files__innLink: '',
      files__innError: '',
      files__innUploading: false,
      files__innValue: '',

      files__snils: '',
      files__snilsName: '',
      files__snilsLink: '',
      files__snilsError: '',
      files__snilsUploading: false,
      files__snilsValue: '',

      files__ip: '',
      files__ipName: '',
      files__ipLink: '',
      files__ipError: '',
      files__ipUploading: false,
      files__ipValue: '',

      files__tax_system: '',
      files__tax_systemName: '',
      files__tax_systemLink: '',
      files__tax_systemError: '',
      files__tax_systemUploading: false,
      files__tax_systemValue: '',

      files__bank_details: '',
      files__bank_detailsName: '',
      files__bank_detailsLink: '',
      files__bank_detailsError: '',
      files__bank_detailsUploading: false,
      files__bank_detailsValue: '',

      files__other: '',
      files__otherName: '',
      files__otherLink: '',
      files__otherError: '',
      files__otherUploading: false,
      files__otherValue: '',
    };
  }
  errorCb = () => {
    this.setState({
      pageError: true,
      files__passportUploading: false,
      files__reg_placeUploading: false,
      files__innUploading: false,
      files__snilsUploading: false,
      files__ipUploading: false,
      files__tax_systemUploading: false,
      files__bank_detailsUploading: false,
      files__otherUploading: false
    });
  };

  componentDidMount() {
    amplitude.getInstance().logEvent('personal_files_update_page.page_view');
  }

  sendAnketa = () => {
    this.setState({ validate: true });
    if (this.validating()) {
      if (!this.props.user.user.foreign)
        sendAnketaDataUpdate({
          files__passport: this.state.files__passport,
          files__reg_place: this.state.files__reg_place,
          files__inn: this.state.files__inn,
          files__snils: this.state.files__snils,
          files__ip: this.state.files__ip,
          files__tax_system: this.state.files__tax_system,
        })
          .then((res) => {
            this.updateUserInfo();
          })
          .catch((err) => {
            catchPromise(err, this.sendAnketa, this.errorCb);
          });
      else {
        sendAnketaDataUpdate({
          files__passport: this.state.files__passport,
          files__reg_place: this.state.files__reg_place,
          files__inn: this.state.files__inn,
          files__bank_details: this.state.files__bank_details,
          files__other: this.state.files__other
        })
          .then((res) => {
            this.updateUserInfo();
          })
          .catch((err) => {
            catchPromise(err, this.sendAnketa, this.errorCb);
          });
      }
    }
  };
  updateUserInfo = () => {
    checkAuth()
      .then((resCheck) => {
        this.props.setUser(resCheck.data);
      })
      .catch((err) => {
        catchPromise(err, this.updateUserInfo, this.errorCb);
      });
  };
  validating = () => {
    return (
      // (!this.props.user.user.foreign && this.state.files__passport != '' &&
      // this.state.files__reg_place != '' &&
      // ((this.props.user.user.fl_type === 'individual' &&
      //   this.state.files__inn != '' &&
      //   this.state.files__snils != '') ||
      //   (this.props.user.user.fl_type === 'ip' &&
      //     this.state.files__ip != '' &&
      //     this.state.files__tax_system != ''))) || (this.props.user.user.foreign && this.state.files__passport != '' &&
      //     this.state.files__reg_place != '' && this.state.files__inn != '' && this.state.files__bank_details != '')
      true
    );
  };

  onPassportImgChange = (e) => {
    const files__passport = e.target.files[0];
    var formData = new FormData();
    formData.append('file', files__passport, files__passport.name);
    this.setState({ files__passport: '', files__passportUploading: true });

    this.onUploadFile(
      formData,
      files__passport.name,
      'files__passport',
      'files__passportUploading',
      'files__passportError',
      'files__passportName',
      'files__passportLink'
    );
  };

  onRegPlaceChange = (e) => {
    const files__reg_place = e.target.files[0];
    var formData = new FormData();
    formData.append('file', files__reg_place, files__reg_place.name);
    this.setState({ files__reg_place: '', files__reg_placeUploading: true });

    this.onUploadFile(
      formData,
      files__reg_place.name,
      'files__reg_place',
      'files__reg_placeUploading',
      'files__reg_placeError',
      'files__reg_placeName',
      'files__reg_placeLink'
    );
  };

  onBankDetailsChange = (e) => {
    const files__bank_details = e.target.files[0];
    var formData = new FormData();
    formData.append('file', files__bank_details, files__bank_details.name);
    this.setState({ files__bank_details: '', files__bank_detailsUploading: true });

    this.onUploadFile(
      formData,
      files__bank_details.name,
      'files__bank_details',
      'files__bank_detailsUploading',
      'files__bank_detailsError',
      'files__bank_detailsName',
      'files__bank_detailsLink'
    ); 
  }

  onFilesOtherChange = (e) => {
    const files__other = e.target.files[0];
    var formData = new FormData();
    formData.append('file', files__other, files__other.name);
    this.setState({ files__other: '', files__otherUploading: true });

    this.onUploadFile(
      formData,
      files__other.name,
      'files__other',
      'files__otherUploading',
      'files__otherError',
      'files__otherName',
      'files__otherLink'
    ); 
  }

  onInnChange = (e) => {
    const files__inn = e.target.files[0];
    var formData = new FormData();
    formData.append('file', files__inn, files__inn.name);
    this.setState({ files__inn: '', files__innUploading: true });

    this.onUploadFile(
      formData,
      files__inn.name,
      'files__inn',
      'files__innUploading',
      'files__innError',
      'files__innName',
      'files__innLink'
    );
  };

  onSnilsChange = (e) => {
    const files__snils = e.target.files[0];
    var formData = new FormData();
    formData.append('file', files__snils, files__snils.name);
    this.setState({ files__snils: '', files__snilsUploading: true });

    this.onUploadFile(
      formData,
      files__snils.name,
      'files__snils',
      'files__snilsUploading',
      'files__snilsError',
      'files__snilsName',
      'files__snilsLink'
    );
  };

  onIpChange = (e) => {
    const files__ip = e.target.files[0];
    var formData = new FormData();
    formData.append('file', files__ip, files__ip.name);
    this.setState({ files__ip: '', files__ipUploading: true });

    this.onUploadFile(
      formData,
      files__ip.name,
      'files__ip',
      'files__ipUploading',
      'files__ipError',
      'files__ipName',
      'files__ipLink'
    );
  };

  onTaxChange = (e) => {
    const files__tax_system = e.target.files[0];
    var formData = new FormData();
    formData.append('file', files__tax_system, files__tax_system.name);
    this.setState({ files__tax_systemUploading: true });

    this.onUploadFile(
      formData,
      files__tax_system.name,
      'files__tax_system',
      'files__tax_systemUploading',
      'files__tax_systemError',
      'files__tax_systemName',
      'files__tax_systemLink'
    );
  };

  onUploadFile = (
    formData,
    fileName,
    file,
    uploading,
    erroring,
    naming,
    linkField
  ) => {
    uploadFile(formData)
      .then((res) => {
        this.setState({
          [file]: res.data.file_id,
          [naming]: fileName,
          [linkField]: res.data.url,
          [uploading]: false,
          [erroring]: '',
        });
      })
      .catch((err) => {
        if (err.response.data && err.response.status != 401)
          this.setState({ [erroring]: err.response.data.message });
        catchPromise(
          err,
          this.onUploadFile.bind(
            this,
            formData,
            fileName,
            file,
            uploading,
            erroring,
            naming,
            linkField
          ),
          this.errorCb
        );
      });
  };

  onRemoveFile = (file) => {
    this.setState({ [file]: '' });
    document.getElementById(file + 'Field').value = '';
  };

  render() {
    return (
      <div className="col-lg-8 offset-lg-2 profileForm">
        {!this.state.pageError ? (
          <div>
            {/* <div className="title card-content">Анкета</div> */}
            <div className="card card-content">
              <div className="card-body">
                {this.props.user.form_status === 'NEED_UPDATE' ? (!isCrypto && !this.props.user.user.foreign) ? (
                  <div className="profileDocs">
                    <h2 className="subtitle">Загрузка документов</h2>
                    <div className="row">
                      <table className="table col-12">
                        <tbody>
                          <tr>
                            <td className="adaptiveCell">
                              <div
                                className={`profileDocs-uploaderTitle ${
                                  this.state.files__passport != '' &&
                                  this.state.files__passportError == ''
                                    ? 'text-success'
                                    : ''
                                } ${
                                  this.state.files__passportError != ''
                                    ? 'text-danger'
                                    : ''
                                }`}
                              >
                                Паспорт (разворот с фото)
                                {!this.state.files__passportUploading &&
                                  this.state.files__passport != '' &&
                                  this.state.files__passportError == '' && (
                                    <i className="icon-checkmark3 uploaderTitleCheck"></i>
                                  )}
                              </div>
                              <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                                <input
                                  type="file"
                                  id="files__passportField"
                                  className="form-control-uniform-custom"
                                  accept=".png, .jpg, .pdf, .jpeg"
                                  disabled={this.state.files__passportUploading}
                                  onChange={(e) => this.onPassportImgChange(e)}
                                />
                                {!this.state.files__passportUploading &&
                                  this.state.files__passport == '' && (
                                    <span>
                                      Загрузить
                                      <img
                                        src="/images/ui/uploadIcon.png"
                                        className="uploadIcon"
                                        alt=""
                                      />
                                    </span>
                                  )}
                                {this.state.files__passportUploading &&
                                  this.state.files__passport === '' && (
                                    <span>
                                      Загрузить
                                      <i className="icon-spinner2 uploaderSpinner spinner"></i>
                                    </span>
                                  )}
                                {!this.state.files__passportUploading &&
                                  this.state.files__passport != '' && (
                                    <span>
                                      Загружено
                                      <i className="icon-checkmark3 uploaderCheck"></i>
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td className="profileDocs-desc">
                              {!this.state.files__passportUploading &&
                                this.state.files__passport != '' && (
                                  <span>
                                    <a
                                      href={this.state.files__passportLink}
                                      target="_blank"
                                    >
                                      {this.state.files__passportName}
                                    </a>
                                    <i
                                      className="icon-cross2 docRemoveFile text-danger"
                                      onClick={() => {
                                        this.onRemoveFile('files__passport');
                                      }}
                                    ></i>
                                  </span>
                                )}
                              {this.state.files__passportError != '' && (
                                <span className="text-danger">
                                  {this.state.files__passportError}
                                </span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="adaptiveCell">
                              <div
                                className={`profileDocs-uploaderTitle ${
                                  this.state.files__reg_place != '' &&
                                  this.state.files__reg_placeError == ''
                                    ? 'text-success'
                                    : ''
                                } ${
                                  this.state.files__reg_placeError != ''
                                    ? 'text-danger'
                                    : ''
                                }`}
                              >
                                Паспорт (разворот с пропиской)
                                {!this.state.files__reg_placeUploading &&
                                  this.state.files__reg_place != '' &&
                                  this.state.files__reg_placeError == '' && (
                                    <i className="icon-checkmark3 uploaderTitleCheck"></i>
                                  )}
                              </div>
                              <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                                <input
                                  type="file"
                                  id="files__reg_placeField"
                                  className="form-control-uniform-custom"
                                  accept=".png, .jpg, .pdf, .jpeg"
                                  disabled={
                                    this.state.files__reg_placeUploading
                                  }
                                  onChange={this.onRegPlaceChange}
                                />
                                {!this.state.files__reg_placeUploading &&
                                  this.state.files__reg_place == '' && (
                                    <span>
                                      Загрузить
                                      <img
                                        src="/images/ui/uploadIcon.png"
                                        className="uploadIcon"
                                        alt=""
                                      />
                                    </span>
                                  )}
                                {this.state.files__reg_placeUploading &&
                                  this.state.files__reg_place === '' && (
                                    <span>
                                      Загрузить
                                      <i className="icon-spinner2 uploaderSpinner spinner"></i>
                                    </span>
                                  )}
                                {!this.state.files__reg_placeUploading &&
                                  this.state.files__reg_place != '' && (
                                    <span>
                                      Загружено
                                      <i className="icon-checkmark3 uploaderCheck"></i>
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td className="profileDocs-desc">
                              {!this.state.files__reg_placeUploading &&
                                this.state.files__reg_place != '' && (
                                  <span>
                                    <a
                                      href={this.state.files__reg_placeLink}
                                      target="_blank"
                                    >
                                      {this.state.files__reg_placeName}
                                    </a>
                                    <i
                                      className="icon-cross2 docRemoveFile text-danger"
                                      onClick={() => {
                                        this.onRemoveFile('files__reg_place');
                                      }}
                                    ></i>
                                  </span>
                                )}
                              {this.state.files__reg_placeError != '' && (
                                <span className="text-danger">
                                  {this.state.files__reg_placeError}
                                </span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="adaptiveCell">
                              <div
                                className={`profileDocs-uploaderTitle ${
                                  this.state.files__inn != '' &&
                                  this.state.files__innError == ''
                                    ? 'text-success'
                                    : ''
                                } ${
                                  this.state.files__innError != ''
                                    ? 'text-danger'
                                    : ''
                                }`}
                              >
                                ИНН
                                {!this.state.files__innUploading &&
                                  this.state.files__inn != '' &&
                                  this.state.files__innError == '' && (
                                    <i className="icon-checkmark3 uploaderTitleCheck"></i>
                                  )}
                              </div>
                              <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                                <input
                                  type="file"
                                  id="files__innField"
                                  className="form-control-uniform-custom"
                                  accept=".png, .jpg, .pdf, .jpeg"
                                  disabled={this.state.files__innUploading}
                                  onChange={this.onInnChange}
                                />
                                {!this.state.files__innUploading &&
                                  this.state.files__inn == '' && (
                                    <span>
                                      Загрузить
                                      <img
                                        src="/images/ui/uploadIcon.png"
                                        className="uploadIcon"
                                        alt=""
                                      />
                                    </span>
                                  )}
                                {this.state.files__innUploading &&
                                  this.state.files__inn === '' && (
                                    <span>
                                      Загрузить
                                      <i className="icon-spinner2 uploaderSpinner spinner"></i>
                                    </span>
                                  )}
                                {!this.state.files__innUploading &&
                                  this.state.files__inn != '' && (
                                    <span>
                                      Загружено
                                      <i className="icon-checkmark3 uploaderCheck"></i>
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td className="profileDocs-desc">
                              {!this.state.files__innUploading &&
                                this.state.files__inn != '' && (
                                  <span>
                                    <a
                                      href={this.state.files__innLink}
                                      target="_blank"
                                    >
                                      {this.state.files__innName}
                                    </a>
                                    <i
                                      className="icon-cross2 docRemoveFile text-danger"
                                      onClick={() => {
                                        this.onRemoveFile('files__inn');
                                      }}
                                    ></i>
                                  </span>
                                )}
                              {this.state.files__innError != '' && (
                                <span className="text-danger">
                                  {this.state.files__innError}
                                </span>
                              )}
                            </td>
                          </tr>
                          {this.props.user.user.fl_type === 'individual' ? (
                            <tr>
                              <td className="adaptiveCell">
                                <div
                                  className={`profileDocs-uploaderTitle ${
                                    this.state.files__snils != '' &&
                                    this.state.files__snilsError == ''
                                      ? 'text-success'
                                      : ''
                                  } ${
                                    this.state.files__snilsError != ''
                                      ? 'text-danger'
                                      : ''
                                  }`}
                                >
                                  СНИЛС
                                  {!this.state.files__snilsUploading &&
                                    this.state.files__snils != '' &&
                                    this.state.files__snilsError == '' && (
                                      <i className="icon-checkmark3 uploaderTitleCheck"></i>
                                    )}
                                </div>
                                <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                                  <input
                                    type="file"
                                    id="files__snilsField"
                                    className="form-control-uniform-custom"
                                    accept=".png, .jpg, .pdf, .jpeg"
                                    disabled={this.state.files__snilsUploading}
                                    onChange={this.onSnilsChange}
                                  />
                                  {!this.state.files__snilsUploading &&
                                    this.state.files__snils == '' && (
                                      <span>
                                        Загрузить
                                        <img
                                          src="/images/ui/uploadIcon.png"
                                          className="uploadIcon"
                                          alt=""
                                        />
                                      </span>
                                    )}
                                  {this.state.files__snilsUploading &&
                                    this.state.files__snils === '' && (
                                      <span>
                                        Загрузить
                                        <i className="icon-spinner2 uploaderSpinner spinner"></i>
                                      </span>
                                    )}
                                  {!this.state.files__snilsUploading &&
                                    this.state.files__snils != '' && (
                                      <span>
                                        Загружено
                                        <i className="icon-checkmark3 uploaderCheck"></i>
                                      </span>
                                    )}
                                </div>
                              </td>
                              <td className="profileDocs-desc">
                                {!this.state.files__snilsUploading &&
                                  this.state.files__snils != '' && (
                                    <span>
                                      <a
                                        href={this.state.files__snilsLink}
                                        target="_blank"
                                      >
                                        {this.state.files__snilsName}
                                      </a>
                                      <i
                                        className="icon-cross2 docRemoveFile text-danger"
                                        onClick={() => {
                                          this.onRemoveFile('files__snils');
                                        }}
                                      ></i>
                                    </span>
                                  )}
                                {this.state.files__snilsError != '' && (
                                  <span className="text-danger">
                                    {this.state.files__snilsError}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ) : null}
                          {this.props.user.user.fl_type === 'ip' ? (
                            <tr>
                              <td className="adaptiveCell">
                                <div
                                  className={`profileDocs-uploaderTitle ${
                                    this.state.files__ip != '' &&
                                    this.state.files__ipError == ''
                                      ? 'text-success'
                                      : ''
                                  } ${
                                    this.state.files__ipError != ''
                                      ? 'text-danger'
                                      : ''
                                  }`}
                                >
                                  Свидетельство о постановке
                                  <br />
                                  на учет в качестве ИП
                                  {!this.state.files__ipUploading &&
                                    this.state.files__ip != '' &&
                                    this.state.files__ipError == '' && (
                                      <i className="icon-checkmark3 uploaderTitleCheck"></i>
                                    )}
                                </div>
                                <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                                  <input
                                    type="file"
                                    id="files__ipField"
                                    className="form-control-uniform-custom"
                                    accept=".png, .jpg, .pdf, .jpeg"
                                    disabled={this.state.files__ipUploading}
                                    onChange={this.onIpChange}
                                  />
                                  {!this.state.files__ipUploading &&
                                    this.state.files__ip == '' && (
                                      <span>
                                        Загрузить
                                        <img
                                          src="/images/ui/uploadIcon.png"
                                          className="uploadIcon"
                                          alt=""
                                        />
                                      </span>
                                    )}
                                  {this.state.files__ipUploading &&
                                    this.state.files__ip === '' && (
                                      <span>
                                        Загрузить
                                        <i className="icon-spinner2 uploaderSpinner spinner"></i>
                                      </span>
                                    )}
                                  {!this.state.files__ipUploading &&
                                    this.state.files__ip != '' && (
                                      <span>
                                        Загружено
                                        <i className="icon-checkmark3 uploaderCheck"></i>
                                      </span>
                                    )}
                                </div>
                              </td>
                              <td className="profileDocs-desc">
                                {!this.state.files__ipUploading &&
                                  this.state.files__ip != '' && (
                                    <span>
                                      <a
                                        href={this.state.files__ipLink}
                                        target="_blank"
                                      >
                                        {this.state.files__ipName}
                                      </a>
                                      <i
                                        className="icon-cross2 docRemoveFile text-danger"
                                        onClick={() => {
                                          this.onRemoveFile('files__ip');
                                        }}
                                      ></i>
                                    </span>
                                  )}
                                {this.state.files__ipError != '' && (
                                  <span className="text-danger">
                                    {this.state.files__ipError}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ) : null}
                          {this.props.user.user.fl_type === 'ip' ? (
                            <tr>
                              <td className="adaptiveCell">
                                <div
                                  className={`profileDocs-uploaderTitle ${
                                    this.state.files__tax_system != '' &&
                                    this.state.files__tax_systemError == ''
                                      ? 'text-success'
                                      : ''
                                  } ${
                                    this.state.files__tax_systemError != ''
                                      ? 'text-danger'
                                      : ''
                                  }`}
                                >
                                  Информационное письмо из ФНС
                                  <br />о применяемой системе налогообложения
                                  {!this.state.files__tax_systemUploading &&
                                    this.state.files__tax_system != '' &&
                                    this.state.files__tax_systemError == '' && (
                                      <i className="icon-checkmark3 uploaderTitleCheck"></i>
                                    )}
                                </div>
                                <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                                  <input
                                    type="file"
                                    id="files__tax_systemField"
                                    className="form-control-uniform-custom"
                                    accept=".png, .jpg, .pdf, .jpeg"
                                    disabled={
                                      this.state.files__tax_systemUploading
                                    }
                                    onChange={this.onTaxChange}
                                  />
                                  {!this.state.files__tax_systemUploading &&
                                    this.state.files__tax_system == '' && (
                                      <span>
                                        Загрузить
                                        <img
                                          src="/images/ui/uploadIcon.png"
                                          className="uploadIcon"
                                          alt=""
                                        />
                                      </span>
                                    )}
                                  {this.state.files__tax_systemUploading &&
                                    this.state.files__tax_system === '' && (
                                      <span>
                                        Загрузить
                                        <i className="icon-spinner2 uploaderSpinner spinner"></i>
                                      </span>
                                    )}
                                  {!this.state.files__tax_systemUploading &&
                                    this.state.files__tax_system != '' && (
                                      <span>
                                        Загружено
                                        <i className="icon-checkmark3 uploaderCheck"></i>
                                      </span>
                                    )}
                                </div>
                              </td>
                              <td className="profileDocs-desc">
                                {!this.state.files__tax_systemUploading &&
                                  this.state.files__tax_system != '' && (
                                    <span>
                                      <a
                                        href={this.state.files__tax_systemLink}
                                        target="_blank"
                                      >
                                        {this.state.files__tax_systemName}
                                      </a>
                                      <i
                                        className="icon-cross2 docRemoveFile text-danger"
                                        onClick={() => {
                                          this.onRemoveFile(
                                            'files__tax_system'
                                          );
                                        }}
                                      ></i>
                                    </span>
                                  )}
                                {this.state.files__tax_systemError != '' && (
                                  <span className="text-danger">
                                    {this.state.files__tax_systemError}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="profileDocs">
                    <h2 className="subtitle">Загрузка документов</h2>
                    <div className="row">
                      <table className="table col-12">
                        <tbody>
                          <tr>
                            <td className="adaptiveCell">
                              <div
                                className={`profileDocs-uploaderTitle ${
                                  this.state.files__passport != '' &&
                                  this.state.files__passportError == ''
                                    ? 'text-success'
                                    : ''
                                } ${
                                  this.state.files__passportError != ''
                                    ? 'text-danger'
                                    : ''
                                }`}
                              >
                                Паспорт (разворот с фото)
                                {!this.state.files__passportUploading &&
                                  this.state.files__passport != '' &&
                                  this.state.files__passportError == '' && (
                                    <i className="icon-checkmark3 uploaderTitleCheck"></i>
                                  )}
                              </div>
                              <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                                <input
                                  type="file"
                                  id="files__passportField"
                                  className="form-control-uniform-custom"
                                  accept=".png, .jpg, .pdf, .jpeg"
                                  disabled={this.state.files__passportUploading}
                                  onChange={(e) => this.onPassportImgChange(e)}
                                />
                                {!this.state.files__passportUploading &&
                                  this.state.files__passport == '' && (
                                    <span>
                                      Загрузить
                                      <img
                                        src="/images/ui/uploadIcon.png"
                                        className="uploadIcon"
                                        alt=""
                                      />
                                    </span>
                                  )}
                                {this.state.files__passportUploading &&
                                  this.state.files__passport === '' && (
                                    <span>
                                      Загрузить
                                      <i className="icon-spinner2 uploaderSpinner spinner"></i>
                                    </span>
                                  )}
                                {!this.state.files__passportUploading &&
                                  this.state.files__passport != '' && (
                                    <span>
                                      Загружено
                                      <i className="icon-checkmark3 uploaderCheck"></i>
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td className="profileDocs-desc">
                              {!this.state.files__passportUploading &&
                                this.state.files__passport != '' && (
                                  <span>
                                    <a
                                      href={this.state.files__passportLink}
                                      target="_blank"
                                    >
                                      {this.state.files__passportName}
                                    </a>
                                    <i
                                      className="icon-cross2 docRemoveFile text-danger"
                                      onClick={() => {
                                        this.onRemoveFile('files__passport');
                                      }}
                                    ></i>
                                  </span>
                                )}
                              {this.state.files__passportError != '' && (
                                <span className="text-danger">
                                  {this.state.files__passportError}
                                </span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="adaptiveCell">
                              <div
                                className={`profileDocs-uploaderTitle ${
                                  this.state.files__reg_place != '' &&
                                  this.state.files__reg_placeError == ''
                                    ? 'text-success'
                                    : ''
                                } ${
                                  this.state.files__reg_placeError != ''
                                    ? 'text-danger'
                                    : ''
                                }`}
                              >
                                Документ, подтверждающий прописку
                                {!this.state.files__reg_placeUploading &&
                                  this.state.files__reg_place != '' &&
                                  this.state.files__reg_placeError == '' && (
                                    <i className="icon-checkmark3 uploaderTitleCheck"></i>
                                  )}
                              </div>
                              <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                                <input
                                  type="file"
                                  id="files__reg_placeField"
                                  className="form-control-uniform-custom"
                                  accept=".png, .jpg, .pdf, .jpeg"
                                  disabled={this.state.files__reg_placeUploading}
                                  onChange={this.onRegPlaceChange}
                                />
                                {!this.state.files__reg_placeUploading &&
                                  this.state.files__reg_place == '' && (
                                    <span>
                                      Загрузить
                                      <img
                                        src="/images/ui/uploadIcon.png"
                                        className="uploadIcon"
                                        alt=""
                                      />
                                    </span>
                                  )}
                                {this.state.files__reg_placeUploading &&
                                  this.state.files__reg_place === '' && (
                                    <span>
                                      Загрузить
                                      <i className="icon-spinner2 uploaderSpinner spinner"></i>
                                    </span>
                                  )}
                                {!this.state.files__reg_placeUploading &&
                                  this.state.files__reg_place != '' && (
                                    <span>
                                      Загружено
                                      <i className="icon-checkmark3 uploaderCheck"></i>
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td className="profileDocs-desc">
                              {!this.state.files__reg_placeUploading &&
                                this.state.files__reg_place != '' && (
                                  <span>
                                    <a
                                      href={this.state.files__reg_placeLink}
                                      target="_blank"
                                    >
                                      {this.state.files__reg_placeName}
                                    </a>
                                    <i
                                      className="icon-cross2 docRemoveFile text-danger"
                                      onClick={() => {
                                        this.onRemoveFile('files__reg_place');
                                      }}
                                    ></i>
                                  </span>
                                )}
                              {this.state.files__reg_placeError != '' && (
                                <span className="text-danger">
                                  {this.state.files__reg_placeError}
                                </span>
                              )}
                            </td>
                          </tr>
                          {!isCrypto &&

                          (<React.Element><tr>
                            <td className="adaptiveCell">
                              <div
                                  className={`profileDocs-uploaderTitle ${
                                      this.state.files__inn != '' &&
                                      this.state.files__innError == ''
                                          ? 'text-success'
                                          : ''
                                  } ${
                                      this.state.files__innError != ''
                                          ? 'text-danger'
                                          : ''
                                  }`}
                              >
                                Документ, подтверждающий постановку на учет в налоговую инспекцию
                                {!this.state.files__innUploading &&
                                this.state.files__inn != '' &&
                                this.state.files__innError == '' && (
                                    <i className="icon-checkmark3 uploaderTitleCheck"></i>
                                )}
                              </div>
                              <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                                <input
                                    type="file"
                                    id="files__innField"
                                    className="form-control-uniform-custom"
                                    accept=".png, .jpg, .pdf, .jpeg"
                                    disabled={this.state.files__innUploading}
                                    onChange={this.onInnChange}
                                />
                                {!this.state.files__innUploading &&
                                this.state.files__inn == '' && (
                                    <span>
                                      Загрузить
                                      <img
                                          src="/images/ui/uploadIcon.png"
                                          className="uploadIcon"
                                          alt=""
                                      />
                                    </span>
                                )}
                                {this.state.files__innUploading &&
                                this.state.files__inn === '' && (
                                    <span>
                                      Загрузить
                                      <i className="icon-spinner2 uploaderSpinner spinner"></i>
                                    </span>
                                )}
                                {!this.state.files__innUploading &&
                                this.state.files__inn != '' && (
                                    <span>
                                      Загружено
                                      <i className="icon-checkmark3 uploaderCheck"></i>
                                    </span>
                                )}
                              </div>
                            </td>
                            <td className="profileDocs-desc">
                              {!this.state.files__innUploading &&
                              this.state.files__inn != '' && (
                                  <span>
                                    <a href={this.state.files__innLink} target="_blank">
                                      {this.state.files__innName}
                                    </a>
                                    <i
                                        className="icon-cross2 docRemoveFile text-danger"
                                        onClick={() => {
                                          this.onRemoveFile('files__inn');
                                        }}
                                    ></i>
                                  </span>
                              )}
                              {this.state.files__innError != '' && (
                                  <span className="text-danger">
                                  {this.state.files__innError}
                                </span>
                              )}
                            </td>
                          </tr>
                            <tr>
                            <td className="adaptiveCell">
                            <div
                            className={`profileDocs-uploaderTitle ${
                            this.state.files__bank_details != '' &&
                            this.state.files__bank_detailsError == ''
                            ? 'text-success'
                            : ''
                          } ${
                            this.state.files__bank_detailsError != ''
                            ? 'text-danger'
                            : ''
                          }`}
                            >
                            Банковские реквизиты
                          {!this.state.files__bank_detailsUploading &&
                            this.state.files__bank_details != '' &&
                            this.state.files__bank_detailsError == '' && (
                            <i className="icon-checkmark3 uploaderTitleCheck"></i>
                            )}
                            </div>
                            <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                            <input
                            type="file"
                            id="files__bank_detailsField"
                            className="form-control-uniform-custom"
                            accept=".png, .jpg, .pdf, .jpeg"
                            disabled={this.state.files__bank_detailsUploading}
                            onChange={this.onBankDetailsChange}
                            />
                          {!this.state.files__bank_detailsUploading &&
                            this.state.files__bank_details == '' && (
                            <span>
                            Загрузить
                            <img
                            src="/images/ui/uploadIcon.png"
                            className="uploadIcon"
                            alt=""
                            />
                            </span>
                            )}
                          {this.state.files__bank_detailsUploading &&
                            this.state.files__bank_details === '' && (
                            <span>
                            Загрузить
                            <i className="icon-spinner2 uploaderSpinner spinner"></i>
                            </span>
                            )}
                          {!this.state.files__bank_detailsUploading &&
                            this.state.files__bank_details != '' && (
                            <span>
                            Загружено
                            <i className="icon-checkmark3 uploaderCheck"></i>
                            </span>
                            )}
                            </div>
                            </td>
                            <td className="profileDocs-desc">
                          {!this.state.files__bank_detailsUploading &&
                            this.state.files__bank_details != '' && (
                            <span>
                            <a href={this.state.files__bank_detailsLink} target="_blank">
                          {this.state.files__bank_detailsName}
                            </a>
                            <i
                            className="icon-cross2 docRemoveFile text-danger"
                            onClick={() => {
                            this.onRemoveFile('files__bank_details');
                          }}
                            ></i>
                            </span>
                            )}
                          {this.state.files__bank_detailsError != '' && (
                            <span className="text-danger">
                          {this.state.files__bank_detailsError}
                            </span>
                            )}
                            </td>
                            </tr>
                          </React.Element>)}
                          <tr>
                            <td className="adaptiveCell">
                              <div
                                className={`profileDocs-uploaderTitle ${
                                  this.state.files__other != '' &&
                                  this.state.files__otherError == ''
                                    ? 'text-success'
                                    : ''
                                } ${
                                  this.state.files__otherError != ''
                                    ? 'text-danger'
                                    : ''
                                }`}
                              >
                                Прочие документы
                                {!this.state.files__otherUploading &&
                                  this.state.files__other != '' &&
                                  this.state.files__otherError == '' && (
                                    <i className="icon-checkmark3 uploaderTitleCheck"></i>
                                  )}
                              </div>
                              <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                                <input
                                  type="file"
                                  id="files__otherField"
                                  className="form-control-uniform-custom"
                                  accept=".png, .jpg, .pdf, .jpeg"
                                  disabled={this.state.files__otherUploading}
                                  onChange={this.onFilesOtherChange}
                                />
                                {!this.state.files__otherUploading &&
                                  this.state.files__other == '' && (
                                    <span>
                                      Загрузить
                                      <img
                                        src="/images/ui/uploadIcon.png"
                                        className="uploadIcon"
                                        alt=""
                                      />
                                    </span>
                                  )}
                                {this.state.files__otherUploading &&
                                  this.state.files__other === '' && (
                                    <span>
                                      Загрузить
                                      <i className="icon-spinner2 uploaderSpinner spinner"></i>
                                    </span>
                                  )}
                                {!this.state.files__otherUploading &&
                                  this.state.files__other != '' && (
                                    <span>
                                      Загружено
                                      <i className="icon-checkmark3 uploaderCheck"></i>
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td className="profileDocs-desc">
                              {!this.state.files__otherUploading &&
                                this.state.files__other != '' && (
                                  <span>
                                    <a href={this.state.files__otherLink} target="_blank">
                                      {this.state.files__otherName}
                                    </a>
                                    <i
                                      className="icon-cross2 docRemoveFile text-danger"
                                      onClick={() => {
                                        this.onRemoveFile('files__other');
                                      }}
                                    ></i>
                                  </span>
                                )}
                              {this.state.files__otherError != '' && (
                                <span className="text-danger">
                                  {this.state.files__otherError}
                                </span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div
                    className="text-center text-success"
                    style={{ fontSize: '20px' }}
                  >
                    Документы отправлены на проверку
                  </div>
                )}
                {this.props.user.form_status == 'NEED_UPDATE' && (
                  <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
                    <div></div>
                    <button
                      className="btn btn-success anketaDocs-submitBtnSend ml-2"
                      onClick={this.sendAnketa}
                    >
                      Отправить
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalFilesUpdate);
