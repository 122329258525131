import React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import InputMask from 'react-input-mask';

import { catchPromise } from '../utils/http';
import { getPhoneCode } from '../utils/utilsHttp';

export class ShopItemPage extends React.Component {
  render() {
    return (
      <div className="content shop content-shopList">
        <div className="card card-content">
          <div className="card-body">
            <div
              className="text-success text-center"
              style={{ fontSize: '20px' }}
            >
              Спасибо за покупку!
            </div>
            <div className="text-center" style={{ fontSize: '20px' }}>
              <a href="/shop">Вернуться в магазин</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(ShopItemPage);
