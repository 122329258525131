import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/ru";
import Baron from "react-baron/dist/es5";
import amplitude from 'amplitude-js';

import { getBonuseById, getBonuses } from "../utils/bonusesHttp";

export class WithdrawAssets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      withdraws: [],
      isLoading: true,
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    amplitude.getInstance().logEvent('withdraw_assets_page.page_view');

    this.loadBonuses();
  }
  loadBonuses = () => {
    getBonuses(1).then((res) => {
      this.setState({
        withdraws: res.data.bonus_withdraw_requests_list,
        isLoading: false,
      });
      getBonuseById(1).then((res) => {
        console.log(res);
      });
    });
  };
  setStatusColor = (status) => {
    switch (status) {
      case 3:
        return "docStatus-success";
      case 4:
      case 10:
        return "docStatus-reject";
      default:
        return "";
    }
  };
  statusTitle = (status) => {
    console.log(status);
    switch (status) {
      case 0:
        return "Новый";
      case 1:
        return "В работе";
      case 2:
        return "На исполнении";
      case 3:
        return "Исполнен";
      case 4:
        return "Отклонён";
      case 10:
        return "Ошибка";
    }
  };

  render() {
    const networkValues = {
      "BEP2": "BNB (BEP2)",
      "BEP20": "BSC (BEP20)",
      "ERC20": "ETH (ERC20)",
      "TRC20": "TRX (TRC20)"
    }

    return (
      <React.Fragment>
        {!this.props.tradingAccounts.trading_accounts.find(acc => !acc.bonus) ? (
          <Redirect to="/app" />
        ) : (
          <div className="documentsMoving withdrawAssets">
            <h1>Внешние переводы</h1>
            {!this.state.pageError ? (
              <div>
                <ul className="nav nav-tabs boardsTabs">
                  <li
                    className="nav-item"
                    onClick={() => {
                      this.setStatusFilter("");
                    }}
                  >
                    <span
                      className="nav-link text-center active show"
                      data-toggle="tab"
                    >
                      <span>Все</span>
                    </span>
                  </li>
                  <li className="closeDealsBtns">
                    <Link
                      to="/app/withdraw-assets/create"
                      className="btn btn-success"
                    >
                      Создать запрос
                    </Link>
                  </li>
                </ul>

                <div className="tab-content">
                  <div className="tab-pane fade active show">
                    {this.state.withdraws.length > 0 &&
                    !this.state.isLoading ? (
                      <div className="table">
                        <table className="tableLayoutFixes">
                          <thead>
                            <tr>
                              <td className="text-right docCreate">СОЗДАН</td>
                              <td className="docCur">Валюта</td>
                              <td>Сеть</td>
                              <td className="docDescr">ДОКУМЕНТ</td>
                              <td className="docComm">КОММЕНТАРИИ</td>
                              <td className="docStatus">СТАТУС</td>
                            </tr>
                          </thead>
                        </table>
                        <Baron>
                          <table className="tableLayoutFixes docsTable">
                            <tbody>
                              {this.state.withdraws.map((doc, index) => (
                                <tr
                                  key={doc.id}
                                  className={`${
                                    doc.updated ? "updatedDoc" : ""
                                  }`}
                                >
                                  <td className="text-right docCreate">
                                    {moment(doc.date, "YYYY-MM-DD").format(
                                      "DD.MM.YYYY"
                                    )}
                                  </td>
                                  <td className="docCur">
                                      {doc.currency}
                                  </td>
                                  <td>
                                      {networkValues[doc.network] ? networkValues[doc.network] : doc.network}
                                  </td>
                                  <td className="docDescr">
                                    <div className="docDescr-title">
                                      <Link
                                        to={`/app/withdraw-assets/${doc.id}`}
                                      >
                                        {doc.title}
                                      </Link>
                                    </div>
                                    <div className="docDescr-text">
                                      {doc.subtitle}
                                    </div>
                                  </td>
                                  <td className="docComm">
                                    <span>{doc.comment}</span>
                                  </td>
                                  <td
                                    className={`docStatus ${this.setStatusColor(
                                      doc.status
                                    )}`}
                                  >
                                    <span>{this.statusTitle(doc.status)}</span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Baron>
                      </div>
                    ) : (
                      <div className="emptyDashboard-plug">
                        {this.state.isLoading ? (
                          <div className="mt-1 text-center spinner-container isLoadingSpinner">
                            <span>
                              <img
                                src="/images/ui/load-spinner.png"
                                className="spinner"
                                alt=""
                              />
                            </span>
                          </div>
                        ) : (
                          <div className="emptyData">нет данных</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <h3 className="text-center text-danger">Произошла ошибка</h3>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tradingAccounts: state.tradingAccounts,
});

export default connect(mapStateToProps)(WithdrawAssets);
