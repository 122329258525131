$(document).ready(function () {
  try {
    $.browserSelector(), $('html').hasClass('chrome') && $.smoothScroll();
  } catch (e) {}
  $('.toggle_menu').click(function () {
    return (
      $(this).toggleClass('on'),
      $('.mobile-pannel').toggleClass('show'),
      $('body').toggleClass('no-scroll'),
      $('.menu-overlay').addClass('show'),
      !1
    );
  }),
    $('.close_menu').click(function (e) {
      return (
        e.preventDefault(),
        $('.toggle_menu').removeClass('on'),
        $('.mobile-pannel').removeClass('show'),
        $('body').removeClass('no-scroll'),
        $('.menu-overlay').removeClass('show'),
        !1
      );
    }),
    $('.menu-overlay').click(function () {
      return (
        $('.toggle_menu').removeClass('on'),
        $('.mobile-pannel').removeClass('show'),
        $('body').removeClass('no-scroll'),
        $('.menu-overlay').removeClass('show'),
        !1
      );
    });
});
