import { SET_UPDATES } from '../actions/updates';

// Expenses Reducer
const reducerDefaultState = { news: [], docs_count: 0, news_count: 0, notifications: 0 };

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_UPDATES:
      return action.updates;
    default:
      return state;
  }
};
