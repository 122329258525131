import React from 'react';
import InputMask from 'react-input-mask';

export default class ChangeAnketaExtra extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  onInnChange = (e) => {
    const d__extra__inn = e.target.value;
    if (
      !d__extra__inn ||
      (d__extra__inn.match(/^[0-9]+$/) && d__extra__inn.length <= 12)
    ) {
      this.props.onValueChange('d__extra__inn', d__extra__inn);
      this.props.onValueValidateCurrentChange('d__extra__inn');
    }
  };
  onSnilsChange = (e) => {
    const d__extra__snils = e.target.value;
    this.props.onValueChange('d__extra__snils', d__extra__snils);
    this.props.onValueValidateCurrentChange('d__extra__snils');
  };

  render() {
    let {
      d__extra__fl_type,
      d__extra__inn,
      d__extra__snils,
      d__extra__ogrnip,
      d__extra__tax_system,
      validate,
      validateActiveFields,
      isReadableExtra,
    } = this.props;
    return (
      <div>
        <div className="anketaChange-subtitleContainer d-flex justify-content-between align-items-center">
          <div className="anketaChange-subtitle">Дополнительные данные</div>
          <span
            className="addressHandWriting"
            onClick={() => {
              this.props.setReadableBlock('isReadableExtra');
            }}
          >
            {isReadableExtra ? (
              <span className="text-danger addressHandWriting-reset">
                Отменить
              </span>
            ) : (
              <span className="text-primary addressHandWriting-change">
                Изменить
              </span>
            )}
          </span>
        </div>
        <div className="docCreate-anketaExtraFlType">
          {d__extra__fl_type == 0 ? 'Физическое лицо' : 'ИП'}
        </div>
        {d__extra__fl_type === 0 ? (
          <div className="row">
            <div className="col-md-6">
              <div className="position-relative formField-container">
                <label
                  className={`floating-label ${
                    d__extra__inn == '' ? 'hidden' : ''
                  }`}
                >
                  ИНН
                </label>
                <input
                  className={`inputUnderLine ${
                    validate &&
                    d__extra__inn.length != 12 &&
                    validateActiveFields.d__extra__inn
                      ? 'inputValidateCurrentWrong'
                      : ''
                  } ${
                    validate && d__extra__inn.length != 12
                      ? 'inputValidateWrong'
                      : ''
                  } ${
                    validate && d__extra__inn.length == 12
                      ? 'inputValidateCorrect'
                      : ''
                  } ${!isReadableExtra ? "anketaReadOnlyFields" : ""}`}
                  readOnly={!isReadableExtra}
                  placeholder="ИНН"
                  value={d__extra__inn}
                  onChange={this.onInnChange}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="position-relative formField-container">
                <label
                  className={`floating-label ${
                    d__extra__snils == '' ? 'hidden' : ''
                  }`}
                >
                  СНИЛС
                </label>
                <InputMask
                  mask="999-999-999 99"
                  className={`inputUnderLine ${
                    validate &&
                    d__extra__snils.replace(/[-_]/g, '').length != 12 &&
                    validateActiveFields.d__extra__snils
                      ? 'inputValidateCurrentWrong'
                      : ''
                  } ${
                    validate &&
                    d__extra__snils.replace(/[-_]/g, '').length != 12
                      ? 'inputValidateWrong'
                      : ''
                  } ${
                    validate &&
                    d__extra__snils.replace(/[-_]/g, '').length == 12
                      ? 'inputValidateCorrect'
                      : ''
                  } ${!isReadableExtra ? "anketaReadOnlyFields" : ""}`}
                  placeholder="СНИЛС"
                  readOnly={!isReadableExtra}
                  value={d__extra__snils}
                  onChange={this.onSnilsChange}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-6">
              <div className="position-relative formField-container">
                <label
                  className={`floating-label ${
                    d__extra__inn == '' ? 'hidden' : ''
                  }`}
                >
                  ИНН
                </label>
                <input
                  className={`inputUnderLine ${
                    validate &&
                    d__extra__inn.length != 12 &&
                    validateActiveFields.d__extra__inn
                      ? 'inputValidateCurrentWrong'
                      : ''
                  } ${
                    validate && d__extra__inn.length != 12
                      ? 'inputValidateWrong'
                      : ''
                  } ${
                    validate && d__extra__inn.length == 12
                      ? 'inputValidateCorrect'
                      : ''
                  } ${!isReadableExtra ? "anketaReadOnlyFields" : ""}`}
                  readOnly={!isReadableExtra}
                  placeholder="ИНН"
                  value={d__extra__inn}
                  onChange={this.onInnChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="position-relative formField-container">
                <label
                  className={`floating-label ${
                    d__extra__ogrnip == '' ? 'hidden' : ''
                  }`}
                >
                  ОГРНИП
                </label>
                <input
                  className="inputUnderLine"
                  placeholder="ОГРНИП"
                  readOnly={true}
                  value={d__extra__ogrnip}
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="position-relative formField-container">
                <label className="floating-label">
                  Применяемая система налогообложения:
                </label>
                <input
                  type="text"
                  className="inputUnderLine mt-1"
                  readOnly={true}
                  value={d__extra__tax_system == 0 ? 'УСН' : 'ОСНО'}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
