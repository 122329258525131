import axios from 'axios';
import Config from '../config/config';

export const getPhoneCode = (data) => {
    return axios({
        method: 'post',
        url: `${Config.apiUrl}/v1/utils/get-sms`,
        data: data,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const getPhoneCodeDiadoc = () => {
    return axios({
        method: 'post',
        url: `${Config.apiUrl}/v1/utils/diadoc/get-sms`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const getMyDssSign = (data) => {
    return axios({
        method: 'post',
        url: `${Config.apiUrl}/v1/utils/diadoc/get-mydss-sign`,
        data: data,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const verifyShopSms = (data) => {
    return axios({
        method: 'post',
        url: `${Config.apiUrl}/v1/utils/verify-shop-sms`,
        data: data,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}