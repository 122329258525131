import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';

import Config from '../../../config/config';
import { uploadFile } from '../../../utils/documentsHttp';
import { catchPromise } from '../../../utils/http';
import { getAnketa } from '../../../utils/anketaHttp';
import { setAnketa } from '../../../actions/anketa';

import ChangeAnketaProfile from './ChangeAnketaProfile';
import ChangeAnketaAddress from './ChangeAnketaAddress';
import ChangeAnketaExtra from './ChangeAnketaExtra';

export class ChangeAnketaForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validate: false,
      validateActiveFields: {
        d__personal__passport__series: false,
        d__personal__passport__number: false,
        d__personal__passport__department_code: false,
        d__extra__inn: false,
        d__extra__snils: false,
        d__bank__bik: false,
        d__bank__acc: false,
      },
      suggestions: [],
      isHandEditing: false,
      isSamePostal: false,
      isSameLive: false,
      regAddressFull: '',
      postalAddressFull: '',
      liveAddressFull: '',
      contracts: props.contracts || [],

      regionRegIdValue: '',
      cityRegIdValue: '',
      streetRegIdValue: '',

      regionPostalIdValue: '',
      cityPostalIdValue: '',
      streetPostalIdValue: '',

      regionLiveIdValue: '',
      cityLiveIdValue: '',
      streetLiveIdValue: '',

      anketaData: {
        contract_id: props.contracts.length > 0 ? props.contracts[0].id : '',

        d__personal__last_name: '',
        d__personal__first_name: '',
        d__personal__middle_name: '',
        d__personal__birth_date: null,
        d__personal__birth_place: '',
        d__personal__sex: true,
        d__personal__passport__series: '',
        d__personal__passport__number: '',
        d__personal__passport__issued_by: '',
        d__personal__passport__issued_date: null,
        d__personal__passport__department_code: '',
        files__passport: '',

        d__address__reg__postal_code: '',
        d__address__reg__region: '',
        d__address__reg__area: '',
        d__address__reg__city: '',
        d__address__reg__street: '',
        d__address__reg__house: '',
        d__address__reg__block: '',
        d__address__reg__flat: '',
        d__address__postal__postal_code: '',
        d__address__postal__region: '',
        d__address__postal__area: '',
        d__address__postal__city: '',
        d__address__postal__street: '',
        d__address__postal__house: '',
        d__address__postal__block: '',
        d__address__postal__flat: '',
        d__address__live__postal_code: '',
        d__address__live__region: '',
        d__address__live__area: '',
        d__address__live__city: '',
        d__address__live__street: '',
        d__address__live__house: '',
        d__address__live__block: '',
        d__address__live__flat: '',
        files__reg_place: '',

        d__extra__inn: '',
        d__extra__fl_type: 0,
        d__extra__snils: '',
        d__extra__ogrnip: '',
        d__extra__tax_system: 0,
        files__inn: '',
        files__snils: '',

        d__bank__bik: '',
        d__bank__name: '',
        d__bank__corr_acc: '',
        d__bank__acc: '',
      },
      files__passportShow: false,
      files__passportName: '',
      files__passportLink: '',
      files__passportError: '',
      files__passportUploading: false,
      files__passportValue: '',

      files__reg_placeShow: false,
      files__reg_placePassportShow: false,
      files__reg_placeName: '',
      files__reg_placeLink: '',
      files__reg_placeError: '',
      files__reg_placeUploading: false,
      files__reg_placeValue: '',

      files__innShow: false,
      files__innName: '',
      files__innLink: '',
      files__innError: '',
      files__innUploading: false,
      files__innValue: '',

      files__snilsShow: false,
      files__snilsName: '',
      files__snilsLink: '',
      files__snilsError: '',
      files__snilsUploading: false,
      files__snilsValue: '',

      originalAnketaData: {},
      isReadableProfile: false,
      isReadableAddress: false,
      isReadableExtra: false,
      isReadableBank: false,
      isLoadFiles: false,
      bankAcc_errorName: '',
      bikError: '',
      isLoading: true,
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };

  componentDidMount() {
    this.getReadyAnketa();
  }
  changeSelectOpened = (name, value) => {
    this.setState({ [name]: value });
  };
  getReadyAnketa = () => {
    this.setState({ isLoading: true });
    getAnketa()
      .then((res) => {
        let anketaData = { ...this.state.anketaData, ...res.data };
        let originalAnketaData = { ...this.state.anketaData, ...res.data };

        anketaData.d__personal__passport__issued_date = moment(
          res.data.d__personal__passport__issued_date,
          'YYYY-MM-DD'
        );

        originalAnketaData.d__personal__passport__issued_date = moment(
          res.data.d__personal__passport__issued_date,
          'YYYY-MM-DD'
        );

        anketaData.files__passport = '';
        anketaData.files__reg_place = '';
        anketaData.files__inn = '';
        anketaData.files__snils = '';

        originalAnketaData.files__passport = '';
        originalAnketaData.files__reg_place = '';
        originalAnketaData.files__inn = '';
        originalAnketaData.files__snils = '';

        this.setState({
          anketaData,
          originalAnketaData,
          isLoading: false,
          pageError: false,
        });
      })
      .catch((err) => {
        catchPromise(err, this.getReadyAnketa, this.errorCb);
      });
  };

  onSubmit = () => {
    let tempData = { code: 'CHANGE_PERSONAL_FORM', personal_form: {} };
    for (var key in this.state.originalAnketaData) {
      if (
        this.state.anketaData[key] != this.state.originalAnketaData[key] &&
        key != 'd__personal__passport__issued_date'
      ) {
        if (key.indexOf('d__') != -1)
          tempData.personal_form[key] = this.state.anketaData[key];
        else tempData[key] = this.state.anketaData[key];
      }
      if (
        key === 'd__personal__passport__issued_date' &&
        this.state.anketaData[key].format('YYYY-MM-DD') !=
          this.state.originalAnketaData[key].format('YYYY-MM-DD')
      )
        tempData.personal_form.d__personal__passport__issued_date = this.state.anketaData.d__personal__passport__issued_date;
    }
    if (JSON.stringify(tempData.personal_form) != '{}') {
      this.setState({ validate: true });
      this.setState({
        validateActiveFields: {
          d__personal__passport__series: true,
          d__personal__passport__number: true,
          d__personal__passport__department_code: true,
          d__extra__inn: true,
          d__extra__snils: true,
          d__bank__bik: true,
          d__bank__acc: true,
        },
      });

      tempData.contract_id = parseInt(this.state.anketaData.contract_id);
      if (
        tempData.personal_form.hasOwnProperty(
          'd__personal__passport__issued_date'
        )
      )
        tempData.personal_form.d__personal__passport__issued_date = tempData.personal_form.d__personal__passport__issued_date.format(
          'YYYY-MM-DD'
        );
      if (this.validating()) {
        this.props.onSubmit(tempData);
      }
    }
  };

  validating = () => {
    if (
      this.validateRs(
        this.state.anketaData.d__bank__acc,
        this.state.anketaData.d__bank__bik
      )
    ) {
      return (
        this.state.anketaData.d__personal__last_name != '' &&
        this.state.anketaData.d__personal__first_name != '' &&
        this.state.anketaData.d__personal__passport__series.length === 4 &&
        this.state.anketaData.d__personal__passport__number.length === 6 &&
        this.state.anketaData.d__personal__passport__issued_by != '' &&
        this.state.anketaData.d__personal__passport__issued_date != null &&
        this.state.anketaData.d__personal__passport__department_code != '' &&
        this.state.anketaData.d__personal__passport__department_code.replace(
          /[-_]/g,
          ''
        ).length === 6 &&
        this.state.anketaData.d__address__live__postal_code.length == 6 &&
        this.state.anketaData.d__address__live__region != '' &&
        this.state.anketaData.d__address__live__city != '' &&
        this.state.anketaData.d__address__live__street != '' &&
        this.state.anketaData.d__address__live__house != '' &&
        this.state.anketaData.d__address__postal__postal_code.length == 6 &&
        this.state.anketaData.d__address__postal__region != '' &&
        this.state.anketaData.d__address__postal__city != '' &&
        this.state.anketaData.d__address__postal__street != '' &&
        this.state.anketaData.d__address__postal__house != '' &&
        this.state.anketaData.d__address__reg__postal_code.length == 6 &&
        this.state.anketaData.d__address__reg__region != '' &&
        this.state.anketaData.d__address__reg__city != '' &&
        this.state.anketaData.d__address__reg__street != '' &&
        this.state.anketaData.d__address__reg__house != '' &&
        ((this.state.anketaData.d__extra__fl_type === 0 &&
          this.state.anketaData.d__extra__inn.length == 12 &&
          this.state.anketaData.d__extra__snils.replace(/[-_]/g, '').length ==
            12) ||
          (this.state.anketaData.d__extra__fl_type === 1 &&
            this.state.anketaData.d__extra__ogrnip.length == 15)) &&
        this.state.anketaData.d__bank__bik.length == 9 &&
        this.state.anketaData.d__bank__name != '' &&
        this.state.anketaData.d__bank__corr_acc != '' &&
        ((this.state.files__passportShow &&
          this.state.anketaData.files__passport != '') ||
          !this.state.files__passportShow) &&
        (((this.state.files__reg_placePassportShow ||
          this.state.files__reg_placeShow) &&
          this.state.anketaData.files__reg_place != '') ||
          (!this.state.files__reg_placePassportShow &&
            !this.state.files__reg_placeShow)) &&
        ((this.state.files__innShow &&
          this.state.anketaData.files__inn != '') ||
          !this.state.files__innShow) &&
        ((this.state.files__snilsShow &&
          this.state.anketaData.files__snils != '') ||
          !this.state.files__snilsShow)
      );
    }
  };

  dotify = (address) => {
    if (address) {
      var shortTypes = [
        'аобл',
        'респ',
        'вл',
        'г',
        'д',
        'двлд',
        'днп',
        'дор',
        'дп',
        'жт',
        'им',
        'к',
        'кв',
        'км',
        'комн',
        'кп',
        'лпх',
        'м',
        'мкр',
        'наб',
        'нп',
        'обл',
        'оф',
        'п',
        'пгт',
        'пер',
        'пл',
        'платф',
        'рзд',
        'рп',
        'с',
        'сл',
        'снт',
        'ст',
        'стр',
        'тер',
        'туп',
        'ул',
        'х',
        'ш',
      ];
      var words = address.split(' ');
      var dottedWords = words.map(function (word) {
        if (shortTypes.indexOf(word) !== -1) {
          return word + '.';
        } else {
          return word;
        }
      });
      return dottedWords.join(' ');
    } else return address;
  };

  checkChangePassport = () => {
    if (
      this.state.anketaData.d__personal__last_name !=
        this.state.originalAnketaData.d__personal__last_name ||
      this.state.anketaData.d__personal__first_name !=
        this.state.originalAnketaData.d__personal__first_name ||
      this.state.anketaData.d__personal__middle_name !=
        this.state.originalAnketaData.d__personal__middle_name ||
      this.state.anketaData.d__personal__passport__series !=
        this.state.originalAnketaData.d__personal__passport__series ||
      this.state.anketaData.d__personal__passport__number !=
        this.state.originalAnketaData.d__personal__passport__number ||
      this.state.anketaData.d__personal__passport__issued_by !=
        this.state.originalAnketaData.d__personal__passport__issued_by ||
      this.state.anketaData.d__personal__passport__issued_date.format(
        'YYYY-MM-DD'
      ) !=
        this.state.originalAnketaData.d__personal__passport__issued_date.format(
          'YYYY-MM-DD'
        ) ||
      this.state.anketaData.d__personal__passport__department_code !=
        this.state.originalAnketaData.d__personal__passport__department_code
    ) {
      this.setState({
        files__passportShow: true,
        files__reg_placePassportShow: true,
      });
    } else {
      this.onRemoveFile('files__passport');
      this.setState({
        files__passportShow: false,
        files__reg_placePassportShow: false,
        anketaData: {
          ...this.state.anketaData,
          files__passport: '',
          files__reg_place:
            !this.state.files__reg_placeShow &&
            !this.state.files__reg_placePassportShow
              ? ''
              : this.state.anketaData.files__reg_place,
        },
      });
    }
  };

  checkChangeRegPlace = () => {
    if (
      this.state.anketaData.d__address__reg__postal_code !=
        this.state.originalAnketaData.d__address__reg__postal_code ||
      this.state.anketaData.d__address__reg__region !=
        this.state.originalAnketaData.d__address__reg__region ||
      this.state.anketaData.d__address__reg__area !=
        this.state.originalAnketaData.d__address__reg__area ||
      this.state.anketaData.d__address__reg__city !=
        this.state.originalAnketaData.d__address__reg__city ||
      this.state.anketaData.d__address__reg__street !=
        this.state.originalAnketaData.d__address__reg__street ||
      this.state.anketaData.d__address__reg__house !=
        this.state.originalAnketaData.d__address__reg__house ||
      this.state.anketaData.d__address__reg__block !=
        this.state.originalAnketaData.d__address__reg__block ||
      this.state.anketaData.d__address__reg__flat !=
        this.state.originalAnketaData.d__address__reg__flat ||
      this.state.anketaData.d__address__postal__postal_code !=
        this.state.originalAnketaData.d__address__postal__postal_code ||
      this.state.anketaData.d__address__postal__region !=
        this.state.originalAnketaData.d__address__postal__region ||
      this.state.anketaData.d__address__postal__area !=
        this.state.originalAnketaData.d__address__postal__area ||
      this.state.anketaData.d__address__postal__city !=
        this.state.originalAnketaData.d__address__postal__city ||
      this.state.anketaData.d__address__postal__street !=
        this.state.originalAnketaData.d__address__postal__street ||
      this.state.anketaData.d__address__postal__house !=
        this.state.originalAnketaData.d__address__postal__house ||
      this.state.anketaData.d__address__postal__block !=
        this.state.originalAnketaData.d__address__postal__block ||
      this.state.anketaData.d__address__postal__flat !=
        this.state.originalAnketaData.d__address__postal__flat ||
      this.state.anketaData.d__address__live__postal_code !=
        this.state.originalAnketaData.d__address__live__postal_code ||
      this.state.anketaData.d__address__live__region !=
        this.state.originalAnketaData.d__address__live__region ||
      this.state.anketaData.d__address__live__area !=
        this.state.originalAnketaData.d__address__live__area ||
      this.state.anketaData.d__address__live__city !=
        this.state.originalAnketaData.d__address__live__city ||
      this.state.anketaData.d__address__live__street !=
        this.state.originalAnketaData.d__address__live__street ||
      this.state.anketaData.d__address__live__house !=
        this.state.originalAnketaData.d__address__live__house ||
      this.state.anketaData.d__address__live__block !=
        this.state.originalAnketaData.d__address__live__block ||
      this.state.anketaData.d__address__live__flat !=
        this.state.originalAnketaData.d__address__live__flat
    ) {
      this.setState({ files__reg_placeShow: true });
    } else {
      this.onRemoveFile('files__reg_place');
      this.setState({
        files__reg_placeShow: false,
        anketaData: {
          ...this.state.anketaData,
          files__reg_place:
            !this.state.files__reg_placeShow &&
            !this.state.files__reg_placePassportShow
              ? ''
              : this.state.anketaData.files__reg_place,
        },
      });
    }
  };

  checkChangeInn = () => {
    if (
      this.state.anketaData.d__extra__inn !=
      this.state.originalAnketaData.d__extra__inn
    ) {
      this.setState({ files__innShow: true });
    } else {
      this.onRemoveFile('files__inn');
      this.setState({
        files__innShow: false,
        anketaData: {
          ...this.state.anketaData,
          files__inn: '',
        },
      });
    }
  };

  checkChangeSnils = () => {
    if (
      this.state.anketaData.d__extra__snils !=
      this.state.originalAnketaData.d__extra__snils
    ) {
      this.setState({ files__snilsShow: true });
    } else {
      this.onRemoveFile('files__snils');
      this.setState({
        files__snilsShow: false,
        files__snils: '',
      });
    }
  };

  onValueValidateCurrentChange = (name) => {
    this.setState({
      validateActiveFields: {
        ...this.state.validateActiveFields,
        [name]: false,
      },
    });
  };
  onValuePassportChange = (name, value) => {
    this.setState(
      () => ({ anketaData: { ...this.state.anketaData, [name]: value } }),
      () => {
        this.checkChangePassport();
      }
    );
  };
  onValueAddressChange = (name, value) => {
    this.setState(
      () => ({ anketaData: { ...this.state.anketaData, [name]: value } }),
      () => {
        this.checkChangeRegPlace();
      }
    );
  };
  onValueExtraChange = (name, value) => {
    this.setState(
      () => ({ anketaData: { ...this.state.anketaData, [name]: value } }),
      () => {
        if (name === 'd__extra__inn') this.checkChangeInn();
        if (name === 'd__extra__snils') this.checkChangeSnils();
      }
    );
  };

  onContractChange = (e) => {
    const contract_id = e.target.value;
    this.setState({ anketaData: { ...this.state.anketaData, contract_id } });
  };

  // События получения данных адреса
  onRegAddressChange = (data) => {
    if (this.state.isReadableAddress) {
      let tempData = this.state.anketaData;
      tempData.d__address__reg__postal_code =
        this.dotify(data.data.postal_code) || '';
      tempData.d__address__reg__region = this.dotify(data.data.region) || '';
      tempData.d__address__reg__area = this.dotify(data.data.area) || '';
      tempData.d__address__reg__city = this.dotify(data.data.city) || '';
      tempData.d__address__reg__street =
        this.dotify(data.data.street_with_type) || '';
      tempData.d__address__reg__house = this.dotify(data.data.house) || '';
      tempData.d__address__reg__block =
        data.data.block_type || data.data.block
          ? this.dotify(data.data.block_type + ' ' + data.data.block)
          : '';
      tempData.d__address__reg__flat = this.dotify(data.data.flat) || '';

      this.setState(
        {
          anketaData: tempData,
          regAddressFull: this.dotify(data.value),
        },
        () => {
          this.checkChangeRegPlace();
        }
      );
    }
  };
  onPostalAddressChange = (data) => {
    if (this.state.isReadableAddress) {
      let tempData = this.state.anketaData;
      tempData.d__address__postal__postal_code =
        this.dotify(data.data.postal_code) || '';
      tempData.d__address__postal__region = this.dotify(data.data.region) || '';
      tempData.d__address__postal__area = this.dotify(data.data.area) || '';
      tempData.d__address__postal__city = this.dotify(data.data.city) || '';
      tempData.d__address__postal__street =
        this.dotify(data.data.street_with_type) || '';
      tempData.d__address__postal__house = this.dotify(data.data.house) || '';
      tempData.d__address__postal__block =
        data.data.block_type || data.data.block
          ? this.dotify(data.data.block_type + ' ' + data.data.block)
          : '';
      tempData.d__address__postal__flat = this.dotify(data.data.flat) || '';

      this.setState(
        {
          anketaData: tempData,
          postalAddressFull: this.dotify(data.value),
        },
        () => {
          this.checkChangeRegPlace();
        }
      );
    }
  };
  onLiveAddressChange = (data) => {
    if (this.state.isReadableAddress) {
      let tempData = this.state.anketaData;
      tempData.d__address__live__postal_code =
        this.dotify(data.data.postal_code) || '';
      tempData.d__address__live__region = this.dotify(data.data.region) || '';
      tempData.d__address__live__area = this.dotify(data.data.area) || '';
      tempData.d__address__live__city = this.dotify(data.data.city) || '';
      tempData.d__address__live__street =
        this.dotify(data.data.street_with_type) || '';
      tempData.d__address__live__house = this.dotify(data.data.house) || '';
      tempData.d__address__live__block =
        data.data.block_type || data.data.block
          ? this.dotify(data.data.block_type + ' ' + data.data.block)
          : '';
      tempData.d__address__live__flat = this.dotify(data.data.flat) || '';

      this.setState(
        {
          anketaData: tempData,
          liveAddressFull: this.dotify(data.value),
        },
        () => {
          this.checkChangeRegPlace();
        }
      );
    }
  };
  onPostalSameReg = () => {
    if (!this.state.isSamePostal) {
      this.setState(
        {
          anketaData: {
            ...this.state.anketaData,
            d__address__postal__postal_code: this.state.anketaData
              .d__address__reg__postal_code,
            d__address__postal__region: this.state.anketaData
              .d__address__reg__region,
            d__address__postal__area: this.state.anketaData
              .d__address__reg__area,
            d__address__postal__city: this.state.anketaData
              .d__address__reg__city,
            d__address__postal__street: this.state.anketaData
              .d__address__reg__street,
            d__address__postal__house: this.state.anketaData
              .d__address__reg__house,
            d__address__postal__block: this.state.anketaData
              .d__address__reg__block,
            d__address__postal__flat: this.state.anketaData
              .d__address__reg__flat,
          },
          postalAddressFull: this.state.regAddressFull,
        },
        () => {
          this.checkChangeRegPlace();
        }
      );
    }
    this.setState({ isSamePostal: !this.state.isSamePostal });
  };
  onLiveSameReg = () => {
    if (!this.state.isSameLive) {
      this.setState(
        {
          anketaData: {
            ...this.state.anketaData,
            d__address__live__postal_code: this.state.anketaData
              .d__address__reg__postal_code,
            d__address__live__region: this.state.anketaData
              .d__address__reg__region,
            d__address__live__area: this.state.anketaData.d__address__reg__area,
            d__address__live__city: this.state.anketaData.d__address__reg__city,
            d__address__live__street: this.state.anketaData
              .d__address__reg__street,
            d__address__live__house: this.state.anketaData
              .d__address__reg__house,
            d__address__live__block: this.state.anketaData
              .d__address__reg__block,
            d__address__live__flat: this.state.anketaData.d__address__reg__flat,
          },
          liveAddressFull: this.state.regAddressFull,
        },
        () => {
          this.checkChangeRegPlace();
        }
      );
    }
    this.setState({ isSameLive: !this.state.isSameLive });
  };

  onHandAddressChange = () => {
    this.setState({ isHandEditing: !this.state.isHandEditing });
  };

  addressChange = (
    e,
    stateName,
    bound,
    locationTitle,
    location,
    clearedFields
  ) => {
    const address = e.target.value;
    let anketaObj = this.state.anketaData;
    anketaObj[stateName] = address;

    this.setState({ anketaObj });
    if (clearedFields && clearedFields.length > 0)
      this.clearAddressFields(clearedFields);
    // Таймер для ввода на ~300мс
    axios({
      method: 'post',
      url: `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
      data: {
        query: address,
        from_bound: { value: bound },
        to_bound: { value: bound },
        locations: [{ [locationTitle]: this.state[location] }],
        restrict_value: true,
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token 7e579cbc4450c2a92c1748e310c877ad0dfcc44c',
      },
    })
      .then((res) => {
        console.log(res);
        this.setState({ suggestions: res.data.suggestions });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  clearAddressFields = (fields) => {
    let obj = { anketaData: this.state.anketaData };
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].indexOf('d__') != -1) obj.anketaData[fields[i]] = '';
      else obj[fields[i]] = '';
    }
    this.setState(obj);
  };
  setAddressState = (stateValues) => {
    let obj = { anketaData: this.state.anketaData };

    for (var key in stateValues) {
      if (key.indexOf('d__') != -1) obj.anketaData[key] = stateValues[key];
      else obj[key] = stateValues[key];
    }
    this.setState(obj);
  };

  validateRs = (rs, bik) => {
    var result = false;

    if (typeof rs === 'number') {
      rs = rs.toString();
    } else if (typeof rs !== 'string') {
      rs = '';
    }
    if (!rs.length) {
      this.setState({ bankAcc_errorName: 'Р/С пуст' });
    } else if (/[^0-9]/.test(rs)) {
      this.setState({ bankAcc_errorName: 'Р/С может состоять только из цифр' });
    } else if (rs.length !== 20) {
      this.setState({
        bankAcc_errorName: 'Р/С может состоять только из 20 цифр',
      });
    } else {
      var bikRs = bik.toString().slice(-3) + rs;
      var checksum = 0;
      var coefficients = [
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
      ];
      for (var i in coefficients) {
        checksum += coefficients[i] * (bikRs[i] % 10);
      }
      if (checksum % 10 === 0) {
        this.setState({ bankAcc_errorName: '' });
        result = true;
      } else {
        this.setState({ bankAcc_errorName: 'Неверно указан р/с' });
      }
    }

    return result;
  };
  onBikChange = (e) => {
    const d__bank__bik = e.target.value;
    if (
      !d__bank__bik ||
      (d__bank__bik.match(/^[0-9]+$/) && d__bank__bik.length <= 9)
    ) {
      let tempData = this.state.anketaData;
      tempData.d__bank__bik = d__bank__bik;
      if (d__bank__bik.length === 9) {
        axios({
          method: 'get',
          url: `${Config.bikUrl}&bik=${d__bank__bik}`,
        })
          .then((res) => {
            if (res.data.error) {
              this.setState({
                errorName: 'Ошибка при получении данных с банка',
                bikError: 'Неверно указан БИК'
              });
            } else {
              this.setState({
                anketaData: {
                  ...tempData,
                  d__bank__name: res.data.name.replace(/&quot;/g, '"'),
                  d__bank__corr_acc: res.data.ks,
                },
                validateActiveFields: {
                  ...this.state.validateActiveFields,
                  d__bank__bik: false,
                },
                errorName: undefined,
                bikError: ''
              });
            }
          })
          .catch((err) => {
            this.setState({
              errorName: 'Ошибка при получении данных с банка',
              anketaData: tempData,
            });
          });
      } else {
        this.setState({
          anketaData: {
            ...tempData,
            d__bank__name: '',
            d__bank__corr_acc: '',
          },
          validateActiveFields: {
            ...this.state.validateActiveFields,
            d__bank__bik: false,
          },
        });
      }
    }
  };
  onAccChange = (e) => {
    const d__bank__acc = e.target.value;
    if (
      !d__bank__acc ||
      (d__bank__acc.match(/^[0-9]+$/) && d__bank__acc.length <= 20)
    ) {
      this.setState(() => ({
        anketaData: { ...this.state.anketaData, d__bank__acc },
        validateActiveFields: {
          ...this.state.validateActiveFields,
          d__bank__acc: false,
        },
      }));
    }
  };

  onPassportImgChange = (e) => {
    const files__passport = e.target.files[0];
    var formData = new FormData();
    formData.append('file', files__passport, files__passport.name);
    this.setState({
      anketaData: { ...this.state.anketaData, files__passport: '' },
      files__passportUploading: true,
    });

    this.onUploadFile(
      formData,
      files__passport.name,
      'files__passport',
      'files__passportUploading',
      'files__passportError',
      'files__passportName',
      'files__passportLink'
    );
  };
  onRegPlaceChange = (e) => {
    const files__reg_place = e.target.files[0];
    var formData = new FormData();
    formData.append('file', files__reg_place, files__reg_place.name);
    this.setState({
      anketaData: { ...this.state.anketaData, files__reg_place: '' },
      files__reg_placeUploading: true,
    });

    this.onUploadFile(
      formData,
      files__reg_place.name,
      'files__reg_place',
      'files__reg_placeUploading',
      'files__reg_placeError',
      'files__reg_placeName',
      'files__reg_placeLink'
    );
  };

  onInnImgChange = (e) => {
    const files__inn = e.target.files[0];
    var formData = new FormData();
    formData.append('file', files__inn, files__inn.name);
    this.setState({
      anketaData: { ...this.state.anketaData, files__inn: '' },
      files__innUploading: true,
    });

    this.onUploadFile(
      formData,
      files__inn.name,
      'files__inn',
      'files__innUploading',
      'files__innError',
      'files__innName',
      'files__innLink'
    );
  };

  onSnilsImgChange = (e) => {
    const files__snils = e.target.files[0];
    var formData = new FormData();
    formData.append('file', files__snils, files__snils.name);
    this.setState({
      anketaData: { ...this.state.anketaData, files__snils: '' },
      files__snilsUploading: true,
    });

    this.onUploadFile(
      formData,
      files__snils.name,
      'files__snils',
      'files__snilsUploading',
      'files__snilsError',
      'files__snilsName',
      'files__snilsLink'
    );
  };

  onUploadFile = (
    formData,
    fileName,
    file,
    uploading,
    erroring,
    naming,
    linkField
  ) => {
    uploadFile(formData)
      .then((res) => {
        this.setState({
          anketaData: { ...this.state.anketaData, [file]: res.data.file_id },
          [naming]: fileName,
          [linkField]: res.data.url,
          [uploading]: false,
          [erroring]: '',
        });
      })
      .catch((err) => {
        if (err.response.data && err.response.status != 401)
          this.setState({ [erroring]: err.response.data.message });
        catchPromise(
          err,
          this.onUploadFile.bind(
            this,
            formData,
            fileName,
            file,
            uploading,
            erroring,
            naming,
            linkField
          ),
          this.errorCb
        );
      });
  };

  onRemoveFile = (file) => {
    let tempAnketa = this.state.anketaData;
    tempAnketa[file] = '';
    this.setState({ anketaData: tempAnketa });
    if (document.getElementById(file + 'Field'))
      document.getElementById(file + 'Field').value = '';
  };

  setReadableBlock = (name) => {
    this.setState({ [name]: !this.state[name] }, () => {
      let tempAnketa = this.state.anketaData;

      if (name == 'isReadableProfile') {
        for (var key in tempAnketa) {
          if (key.indexOf('d__personal') != -1) {
            tempAnketa[key] = this.state.originalAnketaData[key];
          }
        }
        this.setState({
          files__passportShow: false,
          files__reg_placePassportShow: false,
        });
        tempAnketa['files__passport'] = '';
        if (document.getElementById('files__passportField'))
          document.getElementById('files__passportField').value = '';
        if (!this.state.files__reg_placeShow) {
          tempAnketa['files__reg_place'] = '';
          if (document.getElementById('files__reg_placeField'))
            document.getElementById('files__reg_placeField').value = '';
        }
      }
      if (name == 'isReadableAddress') {
        for (var key in tempAnketa) {
          if (key.indexOf('d__address') != -1) {
            tempAnketa[key] = this.state.originalAnketaData[key];
          }
        }
        this.setState({
          files__reg_placeShow: false,
          isSamePostal: false,
          isSameLive: false,
        });
        if (!this.state.files__passportShow) {
          tempAnketa['files__reg_place'] = '';
          if (document.getElementById('files__reg_placeField'))
            document.getElementById('files__reg_placeField').value = '';
        }
      }
      if (name == 'isReadableExtra') {
        for (var key in tempAnketa) {
          if (key.indexOf('d__extra') != -1 || key.indexOf('d__bank') != -1) {
            tempAnketa[key] = this.state.originalAnketaData[key];
          }
        }
        this.setState({ files__innShow: false, files__snilsShow: false });
        tempAnketa['files__inn'] = '';
        if (document.getElementById('files__innField'))
          document.getElementById('files__innField').value = '';
        tempAnketa['files__snils'] = '';
        if (document.getElementById('files__snilsField'))
          document.getElementById('files__snilsField').value = '';
      }
      this.setState({ anketaData: tempAnketa });
      if (
        !this.state.isReadableProfile &&
        !this.state.isReadableAddress &&
        !this.state.isReadableExtra
      )
        this.setState({ isLoadFiles: false });
    });
  };

  render() {
    return (
      <div className="docCreateAnketa">
        {!this.state.pageError ? (
          <div className="docCreateFieldContainer">
            {!this.state.isLoading ? (
              <div>
                {this.state.contracts.length > 0 && (
                  <div className="contractNameDocCancel">{`Договор №${this.state.contracts[0].number} от ${this.state.contracts[0].date}`}</div>
                )}
                <ChangeAnketaProfile
                  d__personal__last_name={
                    this.state.anketaData.d__personal__last_name
                  }
                  d__personal__first_name={
                    this.state.anketaData.d__personal__first_name
                  }
                  d__personal__middle_name={
                    this.state.anketaData.d__personal__middle_name
                  }
                  d__personal__birth_date={
                    this.state.anketaData.d__personal__birth_date
                  }
                  d__personal__birth_place={
                    this.state.anketaData.d__personal__birth_place
                  }
                  d__personal__sex={
                    this.state.anketaData.d__personal__sex
                  }
                  d__personal__passport__series={
                    this.state.anketaData.d__personal__passport__series
                  }
                  d__personal__passport__number={
                    this.state.anketaData.d__personal__passport__number
                  }
                  d__personal__passport__issued_date={
                    this.state.anketaData.d__personal__passport__issued_date
                  }
                  d__personal__passport__department_code={
                    this.state.anketaData.d__personal__passport__department_code
                  }
                  d__personal__passport__issued_by={
                    this.state.anketaData.d__personal__passport__issued_by
                  }
                  validate={this.state.validate}
                  validateActiveFields={this.state.validateActiveFields}
                  onValueValidateCurrentChange={
                    this.onValueValidateCurrentChange
                  }
                  isReadableProfile={this.state.isReadableProfile}
                  onValueChange={this.onValuePassportChange}
                  setReadableBlock={this.setReadableBlock}
                  selectDataMonthIsOpened={this.state.selectDataMonthIsOpened}
                  selectDataYearsIsOpened={this.state.selectDataYearsIsOpened}
                  changeSelectOpened={this.changeSelectOpened}
                />
                <ChangeAnketaAddress
                  validate={this.state.validate}
                  isReadableAddress={this.state.isReadableAddress}
                  isHandEditing={this.state.isHandEditing}
                  regAddressFull={this.state.regAddressFull}
                  postalAddressFull={this.state.postalAddressFull}
                  liveAddressFull={this.state.liveAddressFull}
                  isSamePostal={this.state.isSamePostal}
                  isSameLive={this.state.isSameLive}
                  cityRegIdValue={this.state.cityRegIdValue}
                  cityPostalIdValue={this.state.cityPostalIdValue}
                  cityLiveIdValue={this.state.cityLiveIdValue}
                  addressChange={this.addressChange}
                  suggestions={this.state.suggestions}
                  setAddressState={this.setAddressState}
                  clearAddressFields={this.clearAddressFields}
                  dotify={this.dotify}
                  d__address__reg__postal_code={
                    this.state.anketaData.d__address__reg__postal_code
                  }
                  d__address__reg__region={
                    this.state.anketaData.d__address__reg__region
                  }
                  d__address__reg__area={
                    this.state.anketaData.d__address__reg__area
                  }
                  d__address__reg__city={
                    this.state.anketaData.d__address__reg__city
                  }
                  d__address__reg__street={
                    this.state.anketaData.d__address__reg__street
                  }
                  d__address__reg__house={
                    this.state.anketaData.d__address__reg__house
                  }
                  d__address__reg__block={
                    this.state.anketaData.d__address__reg__block
                  }
                  d__address__reg__flat={
                    this.state.anketaData.d__address__reg__flat
                  }
                  d__address__postal__postal_code={
                    this.state.anketaData.d__address__postal__postal_code
                  }
                  d__address__postal__region={
                    this.state.anketaData.d__address__postal__region
                  }
                  d__address__postal__area={
                    this.state.anketaData.d__address__postal__area
                  }
                  d__address__postal__city={
                    this.state.anketaData.d__address__postal__city
                  }
                  d__address__postal__street={
                    this.state.anketaData.d__address__postal__street
                  }
                  d__address__postal__house={
                    this.state.anketaData.d__address__postal__house
                  }
                  d__address__postal__block={
                    this.state.anketaData.d__address__postal__block
                  }
                  d__address__postal__flat={
                    this.state.anketaData.d__address__postal__flat
                  }
                  d__address__live__postal_code={
                    this.state.anketaData.d__address__live__postal_code
                  }
                  d__address__live__region={
                    this.state.anketaData.d__address__live__region
                  }
                  d__address__live__area={
                    this.state.anketaData.d__address__live__area
                  }
                  d__address__live__city={
                    this.state.anketaData.d__address__live__city
                  }
                  d__address__live__street={
                    this.state.anketaData.d__address__live__street
                  }
                  d__address__live__house={
                    this.state.anketaData.d__address__live__house
                  }
                  d__address__live__block={
                    this.state.anketaData.d__address__live__block
                  }
                  d__address__live__flat={
                    this.state.anketaData.d__address__live__flat
                  }
                  onValueChange={this.onValueAddressChange}
                  onRegAddressChange={this.onRegAddressChange}
                  onPostalAddressChange={this.onPostalAddressChange}
                  onLiveAddressChange={this.onLiveAddressChange}
                  onPostalSameReg={this.onPostalSameReg}
                  onLiveSameReg={this.onLiveSameReg}
                  onHandAddressChange={this.onHandAddressChange}
                  setReadableBlock={this.setReadableBlock}
                />

                <ChangeAnketaExtra
                  d__extra__fl_type={this.state.anketaData.d__extra__fl_type}
                  d__extra__inn={this.state.anketaData.d__extra__inn}
                  d__extra__snils={this.state.anketaData.d__extra__snils}
                  d__extra__ogrnip={this.state.anketaData.d__extra__ogrnip}
                  d__extra__tax_system={
                    this.state.anketaData.d__extra__tax_system
                  }
                  validate={this.state.validate}
                  validateActiveFields={this.state.validateActiveFields}
                  onValueValidateCurrentChange={
                    this.onValueValidateCurrentChange
                  }
                  isReadableExtra={this.state.isReadableExtra}
                  onValueChange={this.onValueExtraChange}
                  setReadableBlock={this.setReadableBlock}
                />

                <div className="row">
                  <div className="col-md-6">
                    <div className="position-relative formField-container">
                      <label
                        className={`floating-label ${
                          this.state.anketaData.d__bank__bik == ''
                            ? 'hidden'
                            : ''
                        }`}
                      >
                        БИК
                      </label>
                      <input
                        type="text"
                        className={`inputUnderLine ${
                          this.state.validate &&
                          this.state.anketaData.d__bank__bik.length != 9 &&
                          this.state.validateActiveFields.d__bank__bik
                            ? 'inputValidateCurrentWrong'
                            : ''
                        } ${
                          this.state.validate &&
                          this.state.anketaData.d__bank__bik.length != 9
                            ? 'inputValidateWrong'
                            : ''
                        } ${
                          this.state.validate &&
                          this.state.anketaData.d__bank__bik.length == 9
                            ? 'inputValidateCorrect'
                            : ''
                        } ${!this.state.isReadableExtra ? "anketaReadOnlyFields" : ""}`}
                        placeholder="БИК"
                        readOnly={!this.state.isReadableExtra}
                        value={this.state.anketaData.d__bank__bik}
                        onChange={this.onBikChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="position-relative formField-container">
                      <label
                        className={`floating-label ${
                          this.state.anketaData.d__bank__name == ''
                            ? 'hidden'
                            : ''
                        }`}
                      >
                        Банк
                      </label>
                      <input
                        type="text"
                        className="inputUnderLine"
                        placeholder="Банк"
                        readOnly={true}
                        value={this.state.anketaData.d__bank__name}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="position-relative formField-container">
                      <label
                        className={`floating-label ${
                          this.state.anketaData.d__bank__acc == ''
                            ? 'hidden'
                            : ''
                        }`}
                      >
                        Р/С
                      </label>
                      <input
                        type="text"
                        className={`inputUnderLine ${
                          this.state.validate &&
                          this.state.bankAcc_errorName != '' &&
                          this.state.validateActiveFields.d__bank__acc
                            ? 'inputValidateCurrentWrong'
                            : ''
                        } ${
                          this.state.validate &&
                          this.state.bankAcc_errorName != ''
                            ? 'inputValidateWrong'
                            : ''
                        } ${
                          this.state.validate &&
                          this.state.bankAcc_errorName == ''
                            ? 'inputValidateCorrect'
                            : ''
                        } ${!this.state.isReadableExtra ? "anketaReadOnlyFields" : ""}`}
                        placeholder="Р/С"
                        readOnly={!this.state.isReadableExtra}
                        value={this.state.anketaData.d__bank__acc}
                        onChange={this.onAccChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="position-relative formField-container">
                      <label
                        className={`floating-label ${
                          this.state.anketaData.d__bank__corr_acc == ''
                            ? 'hidden'
                            : ''
                        }`}
                      >
                        Кор. счет
                      </label>
                      <input
                        type="text"
                        className="inputUnderLine"
                        placeholder="Кор. счет"
                        readOnly={true}
                        value={this.state.anketaData.d__bank__corr_acc}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  {!this.state.isLoadFiles &&
                    (this.state.files__passportShow ||
                      this.state.files__reg_placeShow ||
                      this.state.files__reg_placePassportShow ||
                      this.state.files__innShow ||
                      this.state.files__snilsShow) && (
                      <div className="col-12 text-right">
                        <button
                          type="button"
                          className="btn btnLink"
                          onClick={() => {
                            this.setState({ isLoadFiles: true });
                          }}
                        >
                          Загрузить необходимые файлы
                        </button>
                      </div>
                    )}
                  {this.state.isLoadFiles && (
                    <div className="col-12">
                      {this.state.files__passportShow && (
                        <div className="text-left">
                          <div className="uniform-uploader text-center profileDocs-uploaderBtn">
                            <input
                              type="file"
                              id="files__passportField"
                              accept=".png, .jpg, .pdf, .jpeg"
                              className="form-control-uniform-custom"
                              disabled={this.state.files__passportUploading}
                              onChange={(e) => this.onPassportImgChange(e)}
                            />
                            <span className="action btn btnLink">
                              Прикрепить скан паспорта
                            </span>
                          </div>
                          {this.state.files__passportUploading &&
                            this.state.anketaData.files__passport === '' && (
                              <span>
                                <i className="icon-spinner2 spinner ml-1"></i>
                              </span>
                            )}
                          {!this.state.files__passportUploading &&
                            this.state.anketaData.files__passport != '' && (
                              <span>
                                <i className="icon-checkmark2 text-success profileDocs-uploaderCheck"></i>
                              </span>
                            )}
                          {!this.state.files__passportUploading &&
                            this.state.anketaData.files__passport != '' && (
                              <span>
                                <a
                                  href={this.state.files__passportLink}
                                  target="_blank"
                                >
                                  {this.state.files__passportName}
                                </a>
                                <i
                                  className="icon-cross2 docRemoveFile"
                                  onClick={() => {
                                    this.onRemoveFile('files__passport');
                                  }}
                                ></i>
                              </span>
                            )}
                          {this.state.validate &&
                            this.state.anketaData.files__passport === '' && (
                              <label
                                id="position-error"
                                className="validation-invalid-label"
                              >
                                Обязательное поле.
                              </label>
                            )}
                          {this.state.files__passportError != '' && (
                            <label
                              id="position-error"
                              className="validation-invalid-label"
                            >
                              {this.state.files__passportError}
                            </label>
                          )}
                        </div>
                      )}
                      {(this.state.files__reg_placeShow ||
                        this.state.files__reg_placePassportShow) && (
                        <div className="text-left mt-2">
                          <div className="uniform-uploader text-center profileDocs-uploaderBtn">
                            <input
                              type="file"
                              id="files__reg_placeField"
                              accept=".png, .jpg, .pdf, .jpeg"
                              className="form-control-uniform-custom "
                              disabled={this.state.files__reg_placeUploading}
                              onChange={(e) => this.onRegPlaceChange(e)}
                            />
                            <span className="action btn btnLink">
                              Прикрепить скан прописки
                            </span>
                          </div>
                          {this.state.files__reg_placeUploading &&
                            this.state.anketaData.files__reg_place === '' && (
                              <span>
                                <i className="icon-spinner2 spinner ml-1"></i>
                              </span>
                            )}
                          {!this.state.files__reg_placeUploading &&
                            this.state.anketaData.files__reg_place != '' && (
                              <span>
                                <i className="icon-checkmark2 text-success profileDocs-uploaderCheck"></i>
                              </span>
                            )}
                          {!this.state.files__reg_placeUploading &&
                            this.state.anketaData.files__reg_place != '' && (
                              <span>
                                <a
                                  href={this.state.files__reg_placeLink}
                                  target="_blank"
                                >
                                  {this.state.files__reg_placeName}
                                </a>
                                <i
                                  className="icon-cross2 docRemoveFile"
                                  onClick={() => {
                                    this.onRemoveFile('files__reg_place');
                                  }}
                                ></i>
                              </span>
                            )}
                          {this.state.validate &&
                            this.state.anketaData.files__reg_place === '' && (
                              <label
                                id="position-error"
                                className="validation-invalid-label"
                              >
                                Обязательное поле.
                              </label>
                            )}
                          {this.state.files__reg_placeError != '' && (
                            <label
                              id="position-error"
                              className="validation-invalid-label"
                            >
                              {this.state.files__reg_placeError}
                            </label>
                          )}
                        </div>
                      )}
                      {this.state.files__innShow && (
                        <div className="text-left mt-2">
                          <div className="uniform-uploader text-center profileDocs-uploaderBtn">
                            <input
                              type="file"
                              id="files__innField"
                              accept=".png, .jpg, .pdf, .jpeg"
                              className="form-control-uniform-custom"
                              disabled={this.state.files__innUploading}
                              onChange={(e) => this.onInnImgChange(e)}
                            />
                            <span className="action btn btnLink">
                              Прикрепить скан ИНН
                            </span>
                          </div>
                          {this.state.files__innUploading &&
                            this.state.anketaData.files__inn === '' && (
                              <span>
                                <i className="icon-spinner2 spinner ml-1"></i>
                              </span>
                            )}
                          {!this.state.files__innUploading &&
                            this.state.anketaData.files__inn != '' && (
                              <span>
                                <i className="icon-checkmark2 text-success profileDocs-uploaderCheck"></i>
                              </span>
                            )}
                          {!this.state.files__innUploading &&
                            this.state.anketaData.files__inn != '' && (
                              <span>
                                <a
                                  href={this.state.files__innLink}
                                  target="_blank"
                                >
                                  {this.state.files__innName}
                                </a>
                                <i
                                  className="icon-cross2 docRemoveFile"
                                  onClick={() => {
                                    this.onRemoveFile('files__inn');
                                  }}
                                ></i>
                              </span>
                            )}
                          {this.state.validate &&
                            this.state.anketaData.files__inn === '' && (
                              <label
                                id="position-error"
                                className="validation-invalid-label"
                              >
                                Обязательное поле.
                              </label>
                            )}
                          {this.state.files__innError != '' && (
                            <label
                              id="position-error"
                              className="validation-invalid-label"
                            >
                              {this.state.files__innError}
                            </label>
                          )}
                        </div>
                      )}
                      {this.state.files__snilsShow && (
                        <div className="text-left mt-2">
                          <div className="uniform-uploader text-center profileDocs-uploaderBtn">
                            <input
                              type="file"
                              id="files__snilsField"
                              accept=".png, .jpg, .pdf, .jpeg"
                              className="form-control-uniform-custom"
                              disabled={this.state.files__snilsUploading}
                              onChange={(e) => this.onSnilsImgChange(e)}
                            />
                            <span className="action btn btnLink">
                              Прикрепить скан СНИЛС
                            </span>
                          </div>
                          {this.state.files__snilsUploading &&
                            this.state.anketaData.files__snils === '' && (
                              <span>
                                <i className="icon-spinner2 spinner ml-1"></i>
                              </span>
                            )}
                          {!this.state.files__snilsUploading &&
                            this.state.anketaData.files__snils != '' && (
                              <span>
                                <i className="icon-checkmark2 text-success profileDocs-uploaderCheck"></i>
                              </span>
                            )}
                          {!this.state.files__snilsUploading &&
                            this.state.anketaData.files__snils != '' && (
                              <span>
                                <a
                                  href={this.state.files__snilsLink}
                                  target="_blank"
                                >
                                  {this.state.files__snilsName}
                                </a>
                                <i
                                  className="icon-cross2 docRemoveFile"
                                  onClick={() => {
                                    this.onRemoveFile('files__snils');
                                  }}
                                ></i>
                              </span>
                            )}
                          {this.state.validate &&
                            this.state.anketaData.files__snils === '' && (
                              <label
                                id="position-error"
                                className="validation-invalid-label"
                              >
                                Обязательное поле.
                              </label>
                            )}
                          {this.state.files__snilsError != '' && (
                            <label
                              id="position-error"
                              className="validation-invalid-label"
                            >
                              {this.state.files__snilsError}
                            </label>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="text-center text-danger mt-2">
                  {this.state.bankAcc_errorName}
                  <span className="d-block">{this.state.bikError}</span>
                </div>
              </div>
            ) : (
              <div className="mt-1 text-center spinner-container isLoadingSpinner">
                <span>
                  <img
                    src="/images/ui/load-spinner.png"
                    className="spinner"
                    alt=""
                  />
                </span>
              </div>
            )}
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <Link to="/app/documents/moving" className="btn btn-white">
            Отменить
          </Link>
          <button
            type="button"
            className="btn btn-success"
            disabled={!this.state.isLoadFiles && this.props.isSending}
            onClick={this.onSubmit}
          >
            Создать
            {this.props.isSending && (
              <i className="icon-spinner2 uploaderSpinner spinner"></i>
            )}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    anketa: state.anketa,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAnketa: (anketa) => dispatch(setAnketa(anketa)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAnketaForm);
