import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-datepicker/dist/react-datepicker.css';
import Baron from 'react-baron/dist/es5';
import moment from 'moment';
import 'moment/locale/ru';

export class DocumentCreatePayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contracts: props.contracts || [],
      contract: props.contracts.length > 0 ? props.contracts[0].id : '',
      date_end: null,
      validate: false,
      error: undefined,
      pageError: false,
    };
  }

  componentDidMount() {
    this.loadContracts();
    $('body').on('click', () => {
      if ($('.customSelectListMonth').hasClass('isShowSelect'))
        setTimeout(() => {
          $('.customSelectListMonth').removeClass('isShowSelect');
        }, 50);
      if ($('.customSelectListYear').hasClass('isShowSelect'))
        setTimeout(() => {
          $('.customSelectListYear').removeClass('isShowSelect');
        }, 50);
    });
  }
  loadContracts = () => {};
  validating = () => {
    return this.state.contract != '' && this.state.d__bank__name != '';
  };

  errorCb = () => {
    this.setState({ pageError: true });
  };
  closePopup = () => {
    this.props.closePopup();
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ validate: true });
    if (this.validating()) {
      this.props.onSubmit({
        code: 'CANCEL_CONTRACT',
        contract_id: parseInt(this.state.contract),
        date_end: this.state.date_end.format('YYYY-MM-DD'),
      });
    }
  };

  onContractChange = (e) => {
    const contract = e.target.value;
    this.setState({ contract });
  };
  onDateEndChange = (date_end) => {
    this.setState(() => ({ date_end }));
  };
  changeSelect = (name, value) => {
    if (value) $('.' + name).addClass('isShowSelect');
    else $('.' + name).removeClass('isShowSelect');
  };
  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    let rows = [];
    for (let i = 0; i < 100; i++) {
      rows.push(i);
    }
    return (
      <div
        className="singleDatePickerSelect"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div className="customSelect customSelectSingleDatepicker">
          <div
            className="customSelectedItem"
            onClick={() => {
              this.changeSelect('customSelectListMonth', true);
            }}
          >
            {month.format('MMMM')}
          </div>
          {
            <ul className="customSelectList customSelectListMonth">
              <Baron>
                {moment.months().map((label, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      onMonthSelect(month, index);
                      this.changeSelect('customSelectListMonth', false);
                    }}
                  >
                    {label}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
        <div className="customSelect customSelectSingleDatepicker customSelectSingleDatepicker-year">
          <div
            className="customSelectedItem"
            onClick={() => {
              this.changeSelect('customSelectListYear', true);
            }}
          >
            {month.year()}
          </div>
          {
            <ul className="customSelectList customSelectListYear">
              <Baron>
                {rows.map((row) => (
                  <li
                    key={row}
                    onClick={() => {
                      onYearSelect(month, moment().year() - row);
                      this.changeSelect('customSelectListYear', false);
                    }}
                  >
                    {moment().year() - row}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {!this.state.pageError ? (
          <div className="docCreateFieldContainer">
            {this.state.contracts.length > 0 && (
              <div className="contractNameDocCancel">{`Договор №${this.state.contracts[0].number} от ${this.state.contracts[0].date}`}</div>
            )}

            <div
              className="dateUnderline"
              style={{
                position: 'static',
                width: '160px',
              }}
            >
              <SingleDatePicker
                date={this.state.date_end}
                onDateChange={this.onDateEndChange}
                placeholder="Дата расторжения"
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                numberOfMonths={1}
                isOutsideRange={() => false}
                hideKeyboardShortcutsPanel={true}
                renderMonthElement={this.renderMonthElement}
              />
            </div>

            {this.state.error && (
              <h6 className="col-12 text-center text-danger">
                {this.state.error}
              </h6>
            )}
            {this.state.validate && this.state.contract === '' ? (
              <label id="position-error" className="validation-invalid-label">
                Отсутствует договор.
              </label>
            ) : null}
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <Link to="/app/documents/moving" className="btn btn-white">
            Отменить
          </Link>
          <button
            className="btn btn-success"
            disabled={this.props.isSending}
            onClick={(e) => this.onSubmit(e)}
          >
            Создать
            {this.props.isSending && (
              <i className="icon-spinner2 uploaderSpinner spinner"></i>
            )}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(DocumentCreatePayment);
