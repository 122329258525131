import React, { Component } from 'react';
import Baron from 'react-baron/dist/es5';

import {currencyFormat} from '../utils/currencyUtil';


export default class TechOptions extends Component {
  render() {
    const {
      selectIsOpened,
      optionTimes,
      optionTime,
      boardName,
      toggleOptionsTime
     } = this.props;
    return (
        <div className="tech-options">
            <div className="card">
              <ul>
                <li className="tech-option">
                  <div className="tech-optionTitle">Время торгов по инструментам (основная сессия)</div>
                  {
                    optionTimes.board_work_times && optionTimes.board_work_times.length == 1 && (
                      <div className="tech-optionValue">{optionTime.from}-{optionTime.to}</div>
                    ) 
                  }
                  {
                    optionTimes.board_work_times && optionTimes.board_work_times.length > 1 && (
                      <div className="customSelect">
                        <div
                          className="customSelectedItem"
                          onClick={() => {
                            toggleOptionsTime(true)
                          }}
                        >                      
                          {optionTime.from}-{optionTime.to}
                          {
                            selectIsOpened ? <i className="toggle icon-arrow-up5"></i> : <i className="toggle icon-arrow-down5"></i>
                          }                      
                        </div>
                        {selectIsOpened && (
                          <ul className="customSelectList">
                            {
                              optionTimes.board_work_times.map(time => (
                                <li
                                  onClick={() => {
                                    toggleOptionsTime(false, time);
                                  }}
                                >
                                  {time.from}-{time.to}
                                </li>
                              ))
                            }
                          </ul>
                        )}
                      </div>
                    ) 
                  }
                </li>
                {
                  boardName && boardName.indexOf("Фьючерсы") != -1 && 
                  <li className="tech-option">
                    <div className="tech-optionTitle">Основной биржевой клиринг</div>
                    <div className="tech-optionValue">{optionTimes.main_clearing_time}</div>
                  </li>
                }
                {
                  boardName && boardName.indexOf("Фьючерсы") != -1 && 
                  <li className="tech-option">
                    <div className="tech-optionTitle">Биржевой промежуточный клиринг</div>
                    <div className="tech-optionValue">{optionTimes.middle_clearing_time}</div>
                  </li>
                }
                <li className="tech-option">
                  <div className="tech-optionTitle">Внутренний клиринг</div>
                  <div className="tech-optionValue">{optionTimes.inner_clearing_time}</div>
                </li>
                <li className="tech-option">
                  <div className="tech-optionTitle">Установка дневных просадок</div>
                  <div className="tech-optionValue">{optionTimes.day_drawdown_set}</div>
                </li>
                {
                  boardName && boardName.indexOf("Фьючерсы") != -1 && 
                  <li className="tech-option">
                    <div className="tech-optionTitle">Время вечерней сессии</div>
                    <div className="tech-optionValue">{optionTimes.evening_session}</div>
                  </li>
                }
                <li className="tech-option">
                  <div className="tech-optionTitle">Просадка</div>
                  <div className="tech-optionValue">{optionTimes.leverage}</div>
                </li>
                <li className="tech-option">
                  <div className="tech-optionTitle">Payout</div>
                  <div className="tech-optionValue">{optionTimes.payout * 100}%</div>
                </li>
                <li className="tech-option">
                  <div className="tech-optionTitle">Покупательская способность</div>
                  <div className="tech-optionValue">{currencyFormat(optionTimes.purchasing_power_rub)}</div>
                </li>
              </ul>
            </div>
        </div>
    );
  }
}
