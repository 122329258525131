import { SET_TRANSACTIONS } from '../actions/transactions';

// Expenses Reducer
const reducerDefaultState = {
  transactions: [],
  balance_start: '',
  balance_end: '',
  currency: '',
  page_size: 0,
};

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_TRANSACTIONS:
      return action.transactions;
    default:
      return state;
  }
};
