export const createTooltip = (e, text = '', duration = 2000, delay = 2000) => {
  const tooltipElem = "<div class='customTooltip'>" + text + '</div>';
  const target = e.target;

  if ($(target).parent().find('.customTooltip').length == 0) {
    $(target)
      .parent()
      .append(tooltipElem)
      .find('.customTooltip')
      .delay(delay)
      .fadeOut({
        duration,
        done: () => {
          $(target).parent().find('.customTooltip').remove();
        },
      });
  }
};
