import React, { useRef } from "react";

const formatDate = (date) => {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = date.getFullYear();

  return dd + "." + mm + "." + yyyy;
};
const TagMore = ({ selectedTags, tagsLeft, moreTagsIncludeSelected }) => {
  return (
    <li
      className={`masterClass-tag masterClass-tag-more ${
        moreTagsIncludeSelected ? "masterClass-tagInSearch" : ""
      }`}
    >
      Еще {tagsLeft.length}
      <ul className="moreTagsList">
        {tagsLeft.map((tag) => {
          return (
            <li
              key={tag.name + tag.id}
              className={`${
                selectedTags.findIndex(
                  (selectedTag) => selectedTag.id === tag.id
                ) !== -1
                  ? "masterClass-tagInSearch"
                  : ""
                  ? "masterClass-tagInSearch"
                  : ""
              }`}
            >
              {tag.name}
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export const MasterClass = ({ stream, selectedTags, category }) => {
  const tagsRef = useRef(null);
  let l = 0;
  let i = stream.tags ? stream.tags.length - 1 : 0;

  const eventStartDate = formatDate(new Date(parseInt(stream.event_start_at * 1000)));

  const moreTagsIncludeSelected = (leftTags, selectedTags) => {
    for (let i = 0; i < leftTags.length; i++) {
      if (
        selectedTags.findIndex(
          (selectedTag) => selectedTag.id === leftTags[i].id
        ) !== -1
      ) {
        return true;
      } else if (i === leftTags.length - 1) return false;
    }
  };

  return (
    <div
      className="masterClass"
      onClick={() => window.open(stream.url, "_blank")}
      style={category === "WEBINAR" ? { height: 100 } : {}}
    >
      <div className="masterClass-info">
        <div className="masterClass-text">
          <div className="masterClass-date">{eventStartDate}</div>
          {category === "WEBINAR" ? <React.Fragment>
            <div className="webinar-title">
              {stream.title}
            </div>
            <div className="webinar-host-name">
              {stream.host.first_name} {stream.host.last_name}
            </div>
          </React.Fragment> : <div className="masterClass-host-name">
            {stream.host.first_name} {stream.host.last_name}
          </div>}          
        </div>
        <div
          className="masterClass-avatar"
          style={{ backgroundImage: `url("${stream.host.avatar}")` }}
        ></div>
      </div>
      {category === "WEBINAR" ? null 
        : <ul className="masterClass-tags" ref={tagsRef}>
          {stream.tags.map((tag, index) => {
            l += tag.name.length * 5 + 28;
            if (l > 520) {
              if (i === stream.tags.length - 1) i = index;
              return null;
            }
            return (
              <li
                key={tag.id + tag.name}
                className={`${
                  tag.type === "NEW" ? "masterClass-tagNew" : ""
                } masterClass-tag ${
                  selectedTags.findIndex(
                    (selectedTag) => selectedTag.id === tag.id
                  ) !== -1
                    ? "masterClass-tagInSearch"
                    : ""
                }`}
              >
                {tag.name}
              </li>
            );
          })}
          {i < stream.tags.length - 1 ? (
            <TagMore
              selectedTags={selectedTags}
              moreTagsIncludeSelected={moreTagsIncludeSelected(
                stream.tags.slice(i),
                selectedTags
              )}
              tagsLeft={stream.tags.slice(i)}
            />
          ) : null}
        </ul>
      }
    </div>
  );
};

export default MasterClass;
