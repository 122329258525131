import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import amplitude from 'amplitude-js';

import { catchPromise } from '../utils/http';
import { getAppFiles } from '../utils/appFilesHttp';

export class AppFilesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      downloadingFiles: [],
      notDownloadingFiles: [],
      loaded: true,
      pageError: false,
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    amplitude.getInstance().logEvent('app_files_page.page_view');
  }
  componentWillMount() {
    this.loadFiles();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.files !== this.state.files) {
      this.setState({
        downloadingFiles: this.state.files.filter((file) => file.url_download),
        notDownloadingFiles: this.state.files.filter((file) => !file.url_download)
      })
    }
  }
  loadFiles = () => {
    this.setState({ loaded: true });
    getAppFiles()
      .then((res) => {
        let files = res.data.versions;
        for (let i = 0; i < files.length; i++) {
          files[i].isMore = false;
        }
        this.setState({
          files,
          downloadingFiles: files.filter((file) => file.url_download),
          notDownloadingFiles: files.filter((file) => !file.url_download),
          loaded: false
        }, () => {
          const files = this.state.files;
          for (let i = 0; i < files.length; i++) {
            if ($('.appFiles-description-' + i).height() < 120)
              files[i].isMore = true;
          }
          this.setState({
            files,
            downloadingFiles: files.filter((file) => file.url_download),
            notDownloadingFiles: files.filter((file) => !file.url_download)
          });
        });
      })
      .catch((err) => {
        this.setState({ loaded: false });
        catchPromise(err, this.loadFiles, this.errorCb);
      });
  };

  showMoreDesc = (index) => {
    let files = this.state.files;
    files[index].isMore = true;
    this.setState({ files });
  };

  render() {
    return (
      <div className="appFilesPage">
        <div className="hintBlock files card-content">
          <h4>Как начать работу</h4>
          <ul>
            <li>Загрузите актуальную версию терминала</li>
            <li>При входе в терминал введите адрес сервера, логин и пароль от личного кабинета</li>
          </ul>
          <a href="/app/profile">Посмотреть настройки для входа</a>
        </div>
        <h1 className="title card-content">Последние версии</h1>
        <div className="card-content">
          <div className="appFiles-list">
            {this.state.downloadingFiles.map((file, index) => (
              <div key={index} className="appFiles-listOne">
                <div className="appFiles-logo">
                  <div className="appFiles-logoContainer">
                    <div className="appFiles-logoBlock">
                      {this.props.settings.COLOR_LOGO_URL && (
                        <img src={this.props.settings.COLOR_LOGO_URL[0]} />
                      )}
                    </div>
                    <div className="appFiles-logoDownload text-center">
                      <a href={file.url_download}>Скачать</a>
                    </div>
                  </div>
                </div>
                <div className="appFiles-version">
                  <div className="appFiles-versionName">
                    {this.props.settings.TERMINAL_NAME_PREFIX || "easy_scalp_crypto_"}{file.version}
                  </div>
                  <div className="appFiles-versionDate">
                    {moment(file.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                  </div>
                </div>
                <div className="appFiles-descriptionContainer">
                  <div
                    className={`appFiles-description appFiles-description-${index} ${
                      file.isMore ? 'appFiles-descMore' : ''
                    }`}
                  >
                    {file.description.split('\n').map((item, key) => {
                      return (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                  </div>
                  {!file.isMore && (
                    <div className="appFiles-descriptionMore">
                      <span
                        onClick={() => {
                          this.showMoreDesc(index);
                        }}
                      >
                        Подробнее...
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        {this.state.notDownloadingFiles.length > 3 ? 
          <React.Fragment>
            <h2 className="title card-content">История версий</h2>
            <div className="card-content">
              <div className="appFiles-list">
                {this.state.notDownloadingFiles.map((file, index) => (
                  <div key={index} className="appFiles-listOne">
                    <div className="appFiles-logo">
                      <div className="appFiles-logoContainer">
                        <div className="appFiles-logoBlock">
                          {this.props.settings.COLOR_LOGO_URL && (
                              <img src={this.props.settings.COLOR_LOGO_URL[0]} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="appFiles-version">
                      <div className="appFiles-versionName">
                        {this.props.settings.TERMINAL_NAME_PREFIX || "easy_scalp_crypto_"}{file.version}
                      </div>
                      <div className="appFiles-versionDate">
                        {moment(file.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                      </div>
                    </div>
                    <div className="appFiles-descriptionContainer">
                      <div
                        className={`appFiles-description appFiles-description-${index + this.state.downloadingFiles.length} ${
                          file.isMore ? 'appFiles-descMore' : ''
                        }`}
                      >
                        {file.description.split('\n').map((item, key) => {
                          return (
                            <span key={key}>
                              {item}
                              <br />
                            </span>
                          );
                        })}
                      </div>
                      {!file.isMore && (
                        <div className="appFiles-descriptionMore">
                          <span
                            onClick={() => {
                              this.showMoreDesc(index);
                            }}
                          >
                            Подробнее...
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment> 
        : null}
        {/* {
                !this.state.pageError ? (
                    <div>
                        <News 
                            news={this.state.news} 
                            page_num={this.state.page_num}
                            page_total={this.state.page_total}
                            handlePageChange={this.handlePageChange}
                        />
                        {
                            (this.state.loaded) &&
                                <div className="mt-2 mb-2 text-center spinner-container"><span><img src="/images/ui/load-spinner.png" className="spinner" alt="" /></span></div>
                        }
                    </div>
                ) : (
                    <h3 className="text-center text-danger">Произошла ошибка</h3>
                )
            } */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};

export default connect(mapStateToProps)(AppFilesPage);
