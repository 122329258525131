import { SET_BROADCASTS } from '../actions/broadcasts';

// Expenses Reducer
const reducerDefaultState = [];

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_BROADCASTS:
      return action.broadcasts;
    default:
      return state;
  }
};
