import React from "react";

import { currencyFormat } from "../utils/currencyUtil";

const LiveDataOrder = ({ order, currency, cancelOrder, isMobile }) =>
  !isMobile ? (
    <tr>
      <td>{order.time}</td>
      <td>
        {currencyFormat(
          order.price,
          "",
          parseInt(
            order.price.toString().split(".")[1]
              ? parseInt(order.price.toString().split(".")[1].length)
              : 0
          )
        )}
      </td>
      <td>{order.quantity}</td>
      <td>{order.type === "limit" ? "Лимит" : "Стоп"}</td>
      <td className="closeOrder-icon">
        <i
          className={`icon-cross3 text-danger dashboard-instCloseBtn ${
            order.isBlockedClosing ? "disabledCloseBtn" : ""
          }`}
          onClick={() => {
            if (!order.isBlockedClosing) cancelOrder(order);
          }}
        ></i>
      </td>
    </tr>
  ) : (
    <tr>
      <td>{order.time}</td>
      <td>
        {currencyFormat(
          order.price,
          "",
          parseInt(
            order.price.toString().split(".")[1]
              ? parseInt(order.price.toString().split(".")[1].length)
              : 0
          )
        )}
      </td>
      <td>{order.quantity}</td>
      <td>{order.type === "limit" ? "Лимит" : "Стоп"}</td>
      <td
        className="closeOrder-icon"
        onClick={() => {
          if (!order.isBlockedClosing) cancelOrder(order);
        }}
      >
        <img src="/images/ui/dashboard-close-icon.svg" />
      </td>
    </tr>
  );

export default LiveDataOrder;
