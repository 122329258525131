import React, { useState } from "react"

export const CryptoFinresTableTitle = ({ index, className, children, infoModalContent }) => {
    const [modalIsOpened, setModalIsOpened] = useState(false);

    return (
        <td className={className}
            onMouseEnter={() =>
                setModalIsOpened(true)
            }
            onMouseLeave={() =>
                setModalIsOpened(false)
            }
        >
            {children}
            {modalIsOpened && infoModalContent && (
                <div className="infoModal tableTitle">
                    {infoModalContent}
                </div>
            )}
        </td>
    )
}