import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Route } from 'react-router-dom';

import BroadcastsSchedulePage from './BroadcastsSchedulePage';
import BroadcastsCoursesPage from './BroadcastsCoursesPage';
import { getUsersUpdates } from '../utils/broadcastsHttp';
import { BroadcastsFilterStreams } from './BroadcastsFilterStreams/BroadcastsFilterStreams';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

export class BroadcastsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            streams_count: {},
            headerHeight: "h-128"
        };
        this.filterDropdowns = React.createRef(null);
    }

    componentDidMount() {
        getUsersUpdates().then(res => {
            this.setState({ streams_count: res.data.streams_count })
        })
    }

    render() {
        return (
            <div className="broadcasts">
                <div style={{ display: "flex", justifyContent: "center", backgroundColor: "#fff", width: "100%", position: "relative" }}>
                    <div className="broadcasts-navLinksContainerBorder"></div>
                    <div className="broadcasts-navLinksContainer">
                        <div className="broadcasts-navLinks">
                            <NavLink
                                to="/app/broadcasts/schedule"
                                className="nav-link"
                                activeClassName="active"
                            >
                                <div className="sidebar-title">Расписание</div>
                            </NavLink>
                            <NavLink
                                to="/app/broadcasts/courses"
                                className="nav-link"
                                activeClassName="active"
                            >
                                <div className="sidebar-title">
                                    <span>Курсы</span>
                                {this.state.streams_count["COURSE"] ?
                                        <div className="sidebar-newItems">{this.state.streams_count["COURSE"]}</div>
                                        : null}
                                </div>
                            </NavLink>
                            <NavLink
                                to="/app/broadcasts/master-classes"
                                className="nav-link"
                                activeClassName="active"
                            >
                                <div className="sidebar-title">
                                    <span>Мастер-классы</span>
                                {this.state.streams_count["MASTERCLASS"] ?
                                        <div className="sidebar-newItems">{this.state.streams_count["MASTERCLASS"]}</div>
                                        : null}
                                </div>
                            </NavLink>
                            <NavLink
                                to="/app/broadcasts/analyzes"
                                className="nav-link"
                                activeClassName="active"
                            >
                                <div className="sidebar-title">
                                    <span>Разборы</span>
                                {this.state.streams_count["ANALYSIS"] ?
                                        <div className="sidebar-newItems">{this.state.streams_count["ANALYSIS"]}</div>
                                        : null}
                                </div>
                            </NavLink>
                            <NavLink
                                to="/app/broadcasts/webinars"
                                className="nav-link"
                                activeClassName="active"
                            >
                                <div className="sidebar-title">
                                    <span>Вебинары</span>
                                {this.state.streams_count["WEBINAR"] ?
                                        <div className="sidebar-newItems">{this.state.streams_count["WEBINAR"]}</div>
                                        : null}
                                </div>
                            </NavLink>
                        </div>
                        {location.href.includes("schedule") || location.href.includes("courses") || location.href.includes("webinars")
                            ? null
                            : <div className="broadcasts-navLinksFilter" onClick={() => {
                                if (this.filterDropdowns.current.classList.contains("closed")) {
                                    this.setState({ headerHeight: "h-128" });
                                } else {
                                    this.setState({ headerHeight: "h-68" });
                                }
                                if (this.filterDropdowns.current) {
                                    this.filterDropdowns.current.style.zIndex = -1;
                                    this.filterDropdowns.current.parentNode.style.zIndex = -1;
                                    this.filterDropdowns.current.classList.toggle("closed");
                                    this.filterDropdowns.current.ontransitionend = () => {
                                        this.filterDropdowns.current.style.zIndex = null;
                                        this.filterDropdowns.current.parentNode.style.zIndex = null;
                                    };
                                }
                            }}>
                                <div className="broadcast-filter">
                                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.5 1.25H1.5L7.5 8.345V13.25L10.5 14.75V8.345L16.5 1.25Z" stroke="#8E99AC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div style={{ width: "100%" }}>
                    <Route
                        path={'/app/broadcasts/schedule'}
                        exact={true}
                        component={BroadcastsSchedulePage}
                    />
                    <Route
                        path={'/app/broadcasts/courses'}
                        exact={true}
                        component={BroadcastsCoursesPage}
                    />
                    <Route
                        path={'/app/broadcasts/master-classes'}
                        exact={true}
                        render={() => <BroadcastsFilterStreams category={"MASTERCLASS"} filterDropdowns={this.filterDropdowns} headerHeight={this.state.headerHeight} />}
                    />
                    <Route
                        path={'/app/broadcasts/analyzes'}
                        exact={true}
                        render={() => <BroadcastsFilterStreams category={"ANALYSIS"} filterDropdowns={this.filterDropdowns} headerHeight={this.state.headerHeight} />}
                    />
                    <Route
                        path={'/app/broadcasts/webinars'}
                        exact={true}
                        render={() => <BroadcastsFilterStreams category={"WEBINAR"} filterDropdowns={this.filterDropdowns} headerHeight={this.state.headerHeight} />}
                    />
                </div>
            </div>
        );
    }
}

export default compose(
    withRouter,
    connect()
)(BroadcastsPage);
