import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import Baron from 'react-baron/dist/es5';
import moment from 'moment';
import 'moment/locale/ru';

import { catchPromise } from '../../utils/http';
import { uploadFile } from '../../utils/documentsHttp';

export class ChangeTaxpayerEnterpreneur extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      d__bank__bik: '',
      d__bank__name: '',
      d__bank__corr_acc: '',
      d__bank__acc: '',
      d__extra__ogrnip: '',
      date_started: null,
      contracts: props.contracts || [],
      contract: props.contracts.length > 0 ? props.contracts[0].id : '',

      files__ogrnip: '',
      files__ogrnipName: '',
      files__ogrnipLink: '',
      files__ogrnipError: '',
      files__ogrnipUploading: false,

      files__tax_system: '',
      files__tax_systemName: '',
      files__tax_systemLink: '',
      files__tax_systemError: '',
      files__tax_systemUploading: false,

      bankAcc_errorName: '',
      validate: false,
      validateActiveFields: {
        d__extra__ogrnip: false,
        d__bank__bik: false,
        d__bank__acc: false,
      },
      error: undefined,
      pageError: false,
    };
  }

  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    $('body').on('click', () => {
      if ($('.customSelectListMonth').hasClass('isShowSelect'))
        setTimeout(() => {
          $('.customSelectListMonth').removeClass('isShowSelect');
        }, 50);
      if ($('.customSelectListYear').hasClass('isShowSelect'))
        setTimeout(() => {
          $('.customSelectListYear').removeClass('isShowSelect');
        }, 50);
    });
  }
  closePopup = () => {
    this.props.closePopup();
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ validate: true });
    this.setState({
      validateActiveFields: {
        d__extra__ogrnip: true,
        d__bank__bik: true,
        d__bank__acc: true,
      },
    });
    if (this.validating()) {
      if (
        !this.state.d__bank__bik ||
        (this.state.d__bank__bik.match(/^[0-9]+$/) &&
          this.state.d__bank__bik.length <= 9)
      ) {
        axios({
          method: 'get',
          url: `https://bik-info.ru/api.html?type=json&bik=${this.state.d__bank__bik}`,
        })
          .then((res) => {
            console.log(res);
            if (res.data.error) {
              this.setState({
                errorName: 'Ошибка при получении данных с банка',
              });
            } else {
              if (
                this.validateRs(
                  this.state.d__bank__acc,
                  this.state.d__bank__bik
                )
              ) {
                this.setState({
                  d__bank__name: res.data.name.replace(/&quot;/g, '"'),
                  d__bank__corr_acc: res.data.ks,
                  errorName: undefined,
                });
                this.props.onSubmit({
                  code: 'CHANGE_FL_TYPE_TO_IP',
                  contract_id: this.state.contract,
                  ogrnip: this.state.d__extra__ogrnip,
                  date_started: this.state.date_started.format('YYYY-MM-DD'),
                  bank: {
                    bik: this.state.d__bank__bik,
                    corr_acc: res.data.ks,
                    name: res.data.name.replace(/&quot;/g, '"'),
                    acc: this.state.d__bank__acc,
                  },
                  files__ogrnip: this.state.files__ogrnip,
                  files__tax_system: this.state.files__tax_system,
                });
              }
            }
          })
          .catch((err) => {
            this.setState({ errorName: 'Ошибка при получении данных с банка' });
          });
      }
    }
  };
  validating = () => {
    if (this.validateRs(this.state.d__bank__acc, this.state.d__bank__bik)) {
      return (
        this.state.d__bank__bik.length == 9 &&
        this.state.d__bank__name != '' &&
        this.state.d__bank__corr_acc != '' &&
        this.state.d__extra__ogrnip.length == 15 &&
        this.state.contract != '' &&
        this.state.date_started != null &&
        this.state.files__ogrnip != '' &&
        this.state.files__tax_system != ''
      );
    } else return false;
  };
  validateRs = (rs, bik) => {
    var result = false;

    if (typeof rs === 'number') {
      rs = rs.toString();
    } else if (typeof rs !== 'string') {
      rs = '';
    }
    if (!rs.length) {
      this.setState({ bankAcc_errorName: 'Р/С пуст' });
    } else if (/[^0-9]/.test(rs)) {
      this.setState({ bankAcc_errorName: 'Р/С может состоять только из цифр' });
    } else if (rs.length !== 20) {
      this.setState({
        bankAcc_errorName: 'Р/С может состоять только из 20 цифр',
      });
    } else {
      var bikRs = bik.toString().slice(-3) + rs;
      var checksum = 0;
      var coefficients = [
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
      ];
      for (var i in coefficients) {
        checksum += coefficients[i] * (bikRs[i] % 10);
      }
      if (checksum % 10 === 0) {
        this.setState({ bankAcc_errorName: '' });
        result = true;
      } else {
        this.setState({ bankAcc_errorName: 'Неверно указан р/с' });
      }
    }

    return result;
  };
  onBikChange = (e) => {
    const d__bank__bik = e.target.value;
    if (
      !d__bank__bik ||
      (d__bank__bik.match(/^[0-9]+$/) && d__bank__bik.length <= 9)
    ) {
      this.setState({
        d__bank__bik,
        validateActiveFields: {
          ...this.state.validateActiveFields,
          d__bank__bik: false,
        },
      });
      if (d__bank__bik.length === 9) {
        axios({
          method: 'get',
          url: `https://bik-info.ru/api.html?type=json&bik=${d__bank__bik}`,
        })
          .then((res) => {
            console.log(res);
            if (res.data.error) {
              this.setState({
                errorName: 'Ошибка при получении данных с банка',
              });
            } else {
              this.setState({
                d__bank__name: res.data.name.replace(/&quot;/g, '"'),
                d__bank__corr_acc: res.data.ks,
                errorName: undefined,
              });
            }
          })
          .catch((err) => {
            this.setState({ errorName: 'Ошибка при получении данных с банка' });
          });
      } else {
        this.setState({ d__bank__name: '', d__bank__corr_acc: '' });
      }
    }
  };
  onAccChange = (e) => {
    const d__bank__acc = e.target.value;
    if (
      !d__bank__acc ||
      (d__bank__acc.match(/^[0-9]+$/) && d__bank__acc.length <= 20)
    ) {
      this.setState(() => ({
        d__bank__acc,
        validateActiveFields: {
          ...this.state.validateActiveFields,
          d__bank__acc: false,
        },
      }));
    }
  };
  onOgrnipChange = (e) => {
    const d__extra__ogrnip = e.target.value;
    if (
      !d__extra__ogrnip ||
      (d__extra__ogrnip.match(/^[0-9]+$/) && d__extra__ogrnip.length <= 15)
    ) {
      this.setState(() => ({
        d__extra__ogrnip,
        validateActiveFields: {
          ...this.state.validateActiveFields,
          d__extra__ogrnip: false,
        },
      }));
    }
  };
  onDateStartedChange = (date_started) => {
    this.setState(() => ({ date_started }));
  };
  onContractChange = (e) => {
    const contract = e.target.value;
    this.setState({ contract });
  };
  onOgrnipImgChange = (e) => {
    const files__ogrnip = e.target.files[0];
    var formData = new FormData();
    formData.append('file', files__ogrnip, files__ogrnip.name);
    this.setState({ files__ogrnip: '', files__ogrnipUploading: true });

    this.onUploadFile(
      formData,
      files__ogrnip.name,
      'files__ogrnip',
      'files__ogrnipUploading',
      'files__ogrnipError',
      'files__ogrnipName',
      'files__ogrnipLink'
    );
  };
  onTaxSystemImgChange = (e) => {
    const files__tax_system = e.target.files[0];
    var formData = new FormData();
    formData.append('file', files__tax_system, files__tax_system.name);
    this.setState({ files__tax_system: '', files__tax_systemUploading: true });

    this.onUploadFile(
      formData,
      files__tax_system.name,
      'files__tax_system',
      'files__tax_systemUploading',
      'files__tax_systemError',
      'files__tax_systemName',
      'files__tax_systemLink'
    );
  };

  onUploadFile = (
    formData,
    fileName,
    file,
    uploading,
    erroring,
    naming,
    linkField
  ) => {
    uploadFile(formData)
      .then((res) => {
        this.setState({
          [file]: res.data.file_id,
          [naming]: fileName,
          [linkField]: res.data.url,
          [uploading]: false,
          [erroring]: '',
        });
      })
      .catch((err) => {
        if (err.response.data && err.response.status != 401)
          this.setState({ [erroring]: err.response.data.message });
        catchPromise(
          err,
          this.onUploadFile.bind(
            this,
            formData,
            fileName,
            file,
            uploading,
            erroring,
            naming,
            linkField
          ),
          this.errorCb
        );
      });
  };

  onRemoveFile = (file) => {
    this.setState({ [file]: '' });
    document.getElementById(file + 'Field').value = '';
  };
  changeSelect = (name, value) => {
    if (value) $('.' + name).addClass('isShowSelect');
    else $('.' + name).removeClass('isShowSelect');
  };
  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    let rows = [];
    for (let i = 0; i < 100; i++) {
      rows.push(i);
    }
    return (
      <div
        className="singleDatePickerSelect"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div className="customSelect customSelectSingleDatepicker">
          <div
            className="customSelectedItem"
            onClick={() => {
              this.changeSelect('customSelectListMonth', true);
            }}
          >
            {month.format('MMMM')}
          </div>
          {
            <ul className="customSelectList customSelectListMonth">
              <Baron>
                {moment.months().map((label, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      onMonthSelect(month, index);
                      this.changeSelect('customSelectListMonth', false);
                    }}
                  >
                    {label}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
        <div className="customSelect customSelectSingleDatepicker customSelectSingleDatepicker-year">
          <div
            className="customSelectedItem"
            onClick={() => {
              this.changeSelect('customSelectListYear', true);
            }}
          >
            {month.year()}
          </div>
          {
            <ul className="customSelectList customSelectListYear">
              <Baron>
                {rows.map((row) => (
                  <li
                    key={row}
                    onClick={() => {
                      onYearSelect(month, moment().year() - row);
                      this.changeSelect('customSelectListYear', false);
                    }}
                  >
                    {moment().year() - row}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {!this.state.pageError ? (
          <div className="docCreateTaxpayer">
            {this.state.contracts.length > 0 && (
              <div className="contractNameDocCancel">{`Договор №${this.state.contracts[0].number} от ${this.state.contracts[0].date}`}</div>
            )}
            <div className="docCreateTaxpayerContent">
              <div className="docCreateTaxpayerContent-fields">
                <div className="position-relative">
                  <label
                    className={`floating-label ${
                      this.state.d__extra__ogrnip == '' ? 'hidden' : ''
                    }`}
                  >
                    ОГРНИП
                  </label>
                  <input
                    className={`inputUnderLine regPhoneField ${
                      this.state.validate &&
                      this.state.d__extra__ogrnip.length != 15 &&
                      this.state.validateActiveFields.d__extra__ogrnip
                        ? 'inputValidateCurrentWrong'
                        : ''
                    } ${
                      this.state.validate &&
                      this.state.d__extra__ogrnip.length != 15
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate &&
                      this.state.d__extra__ogrnip.length == 15
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    placeholder="ОГРНИП"
                    value={this.state.d__extra__ogrnip}
                    onChange={this.onOgrnipChange}
                  />
                </div>
                <div className="position-relative">
                  <label
                    className={`floating-label ${
                      this.state.d__bank__bik == '' ? 'hidden' : ''
                    }`}
                  >
                    БИК
                  </label>
                  <input
                    type="text"
                    className={`inputUnderLine  ${
                      this.state.validate &&
                      this.state.d__bank__bik.length != 9 &&
                      this.state.validateActiveFields.d__bank__bik
                        ? 'inputValidateCurrentWrong'
                        : ''
                    } ${
                      this.state.validate && this.state.d__bank__bik.length != 9
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate && this.state.d__bank__bik.length == 9
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    placeholder="БИК"
                    value={this.state.d__bank__bik}
                    onChange={this.onBikChange}
                  />
                </div>
                <div className="position-relative">
                  <label
                    className={`floating-label ${
                      this.state.d__bank__acc == '' ? 'hidden' : ''
                    }`}
                  >
                    Р/С
                  </label>
                  <input
                    type="text"
                    className={`inputUnderLine ${
                      this.state.validate &&
                      this.state.bankAcc_errorName != '' &&
                      this.state.validateActiveFields.d__bank__acc
                        ? 'inputValidateCurrentWrong'
                        : ''
                    } ${
                      this.state.validate && this.state.bankAcc_errorName != ''
                        ? 'inputValidateWrong'
                        : ''
                    } ${
                      this.state.validate && this.state.bankAcc_errorName == ''
                        ? 'inputValidateCorrect'
                        : ''
                    }`}
                    placeholder="Р/С"
                    value={this.state.d__bank__acc}
                    onChange={this.onAccChange}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="inputUnderLine"
                    readOnly={true}
                    placeholder="Банк"
                    value={this.state.d__bank__name}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="inputUnderLine"
                    readOnly={true}
                    placeholder="Кор. счет"
                    value={this.state.d__bank__corr_acc}
                  />
                </div>
                <div className="dateUnderline">
                  <SingleDatePicker
                    date={this.state.date_started}
                    onDateChange={this.onDateStartedChange}
                    placeholder="Дата начала"
                    focused={this.state.focused}
                    onFocusChange={({ focused }) => this.setState({ focused })}
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    hideKeyboardShortcutsPanel={true}
                    renderMonthElement={this.renderMonthElement}
                  />
                  {this.state.validate && this.state.date_started === null ? (
                    <label
                      id="position-error"
                      className="validation-invalid-label"
                    >
                      Обязательное поле.
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="docCreateTaxpayerContent-uploads">
                <div className="docCreateTaxpayerContent-uploadOgrnip">
                  <div className="docCreateTaxpayerContent-uploadsTitle">
                    Скан ОГРНИП
                  </div>
                  <div>
                    <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                      <input
                        type="file"
                        id="files__ogrnipField"
                        className="form-control-uniform-custom"
                        accept=".png, .jpg, .pdf, .jpeg"
                        disabled={this.state.files__ogrnip}
                        onChange={this.onOgrnipImgChange}
                      />
                      {!this.state.files__ogrnipUploading &&
                        this.state.files__ogrnip == '' && (
                          <span>
                            Загрузить
                            <img
                              src="/images/ui/uploadIcon.png"
                              className="uploadIcon"
                              alt=""
                            />
                          </span>
                        )}
                      {this.state.files__ogrnipUploading &&
                        this.state.files__ogrnip === '' && (
                          <span>
                            Загрузить
                            <i className="icon-spinner2 uploaderSpinner spinner"></i>
                          </span>
                        )}
                      {!this.state.files__ogrnipUploading &&
                        this.state.files__ogrnip != '' && (
                          <span>
                            Загружено
                            <i className="icon-checkmark3 uploaderCheck"></i>
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="docCreateTaxpayerContent-uploadsFile">
                    {!this.state.files__ogrnipUploading &&
                      this.state.files__ogrnip != '' && (
                        <span>
                          <a href={this.state.files__ogrnip} target="_blank">
                            {this.state.files__ogrnipName}
                          </a>
                          <i
                            className="icon-cross2 docRemoveFile"
                            onClick={() => {
                              this.onRemoveFile('files__ogrnip');
                            }}
                          ></i>
                        </span>
                      )}
                    {this.state.validate && this.state.files__ogrnip === '' ? (
                      <label
                        id="position-error"
                        className="validation-invalid-label"
                      >
                        Обязательное поле.
                      </label>
                    ) : null}
                    {this.state.files__ogrnipError != '' && (
                      <label
                        id="position-error"
                        className="validation-invalid-label"
                      >
                        {this.state.files__ogrnipError}
                      </label>
                    )}
                  </div>
                </div>
                <div>
                  <div className="docCreateTaxpayerContent-uploadsTitle">
                    скан формы налогооблажения
                  </div>
                  <div>
                    <div className="uniform-uploader text-center profileDocs-uploaderBtn btnUpload">
                      <input
                        type="file"
                        id="files__tax_system"
                        className="form-control-uniform-custom"
                        accept=".png, .jpg, .pdf, .jpeg"
                        disabled={this.state.files__tax_system}
                        onChange={this.onTaxSystemImgChange}
                      />
                      {!this.state.files__tax_systemUploading &&
                        this.state.files__tax_system == '' && (
                          <span>
                            Загрузить
                            <img
                              src="/images/ui/uploadIcon.png"
                              className="uploadIcon"
                              alt=""
                            />
                          </span>
                        )}
                      {this.state.files__tax_systemUploading &&
                        this.state.files__tax_system === '' && (
                          <span>
                            Загрузить
                            <i className="icon-spinner2 uploaderSpinner spinner"></i>
                          </span>
                        )}
                      {!this.state.files__tax_systemUploading &&
                        this.state.files__tax_system != '' && (
                          <span>
                            Загружено
                            <i className="icon-checkmark3 uploaderCheck"></i>
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="docCreateTaxpayerContent-uploadsFile">
                    {!this.state.files__tax_systemUploading &&
                      this.state.files__tax_system != '' && (
                        <span>
                          <a
                            href={this.state.files__tax_system}
                            target="_blank"
                          >
                            {this.state.files__tax_systemName}
                          </a>
                          <i
                            className="icon-cross2 docRemoveFile"
                            onClick={() => {
                              this.onRemoveFile('files__tax_system');
                            }}
                          ></i>
                        </span>
                      )}
                    {this.state.validate &&
                    this.state.files__tax_system === '' ? (
                      <label
                        id="position-error"
                        className="validation-invalid-label"
                      >
                        Обязательное поле.
                      </label>
                    ) : null}
                    {this.state.files__tax_systemError != '' && (
                      <label
                        id="position-error"
                        className="validation-invalid-label"
                      >
                        {this.state.files__tax_systemError}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {this.state.error && (
              <h6 className="col-12 text-center text-danger">
                {this.state.error}
              </h6>
            )}
            {this.state.validate && this.state.contract === '' ? (
              <label id="position-error" className="validation-invalid-label">
                Отсутствует договор.
              </label>
            ) : null}
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <Link to="/app/documents/moving" className="btn btn-white">
            Отменить
          </Link>
          <button
            className="btn btn-success"
            disabled={this.props.isSending}
            onClick={(e) => this.onSubmit(e)}
          >
            Создать
            {this.props.isSending && (
              <i className="icon-spinner2 uploaderSpinner spinner"></i>
            )}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ChangeTaxpayerEnterpreneur);
