import React from 'react';
import PropTypes from 'prop-types';
import { currencyFormat, sumColor } from '../utils/currencyUtil';

const FinresBoard = ({ board, index, boardLength }) => (
  <React.Fragment>
    <tr>
      <td colSpan="5" className="text-center finres-tableBoardName">
        {board.name}
      </td>
    </tr>

    {board.transactions.map((transaction, index) => (
      <tr
        key={index}
        className={`${Math.abs(index % 2) == 1 ? 'tableCellOdd' : ''}`}
      >
        <td className="transactionTableData">{transaction.date}</td>
        <td
          className={`transactionTableFinres ${sumColor(transaction.amount)}`}
        >
          {currencyFormat(transaction.amount)}
        </td>
        <td className="transactionTableCommis">
          {currencyFormat(transaction.fee)}
        </td>
        <td className="transactionTableMarj">
          {currencyFormat(transaction.pnl)}
        </td>
        <td className="transactionTableProsad">{transaction.drawdown}</td>
      </tr>
    ))}
    <tr
      className={`font-weight-bold ${
        boardLength != index + 1 ? 'finresBoardTotal' : ''
      }`}
    >
      <td className="">Итого</td>
      <td className={`${sumColor(board.total.amount)}`}>
        {currencyFormat(board.total.amount)}
      </td>
      <td>{currencyFormat(board.total.fee)}</td>
      <td>{currencyFormat(board.total.pnl)}</td>
      <td></td>
    </tr>
  </React.Fragment>
);

FinresBoard.propTypes = {
  board: PropTypes.object,
  currency: PropTypes.string,
};

export default FinresBoard;
