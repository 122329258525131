import React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import InputMask from 'react-input-mask';

import { catchPromise } from '../utils/http';
import { getPhoneCode } from '../utils/utilsHttp';
import { getProduct } from '../utils/shopHttp';

export class ShopItemPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: {
        full_description: '',
      },
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
  };
  componentDidMount() {
    this.loadProduct();
  }
  loadProduct = () => {
    getProduct(this.props.match.params.key)
      .then((res) => {
        this.setState({ product: res.data });
      })
      .catch((err) => {
        catchPromise(err, this.loadProduct, this.errorCb);
      });
  };

  render() {
    return (
      <div className="content shop content-shopList">
        {!this.state.pageError ? (
          <div className="card card-content">
            <div className="card-body">
              <div>
                <b>{this.state.product.title}</b>
              </div>
              <div style={{ margin: '10px 0' }}>
                {ReactHtmlParser(
                  this.state.product.full_description.replace(
                    /(?:\r\n|\r|\n)/g,
                    '<br />'
                  )
                )}
              </div>
              <div>
                Цена: <b>{this.state.product.price} р.</b>
              </div>
              <div
                className="shopListInfo-link position-relative d-flex justify-content-between align-items-center"
                style={{ marginTop: '15px' }}
              >
                <a className="btn btnBack" href={`/shop`}>
                  Назад
                </a>
                <a
                  className="btn btn-success"
                  href={`/shop/${this.state.product.id}/buy`}
                >
                  Купить
                </a>
              </div>
            </div>
            <div className="shopContacts-link">
              <a href="/shop/contacts" target="_blank">
                Контакты
              </a>
            </div>
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
      </div>
    );
  }
}

export default connect()(ShopItemPage);
