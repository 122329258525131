import { SET_NEWS } from '../actions/news';

// Expenses Reducer
const reducerDefaultState = { news: [], page_num: 1, page_total: 1 };

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_NEWS:
      return action.news;
    default:
      return state;
  }
};
