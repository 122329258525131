import React from "react";

export default class AnketaTypeChoose extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        validate: false,
    }
  }

  action = (action, currIndex) => {
    this.setState({ validate: true });
    if (this.validating()) {
        this.props.action(action, currIndex);
      }
  }

  componentDidUpdate() {
  }

  validating = () => {
    return true;
  };

  componentDidMount() {
    console.log(this.props);
  }

  render() {
    return (
      <div className="d-flex flex-column">
        <div className="text-center">
          <h3 className="anketaTypeChooseTitle">
            Выбор Анкеты
          </h3>
          <div className="anketaTypeChoose__wrapper">
            <div className="anketaTypeChoose">
              <div className="anketaType">
                <input class="styled-checkbox" id="anketa-type-resident" type="checkbox" checked={this.props.isResident} onChange={() => !this.props.isResident && this.props.toggleAnketaType()} />
                <label for="anketa-type-resident" className="anketaTypeTitle">Резидент</label>
                <p className="anketa-type-text">
                  Гражданин РФ, у которого есть место постоянного проживания РФ (прописка)
                </p>
              </div>
              <div className="anketaType">
                <input class="styled-checkbox" id="anketa-type-noresident" type="checkbox" checked={!this.props.isResident} onChange={() => this.props.isResident && this.props.toggleAnketaType()} />
                <label for="anketa-type-noresident" className="anketaTypeTitle">Не Резидент</label>
                <p className="anketa-type-text">
                  Иностранные граждане, постоянно не проживающие на територии РФ
                </p>
              </div>
            </div>
            <br />
            <p className="anketaFillingRules">
              Перед заполнением, ознакомьтесь с <a href="https://drive.google.com/file/d/1P4oqyQAqVpSIV2c7wvFAP5F3nzRcJKf4/view" target="_blank">правилами заполнения анкеты</a>
            </p>
          </div>
          <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
            <div></div>
            <button
              className="btn btn-success"
              onClick={() => {
                this.action(this.props.next, 0);
              }}
            >
              Далее
            </button>
          </div>
        </div>
      </div>
    );
  }
}
