import axios from 'axios';
import Config from '../config/config';

export const SET_SETTINGS = 'SET_SETTINGS';

export const setSettings = (settings) => {
  const settingsCopy = {};

  for (let key of Object.keys(settings)) {
    settingsCopy[key.toUpperCase()] = settings[key];
  }

  return {
    type: SET_SETTINGS,
    settings: settingsCopy,
  }
}
