import axios from 'axios';
import Config from '../config/config';

export const getProducts = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/shop/items`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const getProduct = (id) => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/shop/items/${id}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const sendSurvey = (surveyData, lead_id) => {
  return axios({
    method: 'post',
    url: `${Config.apiUrl}/v1/leads/${lead_id}/survey/`,
    data: {data: surveyData},
    withCredentials: true,
  });
};