import axios from "axios";
import Config from "../config/config";

export const getCryptoParams = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/crypto_params/`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};