import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setUser } from "../actions/user";
import { checkAuth } from "../utils/http";

class GoogleAuthRemoveReady extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevPage: props.prevPage,
      nextPage: props.nextPage,
    };
  }

  action = (action, currIndex) => {
    this.props.action(action, currIndex);
  };

  componentWillUnmount() {
    checkAuth().then((resCheck) => {
      this.props.setUser(resCheck.data);
    });
  }

  render() {
    return (
      <div className="googleAuthAddReady">
        <div className="row">
          <h2 className="col-12 subtitle">Готово</h2>
        </div>
        <div>
          Вы отключили двухфакторную аутентификацию. Подключить новый Google
          Authenticator можно на странице профиля.
        </div>
        <div className="mt-4 text-center">
          <img src="/images/ui/check-icon.png" alt="Ready" />
          <div className="w-75 m-auto text-secondary">
            {this.props.user.user.roles.includes("trader")
              ? "Код не будет запрашиваться при входе в аккаунт. Доступ к API key и всем операциям вывода заблокирован."
              : this.props.user.user.roles.includes("bonus")
              ? "Код не будет запрашиваться при входе в аккаунт. Доступ к операциям вывода заблокирован."
              : this.props.user.user.roles.length === 0
              ? "Код не будет запрашиваться при входе в аккаунт."
              : null}
          </div>
        </div>

        <div className="btnSubmits card-content d-flex justify-content-end align-items-center">
          <Link to="/app/profile">
            <button className="btn btn-success">К профилю</button>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleAuthRemoveReady);
