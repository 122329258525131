import { SET_LAST_NEWS } from '../actions/lastNews';

// Expenses Reducer
const reducerDefaultState = [];

export default (state = reducerDefaultState, action) => {
  switch (action.type) {
    case SET_LAST_NEWS:
      return action.lastNews;
    default:
      return state;
  }
};
