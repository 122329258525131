import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { catchPromise } from '../../utils/http';
import { currencyFormat } from '../../utils/currencyUtil';
import { createDocument } from '../../utils/documentsHttp';

export class DocumentCreatePayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contracts: props.contracts || [],
      contract_id: props.contracts.length > 0 ? props.contracts[0].id : '',
      accounts: props.accounts.filter((account) => !account.demo),
      account:
        props.accounts.length > 0
          ? props.accounts.filter((account) => !account.demo)[0].id
          : '',
      sum: '',
      accountBalance:
        props.accounts.length > 0
          ? props.accounts.filter((account) => !account.demo)[0].balance
          : '',
      currency:
        props.accounts.length > 0
          ? props.accounts.filter((account) => !account.demo)[0].currency
          : 'RUR',
      calendarFocused: false,
      doneMessage: '',
      selectIsOpened: false,
      isSending: false,
      error: undefined,
      pageError: false,
    };
  }

  errorCb = () => {
    this.setState({ pageError: true });
  };

  componentDidMount() {
    $('body').on('click', () => {
      if (this.state.selectIsOpened) {
        setTimeout(() => this.setState({ selectIsOpened: false }), 100);
      }
    });
  }

  onSubmitDoc = (e) => {
    e.preventDefault();

    this.setState({ doneMessage: '' });

    if (!this.state.sum || !this.state.account) {
      this.setState({ error: 'Заполните сумму.' });
      return;
    }
    if (!this.state.contract_id) {
      this.setState({ error: 'Отсутствует номер договора' });
      return;
    }
    this.setState({ isSending: true });
    createDocument({
      code: 'GET_MONEY',
      payment_request: {
        trading_account_id: parseInt(this.state.account),
        amount: this.state.sum,
      },
      contract_id: parseInt(this.state.contract_id),
    })
      .then((res) => {
        this.setState({
          doneMessage: 'Выплата успешна произведена.',
          isSending: false,
        });
        this.props.onSubmit(res.data.doc_id);
      })
      .catch((err) => {
        this.setState({ isSending: false });

        if (err.response.data && err.response.status != 401) {
          if (
            err.response.data.message.indexOf(
              'Недостаточно средств для вывода'
            ) != -1
          ) {
            this.setState({ error: 'Недостаточно средств для вывода' });
            return;
          }
          if (
            err.response.data.message.indexOf(
              'Поле amount должно быть положительным числом'
            ) != -1
          ) {
            this.setState({
              error: 'Поле "сумма" должно быть положительным числом',
            });
            return;
          }
          if (
            err.response.data.message.indexOf('Торговый счет не найден') != -1
          ) {
            this.setState({ error: 'Торговый счет не найден' });
            return;
          }
          if (
            err.response.data.message.indexOf(
              'Минимальная сумма вывода'
            ) != -1
          ) {
            this.setState({
              error: err.response.data.message,
            });
            return;
          }
        } else catchPromise(err, this.onSubmit, this.errorCb);
      });
  };

  onContractChange = (e) => {
    const contract = e.target.value;
    this.setState({ contract });
  };
  onAccountChange = (account) => {
    const accountBalance = this.state.accounts.filter(
      (tradingAccount) => account === tradingAccount.id
    )[0].balance;
    const currency = this.state.accounts.filter(
      (tradingAccount) => account === tradingAccount.id
    )[0].currency;
    this.setState(() => ({
      account,
      accountBalance,
      currency,
      selectIsOpened: false,
    }));
  };
  onSumChange = (e) => {
    const sum = e.target.value;
    if (!sum || sum.match(/^\d*(\.\d{0,2})?$/)) {
      this.setState(() => ({ sum }));
    }
  };
  render() {
    return (
      <div className="docCreatePayment">
        {!this.state.pageError ? (
          <div className="docCreateFieldContainer">
            {this.state.contracts.length > 0 && (
              <div className="contractNameDocCancel">{`Договор №${this.state.contracts[0].number} от ${this.state.contracts[0].date}`}</div>
            )}
            <div className="customSelect-payAccContainer">
              <span>Счет</span>
              <div className="customSelect">
                <div
                  className="customSelectedItem"
                  onClick={() => {
                    this.setState({ selectIsOpened: true });
                  }}
                >
                  {
                    this.state.accounts.find(
                      (acc) => acc.id === this.state.account
                    ).name
                  }
                  <i className="toggle icon-menu-open"></i>
                </div>
                {this.state.selectIsOpened && (
                  <ul className="customSelectList">
                    {this.state.accounts.map((account) => (
                      <li
                        key={account.id}
                        onClick={() => {
                          this.onAccountChange(account.id);
                        }}
                      >
                        {account.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="contractNameDocCancel">Заявки на выплату вознаграждения принимаются с понедельника по пятницу. Выплата вознаграждения производится в первый рабочий день следующей недели.</div>
            <input
              type="text"
              className="inputUnderLine docCreatePayment-sumField"
              placeholder="Сумма вывода"
              value={this.state.sum}
              onChange={this.onSumChange}
            />
            {this.props.accounts.length > 0 && (
              <div className="docCreatePayment-sumDescr">
                <span>доступно для вывода </span>
                {this.state.accountBalance.trading && (
                  <span>
                    {currencyFormat(
                      this.state.accountBalance.trading,
                      this.state.currency
                    )}
                  </span>
                )}
              </div>
            )}
            {this.state.error && (
              <h6 className="text-center text-danger">{this.state.error}</h6>
            )}
            {this.state.doneMessage && (
              <h6 className="text-center text-success">
                {this.state.doneMessage}
              </h6>
            )}
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
        <div className="btnSubmits card-content d-flex justify-content-between align-items-center">
          <Link to="/app/documents/moving" className="btn btn-white">
            Отменить
          </Link>
          <button
            className="btn btn-success"
            disabled={this.state.isSending}
            onClick={(e) => this.onSubmitDoc(e)}
          >
            Создать
            {this.state.isSending && (
              <i className="icon-spinner2 uploaderSpinner spinner"></i>
            )}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(DocumentCreatePayment);
