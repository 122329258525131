import axios from 'axios';
import Config from '../config/config';

export const getAppFiles = () => {
  return axios({
    method: 'get',
    url: `${Config.apiUrl}/v1/versions/`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
